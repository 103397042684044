import React, { FC, ReactNode } from "react";

import { Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View, TouchableOpacity, StyleProp, ViewStyle } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import Animated from "react-native-reanimated";

import { ImageToBeUsed } from "@app/common/types/image.type";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useUploadNewImage } from "@app/hooks/utils/use-upload-new-image.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { pickImages } from "@app/utils/image-picker.util";

import { DraggableImages } from "./draggable-images/draggable-images.component";
import { ImagePickerItem } from "./image-picker-item/image-picker-item.component";
import { imageHeight, itemSize, styles, verticalGap } from "./images-picker.style";

interface Props {
  images: ImageToBeUsed[];
  legend?: ReactNode;
  allowMultipleSelection?: boolean;
  required?: boolean;
  style?: StyleProp<ViewStyle>;
  setImages: (callback: (prevImages: ImageToBeUsed[]) => ImageToBeUsed[]) => void;
  onPressAddImage?: () => void;
  onImageDeleted?: (imageUrl: string) => void;
}

export const ImagesPicker: FC<Props> = ({
  style,
  images,
  setImages,
  legend,
  allowMultipleSelection = true,
  required = true,
  onPressAddImage,
  onImageDeleted,
}) => {
  const { colors, roundness } = useTheme();
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const { uploadNewImage, loading: loadingNewImage } = useUploadNewImage();

  const userId = profile?.userId;

  const handleAddImage = async (): Promise<void> => {
    if (onPressAddImage) {
      onPressAddImage();
    } else {
      const imagesResult = await pickImages({ selectionLimit: !allowMultipleSelection ? 1 : 0 });
      if (imagesResult.error || !imagesResult.assets?.length) return;

      const imagesAsInputs = imagesResult.assets.map(f => ({ imageUrl: f.uri, imageName: f.fileName, userId, setImages }));

      await Promise.all(imagesAsInputs.map(image => uploadNewImage(image)));
    }
  };

  const handleRemoveImage = (imageUrl: string): void => {
    setImages?.(imgs => imgs.filter(i => i.imageUrl !== imageUrl));
    onImageDeleted?.(imageUrl);
  };

  const handleOrderChange = (imgs: ImageToBeUsed[]): void => {
    setImages?.(() => imgs);
  };

  return (
    <View
      style={[
        style,
        styles.root,
        ...conditionalItem<ViewStyle>(!allowMultipleSelection || images.length <= 1 || !images.length, {
          ...styles.center,
          height: imageHeight + 32,
        }),
      ]}>
      {legend}
      <Animated.ScrollView
        horizontal
        scrollEnabled={allowMultipleSelection && images.length >= 1}
        scrollEventThrottle={16}
        contentContainerStyle={[
          styles.scrollViewContainer,
          ...conditionalItem<ViewStyle>(!images.length || !allowMultipleSelection, { justifyContent: "center", paddingHorizontal: verticalGap }),
          ...conditionalItem<ViewStyle>(images.length < 1 || !allowMultipleSelection, styles.fullWidth, [{ width: (images.length + 1) * itemSize }]),
        ]}>
        <View style={[styles.rowContainer, { height: imageHeight + 32 }]}>
          {!allowMultipleSelection && images.length ? null : (
            <TouchableOpacity onPress={() => void handleAddImage()} disabled={loadingNewImage}>
              <View
                style={[
                  styles.rowItem,
                  styles.addContainer,
                  { borderColor: colors.outline, backgroundColor: colors.surfaceVariant, borderRadius: roundness },
                ]}>
                {loadingNewImage ? <ActivityIndicator color={colors.outline} /> : <Plus color={colors.outline} />}
                <Text variant="labelSmall" textAlign="center" style={styles.addImageText}>
                  {t("studioMini.addImage", { context: required === false ? "optional" : undefined })}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          {allowMultipleSelection && images.length ? (
            <DraggableImages images={images} onImageOrderChange={handleOrderChange} onRemove={handleRemoveImage} />
          ) : null}
          {!allowMultipleSelection && images.length ? (
            <View key={images[0].imageUrl}>
              <ImagePickerItem imageUrl={images[0].imageUrl} onRemove={handleRemoveImage} />
            </View>
          ) : null}
        </View>
      </Animated.ScrollView>
    </View>
  );
};
