import React, { FC, useMemo } from "react";

import { LinkSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { TextInput as RNTextInput } from "react-native-paper";

import TwitterIcon from "@app/assets/logos/twitter-logo.svg";
import { UpdateProfileInput } from "@app/common/graphql/generated/schema.graphql";
import { Routes, ProfileStackScreenProps } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { EditProfilePicture } from "@app/components/profile/edit-profile-picture/edit-profile-picture.component";
import { LinkInstagramAccountButton } from "@app/components/profile/link-intagram-account-button/link-instagram-account-button.component";
import { useProfileContext } from "@app/context/profile/profile.context";

import { styles } from "./edit-profile.style";

export const EditProfileScreen: FC<ProfileStackScreenProps<Routes.editProfile>> = ({ navigation }) => {
  const { t } = useTranslation();

  const { profile, loading: profileLoading } = useProfileContext();

  const handleEditField = (fieldName: keyof UpdateProfileInput): void => {
    const multipleValues = fieldName === "tags" || fieldName === "pronouns";
    navigation.navigate(Routes.editProfileField, { fieldName, multipleValues });
  };

  const commonInputProps = useMemo(
    () => ({
      editable: false,
      loading: profileLoading,
    }),
    [profileLoading],
  );

  const commonLinkButtonProps: Omit<ButtonProps, "children"> = {
    fullWidth: true,
    size: "large",
    contentStyle: styles.linkButton,
  };

  return (
    <ScreenWrapper withScrollView contentContainerStyle={styles.root}>
      <EditProfilePicture />
      <TextInput
        label={t("userInfo.fields.displayName.label")}
        value={profile?.displayName ?? ""}
        {...commonInputProps}
        onPressIn={() => handleEditField("displayName")}
      />
      <TextInput
        label={t("userInfo.fields.username.label")}
        value={profile?.username ?? ""}
        left={color => <RNTextInput.Affix text="@" textStyle={{ color }} />}
        {...commonInputProps}
        onPressIn={() => handleEditField("username")}
      />
      <TextInput
        label={t("userInfo.fields.occupation.label")}
        value={profile?.occupation ?? ""}
        {...commonInputProps}
        onPressIn={() => handleEditField("occupation")}
      />
      <TextInput
        label={t("userInfo.fields.pronouns.label")}
        value={profile?.pronouns.join("/") ?? ""}
        {...commonInputProps}
        onPressIn={() => handleEditField("pronouns")}
      />
      <TextInput label={t("userInfo.fields.bio.label")} value={profile?.bio ?? ""} {...commonInputProps} onPressIn={() => handleEditField("bio")} />
      <TextInput
        label={t("userInfo.fields.tags.label")}
        value={profile?.tags.join(", ") ?? ""}
        multiline
        {...commonInputProps}
        onPressIn={() => handleEditField("tags")}
      />

      <View style={styles.linksContainer}>
        <Text variant="inputText">{t("userInfo.fields.links.label")}</Text>
        <Button icon={LinkSimple} {...commonLinkButtonProps} onPress={() => handleEditField("externalLink")}>
          {profile?.externalLink ?? t("userInfo.fields.externalLink.label")}
        </Button>
        <Button
          icon={({ size, ...iconProps }) => <TwitterIcon height={size} width={size} {...iconProps} />}
          {...commonLinkButtonProps}
          onPress={() => handleEditField("twitterLink")}>
          {profile?.twitterLink ?? t("userInfo.fields.twitterLink.label")}
        </Button>
        <LinkInstagramAccountButton />
      </View>
    </ScreenWrapper>
  );
};
