import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { GenericDialog } from "@app/components/common/generic-dialog/generic-dialog.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";

import { StartCampaignButton } from "../start-campaign-button/start-campaign-button.component";
import { StartCampaignInfo } from "../start-campaign-info/start-campaign-info.component";

interface Props {
  collab: Collab;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const StartCampaignDialog: FC<Props> = ({ collab, visible, setVisible }) => {
  const { t } = useTranslation();
  const { config } = useConfigContext();

  const likeCountObjective = config?.likeCountObjective;

  const {
    collabId,
    creator: { userId },
    images,
    progress,
  } = collab;
  const imageUrl = images?.[0]?.imageUrl;

  const title = progress.canRequestQuote
    ? t("startCampaign.dialog.title.canRequestQuote", { likeCountObjective })
    : t("startCampaign.dialog.title.canStartCampaign");

  return (
    <GenericDialog
      title={textProps => <Text {...textProps}>{title}</Text>}
      caption={textProps => <StartCampaignInfo collab={collab} {...textProps} />}
      imageUrl={imageUrl ?? ""}
      buttons={[buttonProps => <StartCampaignButton {...buttonProps} collabId={collabId} creatorId={userId} progress={progress} />, undefined]}
      dismissLabel={t("startCampaign.dialog.notNow")}
      visible={visible}
      setVisible={setVisible}
    />
  );
};
