import React, { FC, useCallback, useMemo } from "react";

import { Skeleton } from "moti/skeleton";
import { CaretRight, Placeholder } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ListRenderItem } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useTheme } from "react-native-paper";

import { CategoryWithCount, PaginatedCategoriesWithCount } from "@app/common/graphql/generated/schema.graphql";
import { Box } from "@app/components/common/box/box.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";

import { styles } from "./studio-select-product-category.style";

interface Props {
  onPress: (category: CategoryWithCount) => void;
  categories?: PaginatedCategoriesWithCount;
  loading: boolean;
}

export const StudioSelectProductCategory: FC<Props> = ({ onPress, categories, loading }) => {
  const { t } = useTranslation();
  const { colors, roundness, dark } = useTheme();

  const emptyComponent = useMemo(
    () =>
      loading && !categories ? (
        <Box rowGap={8}>
          {Array.from(Array(2).keys()).map(key => (
            <Skeleton key={key} width="100%" radius={roundness} height={40} colorMode={dark ? "dark" : "light"} />
          ))}
        </Box>
      ) : (
        <EmptyState icon={({ color }) => <Placeholder color={color} size={32} />} message={t("emptyState.categories.message")} />
      ),
    [categories, dark, loading, roundness, t],
  );

  const renderItem = useCallback<ListRenderItem<CategoryWithCount>>(
    ({ item }) => (
      <ListItem
        title={item.name}
        imageProps={{ image: item.imageUrl, size: 40, style: { borderRadius: roundness } }}
        titleProps={{ variant: "subtitle1", textTransform: "capitalize" }}
        onPress={() => onPress(item)}
        right={<CaretRight color={colors.primary} weight="thin" />}
      />
    ),
    [colors.primary, onPress, roundness],
  );

  return (
    <FlatList
      data={categories?.nodes?.filter(cat => cat.catalogProductsCount)}
      keyExtractor={item => item.categoryId}
      contentContainerStyle={styles.listContainer}
      ListEmptyComponent={emptyComponent}
      renderItem={renderItem}
    />
  );
};
