import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, JSX } from "react";

import { ArrowLeft } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { Keyboard, ListRenderItem, View, ViewStyle } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { IconButton } from "react-native-paper";

import { StudioBottomSheetStep, StudioFlowType } from "@app/common/enums/studio-flow-type.enum";
import { CategoryWithCount } from "@app/common/graphql/generated/schema.graphql";
import { NestedAnimatedView } from "@app/components/common/bottom-sheet/bottom-sheet.common";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button } from "@app/components/common/button/button.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useCategories } from "@app/hooks/api/use-categories.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./bottom-sheet-studio-generation.style";
import { getInitialFlow, getInitialStep } from "./bottom-sheet-studio-generation.util";
import { InputPrompt } from "./input-prompt/input-prompt.component";
import { StudioSelectFlow } from "./studio-select-flow/studio-select-flow.component";
import { StudioSelectImageSourceStep } from "./studio-select-image-source/studio-select-image-source.component";
import { StudioSelectProduct } from "./studio-select-product/studio-select-product.component";
import { StudioSelectProductCategory } from "./studio-select-product-category/studio-select-product-category.component";
import { StudioSavedImagesStep } from "./studio-select-saved-image/studio-select-saved-image.component";

interface StepInterface {
  content: JSX.Element;
  id: StudioBottomSheetStep;
  title: string;
  onBack?: () => void;
  rootStyle: ViewStyle;
}

interface Props {
  onPressGenerate: () => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function
export const BottomSheetStudioGeneration = forwardRef<BottomSheetRefProps, Props>(({ onPressGenerate }, ref) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { profile } = useProfileContext();
  const { generation, setGeneration } = useStudioMiniContext();

  const listRef = useRef<FlatList<StepInterface>>(null);

  const [step, setStep] = useState(StudioBottomSheetStep.selectFlow);
  const [flow, setFlow] = useState<StudioFlowType | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<CategoryWithCount | null>(null);
  const [studioBottomSheetWebRef, setStudioBottomSheetWebRef] = useState<BottomSheetRefProps | null>(null);

  const { data: categories, loading: loadingCategories } = useCategories({ skip: !profile?.userId });

  const bottomSheetRef = useCallback((node: BottomSheetRefProps | null) => {
    if (node) {
      setStudioBottomSheetWebRef(node);
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({ open: () => studioBottomSheetWebRef?.open(), close: () => studioBottomSheetWebRef?.close(), isOpen: !!studioBottomSheetWebRef?.isOpen }),
    [studioBottomSheetWebRef],
  );

  const handleSelectFlow = useCallback((newFlow: StudioFlowType): void => {
    setFlow(newFlow);

    if (newFlow === StudioFlowType.blank) {
      setStep(StudioBottomSheetStep.prompt);
    } else {
      setStep(StudioBottomSheetStep.catalog);
    }
  }, []);

  const handleSelectCategory = useCallback((category: CategoryWithCount): void => {
    setSelectedCategory(category);
    setStep(StudioBottomSheetStep.catalogProduct);
  }, []);

  const handleGoToFirstStep = useCallback((): void => {
    setFlow(null);
    setStep(StudioBottomSheetStep.selectFlow);
    setGeneration(prev => ({ ...prev, image: undefined, catalogProduct: undefined }));
  }, [setGeneration]);

  const handleOnClear = (): void => {
    setGeneration(prev => ({ ...prev, prompt: "", catalogProduct: undefined, image: undefined }));
    setFlow(StudioFlowType.blank);
  };

  const setInitialPosition = useCallback((): void => {
    if (step === StudioBottomSheetStep.prompt) return;

    setStep(getInitialStep(generation));
    setFlow(getInitialFlow(generation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generation, width]);

  useEffect(() => {
    isWeb ? listRef.current?.scrollToOffset({ offset: step * width, animated: false }) : listRef.current?.scrollToIndex({ index: step });
  }, [step, width]);

  useEffect(() => {
    setInitialPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioBottomSheetWebRef?.isOpen]);

  const renderSlide = useCallback<ListRenderItem<StepInterface>>(
    ({ item }) => (
      <NestedAnimatedView isVisible={item.id === step} width={width}>
        <View style={item.rootStyle}>{item.content}</View>
      </NestedAnimatedView>
    ),
    [step, width],
  );

  const slides = useMemo<StepInterface[]>(
    () => [
      {
        id: StudioBottomSheetStep.selectFlow,
        title: t("studioMini.prompt.wizard.imagineDream"),
        content: <StudioSelectFlow onSelectFlow={handleSelectFlow} hideCatalogFlow={!!categories?.nodes?.length} />,
        rootStyle: styles.slideWrapper,
      },
      {
        id: StudioBottomSheetStep.catalog,
        title: flow === StudioFlowType.image ? t("studioMini.prompt.wizard.addImage") : t("studioMini.prompt.wizard.catalog"),
        content:
          flow === StudioFlowType.image ? (
            <StudioSelectImageSourceStep
              onImageSelected={() => setStep(StudioBottomSheetStep.prompt)}
              onNavigateToSaved={() => setStep(StudioBottomSheetStep.catalogProduct)}
            />
          ) : (
            <StudioSelectProductCategory categories={categories} loading={loadingCategories} onPress={handleSelectCategory} />
          ),
        onBack: handleGoToFirstStep,
        rootStyle: flow === StudioFlowType.image ? styles.slideFullScreen : styles.slideWrapper,
      },
      {
        id: StudioBottomSheetStep.catalogProduct,
        title: flow === StudioFlowType.image ? t("studioMini.prompt.wizard.addImage") : selectedCategory?.name ?? "",
        content:
          flow === StudioFlowType.image ? (
            <StudioSavedImagesStep onUseImage={() => setStep(StudioBottomSheetStep.prompt)} />
          ) : (
            <StudioSelectProduct category={selectedCategory} onSelectProduct={() => setStep(StudioBottomSheetStep.prompt)} />
          ),
        onBack: () => {
          setGeneration(prev => ({ ...prev, image: undefined, catalogProduct: undefined }));
          setStep(StudioBottomSheetStep.catalog);
        },
        rootStyle: flow === StudioFlowType.image ? styles.slideFullScreen : styles.slideWrapper,
      },
      {
        id: StudioBottomSheetStep.prompt,
        title: t("studioMini.prompt.wizard.writePrompt"),
        content: (
          <InputPrompt onPressGenerate={onPressGenerate} withImage={flow === StudioFlowType.image} onClear={() => setFlow(StudioFlowType.blank)} />
        ),
        onBack: () => {
          Keyboard.dismiss();
          if (flow === StudioFlowType.blank) {
            handleGoToFirstStep();
          } else if (flow === StudioFlowType.catalog) {
            setStep(StudioBottomSheetStep.catalogProduct);
          } else {
            setGeneration(prev => ({ ...prev, image: undefined }));
            setStep(StudioBottomSheetStep.catalog);
          }
        },
        rootStyle: styles.slideWrapper,
      },
    ],
    [
      categories,
      flow,
      handleGoToFirstStep,
      handleSelectCategory,
      handleSelectFlow,
      loadingCategories,
      onPressGenerate,
      selectedCategory,
      setGeneration,
      t,
    ],
  );

  return (
    <BottomSheet
      ref={bottomSheetRef}
      enablePanDownToClose={false}
      backdropComponent={null}
      animateOnMount={isWeb ? !generation.taskIds : false}
      index={!isWeb && generation.taskIds ? -1 : undefined}
      isModal={false}
      title={slides[step].title}
      leftHeader={
        slides[step].onBack ? (
          <IconButton icon={({ color, size }) => <ArrowLeft color={color} size={size} />} onPress={slides[step].onBack} />
        ) : undefined
      }
      rightHeader={
        step === StudioBottomSheetStep.prompt && (
          <Button disabled={!generation.prompt && !generation.catalogProduct && !generation.image} onPress={handleOnClear}>
            {t("cta.clear")}
          </Button>
        )
      }
      scrollable
      contentContainerStyle={styles.bottomSheetContainer}>
      <FlatList
        ref={listRef}
        initialNumToRender={slides.length}
        data={slides}
        contentContainerStyle={isWeb ? { width } : {}}
        keyExtractor={item => `studio-generation-prompt-wizard-slide-${item.id.toString()}`}
        horizontal
        scrollEnabled={false}
        pagingEnabled
        renderItem={renderSlide}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        getItemLayout={(_data, index) => ({ length: width, offset: index * width, index })}
      />
    </BottomSheet>
  );
});

BottomSheetStudioGeneration.displayName = "BottomSheetStudioGeneration";
