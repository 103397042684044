import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { InstagramPictureFilteringArgs, PaginatedInstagramPictures } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface Response {
  instagramPictures: PaginatedInstagramPictures;
}

export interface Variables {
  where: InstagramPictureFilteringArgs;
}

const instagramPicturesQuery = gql`
  query InstagramPictures($where: InstagramPictureFilteringArgs!) {
    instagramPictures(where: $where) {
      nodes {
        imageUrl
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`;

export function useInstagramPictures(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "instagramPictures"> {
  const { data, loading, error } = useQuery<Response, Variables>(instagramPicturesQuery, {
    ...options,
  });

  return { data: data?.instagramPictures, loading, error };
}
