import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { ClientName } from "@app/common/enums/client-name.enum";
import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  uploadStudioImage: StudioImage;
}

interface Variables {
  input: FormData;
  userId: string;
}

interface UploadStudioImageResult extends MutationResult<Response, "uploadStudioImage"> {
  uploadImage: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const uploadStudioImageMutation = gql`
  # eslint-disable-next-line @graphql-eslint/known-type-names
  mutation UploadStudioImage($input: UploadImageInput!, $userId: String!) {
    # eslint-disable-next-line @graphql-eslint/fields-on-correct-type
    uploadStudioImage(input: $input, userId: $userId)
      @rest(bodyKey: "input", bodySerializer: "fileEncode", method: "PUT", path: "/studio_images/{args.userId}/image", type: "File") {
      ...CoreStudioImageFields
    }
  }
  ${coreStudioImageFields}
`;

export function useUploadStudioImage(): UploadStudioImageResult {
  const [uploadImage, { loading, error, data }] = useMutation<Response, Variables>(uploadStudioImageMutation, {
    context: { clientName: ClientName.bloomRest },
  });

  return { uploadImage, loading, error, data: data?.uploadStudioImage };
}
