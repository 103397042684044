import { SENTRY_DNS, APP_ENV, NODE_ENV, ENABLE_SENTRY } from "@env";
import * as Sentry from "@sentry/react-native";
import Constants from "expo-constants";
import * as Device from "expo-device";

import { platformHeaderValue } from "@app/context/mixpanel/default-values.const";

import { getAppVersion } from "./app.util";
import { toBoolean } from "./boolean.util";
import { crashlytics } from "./crashlytics/crashlytics.util";
import { isWeb } from "./device.util";
import { log } from "./logger/logger.util";

export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation({
  enableTimeToInitialDisplay: true,
});

const allTags: Record<string, string | null | number> = {
  version: getAppVersion(),
  platform: platformHeaderValue,
  modelName: Device.modelName,
  deviceType: Device.deviceType,
  osVersion: Device.osVersion,
  totalMemory: Device.totalMemory,
  deviceId: Constants.sessionId,
  appOwnership: Constants.appOwnership || "N/A",
};

export function initSentry(): void {
  log.debug("🪿 Initializing Sentry 🍌");

  Sentry.init({
    attachScreenshot: true,
    debug: NODE_ENV !== "production",
    denyUrls: [/^chrome-extension:\/\/.*/],
    dsn: SENTRY_DNS,
    enabled: toBoolean(ENABLE_SENTRY, true),
    environment: APP_ENV,
    integrations: [new Sentry.ReactNativeTracing({ routingInstrumentation })],
    release: getAppVersion(),
    tracesSampleRate: 0.2,
  });

  for (const key in allTags) {
    const value = allTags[key];

    Sentry.setTag(key, value);
    if (!isWeb && value) {
      void crashlytics.setTag(key, typeof value === "number" ? value.toString() : value);
    }
  }

  if (Constants.appOwnership === "expo" && Constants.expoVersion) {
    Sentry.setTag("expoAppVersion", Constants.expoVersion);
  }
}
