import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  horizontalPadding: {
    paddingHorizontal: 20,
  },
  listItem: {
    padding: 20,
  },
  minorRowGap: {
    rowGap: 4,
  },
  root: {
    paddingVertical: 20,
    rowGap: 16,
  },
  rowGap: {
    rowGap: 8,
  },
});
