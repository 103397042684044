import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  image: {
    position: "absolute",
  },
  root: {
    rowGap: 8,
  },
  textContainer: {
    paddingHorizontal: 20,
    rowGap: 8,
  },
});
