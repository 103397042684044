import React, { FC, ReactNode } from "react";

import { LinkBreak } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import InstagramIcon from "@app/assets/logos/instagram-logo.svg";
import { transparent } from "@app/common/style/theme";
import { Alert } from "@app/components/common/alert/alert.component";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useGenerateInstagramLoginUrl } from "@app/hooks/api/use-generate-instagram-login.hook";
import { useDeleteInstagramAccountData } from "@app/hooks/api/use-remove-instagram-login.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

import { styles } from "./link-instagram-account-button.style";

interface Props extends Omit<ButtonProps, "children"> {
  children?: ReactNode;
  hideLinkingWarning?: boolean;
}

export const LinkInstagramAccountButton: FC<Props> = ({ children, contentStyle, containerStyle, hideLinkingWarning, ...buttonProps }) => {
  const { t } = useTranslation();
  const { navigateToWebview } = useNavigateToWebview();
  const { showErrorSnackbar } = useSnackbarContext();
  const { colors } = useTheme();

  const { profile, updateState } = useProfileContext();
  const { generateInstagramLoginUrl, loading: loadingGenerateInstagramUrl } = useGenerateInstagramLoginUrl();
  const { deleteInstagramAccountData, loading: loadingDeleteInstagramLink } = useDeleteInstagramAccountData();

  const handleUnlinkInstagram = (): void => {
    if (!profile?.userId || !profile?.instagramUsername) return;

    Alert.alert(t("userInfo.fields.instagramLink.remove"), t("userInfo.fields.instagramLink.confirm"), [
      { text: t("cta.cancel"), style: "cancel" },
      {
        text: t("cta.confirm"),
        style: "destructive",
        onPress: () =>
          void deleteInstagramAccountData({
            variables: { userId: profile.userId },
            onCompleted: () => {
              updateState({
                input: { [UserStateEnum.linkInstagramAccountFullScreenDismissed]: true, [UserStateEnum.linkInstagramAccountDismissed]: true },
              });
            },
            onError: error => showErrorSnackbar({ error }),
          }),
      },
    ]);
  };

  const handleLinkInstagram = (): void => {
    if (!profile?.userId || profile?.instagramUsername) return;

    void generateInstagramLoginUrl({
      variables: { userId: profile.userId },
      onCompleted: link => {
        updateState({
          input: { [UserStateEnum.linkInstagramAccountFullScreenDismissed]: true, [UserStateEnum.linkInstagramAccountDismissed]: true },
        });
        navigateToWebview(link.generateInstagramLoginUrl.url, "Instagram");
      },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <View>
      <View style={styles.row}>
        <Button
          size="large"
          loading={loadingGenerateInstagramUrl}
          icon={({ size, ...iconProps }) => <InstagramIcon height={size} width={size} {...iconProps} />}
          onPress={handleLinkInstagram}
          containerStyle={[styles.buttonContainer, containerStyle]}
          contentStyle={[styles.button, contentStyle]}
          rippleColor={profile?.instagramUsername ? transparent : undefined}
          {...buttonProps}>
          {children ?? profile?.instagramUsername ?? t("userInfo.fields.instagramLink.add")}
        </Button>
        {!!profile?.instagramUsername && (
          <IconButton
            icon={() => (loadingDeleteInstagramLink ? <ActivityIndicator /> : <LinkBreak size={24} color={colors.onBackground} />)}
            onPress={handleUnlinkInstagram}
            style={styles.iconButton}
          />
        )}
      </View>
      {!profile?.instagramUsername && !hideLinkingWarning && (
        <Text variant="helperText" color="tertiary">
          {t("userInfo.fields.instagramLink.linkingWarning")}
        </Text>
      )}
    </View>
  );
};
