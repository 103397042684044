/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines */

import { BrowseOption } from "@app/common/enums/browse-option.enum";
import { FilterCategory } from "@app/common/enums/filter-category.enum";
import {
  AvailablePurchaseMethod,
  Badge,
  CollabCampaignStatus,
  ContestRewardType,
  ContestStatus,
  MilestoneType,
  OrderStatus,
  Stage,
} from "@app/common/graphql/generated/schema.graphql";
import { isIos } from "@app/utils/device.util";

export default {
  aiStudio: "AI Studio",
  app: {
    appVersion: "App version {{version}}",
    description: "Where your dream collabs come to life",
  },
  browseOption: {
    [BrowseOption.concepts]: {
      title: `$t(products.stage.${Stage.concept})`,
    },
    [BrowseOption.contests]: {
      title: "Contests",
    },
    [BrowseOption.sellables]: {
      title: `$t(products.stage.${Stage.forSale})`,
    },
    [BrowseOption.underReview]: {
      title: `$t(products.stage.${Stage.underReview})`,
    },
    [BrowseOption.users]: {
      title: "Creators",
    },
  },
  cart: {
    addedToCart: "Added to cart!",
    cartItemUpdated: "Cart item updated!",
    items: "Items",
    label: "Cart",
    soldOut: "Sold out",
    unavailable: "Unavailable",
    viewProductDetails: "View item details",
  },
  comments: {
    commentsWithCount: "Comments ({{commentCount}})",
    label: "Comments",
    now: "Now",
    placeholder: "Leave a comment...",
    signIn: "Sign in to comment",
  },
  confirmation: {
    deleteCollab: {
      message: "$t(confirmation.deleteItem.message)",
      title: "$t(confirmation.deleteItem.title)",
    },
    deleteCollabUpdate: {
      message: "$t(confirmation.deleteItem.message)",
      title: "$t(confirmation.deleteItem.title)",
    },
    deleteItem: {
      message: "Once deleted, you will not be able to retrieve it",
      title: "Delete item?",
    },
    enterContestPassword: {
      message: "To enter this contest, a password is required. Only eligible participants who have received a password via email may participate.",
      password: "Password",
      title: "Password required",
    },
    report: {
      inputHelper: "Explain why (Optional)",
      message: "Your report is anonymous and will be reviewed shortly",
      successCollab: "Item reported",
      successComment: "Comment reported",
      successUser: "User reported",
      titleCollab: "Report item?",
      titleComment: "Report comment?",
      titleUser: "Report user?",
    },
  },
  contest: {
    contestEnded: "Contest ended",
    contestStarting: "Contest starting",
    contestWinner: "Contest winner",
    description: "Description",
    disclaimer: {
      label: "Disclaimer",
      text: "This contest is independently organized and is not sponsored, endorsed, or administered by {{appleInc}}any third party entities. Participation in this contest is subject to our <tclink>terms and conditions</tclink>.",
    },
    entry: "Contest entry",
    imageSource: {
      useStudio: "Create with $t(aiStudio) tool",
    },
    label: "{{name}} contest",
    leaderboard: "Leaderboard",
    nbDaysLeft: "left",
    nbSubmissions_one: "{{count}} submission",
    nbSubmissions_other: "{{count}} submissions",
    noStarted: "Not started",
    participate: "Participate",
    participateInContest: "Participate in contest",
    postEntry: "Post entry",
    prize: "Prize",
    results: "Results",
    viewContestDetails: "View contest details",
    viewEntries: "View entries",
    viewOtherEntries: "View other entries",
    vote: "Vote",
  },
  cta: {
    add: "Add",
    addToCart: "Add to cart",
    addToCartWithPrice: "Add to cart - <price/>",
    apply: "Apply",
    autogenerate: "Let AI give you a head start",
    buyNow: "Buy now",
    buyNowWithPrice: "Buy now - <price/>",
    cancel: "Cancel",
    checkout: "Checkout",
    checkoutWithPrice: "Checkout - {{price}}",
    clear: "Clear",
    confirm: "Confirm",
    confirmWithPrice: "Confirm - {{price}}",
    contactUs: "Contact us",
    continue: "Continue",
    copyLink: "Copy link",
    createCollab: "Create Collab",
    delete: "Delete",
    deleteCollab: "Delete Collab",
    dismiss: "Dismiss",
    done: "Done",
    edit: "Edit",
    filter: "Filter",
    gotIt: "Got it!",
    learnMore: "Learn more",
    like: "Like",
    like_false: "Like",
    like_true: "Liked",
    login: "Log in",
    logout: "Log out",
    more: "More",
    next: "Next",
    no: "No",
    ok: "Ok",
    open: "Open",
    openSettings: "Open settings",
    openStore: "Open store",
    pickAVariant: "Pick a variant (e.g. size)",
    post: "Post",
    refresh: "Refresh",
    remove: "Remove",
    report: "Report",
    reserve: "Reserve",
    reserveWithPrice: "Reserve - <price/>",
    save: "Save",
    saveAddress: "Save address",
    search: "Search",
    share: "Share",
    shareConcept: "Share concept",
    shareImage: "Share image",
    signIn: "Sign in",
    skip: "Skip",
    sort: "Sort",
    submit: "Submit",
    tryAgain: "Try again",
    update: "Update",
    updateCollab: "Update Collab",
    viewCollabUpdateDetails: "View update details",
    viewContestDetails: "View contest details",
    viewDetails: "View details",
    viewItemDetails: "View item details",
    viewOrders: "View orders",
    wouldBuy: "Would buy",
    wouldNotBuy: "Wouldn't buy",
    yes: "Yes",
  },
  dateTimePicker: {
    addDateTime: "Add {{fieldLabel}} time",
    resetDateTime: "Reset {{fieldLabel}} time",
  },
  downloadPrompt: {
    app: "Off/Script App",
    howToProceed: "How would you like to proceed?",
    joinUser: "Join {{username}} on the App",
    stayInBrowser: "Stay in the browser",
  },
  emptyState: {
    cart: {
      cta: "Browse feed",
      message: "Your cart is currently empty",
    },
    catalogProducts: {
      message: "No catalog product to display",
    },
    categories: {
      message: "No categories to display",
    },
    collabs: {
      cta: "Start creating",
      message: "Use our Studio tool to create, post and share your concept ideas",
    },
    comments: {
      message: "Be the first to comment!",
    },
    contestEntries: {
      message: "No contest entries yet",
    },
    creations: "No creations",
    [`contests_${ContestStatus.completed}`]: "No completed contests",
    [`contests_${ContestStatus.inProgress}`]: "No active contests",
    [`contests_${ContestStatus.notStarted}`]: "No contests that are not started yet",
    instagram: {
      cta: "Link Instagram",
      message:
        "Enhance your O/S profile by seamlessly linking your Instagram account. This not only showcases your vibrant IG pictures but also enables us to stay connected and reach out to you if your concept gains momentum!",
    },
    likes: {
      [`cta_${Stage.concept}`]: `Browse $t(products.stage.${Stage.concept}, lowercase)`,
      [`cta_${Stage.forSale}`]: `Browse $t(products.stage.${Stage.forSale}, lowercase)`,
      [`cta_${Stage.underReview}`]: `Browse $t(products.stage.${Stage.underReview}, lowercase)`,
      [`message_${Stage.concept}`]: `The $t(products.stage.${Stage.concept}, lowercase) you like will show up here`,
      [`message_${Stage.forSale}`]: `The items $t(products.stage.${Stage.forSale}, lowercase) that you like will show up here`,
      [`message_${Stage.underReview}`]: `The $t(products.stage.${Stage.underReview}, lowercase) you like will show up here`,
    },
    notifications: {
      message: "Your notifications will show up here",
    },
    orders: {
      cta: "Browse feed",
      message: "Items you buy will show up here",
    },
    profile: {
      cta: "Edit profile",
      message: "Start personalizing your account!",
    },
    savedImages: {
      cta: "Open $t(aiStudio)",
      message: "Your saved shots will show up here",
    },
    updates: {
      cta: "Add an update",
      message: "No updates yet",
      message_asOwner: "Your updates will show up here",
    },
  },
  enum: {
    badge: {
      [Badge.staff]: "O/S Staff",
      [Badge.supercreator]: "Supercreator",
      [Badge.superseller]: "Superseller",
      [Badge.supercreatorAndSuperseller]: "Superstar",
      [Badge.vettedCreator]: "Vetted creator",
    },
    collabCampaignStatus: {
      [`${CollabCampaignStatus.cancelled}_short`]: "Closed",
      [CollabCampaignStatus.cancelled]: "Closed campaign",
      [CollabCampaignStatus.inProgress]: "Ongoing campaign",
      [CollabCampaignStatus.successful]: "Successful",
    },
    contestRewardType: {
      [ContestRewardType.becomeCreator]: "Vetted creator status",
      [ContestRewardType.custom]: "{{value}}",
      [ContestRewardType.money]: "{{value}} cash prize",
      [ContestRewardType.supercoin]: "${{value}} in Supercoins",
      [ContestRewardType.token]: "{{value}} Studio tokens",
    },
    contestStatus: {
      [ContestStatus.completed]: "Past",
      [ContestStatus.inProgress]: "Active",
      [ContestStatus.notStarted]: "Not started",
    },
    filterCategory: {
      [FilterCategory.category]: "Category",
      [FilterCategory.creator]: "Creator",
      [FilterCategory.isCreator]: "Only show verified creators",
      [FilterCategory.stage]: "Stage",
    },
    milestoneType: {
      [`${MilestoneType.collabLikeMilestone}_one`]: "Get <bold>{{count}}</bold> total like",
      [`${MilestoneType.collabLikeMilestone}_other`]: "Get <bold>{{count}}</bold> total likes",
      [`${MilestoneType.collabLikeMilestone}_recurring_one`]: "Get <bold>{{count}}</bold> like",
      [`${MilestoneType.collabLikeMilestone}_recurring_other`]: "Get <bold>{{count}}</bold> likes",
      [`${MilestoneType.collabMilestone}_one`]: "Post <bold>{{count}}</bold> concept",
      [`${MilestoneType.collabMilestone}_other`]: "Post <bold>{{count}}</bold> concepts",
      [`${MilestoneType.commentMilestone}_one`]: "Leave <bold>{{count}}</bold> comment",
      [`${MilestoneType.commentMilestone}_other`]: "Leave <bold>{{count}}</bold> comments",
      [`${MilestoneType.launchCampaignMilestone}_one`]: "Launch <bold>{{count}}</bold> campaign",
      [`${MilestoneType.launchCampaignMilestone}_other`]: "Launch <bold>{{count}}</bold> campaigns",
      [`${MilestoneType.preorderPuchaseMilestone}_one`]: "Back <bold>{{count}}</bold> successful campaign",
      [`${MilestoneType.preorderPuchaseMilestone}_other`]: "Reserve <bold>{{count}}</bold> successful campaigns",
      [`${MilestoneType.referralMilestone}_one`]: "Refer <bold>{{count}}</bold> friend",
      [`${MilestoneType.referralMilestone}_other`]: "Refer <bold>{{count}}</bold> friends",
      [`${MilestoneType.shareMilestone}_one`]: "Share <bold>{{count}}</bold> concept",
      [`${MilestoneType.shareMilestone}_other`]: "Share <bold>{{count}}</bold> concepts",
      [`${MilestoneType.swipeMilestone}_one`]: "Swipe <bold>{{count}}</bold> time",
      [`${MilestoneType.swipeMilestone}_other`]: "Swipe <bold>{{count}}</bold> times",
    },
    orderStatus: {
      [OrderStatus.cancelled]: "Cancelled",
      [OrderStatus.open]: "Open",
      [OrderStatus.confirmed]: "Confirmed",
      [OrderStatus.inProgress]: "In progress",
      [OrderStatus.onHold]: "On hold",
      [OrderStatus.scheduled]: "Scheduled",
      [OrderStatus.shipped]: "Shipped",
      paymentDeclined: "Payment declined",
    },
    sort: {
      createdAtAsc: "Oldest first",
      createdAtDesc: "Newest first",
      likeCountDesc: "Most popular",
      popular: "Most popular",
      priceAsc: "Least to most expensive",
      priceDesc: "Most to least expensive",
    },
    stage: {
      [Stage.concept]: "Concept",
      [Stage.contest]: "Contest entry",
      [Stage.forSale]: "For sale",
      [Stage.hidden]: "Hidden",
      [Stage.underReview]: "Campaign",
    },
  },
  error: {
    alreadySuperseller: "You're already a superseller!",
    cannotStartCampaignYet: "You cannot start a campaign for this concept.",
    generic: "Something went wrong",
    instagramShare: {
      alertDescription: "This sharing method requires us to download the image first",
      alertTitle: "Allow Off/Script to download photos",
    },
    invalidCartItems: "Make sure to pick a variant (e.g. size) if required and remove all sold out items",
    invalidFormat: "Invalid format.",
    invalidImageFileType: "Image size must be under 10MB and we only accept the following file types: gif, jpeg, png, webp.",
    invalidReferralLink: "Invalid referral link",
    invalidUsername: "Invalid format. It can only contain letters, numbers, underscores, and dots.",
    label: "Error",
    login: {
      fromChina: "Some app functionalities are blocked in China. Unfortunately, this is outside of our control.",
      generic: "Sorry, we couldn't log you in. Please try again",
      popupBlocked: "The login popup was blocked. Please check your browser's settings and try again.",
    },
    maintenance: {
      message: "We're currently making some fixes to make your experience even better.\n\nPlease come back later.",
      title: "Maintenance in progress",
    },
    maxContestSubmissionsReached: "You've already submitted {{max}} concepts for this contest!",
    maxImages: "You can only have a maximum of {{maxImages}} images!",
    missingRequiredFields: "You are missing some required fields",
    noInternet: "No internet connection",
    noProfile: "We couldn't fetch your profile! Try again later.",
    notFound: {
      cta: "Back to feed",
      message: "Sorry, that page doesn't exist.",
      title: "404",
    },
    noVariantSelected: "Make sure to pick a variant (e.g. size)",
    redirection: "The redirection was blocked by your browser.",
    required: "This field is required.",
    supercoinsUsedExceedsLimit: "You cannot use more than {{limit}} of Supercoins for this purchase.",
    updateAvailable: {
      cta: `Open ${isIos ? "App" : "Play"} Store`,
      message: "New features and functionalities await that make the version on your device obsolete.",
      title: "Please update your app",
    },
    usernameTaken: "This username is unavailable. Please try a new one.",
    warning: "Warning",
    wrongPassword: "Wrong password.",
  },
  errorBoundary: {
    foundABug: "It seems you found a bug!",
    helpUs: "Could you let us know what you were doing right before the app crashed? This will help us fix the issue as quickly as possible!",
    reload: "Reload the app",
    sendAndReload: "Send report & reload the app",
    sorry: "Sorry about that...",
    tellUsWhatHappened: "Tell us what happened",
  },
  featureAnnouncement: {
    cta: "Learn more",
    subtitle: "Want <b>infinite Studio tokens</b>? This just might be for you",
    title: "Become a Supercreator!",
    whatsNew: "What's new:",
  },
  featured: {
    label: "Featured",
  },
  feed: {
    dislike: "Nope",
    endOfFeed: {
      cta: "Browse catalog",
      subtitle: "View all the items in our catalog",
      title: "Want to see items again?",
    },
    like: "Like",
    purchaseItem: {
      [Stage.forSale]: {
        subtitle: "You can buy it now for",
        title: "This item is real!",
      },
      [Stage.underReview]: {
        [`subtitle_${AvailablePurchaseMethod.checkout}`]: "You can buy it now for",
        [`title_${AvailablePurchaseMethod.checkout}`]: "This campaign reached its goal on {{date}}",
        [`subtitle_${AvailablePurchaseMethod.preorder}`]: "Pre-order now for",
        [`title_${AvailablePurchaseMethod.preorder}`]: "You spotted it early!",
        [`purchaseExplanation_${AvailablePurchaseMethod.preorder}`]:
          "Back this campaign to help kickstart production! Your card will only be charged if the project meets its goal after the {{campaignDurationInDays}}-day campaign period.",
        [`purchaseExplanation_${AvailablePurchaseMethod.checkout}`]:
          "The product is currently in development. Generally, successful campaigns ship products after 3 to 6 months depending on complexity.",
      },
    },
  },
  feedback: {
    description: "Your feedback is very important to us. Know that you can always give us more feedback in the future from the Settings section.",
    pageTitle: "Give us feedback",
    reachOut: "Allow the O/S team to reach out by email for further discussion",
    success: "Feedback submitted. Thanks!",
    title: "Suggest a new feature, report an issue or tell us what you like!",
    yourEmail: "Your email",
    yourFeedback: "Your feedback",
  },
  filter: {
    filterBy: "Filter by",
    filterItemsBy: "Filter items by",
    filterUsersBy: "Filter users by",
  },
  form: {
    characterCount: "{{count}}/{{total}} characters",
  },
  godMode: {
    catalogProduct: {
      create: "Create catalog product",
      created: "Catalog product created",
      createdButImageFailed: "Catalog product created, but $t(godMode.imageUploadFailed)",
      delete: "Delete catalog product",
      deleted: "Catalog product deleted",
      edit: "Edit catalog product",
      edited: "Catalog product edited",
      editedButImageFailed: "Catalog product edited, but $t(godMode.imageUploadFailed)",
    },
    clearLocalStorage: "Clear local storage",
    collabCreated: "Collab created",
    collabDeleted: "Collab deleted",
    collabEdited: "Collab edited",
    confirmApprove: "Do you really want to approve this collab?",
    confirmDelete: "Do you really want to delete this collab?",
    contest: {
      create: "Create contest",
      created: "Contest created",
      createdButImageFailed: "Contest created successfully, but $t(godMode.imageUploadFailed)",
      edit: "Edit contest",
      imageFailed: "$t(godMode.image.imageFailed)",
      imageRequired: "$t(godMode.image.imageRequired)",
      moveEntriesToConcept: "Promote entries to concept",
      update: "Update contest",
      updated: "Contest updated",
      updatedButImageFailed: "Contest updated successfully, but $t(godMode.imageUploadFailed)",
    },
    createCollab: "Create collab",
    deleteCollabsForUser: {
      confirmation:
        "The collabs owned by this user will also be deleted. Do you want to continue? By pressing yes, this user and all of their collabs will be immediately removed and cannot be recovered.",
      label: "Delete all collabs' user",
      success: "User and his {{deletedCollabs}} collabs successfully deleted.",
    },
    deleteUser: {
      confirmation: "Are you sure you want to delete this user? By pressing yes, the user will be immediately removed.",
      label: "Delete user",
      success: "User successfully deleted.",
    },
    editCollab: "Edit collab",
    image: {
      imageFailed: "Image upload failed",
      imageRequired: "Please upload an image.",
      warningReorder:
        "You cannot add or remove an image at the same time as reordering the existing images. Please save your changes in between those two steps",
    },
    imageRatio: "Images will be displayed with a {{imageRatio}} ratio",
    imageUploadFailed: "image upload failed",
    likesDeleted: "{{count}} likes deleted",
    mainActions: "Main Actions",
    manageContestPassword: {
      addPassword: {
        explanation: "Contestants will be required to enter this password to participate in the contest.",
        label: "Add password",
        success: "Password successfully added.",
      },
      deletePassword: {
        confirmation: "Are you sure you want to delete the contest password? By pressing yes, the password will be immediately removed.",
        label: "Delete password",
        success: "Password successfully deleted.",
      },
      enterPassword: "Enter password",
      updatePassword: {
        confirmation: "Are you sure you want to update the existing contest password? By pressing yes, the password will be immediately updated.",
        label: "Update password",
        success: "Password successfully updated.",
      },
    },
    managePromos: {
      active: "Active",
      areYouSure: "Are you sure you want to delete this promo?",
      cannotBeUndone: "This action cannot be undone.",
      copyPromoId: "Copy promo id",
      create: "Create promo",
      created: "Promo created",
      createdButImageFailed: "Promo created successfully, but image upload failed",
      delete: "Delete promo",
      deleteSuccess: "Promo successfully deleted",
      image: "Promo image",
      imageFailed: "Image upload failed",
      imageRequired: "Please enter an image url or upload an image.",
      inactive: "Inactive",
      orUploadImage: "Or upload an image",
      previewPromoCard: "Preview promo card",
      title: "Manage promos",
      update: "Update promo",
      updated: "Promo updated",
      updatedButImageFailed: "Promo updated successfully, but image upload failed",
    },
    noChanges: "No changes were made",
    none: "None",
    noSuggestion: "No Suggestions available",
    onlyForAdmin: "Only visible for admin",
    promoImpressionsDeleted: "{{count}} promos marked as unseen",
    resetMyLikes: "Reset my likes",
    resetMyPromoImpressions: "Reset my promo views",
    resetOnboarding: "Reset onboarding",
    setFeatured: {
      collab: "Set as featured collab",
      confirmationCollab: "Are you sure you want to set this collab as featured?",
      confirmationCreator: "Are you sure you want to set this creator as featured?",
      creator: "Set as featured creator",
      errorNotACreator: "If you want to set this profile as featured creator, you have to first change his status to 'creator'",
    },
    success: "Success!",
    testDeeplink: "Test deeplink",
    testVideo: "Test video",
    throwError: "Throw Error",
    title: "God mode",
    toggleCreatorStatus: "Toggle Creator Status",
    updateOrderCountNeeded: {
      enterOrderCountNeeded: "Enter order count needed",
      explanation:
        "The order count needed correspond to the number of orders required to pass to the next stage: in development.\nThe current value is {{currentOrderCountNeeded}}.",
      label: "Update order count needed",
      success: "Order count needed successfully updated",
    },
    updateStage: {
      currentFeed: "Current stage",
      currentStage: "Current detailed stage",
      detailedStage: {
        archived: {
          description: "Sale ended. Make sure to archive the product in shopify first.",
          label: "Archived",
        },
        concept: {
          label: "Concept",
        },
        contest: {
          label: "Contest entry",
        },
        denied: {
          description: "Denied for not following guidelines. Only visible for admins and the collab's creator.",
          label: "Denied",
        },
        development: {
          label: "In development",
        },
        developmentDecision: {
          description: "Waiting for development approval",
          label: "Development decision",
        },
        developmentDenied: {
          description: "Development was denied for that concept",
          label: "Development denied",
        },
        developmentFailed: {
          description: "Development failed or judged impossible",
          label: "Development failed",
        },
        forSale: {
          label: "For sale",
        },
        softDeleted: {
          description: "No longer visible anywhere for anyone (including admin) on the app",
          label: "Soft deleted",
        },
        underReview: {
          label: `$t(enum.stage.${Stage.underReview})`,
        },
        underReviewDecision: {
          description: `100 likes goal was reached, waiting to move to $t(enum.stage.${Stage.underReview}, lowercase)`,
          label: `$t(enum.stage.${Stage.underReview}) decision`,
        },
        underReviewDecisionQuoteRequested: {
          description: `...`,
          label: `$t(enum.stage.${Stage.underReview}) decision quote requested`,
        },
        underReviewDenied: {
          description: `$t(enum.stage.${Stage.underReview}) was denied for that concept`,
          label: `$t(enum.stage.${Stage.underReview}) denied`,
        },
        underReviewReady: {
          description: `The collab's creator can now start the $t(enum.stage.${Stage.underReview}, lowercase)`,
          label: `$t(enum.stage.${Stage.underReview}) ready to be started`,
        },
      },
      editSuccessful: "Stage updated successfully!",
      fields: {
        externalProductId: {
          error: "Invalid external product id",
          helper:
            "You can find it in the shopify url of the product:\n1) Open the <slink>admin shopify console</slink>\n2) Click on the product you want to sale\n3) Change its status to 'active'\n4) Copy the number from the shopify url",
          label: "External product id",
        },
        orderCountNeeded: {
          label: "Order count needed",
        },
        reviewEndAt: {
          label: "Review end at..",
        },
      },
      label: "Update stage",
      newFeed: "New stage",
      newStage: "New detailed stage",
      referToThisDocumentation: "Refer to this documentation",
    },
  },
  inAppBrowser: {
    alert: "Please visit our app on an external browser to sign in with Apple, Facebook or Google",
    alertLink_android: "Visit our app on an external browser using the button below to sign in with Google or Facebook",
    alertLink_ios: "Please copy the link using the button below and visit our app on an external browser to sign in with  Apple, Google or Facebook.",
    bySigningUp: "By signing up",
    continueOnApp: "Continue on Off/Script",
    continueOnBrowser: "Limited experience in browser",
    cta_android: "Continue on external browser",
    cta_ios: "Copy link to clipboard",
    joinDesigners: "1000s of other designers are on Off/Script",
    joinMe: "Join me on Off/Script!",
    joinMe_concept: "Join me on Off/Script to check out this concept!",
    joinMe_contest: "Join me on Off/Script to check out this contest!",
    joinMe_user: "Join me on Off/Script to check out this profile!",
    launchpad: "The launchpad for your creative product ideas",
    or: "or",
    otherDesigner: "{{username}} and $t(inAppBrowser.joinDesigners)",
    title_android: "For an improved experience, click the button below to open Off/Script in an external browser.",
    title_ios: "For an improved experience, please open Off/Script in an external browser.",
    willEarn: "Will earn",
    youAnd: "You & {{username}}",
  },
  login: {
    anonymous: "I don't want to sign in yet",
    email: {
      checkYourEmail: "Check your email",
      confirmNewPassword: "Confirm new password",
      confirmPassword: "Confirm password",
      createAnAccount: "Create an account",
      emailAddress: "Email address",
      enterEmail: "Enter an email",
      forgotPassword: "Forgot password?",
      invalidActionCode: "This link is invalid or expired.\nPlease try again.",
      invalidPassword: "Invalid password. Please try again.",
      mustMatch: "The passwords do not match",
      newPassword: "New password",
      noRedirect: "If you're not redirected, please go back and sign in",
      oldPassword: "Old password",
      password: "Password",
      passwordReset: "Password reset",
      passwordRules: "Password needs to be at least 8 characters long and contain at least 1 number and 1 symbol.",
      passwordUpdatedRelogin: "Password updated. Please sign in again",
      resendEmail: "Resend email",
      resetPasswordEmailSent: "If an account exists under\n<bold>{{email}}</bold>\nyou'll receive a password reset email",
      signIn: "Sign in",
      signInButton: "Sign in with email",
      verificationEmailSent: "We've sent a verification email to\n<bold>{{email}}</bold>",
      verifyEmail: "Verify email",
    },
    footer: "By connecting your account you confirm that you agree with our <tclink>Terms and Conditions</tclink>",
    linkingProviders: {
      accountExists: "You already have an account!",
      signInAgain: "Please sign in with one of your original sign-in methods to link your accounts",
      signInAndLink: "Sign in and link accounts",
    },
    message: "Continue with {{provider}}",
    or: "or",
    title: "Where your dream products come to life",
    titleReferred: "You've been invited to become a creator",
  },
  loginPrompt: {
    defaultTitle: "This section requires an account",
    feed: {
      message: "You'll get notified when concepts become reality, and more!",
      title: "Log in to see more products!",
    },
    notifications: {
      message: "Log in to get notified when concepts become reality, and more!",
    },
    orders: {
      message: "Log in to see the status of your orders, and more!",
    },
    profile: {
      message: "Log in to manage your profile, create concepts and more!",
    },
  },
  names: {
    facebook: "Facebook",
    instagramDM: "Message",
    instagramPost: "Post",
    instagramStory: "Story",
    messages: "Messages",
    messenger: "Messenger",
    reddit: "Reddit",
    whatsApp: "WhatsApp",
    x: "X",
  },
  noResults: {
    default: "No matching results.\nPlease try a different search.",
  },
  notifications: {
    becomeCreator: "CONGRATS! You are now a creator. Invite other creators to join!",
    becomeSellable: "This item is now available for sale!",
    collabComment: "Comment received",
    collabComment_one: "{{username}} commented on your concept: {{collabName}}",
    collabComment_other: "{{count}} new comments on your concept: {{collabName}}",
    collabUpdate_one: "A new update was shared",
    collabUpdate_other: "{{count}} new updates where shared",
    collabUpdateComment_one: "{{username}} commented on your concept update",
    collabUpdateComment_other: "{{count}} new comments on your concept update",
    contestEndingSoon: "This contest ends in 3 days!",
    contestGoLive: "You can participate in a new design contest!",
    contestTopUser: "CONGRATS! You won the contest. We will reach out to you shortly for the prize!",
    deletedCollab: "Cannot find item",
    deniedInDevelopment: "Your concept didn't reach the necessary interest from the community.",
    deniedUnderReview: `Your concept has not been approved for the $t(enum.stage.${Stage.underReview}) stage as it didn't match our submission guidelines. Make sure to create a product fitting with our manufacturable categories in your next submission. We're looking forward to seeing your next design!`,
    earlier: "Earlier",
    fullLikesMilestone: "Your concept reached {{count}} likes! Do you want a quote so that you can start your campaign?",
    halfLikesMilestone: "{{count}} people liked your concept! Keep sharing it, you're doing great.",
    like_one: "1 person added your creation to their likes",
    like_other: "{{count}} people added your creation to their likes",
    likedConceptPromotedInDevelopment: "A concept you liked has been promoted to in Development",
    likedConceptPromotedUnderReview:
      "A concept you liked is now open for preorders - go claim your EARLY BIRD DISCOUNT! This campaign is open for a limited time, don't miss out!",
    new: "New",
    ownedConceptInDevelopment: `Congrats on making it through the $t(enum.stage.${Stage.underReview}) stage. This means that we're going to get started with the sample production. You should have received an email from community@offscriptmtl.com providing more details`,
    ownedConceptPromotedUnderReview:
      "CONGRATS! You've launched a campaign for your concept. You have {{campaignDurationInDays}} days to reach a target of {{orderCountNeeded}} units reserved before we start developing your product. You should have received an email from community@offscriptmtl.com providing more details!",
    ownedConceptPromotedUnderReviewReady: "We have a quote for you! Are you ready to launch your concept's campaign?",
    paymentFailed: "Payment failed",
    paymentFailedSubtitle: "We couldn't process one of your payments. Please update your card details to ensure you receive your purchase!",
    paymentIncoming: "Successful campaign!”",
    paymentIncomingSubtitle: "You will soon be charged for the campaign. Please ensure your payment information are up-to-date.",
    paymentSucceeded: "Payment successful",
    paymentSucceededSubtitle: "We've charged your card for a campaign you supported. Thank you!",
    permission: {
      subtitle: "Enable notifications to get news about your creations, trending concepts, discounts & promotions, and lots more",
      title: "Never miss out on deals & updates!",
      turnOn: "Turn on notifications",
    },
    preorderPlaced_one: "You just made a sale!",
    preorderPlaced_other: "You just made {{count}} sales!",
    underReviewEndingSoon:
      "{{count}} days left to gather enough interest from the community for {{name}} to move into production. Congrats on making it to this point, you're part of the top 1% of submissions on the app this month!",
  },
  onboarding: {
    app: {
      cta: "Join 1,000s of tastemakers ✨",
      subtitle: "Sign in and join 1,000s of tastemakers ✨",
      title: "The launchpad for your creative product ideas",
    },
    getStarted: "Get started",
    instagram: {
      description: "It will also allow us to tag your top posts as we reshare them, giving visibility to your page!",
      subtitle:
        "For creators, linking your Instagram account will instantly enhance your profile by showcasing your art directly on your O/S profile.",
      title: "Would you like to link your Instagram account?",
    },
    popper: {
      autogenerateDescription: "Try letting us describe the technical aspects of your concept for you!",
      goals:
        "You can see the campaign goals here! The first is to get {{likeCountObjective}} likes. Once reached, we will set a funding goal, and you'll be able to reserve the item!",
      studio: "You can access the\n$t(aiStudio) from here!",
      supercoins: "You can tap on your balance to see how close you are to collecting more.",
    },
    supercoins: {
      subtitle: "You'll collect Supercoins when you perform a variety of actions and you can use them to reduce your balance at checkout.",
      title: "You've earned {{rewards}}!",
    },
    swipe: {
      subtitle: "(Swipe left if you don't)",
      title: "Swipe right if you like",
    },
  },
  order: {
    label: "Orders",
    orderStatus: "Order status",
    orderTotal: "Order total",
    shipping: "Shipping",
    subtotal: "Subtotal",
    tax: "Tax",
    viewOrderSummary: "View order summary",
  },
  preorder: {
    addPaymentMethod: "Add a payment method",
    addressSheetTitleAdd: "Add shipping address",
    addressSheetTitleEdit: "Edit shipping address",
    addShippingAddress: "Add a shipping address",
    billingAddress: "Billing address",
    cancelPreorder: {
      cancelCta: "Back",
      confirmCta: "Yes, cancel",
      disclaimer:
        "This campaign has closed, so you won't be able to place a new order. If you'd still like to receive this item, try updating your payment information instead.",
      label: "Cancel reservation",
      message: "Are you sure you wish to cancel your <b>{{price}}</b> reservation for <b>{{productName}}</b>?",
      title: "Cancel your reservation",
    },
    cardDetails: "Card details",
    cardExpiryDate: "Expires: {{month}}/{{year}}",
    confirmEmailAddress: "Confirm your email address",
    creatorCaption: "Created by {{name}}",
    deletePaymentMethod: {
      message: "If you change your mind, you'll have to re-enter your payment information.",
      title: "Delete payment method?",
    },
    editReservation: "Edit reservation",
    emailAddress: "Email address",
    emailStep: "1. Email address",
    enterRewardsHelper: "Your Supercoins will be reimbursed if the campaign does not succeed.",
    enterRewardsMax: "Max ({{max}})",
    enterRewardsPlaceholder: "Enter amount ($)",
    enterRewardsStep: "3. Use Supercoins to cover up to {{limit}} of the purchase! <gray>Current balance: {{supercoinsBalance}}</gray>",
    error: {
      emailsMustMatch: "The emails do not match",
      invalidAddress: '$t(preorder.error.invalidAddressGeneric, {"value": "address"})',
      invalidAddress_province: '$t(preorder.error.invalidAddressGeneric, {"value": "province"})',
      invalidAddress_region: '$t(preorder.error.invalidAddressGeneric, {"value": "region"})',
      invalidAddress_state: '$t(preorder.error.invalidAddressGeneric, {"value": "state"})',
      invalidAddress_zipCode: '$t(preorder.error.invalidAddressGeneric, {"value": "zip code"})',
      invalidAddressGeneric: "Your {{value}} is invalid. Please verify your address and try again. If the error persists, contact us for assistance.",
      missingRequiredField: "Please include a complete address",
      noShipppingRateAvailable: "Sorry, we don't ship to your address. Contact us to ask about adding your country.",
      orderAlreadyPaid: "Order already paid",
      tooManyPaymentMethods: "You've reached the maximum number of payment methods. Please delete a payment method before adding a new one.",
    },
    free: "Free!",
    hiddenCardNumber: "****{{last4Digits}}",
    paymentDeclined:
      "We attempted to charge {{charge}} to your account on {{date}}, but the payment was declined. Please update your credit card information.\n\nWe will try to process the payment again on {{nextDate}}.\n\nIf the payment is not successful, your reservation might be canceled.\n\nThank you for your attention to this matter.",
    paymentDeclinedWithoutNext:
      "We attempted to charge {{charge}} to your account on {{date}}, but the payment was declined. Please update your credit card information.\n\nIf the payment is not successful, your reservation might be canceled.\n\nThank you for your attention to this matter.",
    paymentMethodStep: "{{stepNumber}}. Payment method",
    paymentSheetTitle: "Add payment method",
    productMightChangeDisclaimer:
      "Please be advised that as we continue to develop this product, certain details may undergo revisions. Any changes will be promptly communicated in the updates section to keep you informed.",
    quantityLabel: "Quantity:",
    reserveCaption: "You will only be charged if and when the campaign reaches its funding goal.",
    [`reserveCaption_${CollabCampaignStatus.inProgress}`]: "You will only be charged if and when the campaign reaches its funding goal.",
    [`reserveCaption_${CollabCampaignStatus.cancelled}`]: "This campaign has not reached its goal on <b>{{date}}</b>.",
    [`reserveCaption_${CollabCampaignStatus.successful}`]:
      "This campaign reached its goal on <b>{{date}}</b>! The product is currently in development. Generally, successful campaigns ship products after <b>3 to 6 months</b> depending on complexity.",
    reserveCaptionWithInfo: "You will only be charged <b>{{price}}</b> (incl. shipping) on <b>{{date}}</b> if the campaign reaches its funding goal.",
    [`reserveCaptionWithInfo_${CollabCampaignStatus.cancelled}`]:
      "This campaign has not reached its goal on <b>{{date}}</b> and you were not charged.",
    [`reserveCaptionWithInfo_${CollabCampaignStatus.successful}`]:
      "You should expect to receive this item between <b>{{shipDateMin}}</b> and <b>{{shipDateMax}}</b> depending on complexity.",
    reservePageShippingAddressDisclaimer: "This will apply to your current order as well as all future orders. It will not affect existing orders.",
    shipping: "Shipping",
    shippingAddressDisclaimer: "This will apply to all future orders. It will not affect existing orders.",
    shippingAddressStep: "{{stepNumber}}. Shipping address",
    signInToSeeOrders: "Sign in to see the status of your orders!",
    subtotal: "Subtotal",
    success: {
      cta: "Share this concept",
      subtitle: "You've successfully reserved {{name}}, bringing it one step closer to becoming a real product. Spread the word!",
      title: "Thank you!",
    },
    tax: "Tax",
    termsAndConditions: "By reserving, you agree to the O/S <link>Terms and Conditions</link>",
    total: "Total",
  },
  productDetails: {
    aboutMe: "About me",
    awaitingFirstGoal: "Awaiting first goal",
    campaignStartTBD: "Campaign start TBD",
    category: "Category",
    description: "Description",
    flagged: "Flagged. See <glink>why</glink>.",
    inDevelopment: "✓ In development!",
    inDevelopment_short: "In development",
    itemsLeft_one: "{{count}} item left",
    itemsLeft_other: "{{count}} items left",
    nbDaysLeft: "{{nbDaysLeft}} left",
    noDaysLeft: "0 days left",
    pickA: "Pick a",
    quantity: "Quantity",
    seeMore: "See more",
    sizeGuide: "Size Guide",
    updates: {
      label: "Updates",
      update: "Update",
      updateWithIndex: "Update #{{index}}",
    },
  },
  products: {
    earlyBirdAlert: "Enjoy these early bird discounts until time runs out!",
    mostPopular: "Trending items",
    price: "{{integer}}{{decimal, sup}}",
    priceAndCompareAtPrice: "<lineThrough>{{compareAtInteger}}{{compareAtDecimal, sup}}</lineThrough> <red>$t(products.price)</red>",
    priceRange: "{{floorInteger}}{{floorDecimal, sup}} - {{ceilingInteger}}{{ceilingDecimal, sup}}",
    stage: {
      [Stage.concept]: "Concepts",
      [Stage.forSale]: "For sale",
      [Stage.underReview]: "Campaigns",
      [Stage.contest]: "Contest entries",
      [Stage.hidden]: "Hidden",
    },
    status_inDevelopment: "In development",
    [`status_${Stage.forSale}`]: "Ships immediately",
    [`status_${Stage.underReview}`]: "{{fundedPercentage}}% funded",
    [`status_${Stage.concept}`]: "{{likeCount}}/{{likeCountObjective}} Likes",
  },
  productUpdates: {
    fields: {
      content: {
        label: "Content",
        placeholder: "Dear community...",
      },
      title: {
        label: "Title",
      },
    },
    viewItemDetails: "View item details",
    [`viewItemDetails_${Stage.underReview}`]: `View $t(enum.stage.${Stage.underReview}, lowercase) details`,
    [`viewItemDetails_${Stage.forSale}`]: `View product details`,
  },
  profile: {
    editProfile: "Edit Profile",
    instagramError:
      "We're unable to display your Instagram pictures on your profile because we don't have the necessary permissions. Please unlink and relink your Instagram account, making sure to turn ON your picture sharing settings when you do so.",
    instagramPhoto_one: "{{count}} Instagram Photo",
    instagramPhoto_other: "{{count}} Instagram Photos",
    more: {
      accountSettings: "Settings",
      badgeLegend: {
        superstarExplanation: "(seller + creator)",
        title: "Badge legend",
      },
      becomeACreator: {
        applicationPending: "Your application is pending",
        apply: "Apply to showcase your designs to our community! We'll reach out by email.",
        imACreator: "I'm a creator",
        label: "Become a creator",
        title: "Are you a creator?",
        yourEmail: "Your email",
      },
      title: "More",
    },
  },
  promos: {
    leaveReview: {
      ctaLabel: "Give a review",
      subtitle: "Leave us a review!",
      text: `Every review matters! The greater the traction we get on the ${isIos ? "App" : "Play"} store, the more concepts we can bring to life!`,
      title: "Like our app?",
    },
    newArrival: {
      ctaLabel: "View details",
      subtitle: "You can now order this item!",
      title: "New arrival!",
    },
    newContest: {
      mainCtaLabel: "Enter contest",
      secondaryCtaLabel: "See entries",
      text: {
        [ContestRewardType.becomeCreator]: "For the chance to become a creator!",
        [ContestRewardType.custom]: "For the chance to win {{rewardValue}}!",
        [ContestRewardType.money]: "For the chance to win a {{rewardValue}} cash prize!",
        [ContestRewardType.supercoin]: "For the chance to win ${{rewardValue}} in Supercoins!",
        [ContestRewardType.token]: "For the chance to win {{rewardValue}} Studio tokens!",
      },
      title: "New contest!",
    },
    tokenGift: {
      ctaLabel: "Start creating",
      subtitle: "{{tokens}} Studio tokens",
      text: "Take the Studio for a spin with this little welcome present to get you started!",
      title: "You get a gift!",
    },
  },
  referral: {
    ctaLabel: "Share app",
    settingsLabel: "Refer a friend",
    shareMessage: "You're invited to come create on Off/Script!",
    title: "Invite your friends and fans!",
  },
  search: {
    items: "Items",
    placeholder: "Search...",
    users: "Users",
  },
  settings: {
    about: {
      label: "About",
      privacyPolicy: "Privacy Policy",
      termsOfUse: "Terms of Use",
    },
    changePassword: "Change password",
    deleteAccount: {
      action: "Delete account",
      areYouSure: "Are you sure?",
      description:
        "If you delete your account, all your personal information will be erased. You will be able to create a new account in the future if you wish, but this data will not be recovered.",
      permanentlyDelete: "Permanently delete account?",
      requestTakesTime: "Requests may take a few days to be processed.",
      title: "Delete your account",
    },
    feedback: {
      label: "$t(feedback.pageTitle)",
    },
    help: {
      body: "I need help with...",
      error: "Something went wrong - but you can still reach us at community@offscriptmtl.com",
      error_dev: "Something went wrong - this is expected if using the simulator",
      label: "Help",
      subject: "Request for Assistance",
    },
    notifications: {
      email: "Email",
      emailCta: "Subscribe",
      emailCtaSuccess: "Subscribed!",
      label: "Notifications",
      push: "Push",
      pushCta: "Adjust push settings",
      pushDescription: "Stay updated on new concepts, and activity from your favorite creators, when you're not here.",
      pushDescriptionCta: "You can always turn these on or off in your phone settings.",
      settings: {
        accountUpdates: {
          title: "Account updates",
        },
        appUpdates: {
          description:
            "You are automatically subscribed to our newsletter when creating an account.\n\nClick the button below if you've unsubscribed and would like to receive our newsletter once again.",
          title: "Product updates & Newsletter",
        },
        collabProgress: {
          description: "A concept you created or liked has moved on to the next stage!",
          title: "Concept progression",
        },
        commentsAndReplies: {
          description: 'Miguel commented: "Wow" on your item!',
          title: "Comments & Replies",
        },
        contestUpdates: {
          description: "A new contest has gone live!",
          title: "Contest updates",
        },
        likes: {
          description: "A user liked your concept",
          title: "Likes",
        },
        privateMessages: {
          description: "The O/S team left you a message!",
          title: "Private message",
        },
        productUpdates: {
          description: "An update was shared on an item you liked",
          title: "Updates",
        },
        promotions: {
          title: "Promotions",
        },
      },
    },
    paymentInformation: {
      label: "Payment information",
    },
    shippingAddress: {
      label: "Shipping address",
    },
    subscriptions: "Subscriptions",
  },
  share: {
    browseFeed: "Browse the feed",
    curious: "Curious to see more\namazing concepts?",
    joinMe: "Join me on the app",
    message: "Check this out on Off/Script!",
    open: "Open",
    openApp: "Open app",
    shareHelperText: "Share a story to enable the copy to clipboard button",
    shareStory: "Share as a story",
  },
  sort: {
    sortItemsBy: "Sort items by",
  },
  startCampaign: {
    askForAQuote: "Ask for a quote",
    confirmation: {
      disclaimer: {
        points: [
          "Have a <bold>great product description & updated profile</bold>.",
          "<bold>Build a contact list</bold> with your first few buyers coming from your personal network.",
          "Think about a <bold>communication plan pre-launch</bold> and during the campaign.",
          "The best campaigns have <bold>3 visuals or more</bold>. The more the better to drive conversion.",
          "Commit to 1 campaign at a time to maximize success. Less is more!",
        ],
        title:
          "Launching a campaign is hard and is unlikely to be successful unless you put effort into it! Make sure to do these 5 things before launching!",
      },
      dismissCta: "Maybe later",
      message: "This cannot be undone and you will no longer be able to edit your concept.",
      startCta: "Start now",
      title: "Start campaign immediately?",
    },
    dialog: {
      explanation: {
        canRequestQuote:
          "Ready to start your campaign? First, you'll need a quote detailing the selling price and order quantity target for your campaign. Use the button below to ask us to generate one for you (and don't worry, it's free).\n\nThis usually takes up to a week, and then you'll be able to start your campaign. We're excited to see your vision come to life!",
        canStartCampaign:
          "You can now start campaigning for your product to be manufactured! You will have <bold>{{campaignDurationInDays}} days</bold> to get <bold>{{orderCountNeeded}}</bold> people to purchase this item at a price of <bold>{{price}}</bold> ({{compareAtPrice}} after the campaign succeeds). We'll keep {{offScriptPercentageOfRevenue}}% of revenue.\n\nView the <chLink>Creator Handbook</chLink> to learn more.\n\n<bold>Ready?</bold>",
        waitingForQuote:
          "This usually takes up to a week. We'll notify you when we know how much this will sell for and how many you'll need to sell so that you can start your campaign!",
      },
      notNow: "Not now",
      title: {
        canRequestQuote: "Your item reached {{likeCountObjective}} likes!",
        canStartCampaign: "We have a quote for you!",
        waitingForQuote: "Generating a quote for you...",
      },
    },
    label: "Start campaign",
    moreInfo: "More info",
    obtainingQuote: "⏳ Obtaining quote...",
    quoteAvailable: "Quote available",
    // TODO: Add this once superseller can request unlimited quotes: To get more quotes, consider upgrading to the <sLink>Superseller plan↗<sLink/>.
    quoteRequestLimit:
      "You can only request {{quoteRequestsPerWeek}} quotes per week. Your next quote request will be available on {{nextQuoteRequestAvailableAt}}.",
    startYourCampaign: "🔥 Start your campaign!",
    success: {
      caption: "The <chLink>Creator Handbook</chLink> you were emailed contains great tips to maximize your campaign's success. Check it out!",
      cta: "Share campaign",
      steps: {
        "0": "Post",
        "1": "Get {{likeCountObjective}} likes",
        "2": "Start your {{campaignDurationInDays}}-day campaign",
        "3": "Earn big from your idea",
      },
      subtitle: "You've reached the most important part of your journey. Good luck!",
      title: "Congrats",
    },
  },
  studioMini: {
    addImage: "Add image",
    addImage_optional: "Add image\n(Optional)",
    autogenerateDescription: {
      confirm: "Autogenerate",
      confirmationMessage: "This will overwrite your existing description.",
      confirmationTitle: "Autogenerate description?",
    },
    conceptSubmitted: {
      caption: "Encourage your friends to like it - this could be the start of something big!",
      cta: "Share Concept",
      steps: {
        "0": "$t(startCampaign.success.steps.0)",
        "1": '$t(startCampaign.success.steps.1, {"likeCountObjective": {{likeCountObjective}}})',
        "2": '$t(startCampaign.success.steps.2, {"campaignDurationInDays": {{campaignDurationInDays}})',
        "3": "$t(startCampaign.success.steps.3)",
      },
      subtitle: "If your concept gets over {{likeCountObjective}} likes, we will review it for the campaign stage and reach out!",
      title: "Great design!",
    },
    contestEntrySubmitted: {
      caption:
        "If your concept gets over {{likeCountObjective}} likes, even after the contest ends, we will review it for the campaign stage and reach out! $t(studioMini.conceptSubmitted.caption)\n\nYou can submit {{maxContestSubmissionsAllowed}} concepts for this contest.",
      cta: "Share contest entry",
      steps: {
        "0": "Post",
        "1": "Get most likes",
        "2": "Win prize",
      },
      subtitle: "If your contest entry is the most liked, you will stand to win the prize!",
      title: "Great design!",
    },
    create: {
      updated: "Concept updated!",
    },
    explore: "Inspiration",
    fields: {
      confirmConcept: {
        label:
          "I confirm that this concept is a product that I would like to see come to life (don't post random images and help us keep the feed clean!❤️)",
      },
      contest: {
        label: "Contest",
        participation: "{{submission}} submissions",
        toggle: "This is a contest submission",
      },
      productCategory: {
        label: "Item category",
      },
      productDescription: {
        instructions:
          "Craft a compelling product description that highlights its unique features and why it's a must-have. A well-written description can make all the difference!",
        label: "Item description",
        placeholder: "Describe your concept, inspiration, etc.",
      },
      productName: {
        label: "Item name",
      },
      terms: {
        label:
          "I confirm that this concept respects our <glink>guidelines</glink> and understand it may be removed from the feed if it doesn't (help us keep the feed clean! ❤️)",
      },
    },
    imageSource: {
      savedShots: "Saved shots",
      uploadDescription:
        "▪︎ Square images yield the best results ({{imageRatio}})\n▪  Image size must be under 10MB\n▪  Accepted formats: gif, jpeg, png, webp.\n▪  Maximum of {{maxImages}} images.",
      uploadImages: "Upload images from your device",
    },
    madeWithStudio: "Made with $t(aiStudio)",
    popper: {
      explore: "See concepts made with the $t(aiStudio) for inspiration!",
      inspiration: "You can find inspiration\nhere if you need ideas!",
      leave: "Finally, you can exit\nthe workshop from here!",
      publish: "Save your favorite shots and post them from here!",
      save: "The concepts you create and save in the $t(aiStudio) will be found here!",
      variations: "Tap here to generate\nmore variations of an\nimage you like",
    },
    projects: {
      create: {
        resume: {
          subtitle: "Pick up where you last left off.",
          title: "<b>Resume</b> last session",
        },
        start: {
          subtitle: "Write a prompt to begin!",
          title: "<b>Generate</b> an image",
        },
        subtitle: "And imagine your dream product!",
        title: "Use our $t(aiStudio) ✨",
      },
      featured: {
        cta: "View details",
        title: "Featured contest",
      },
      inspiration: {
        subtitle: "Take a look at concepts generated with our Studio along with the associated prompts!",
        title: "Need inspiration? 🧠",
      },
      publish: {
        contest: {
          subtitle: "Enter a contest and win prizes!",
          title: "Submit a <b>contest entry</b>",
        },
        post: {
          subtitle: "Share a sketch, a 3D render, an AI image...",
          title: "Post a <b>concept</b>",
        },
        saved: {
          subtitle: "View the images you’ve saved in the Studio.",
          title: "View your <b>saved shots</b>",
        },
        subtitle: "You maintain your IP rights and will receive a share of profits from product sale if it makes it to production!",
        title: "Post a creation 📬",
      },
    },
    prompt: {
      actions: {
        poses: "Poses",
        posesDisabled: "Poses\n(Soon!!)",
        post: "Post",
        save: "Save",
        variations: "Variations",
      },
      creditLeft: {
        limitedDetails: "Each generation in the studio costs 1 token. Every day you get {{tokens}} tokens to play with - use them wisely!",
        title: "You currently have {{tokens}} tokens",
        unlimitedDetails: "Thanks for using Off/Script for your creations!",
        unlimitedForEveryoneDetails: "For a limited time, you can play with our studio as much as you want - take advantage while it lasts!",
      },
      deleteSet: "Delete set",
      download: "Download",
      generationError: "An error occurred while generating this set",
      imageId: "Image {{id}}",
      loading: {
        1: "<tip>Tip:</tip> The order of the words in your prompt affects your results (the first words carry more weight than the last)",
        2: "<tip>Tip:</tip> To softly increase the focus of a part of your prompt, use (smooth brackets).\n\n<eg>e.g. ‘’a pink and (fiery) dress’’</eg>",
        3: "<tip>Tip:</tip> Try specifying the attributes and colors of your item, the background, how it is shot...\n\n<eg>e.g. ‘’A full body shot of a man wearing a blue quilted jacket, walking in a busy neon-lit street’’</eg>",
        4: "<tip>Tip:</tip> To heavily increase the focus of a part of your prompt, add two dots and a magnitude.\n\n<eg>e.g. ‘’a (green:1.2) leather jacket’’</eg>",
        5: "<tip>Tip:</tip> Try using different themes: Cyberpunk, Grunge, Minimalism, Punk, Vintage, Retro, etc...",
        6: "<tip>Tip:</tip> Try using different materials: Denim, Velvet, Leather, Linen, Silk, Corduroy, Mesh, Tweed, etc...",
        7: "<tip>Tip:</tip> Try using different patterns: Floral, Stripes, Paisley, Houndstooth, Plaid, Animal print, Geometric, Tie-Dye, etc...",
      },
      loadingMessage: "A generation usually takes 30sec on average",
      menu: {
        backToProjects: "Back to Workshop",
        exploreIdeas: "Find inspiration",
        leaveStudio: "Leave Studio",
        publish: "$t(studioMini.prompt.post)",
        viewSavedShots: "View saved shots",
      },
      noCreditLeft: {
        anonymousDetails: "Users who create an account get {{tokens}} tokens to play with each day!",
        anonymousTitle: "Sign in to get free tokens",
        details: "You ran out of tokens, but don't worry - you'll get {{tokens}} new ones tomorrow!'",
        title: "Come back tomorrow",
      },
      or: "or",
      remix: "Generate another set | ",
      selectImage: "Select an image(s)",
      set: "Set {{number}}",
      setPoses: '$t(studioMini.prompt.set, {"number": {{number}}}) (Poses)',
      setVariations: '$t(studioMini.prompt.set, {"number": {{number}}}) (Variations)',
      tryAnotherPrompt: "Try Another Prompt",
      use: "Use",
      wizard: {
        addImage: "$t(studioMini.addImage)",
        catalog: "Catalog",
        describeIdea: "Describe your idea",
        generate: "Generate",
        howToStart: "How would you like to start?",
        imagineDream: "Imagine your dream product ✨",
        pickItem: "Pick an item from the catalog",
        placeholder: "Describe in great detail the product you have in mind.",
        startFromImage: "Start from an existing image",
        writePrompt: "Write a prompt",
      },
    },
    saved: {
      copyPrompt: "Copy prompt",
      prompt: "Prompt",
      promptCopied: "Prompt copied!",
      savedShots: "Saved shots",
      selectedImages_one: "{{count}} image",
      selectedImages_other: "{{count}} images",
      selectedImages_zero: "{{count}} image",
      usePrompt: "Use this prompt",
    },
    studio: "Studio",
    warningMessage: "You're about to replace your current project, are you sure?",
  },
  success: {
    copied: "Copied!",
    generic: "Success!",
    linkCopied: "Link copied!",
  },
  supercoins: {
    balance: "Supercoins balance",
    banner: "Earn Supercoins to support campaigns or buy products in the app!",
    cancelPurchaseDisclaimer: "If you’ve used any Supercoins for this transaction, they will be reimbursed.",
    completed: "Completed",
    onReferral: "Refer a friend or a fan and both of you receive {{reward}} in Supercoins when they create a new account!",
    onShare:
      "If they make a new account, both of you will receive {{reward}} in Supercoins! Use the link below or share any concept in the app to receive the reward.",
    recurring: "Recurring",
    rewards: "Rewards",
  },
  supercreator: {
    advantages: [
      "<b>Unlimited</b> Studio tokens for image and description generations (instead of {{numberOfTokensPerDay}} per day)",
      "Faster image and description generation (prioritized ranking in queue)",
      "Early access to experimental Studio features",
    ],
    explanation: {
      oneLine: "{{featureExplanation}} The <b>Supercreator</b> plan might be for you!",
      subtitle: "The <b>Supercreator</b> plan might be for you!",
      title: "Want infinite tokens?",
    },
    label: "Supercreator",
    slogan: "Don't limit your creativity, unlock the Studio's full capabilities",
    subscribe: "Subscribe",
    subscribed: "Subscribed",
    subscribeWithPrice: "Subscribe for {{price}}/mo",
    success: "Success - welcome to the program!",
    termsAndPrivacy: "<tlink>Terms of Service</tlink> and <plink>Privacy Policy</plink>",
  },
  superplan: {
    explanation: {
      oneLine: "{{featureExplanation}} Pick the <b>Superplan</b> that best fits your ambitions!",
      subtitle: "Pick the <b>Superplan</b> that best fits your ambitions!",
      title: "Want to level up your experience?",
    },
    explanationByFeature: {
      infiniteTokens: "Want infinite tokens?",
      priorityInGenerationQueue: "Want faster Studio generations?",
    },
    manageSubscriptions: "Manage subscriptions",
    needToDownloadApp: "Interested in subscribing? Download our app!",
    pick: "<b>Pick the Superplan that best fits your ambitions</b>\n(or both if you want it all!)",
  },
  superseller: {
    advantages: [
      "<b>Lower minimum order quantities</b>",
      "Technical product descriptions written by our product creation staff",
      "Access to a <b>network of affiliates</b> (blogs, influencers) who will promote your products",
      "Exclusive opportunities for large-scale projects with our retail partners",
      'Entry to the invite list for our weekly "Creator Tuesday" spotlight on Instagram',
    ],
    disclaimer:
      "<b>* Leading with value</b>\nWe plan to offer these extra perks in a subscription plan priced at {{futureMonthlyPrice}}/mo. However, until we're sure that we can significantly boost your revenue potential by at least 10x, we're making them available for users who pay a one-time fee. Take advantage of this opportunity to try the plan for virtually no cost! And don't worry, we'll notify you before we raise the price.",
    explanation: {
      oneLine: "{{featureExplanation}} The <b>Superseller</b> plan might be for you!",
      subtitle: "The <b>Superseller</b> plan might be for you!",
      title: "Want help marketing your concepts?",
    },
    label: "Superseller",
    loadingMessage: "Give us a moment to complete your purchase. Once complete, it may take a few minutes to apply it to your account.",
    purchaseSuccess: "Purchase successful!",
    slogan: "Reach your campaign goals more easily",
    subscribe: "Subscribe",
    subscribed: "Subscribed",
    subscribeWithPrice: "Subscribe for {{price}}",
    termsAndPrivacy: "<tlink>Terms of Service</tlink> and <plink>Privacy Policy</plink>",
  },
  userInfo: {
    fields: {
      bio: {
        label: "Bio",
      },
      displayName: {
        label: "Name",
      },
      externalLink: {
        label: "Add external link",
        placeholder: "URL",
      },
      instagramLink: {
        add: "Add Instagram link",
        confirm: "Your Instagram link will be removed from your profile",
        errorLink: "We couldn't find the user's Instagram handle, Please try again later",
        linkingWarning: "Linking your Instagram will also reveal pictures from your Instagram account on your O/S profile.",
        remove: "Remove Instagram link",
      },
      links: {
        label: "Links",
      },
      occupation: {
        label: "Occupation",
        placeholder: "Ex. Art Director, Influencer, Student",
      },
      picture: {
        changePicture: "Change picture",
        editPicture: "Edit picture",
        removePicture: "Remove picture",
      },
      pronouns: {
        helper: "Add up to 4 individual pronouns.",
        label: "Pronouns",
        pronoun: "Pronoun",
      },
      tags: {
        helper: "This will help others find your account. Add up to 10 tags. Each tag can have up to 20 characters.",
        label: "Tags",
        tag: "Tag",
      },
      twitterLink: {
        label: "Add X link",
        placeholder: "twitter.com/janetdoe",
      },
      uploadVideoId: {
        label: "Video",
      },
      username: {
        helper: "Usernames can contain letters, numbers, underscores, and periods.",
        label: "Username",
      },
    },
    subtitle: "You can always change these later.",
    title: "What should we call you?",
  },
  webview: {
    aboutToLeave: "You are about to leave the website",
    goNow: "Go Now",
    redirection: "Redirection in progress..",
  },
};
