import React from "react";

import { Warning } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import { ImageToBeUsed } from "@app/common/types/image.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { generateImageToFormData } from "@app/utils/image-upload.util";

import { useUploadStudioImage } from "../api/use-upload-studio-image.hook";

interface CreateStudioImageParams {
  imageUrl: string;
  imageName?: string | null;
  userId?: string;
  setImages: (callback: (prevImages: ImageToBeUsed[]) => ImageToBeUsed[]) => void;
}

interface CreateStudioImageUtilities {
  createStudioImage: (params: CreateStudioImageParams) => Promise<void>;
  loading: boolean;
}

// TODO: remove this and use instead use-upload-new-image.hook.ts
export function useCreateStudioImage(): CreateStudioImageUtilities {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const { uploadImage, loading } = useUploadStudioImage();

  const createStudioImage = async ({ imageUrl, imageName, userId, setImages }: CreateStudioImageParams): Promise<void> => {
    if (!userId) return;

    const form = await generateImageToFormData(imageUrl, imageName);
    await uploadImage({
      variables: { input: form, userId },
      onCompleted: data => {
        if (!data.uploadStudioImage) return;
        setImages(prevImages => [...prevImages, { ...data.uploadStudioImage, imageSource: "upload" }]);
      },
      onError: e => {
        if (e.message.includes("400")) {
          showErrorSnackbar({ message: t("error.invalidImageFileType"), icon: <Warning weight="thin" color={colors.onPrimary} size={24} /> });
        } else {
          showErrorSnackbar({ error: e });
        }
      },
    });
  };

  return { loading, createStudioImage };
}
