import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab } from "@app/common/graphql/generated/schema.graphql";

interface Response {
  getCachedCollab: (collabId: string) => Collab | null;
}

export function useCachedCollab(): Response {
  const client = useApolloClient();

  const getCachedCollab = useCallback(
    (collabId: string): Collab | null =>
      client.readFragment<Collab>({
        id: collabId,
        fragment: collabFields,
        fragmentName: "CollabFields",
      }),
    [client],
  );

  return { getCachedCollab };
}
