import React, { FC, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";
import { Route, SceneMap, TabBar, TabView } from "react-native-tab-view";

import { CartOrdersTab } from "@app/common/enums/cart-orders-tab.enum";
import { NavigateContext, TrackEvent } from "@app/common/enums/track-events.enum";
import { customFonts } from "@app/common/style/fonts";
import { CartStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { CartList } from "@app/components/cart-orders/cart-list/cart-list.component";
import { OrderList } from "@app/components/cart-orders/order-list/order-list.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isAndroidApp } from "@app/utils/device.util";

import { styles } from "./cart-orders.style";

const indexMap = {
  [CartOrdersTab.cart]: 0,
  [CartOrdersTab.orders]: 1,
};

const renderSceneCartOrders = SceneMap({
  [CartOrdersTab.cart]: CartList,
  [CartOrdersTab.orders]: OrderList,
});

export const CartOrdersScreen: FC<CartStackScreenProps<Routes.cartOrders>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { width: windowWidth } = useWindowDimensions();
  const { trackInMixpanel } = useMixpanelContext();

  const currentTab = route.params?.tab;
  const [indexCartOrders, setIndexCartOrders] = useState(currentTab && currentTab in indexMap ? indexMap[currentTab] : 0);
  const [routesCartOrders] = useState<Route[]>([
    { key: CartOrdersTab.cart, title: t("cart.label") },
    { key: CartOrdersTab.orders, title: t("order.label") },
  ]);

  const handleIndexChange = useCallback(
    (index: number) => {
      setIndexCartOrders(index);
      const tab = index === 0 ? CartOrdersTab.cart : CartOrdersTab.orders;
      navigation.setParams({ tab });
      trackInMixpanel(TrackEvent.navigate, { tab, context: NavigateContext.cartOrdersScreen });
    },
    [navigation, trackInMixpanel],
  );

  return (
    <ScreenWrapper safeAreaEdges={isAndroidApp ? ["top", "bottom", "left", "right"] : undefined}>
      <TabView
        navigationState={{ index: indexCartOrders, routes: routesCartOrders }}
        renderScene={renderSceneCartOrders}
        onIndexChange={handleIndexChange}
        initialLayout={{ width: windowWidth }}
        lazy
        renderTabBar={props => (
          <TabBar
            {...props}
            indicatorStyle={{ backgroundColor: colors.onBackground }}
            style={[{ backgroundColor: colors.background }, styles.tab]}
            labelStyle={[{ color: colors.onBackground }, styles.tabLabel, customFonts.buttonSmall]}
          />
        )}
      />
    </ScreenWrapper>
  );
};
