import React, { FC, ReactNode } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { StyleProp, View, ViewStyle } from "react-native";
import { Surface } from "react-native-paper";

import gradient from "@app/assets/images/superseller-gradient.png";
import { Image } from "@app/components/common/image/image.component";
import { useComponentSize } from "@app/hooks/utils/use-component-size.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { borderColor, styles } from "./superplan-background.style";

interface Props {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const SuperplanBackground: FC<Props> = ({ children, style }) => {
  const { width } = useWindowDimensions();

  const defaultHeight = 300;
  const [size, onLayout] = useComponentSize({ defaultSize: { height: defaultHeight } });

  return (
    <Surface elevation={2} style={[styles.root, { borderColor }, style]}>
      <View style={styles.container}>
        <Image source={gradient} width={width} style={{ height: size?.height }} />
        <LinearGradient locations={[0, 1]} colors={["rgba(255,255,255,0.60)", "rgba(255,255,255,0.50)"]} style={styles.container} />
      </View>
      {children && <View onLayout={onLayout}>{children}</View>}
    </Surface>
  );
};
