import { gql, useMutation, MutationFunctionOptions, FetchResult } from "@apollo/client";

import { DeletedPromoImpressions } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

export interface Response {
  deleteAllPromoImpressions: DeletedPromoImpressions;
}

interface Variables {
  userId: string;
}

interface DeleteAllPromoImpressionsResult extends MutationResult<Response, "deleteAllPromoImpressions"> {
  deleteAllPromoImpressions: (options?: MutationFunctionOptions<Response, Partial<Variables>>) => Promise<FetchResult>;
}

const deleteAllPromoImpressionsMutation = gql`
  mutation DeleteAllPromoImpressions($userId: UserId!) {
    deleteAllPromoImpressions(userId: $userId) {
      deletedImpressions
    }
  }
`;

export function useDeleteAllPromoImpressions(): DeleteAllPromoImpressionsResult {
  const [deleteAllPromoImpressions, { loading, error, data }] = useMutation<Response, Partial<Variables>>(deleteAllPromoImpressionsMutation);

  return { deleteAllPromoImpressions, loading, error, data: data?.deleteAllPromoImpressions };
}
