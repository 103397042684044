import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";

import { isWeb } from "./device.util";
import { isObjectWithProperties } from "./object.util";

const imageDir = `${FileSystem.cacheDirectory ?? ""}offScript/`;
export const imageFileUri = (id: string): string => `${imageDir}gif_${id}.jpeg`;

export function getFileExtensionFromMimeType(mimetype: string): string {
  switch (mimetype) {
    case "image/gif":
      return ".gif";
    case "image/jpeg":
      return ".jpeg";
    case "image/png":
      return ".png";
    case "image/webp":
      return ".webp";
    default:
      return "";
  }
}

export async function ensureDirExists(): Promise<void> {
  const dirInfo = await FileSystem.getInfoAsync(imageDir);
  if (!dirInfo.exists) {
    await FileSystem.makeDirectoryAsync(imageDir, { intermediates: true });
  }
}

export async function getImageFile(url: string, id: string): Promise<string> {
  const fileUri = imageFileUri(id);
  const fileInfo = await FileSystem.getInfoAsync(fileUri);

  if (!fileInfo.exists) {
    const file = await FileSystem.downloadAsync(url, fileUri);
    return file.uri;
  }

  return fileUri;
}

export async function downloadFile(url: string, uniqId: string, name?: string): Promise<void> {
  if (!isWeb) {
    const fileType = url.substring(url.lastIndexOf(".") + 1);
    const fileName = name ?? uniqId;

    await ensureDirExists();
    const fileUri = await getImageFile(url, uniqId);

    const isSharingAvailable = await Sharing.isAvailableAsync();

    if (isSharingAvailable) {
      await Sharing.shareAsync(fileUri, { dialogTitle: fileName, UTI: fileType ?? "JPEG" });
    }
  } else {
    const imageResponse = await fetch(url);
    const imageBlob = await imageResponse.blob();

    const fileName = `${name ?? uniqId}${getFileExtensionFromMimeType(imageBlob.type)}`;
    const imageFile = new File([imageBlob], fileName, { type: imageBlob.type });

    const shareData: ShareData = { files: [imageFile] };

    try {
      await navigator.share(shareData);
    } catch (error) {
      if (isObjectWithProperties(error, "name") && error.name === "AbortError") {
        return;
      }

      const blobURL = URL.createObjectURL(imageBlob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style = "display: none";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(blobURL);
    }
  }
}
