import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  name: {
    flexShrink: 1,
  },
  root: {
    alignItems: "center",
    columnGap: 2,
    flexDirection: "row",
  },
});
