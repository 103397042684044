import React, { FC, useCallback, useEffect } from "react";

import { Bell } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { OnboardingStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { OnboardingScreenLayout } from "@app/components/initial-states/onboarding-screen-layout/onboarding-screen-layout.component";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { usePushNotificationPermissionContext } from "@app/context/push-notifications/permission/push-notifications-permission.context";

import { styles } from "./notifications-permission.style";

export const NotificationsPermissionScreen: FC<OnboardingStackScreenProps<Routes.notificationsPermission>> = ({ navigation }) => {
  const { t } = useTranslation();

  const { onOnboardingStepCompleted } = useOnboardingContext();
  const { skipPrompt, requestPermission, shouldPromptForPermission } = usePushNotificationPermissionContext();

  const handleNext = useCallback(() => {
    skipPrompt();
    onOnboardingStepCompleted(Routes.notificationsPermission, navigation);
  }, [navigation, onOnboardingStepCompleted, skipPrompt]);

  useEffect(() => {
    if (!shouldPromptForPermission) {
      onOnboardingStepCompleted(Routes.notificationsPermission, navigation);
    }
  }, [navigation, onOnboardingStepCompleted, shouldPromptForPermission]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <Button onPress={handleNext}>{t("cta.skip")}</Button>,
    });
  }, [handleNext, navigation, t]);

  return (
    <OnboardingScreenLayout
      title={t("notifications.permission.title")}
      subtitle={t("notifications.permission.subtitle")}
      content={
        <View style={styles.bellIconContainer}>
          <Bell weight="fill" size={24} />
        </View>
      }
      bottomButton={buttonProps => (
        <Button {...buttonProps} onPress={requestPermission} icon={Bell}>
          {t("notifications.permission.turnOn")}
        </Button>
      )}
    />
  );
};
