import React, { FC } from "react";

import { ArrowsClockwise, MagnifyingGlass } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import SwipeFill from "@app/assets/icons/swipe-fill.svg";
import { productStackMargin } from "@app/common/constants/products.const";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { GenericCard } from "@app/components/common/generic-card/generic-card.component";
import { LoginPrompt } from "@app/components/common/login-prompt/login-prompt.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./no-product-card.style";

interface Props {
  resetFeed: () => void;
}

export const NoProductCard: FC<Props> = ({ resetFeed }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { state } = useAuthContext();
  const navigation = useNavigation();
  const { width: windowWidth } = useWindowDimensions();

  return !state.connected ? (
    <LoginPrompt
      title={{ text: t("loginPrompt.feed.title"), variant: "h6" }}
      message={{ text: t("loginPrompt.feed.message"), variant: "h7" }}
      onTopOfEverything={false}
    />
  ) : (
    <GenericCard
      containerStyle={[styles.root, { backgroundColor: colors.tertiaryContainer }]}
      cta={
        <Button
          icon={props => <MagnifyingGlass {...props} weight="fill" />}
          onPress={() => {
            navigation.navigate(Routes.home, {
              screen: Tabs.search,
              params: { screen: Routes.search, initial: false },
            });
          }}
          mode="contained"
          size="large">
          {t("feed.endOfFeed.cta")}
        </Button>
      }
      backButton={{ onPress: resetFeed, icon: iconProps => <ArrowsClockwise {...iconProps} weight="thin" /> }}
      image={({ size, color }) => <SwipeFill width={size} height={size} color={color} />}
      subtitle={t("feed.endOfFeed.subtitle")}
      title={t("feed.endOfFeed.title")}
      width={windowWidth - productStackMargin * 2}
    />
  );
};
