import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { SearchStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { HomeStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { SearchHeader } from "@app/components/products/search-header/search-header.component";
import { SearchContextProvider } from "@app/context/search/search.provider";
import { SearchScreen } from "@app/screens/search/search.screen";
import { SearchResultsScreen } from "@app/screens/search-results/search-results.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<SearchStackParamList>();

export const SearchStack: FC<HomeStackScreenProps<Tabs.search>> = () => {
  return (
    <SearchContextProvider>
      <SearchHeader />
      <Stack.Navigator
        initialRouteName={Routes.search}
        screenOptions={{
          ...commonScreenOptions(),
          headerShown: false,
        }}>
        <Stack.Screen name={Routes.search} component={SearchScreen} />
        <Stack.Screen name={Routes.searchResults} component={SearchResultsScreen} />
      </Stack.Navigator>
    </SearchContextProvider>
  );
};
