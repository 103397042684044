import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import {
  collabUpdateFields,
  contestFieldsWithoutCategory,
  coreCollabFields,
  coreProfileFields,
  orderFields,
} from "@app/common/graphql/fragments.graphql";
import { PaginatedNotifications } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { useProfileContext } from "@app/context/profile/profile.context";

export interface NotificationsResponse {
  notifications: PaginatedNotifications;
}

export interface Variables {
  after?: number;
  first?: number;
  userId: string;
}

const notificationsQuery = gql`
  query Notifications($after: NotificationId, $first: Int, $userId: UserId!) {
    notifications(after: $after, first: $first, userId: $userId) {
      nodes {
        activities {
          activityId
          actor {
            ... on DeletedObject {
              objectId
            }
            ... on Collab {
              ...CoreCollabFields
            }
            ... on Contest {
              ...ContestFieldsWithoutCategory
            }
            ... on Profile {
              ...CoreProfileFields
            }
            ... on CollabUpdate {
              ...CollabUpdateFields
            }
            ... on Order {
              isPreorder
              lineItems {
                collab {
                  ...CoreCollabFields
                }
              }
              orderId
              orderStatus: status
              totalPrice
            }
          }
          happenedAt
          object {
            ... on DeletedObject {
              objectId
            }
            ... on Collab {
              ...CoreCollabFields
            }
            ... on Contest {
              ...ContestFieldsWithoutCategory
            }
            ... on Profile {
              ...CoreProfileFields
            }
            ... on CollabUpdate {
              ...CollabUpdateFields
            }
            ... on Order {
              isPreorder
              lineItems {
                collab {
                  ...CoreCollabFields
                  creator {
                    ...CoreProfileFields
                  }
                }
              }
              orderId
              orderStatus: status
              totalPrice
            }
          }
        }
        actorCount
        isSeen
        notificationId
        verb
      }
      pageInfo {
        hasNextPage
        next
      }
    }
  }
  ${coreCollabFields}
  ${contestFieldsWithoutCategory}
  ${coreProfileFields}
  ${collabUpdateFields}
  ${orderFields}
`;

export function useNotifications(options: QueryHookOptions<NotificationsResponse, Variables>): QueryResult<NotificationsResponse, "notifications"> {
  const { profile } = useProfileContext();

  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<NotificationsResponse, Variables>(notificationsQuery, {
    ...options,
    notifyOnNetworkStatusChange: true,
    skip: !profile?.userId,
  });

  return { data: data?.notifications, loading, error, networkStatus, fetchMore, refetch };
}
