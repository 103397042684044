import React, { FC } from "react";

import { ColorValue } from "react-native";

import StaffBadge from "@app/assets/icons/staff-badge.svg";
import SupercreatorBadge from "@app/assets/icons/supercreator-badge.svg";
import SuperplanBadge from "@app/assets/icons/superplan-badge.svg";
import SupersellerBadge from "@app/assets/icons/superseller-badge.svg";
import SuperstarBadge from "@app/assets/icons/superstar-badge.svg";
import VerifiedBadge from "@app/assets/icons/verified-badge.svg";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { Badge } from "@app/common/graphql/generated/schema.graphql";
import { assertNever } from "@app/utils/runtime.util";

export type BadgeType = Badge | SuperplanType | "superplan";

interface Props {
  size?: number;
  type?: BadgeType;
  color?: ColorValue;
}

export const UserBadge: FC<Props> = ({ type, size = 16, color }) => {
  switch (type) {
    case Badge.vettedCreator:
      return <VerifiedBadge width={size} height={size} />;
    case Badge.staff:
      return <StaffBadge width={size} height={size} />;
    case Badge.supercreatorAndSuperseller:
      return <SuperstarBadge width={size} height={size} />;
    case Badge.superseller:
    case SuperplanType.superseller:
      return <SupersellerBadge width={size} height={size} />;
    case Badge.supercreator:
    case SuperplanType.supercreator:
      return <SupercreatorBadge width={size} height={size} />;
    case "superplan":
      return <SuperplanBadge width={size} height={size} color={color} />;
    case Badge.none:
    case undefined:
      return null;
    default:
      return assertNever(type);
  }
};
