import React, { FC } from "react";

import { SafeAreaView } from "react-native";
import { useTheme } from "react-native-paper";

import { Routes, NavigationProps } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioImageListDetails } from "@app/components/studio-mini/studio-image-list-details/studio-image-list-details.component";
import { StudioImageMainActionButtons } from "@app/components/studio-mini/studio-image-main-action-buttons/studio-image-main-action-buttons.component";
import { useStudioImage } from "@app/hooks/api/studio/use-studio-image.hook";

import { styles } from "./saved-image-details.style";

export const SavedImageDetailsScreen: FC<NavigationProps<Routes.studioMiniSavedDetails>> = ({ navigation, route }) => {
  const { colors } = useTheme();
  const { imageId } = route.params;

  const { data: image, loading } = useStudioImage({
    variables: { imageId },
    onError: () => navigation.replace(Routes.studioMiniSaved),
  });

  const actionButtons = <StudioImageMainActionButtons image={image} loading={loading} />;

  return (
    <>
      <ScreenWrapper withScrollView contentContainerStyle={styles.root} staticContent={actionButtons}>
        <StudioImageListDetails image={image} context="saved" />
      </ScreenWrapper>
      <SafeAreaView style={[styles.bottomIosHandle, { backgroundColor: colors.tertiaryContainer }]} />
    </>
  );
};
