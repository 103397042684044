/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { addEventListener } from "@react-native-community/netinfo";
import { AppState } from "react-native";

import { analytics } from "@app/common/analytics/analytics";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { log } from "@app/utils/logger/logger.util";

import { AppStateContext } from "./app-state.context";

export const AppStateContextProvider: FC<ChildrenProp> = ({ children }) => {
  const appState = useRef(AppState.currentState);
  const [isAppOnForeground, setIsAppOnForeground] = useState(appState.current === "active");
  const [isInternetWorking, setIsInternetWorking] = useState(true);

  const contextValue = useMemo(() => ({ isAppOnForeground, isInternetWorking }), [isAppOnForeground, isInternetWorking]);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        log.info("[AppState]: App has come to the foreground!");
        void analytics.logAppOpen();
      }

      appState.current = nextAppState;
      setIsAppOnForeground(appState.current === "active");
      log.info("[AppState]:", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = addEventListener(state => {
      log.info("[AppState]:", JSON.stringify(state));
      setIsInternetWorking(state.isInternetReachable === null || state.isInternetReachable);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <AppStateContext.Provider value={contextValue}>{children}</AppStateContext.Provider>;
};
