import React, { FC, useEffect, useRef, useState } from "react";

import { ApolloError } from "@apollo/client";
import { Coins, Sparkle } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableHighlight, View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import { transparent } from "@app/common/style/theme";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ConfirmationDialog } from "@app/components/common/confirmation-dialog/confirmation-dialog.component";
import { Popper } from "@app/components/common/popper/popper.component";
import { Text } from "@app/components/common/text/text.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useGeneratedDescription } from "@app/hooks/api/ml/use-generated-description.hook";
import { useStartGeneratingDescription } from "@app/hooks/api/ml/use-start-generating-description.hook";
import { useImageGenerationCredit } from "@app/hooks/api/use-image-generation-credits.hook";

import { BottomSheetCreditInfo } from "../studio-token-button/bottom-sheet-credit-info/bottom-sheet-credit-info.component";

import { styles } from "./autogenerate-description-button.style";

interface Props {
  alreadyHasDescription: boolean;
  setCurrentDescription: (description: string) => void;
}

export const AutogenerateDescriptionButton: FC<Props> = ({ alreadyHasDescription, setCurrentDescription }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();

  const sheetRef = useRef<BottomSheetRefProps>(null);

  const { showErrorSnackbar } = useSnackbarContext();
  const { profile, updateState, state: userState, loading: loadingProfile } = useProfileContext();

  const {
    inputs: { images },
    isCurrentlyGeneratingDescription,
    setIsCurrentlyGeneratingDescription,
  } = useStudioMiniContext();

  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [autogenerateButtonPopperOpen, setAutogenerateButtonPopperOpen] = useState(false);

  const { startGeneratingDescription } = useStartGeneratingDescription();
  const { getGeneratedDescription, stopPolling } = useGeneratedDescription();
  const { data: imageCredits, loading: loadingCredit } = useImageGenerationCredit();

  const isGenerateButtonLoading = loadingCredit || isCurrentlyGeneratingDescription;
  const contentColor = isGenerateButtonLoading ? colors.action.active : colors.primary;

  useEffect(() => {
    if (!profile?.userId || loadingProfile) return undefined;

    let timeout: NodeJS.Timeout;
    if (!userState?.autogenerateDescriptionPopperDismissed) {
      timeout = setTimeout(() => {
        setAutogenerateButtonPopperOpen(true);
      }, 500);
    }

    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [loadingProfile, profile?.userId, userState?.autogenerateDescriptionPopperDismissed]);

  const handleError = (error: ApolloError): void => {
    showErrorSnackbar({ error });
    stopPolling?.();
    setIsCurrentlyGeneratingDescription(false);
  };

  const handleGenerate = (): void => {
    if (!profile) return;

    setIsCurrentlyGeneratingDescription(true);

    void startGeneratingDescription({
      variables: { userId: profile.userId, input: { imageId: images[0].imageId } },
      onCompleted: studioTaskData => {
        setConfirmationDialogVisible(false);
        void getGeneratedDescription({
          variables: { userId: profile.userId, where: { studioTaskId: studioTaskData.startGeneratingDescription.studioTaskId } },
          pollInterval: 2000,
          onCompleted: descriptionData => {
            if (descriptionData.generatedDescription) {
              stopPolling?.();
              setCurrentDescription(descriptionData.generatedDescription.description);
              setIsCurrentlyGeneratingDescription(false);
            }
          },
          onError: handleError,
        });
      },
      onError: handleError,
    });
  };

  const handleButtonPress = (): void => {
    if (!imageCredits || (!imageCredits.unlimited && imageCredits.tokensLeft === 0)) {
      sheetRef.current?.open();
    } else {
      alreadyHasDescription ? setConfirmationDialogVisible(true) : handleGenerate();
    }
  };

  if (images.length === 0) return null;

  return (
    <>
      <Popper
        isOpen={autogenerateButtonPopperOpen}
        setIsOpen={setAutogenerateButtonPopperOpen}
        message={t("onboarding.popper.autogenerateDescription")}
        onClose={() => updateState({ input: { [UserStateEnum.autogenerateDescriptionPopperDismissed]: true } })}
        placement="bottom"
        childContentSpacing={0}
        showChildInTooltip={false}
        backgroundColor={transparent}>
        <TouchableHighlight
          onPress={handleButtonPress}
          underlayColor={colors.action.selected}
          disabled={isGenerateButtonLoading}
          style={[{ borderRadius: roundness }, styles.buttonContainer]}>
          <View
            style={[
              {
                borderColor: isGenerateButtonLoading ? colors.action.disabled : colors.tertiary,
                borderRadius: roundness,
              },
              styles.button,
            ]}>
            {isGenerateButtonLoading ? <ActivityIndicator color={contentColor} size={20} /> : <Sparkle size={20} color={contentColor} />}
            <Text variant="buttonMedium" style={{ color: contentColor }}>
              {t("cta.autogenerate")}
            </Text>
            <View style={[styles.divider, { backgroundColor: contentColor }]} />
            <Coins weight="thin" color={contentColor} size={20} />
          </View>
        </TouchableHighlight>
      </Popper>

      <ConfirmationDialog
        visible={confirmationDialogVisible}
        title={t("studioMini.autogenerateDescription.confirmationTitle")}
        message={t("studioMini.autogenerateDescription.confirmationMessage")}
        confirmProps={{
          label: t("studioMini.autogenerateDescription.confirm"),
          loading: isCurrentlyGeneratingDescription,
          onPress: handleGenerate,
        }}
        onDismiss={() => setConfirmationDialogVisible(false)}
      />
      <BottomSheetCreditInfo ref={sheetRef} imageCredits={imageCredits} onClose={() => sheetRef.current?.close()} />
    </>
  );
};
