import React, { FC } from "react";

import { FlashListProps, FlashList } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { Skeleton } from "@app/components/common/skeleton/skeleton.component";
import { Text } from "@app/components/common/text/text.component";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles as profileListStyle } from "../profile-list.style";

import { styles } from "./profile-list-empty.style";

interface Props {
  loading: boolean;
  loadingFlashListProps: Omit<FlashListProps<Profile>, "renderItem" | "data">;
  itemWidth: number;
}

export const ProfileListEmpty: FC<Props> = ({ loading, itemWidth, loadingFlashListProps }) => {
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const { roundness } = useTheme();
  const { t } = useTranslation();

  const noResultState = (
    <View style={[styles.root, styles.center]}>
      <Text variant="body2" color="tertiary" textAlign="center">
        {t("noResults.default")}
      </Text>
    </View>
  );

  const loadingState = (
    <View style={{ width: screenWidth, height: screenHeight }}>
      <FlashList
        data={new Array(10).fill(0)}
        keyExtractor={(_item, index) => `skeleton-profile-${index}`}
        renderItem={() => (
          <View style={[profileListStyle.itemWrapper, { width: itemWidth }, styles.skeletonsWrapper]}>
            <Skeleton height={itemWidth * imageRatio} width={itemWidth} radius="square" />
            <Skeleton height={customFonts.caption.lineHeight} width={(itemWidth * 3) / 4} radius={roundness} />
            <Skeleton height={customFonts.caption.lineHeight} width={itemWidth / 2} radius={roundness} />
          </View>
        )}
        {...loadingFlashListProps}
      />
    </View>
  );

  if (loading) return loadingState;

  return noResultState;
};
