import React, { FC, useEffect } from "react";

import { ENABLE_SENTRY, REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID } from "@env";
import * as Sentry from "@sentry/react-native";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { ImageRequireSource } from "react-native";

import { ErrorBoundary } from "@app/components/common/error-boundary/error-boundary.component";
import { ColorSchemeContextProvider } from "@app/context/color-scheme/color-scheme.provider";
import { withLDProvider } from "@app/context/launch-darkly/launch-darkly";
import { defaultLDContext } from "@app/context/launch-darkly/launch-darkly.context";
import { RootContextProviders } from "@app/router/app-context-providers";
import { Router } from "@app/router/router";
import { toBoolean } from "@app/utils/boolean.util";
import { initDebugConsole } from "@app/utils/debug-console/debug-console.util";
import { initFacebookSDK } from "@app/utils/facebook/init-fbsdk.util";
import { initSentry } from "@app/utils/init-sentry";

import "@app/locales/i18n";

if (toBoolean(ENABLE_SENTRY, true) === true) {
  initSentry();
}

void SplashScreen.preventAutoHideAsync();

const App: FC = () => {
  const [fontsLoaded] = useFonts({
    /* eslint-disable @typescript-eslint/naming-convention */
    PPNeueMontreal_400Regular: require("@app/assets/fonts/PPNeueMontreal-Regular.ttf") as ImageRequireSource,
    PPNeueMontreal_500Medium: require("@app/assets/fonts/PPNeueMontreal-Medium.ttf") as ImageRequireSource,
    PPNeueMontreal_600SemiBold: require("@app/assets/fonts/PPNeueMontreal-SemiBold.ttf") as ImageRequireSource,
    PPNeueMontreal_700Bold: require("@app/assets/fonts/PPNeueMontreal-Bold.ttf") as ImageRequireSource,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  useEffect(() => {
    void initFacebookSDK();
    initDebugConsole();
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <ColorSchemeContextProvider>
      <RootContextProviders>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </RootContextProviders>
    </ColorSchemeContextProvider>
  );
};

export default Sentry.wrap(
  withLDProvider({
    clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: defaultLDContext,
  })(App),
);
