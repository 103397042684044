import React, { RefObject, forwardRef, useState, JSX } from "react";

import { Platform, View } from "react-native";
import { useTheme } from "react-native-paper";

import { Collab, CollabVariants, ProductVariant } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { ListItem } from "@app/components/common/list-item/list-item.component";

import { AssignVariantToCartItemButton } from "../../cart-orders/cart-list/assign-variant-to-cart-item-button/assign-variant-to-cart-item-button.component";
import { CreatorName } from "../../profile/creator-name/creator-name.component";
import { AddToCartButton } from "../add-to-cart-button/add-to-cart-button.component";
import { BuyNowButton } from "../buy-now-button/buy-now-button.component";
import { VariantPicker } from "../product-details/variant-picker/variant-picker.component";
import { ReserveButton } from "../reserve-button/reserve-button.component";

import { styles } from "./bottom-sheet-variant-picker.style";

export type BuyAction = "addToCart" | "buyNow" | "preorder";
export type AssignAction = "assignVariant";

interface CommonProps {
  collabVariants?: CollabVariants;
  product: Collab;
}

interface AssignVariantProps extends CommonProps {
  cartItemId: string;
  buyAction: AssignAction;
}

interface OtherBuyActionProps extends CommonProps {
  cartItemId?: never;
  buyAction: BuyAction;
}

type Props = AssignVariantProps | OtherBuyActionProps;

export const BottomSheetVariantPicker = forwardRef<BottomSheetRefProps, Props>(({ collabVariants, product, cartItemId, buyAction }, ref) => {
  const { roundness } = useTheme();
  const { collabId, price, images, name, creator, sizeGuideUrl } = product;

  const [variantOptionsOpen, setVariantOptionsOpen] = useState(false);
  const [variant, setVariant] = useState<ProductVariant | undefined>();
  const [quantity, setQuantity] = useState<number>(1);

  const productPrice = variant?.price ?? price;

  const mapButtons: Record<BuyAction | AssignAction, JSX.Element> = {
    addToCart: (
      <AddToCartButton
        collabId={collabId}
        variantId={variant?.variantId}
        inventoryQuantity={variant?.inventoryQuantity}
        price={productPrice}
        quantity={quantity}
      />
    ),
    assignVariant: (
      <AssignVariantToCartItemButton
        cartItemId={cartItemId ?? ""}
        variantId={variant?.variantId}
        inventoryQuantity={variant?.inventoryQuantity}
        price={productPrice}
        quantity={quantity}
      />
    ),
    buyNow: (
      <BuyNowButton
        variantId={variant?.variantId}
        inventoryQuantity={variant?.inventoryQuantity}
        price={productPrice}
        quantity={quantity}
        mode="contained"
        onPressStart={() => (ref as RefObject<BottomSheetRefProps>).current?.close()}
      />
    ),
    preorder: (
      <ReserveButton
        collabId={collabId}
        variantId={variant?.variantId}
        inventoryQuantity={variant?.inventoryQuantity}
        price={productPrice}
        quantity={quantity}
        mode="contained"
        onPressStart={() => (ref as RefObject<BottomSheetRefProps>).current?.close()}
      />
    ),
  };

  const buyButton = mapButtons[buyAction];

  const content = (
    <View style={styles.contentSheet}>
      <ListItem
        title={name}
        titleProps={{ variant: "caption" }}
        description={
          <CreatorName name={creator.displayName} badge={creator.badge} iconSize={16} textProps={{ variant: "caption", color: "tertiary" }} />
        }
        imageProps={{ image: images.length ? images[0].imageUrl : undefined, size: 80, style: { borderRadius: roundness } }}
        contentContainerStyle={styles.productInfoContent}
      />
      <VariantPicker
        variants={collabVariants?.variants}
        options={collabVariants?.options}
        quantity={quantity}
        variant={variant}
        sizeGuideUrl={sizeGuideUrl}
        setQuantity={setQuantity}
        setVariant={setVariant}
        setBottomSheetOpen={setVariantOptionsOpen}
      />
    </View>
  );

  return Platform.OS === "web" ? (
    <BottomSheet ref={ref} footer={variantOptionsOpen ? undefined : buyButton} onDismiss={() => setVariantOptionsOpen(false)}>
      <View style={variantOptionsOpen ? styles.hiddenView : {}}>{content}</View>
    </BottomSheet>
  ) : (
    <BottomSheet ref={ref} footer={buyButton}>
      {content}
    </BottomSheet>
  );
});

BottomSheetVariantPicker.displayName = "BottomSheetVariantPicker";
