/* eslint-disable complexity */
import React, { FC, JSX, useRef } from "react";

import { ResourceKey } from "i18next";
import { CaretDown, ShoppingCartSimple, Tote } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";

import HandCoins from "@app/assets/icons/hand-coins-fill.svg";
import { AvailablePurchaseMethod, Collab, CollabCampaignStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { useLazyCollabVariants } from "@app/hooks/api/use-collab-variants.hook";
import { ColorKey } from "@app/utils/color-theme.util";

import { AssignAction, BottomSheetVariantPicker, BuyAction } from "../bottom-sheet-variant-picker/bottom-sheet-variant-picker.component";
import { ProductPrice } from "../product-price/product-price.component";

import { styles } from "./open-variant-picker-button.style";

interface CommonProps extends Omit<ButtonProps, "children"> {
  product: Collab;
  buttonComponent?: FC<{ onPress: () => void; loading: boolean }>;
  displayPrice?: boolean;
}

interface AssignVariantProps extends CommonProps {
  cartItemId: string;
  buyAction: AssignAction;
}

interface OtherBuyActionProps extends CommonProps {
  cartItemId?: never;
  buyAction: BuyAction;
}

type Props = AssignVariantProps | OtherBuyActionProps;

export const OpenVariantPickerButton: FC<Props> = ({
  buyAction,
  product,
  cartItemId,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  buttonComponent: ButtonComponent,
  displayPrice,
  ...buttonProps
}) => {
  const { t } = useTranslation();
  const variantPickerSheetRef = useRef<BottomSheetRefProps>(null);

  const { getVariants, data: variants, loading: loadingVariants } = useLazyCollabVariants();

  const canBePreordered = product.progress.stage === Stage.underReview && product.availablePurchaseMethod === AvailablePurchaseMethod.preorder;
  const isSellable =
    (product.progress.stage === Stage.forSale || product.progress.stage === Stage.underReview) &&
    product.availablePurchaseMethod === AvailablePurchaseMethod.checkout;
  const allVariantsSoldOut = product.inventoryQuantity !== undefined && product.inventoryQuantity <= 0;
  const closedCampaign = product.campaign?.status === CollabCampaignStatus.cancelled;

  const handleOpenVariantPicker = (): void => {
    void getVariants({
      variables: { where: { collabIds: [product.collabId] } },
      onCompleted: () => variantPickerSheetRef.current?.open(),
    });
  };

  const getLabelButton = (
    keyWithoutPrice: ResourceKey,
    keyWithPrice: ResourceKey,
    size: "xsmall" | "small" | "medium" | "large" = "medium",
    mode: "contained" | "outlined" = "contained",
  ): JSX.Element => {
    const colorVariant = buttonProps.color ?? "primary";
    const variant = size === "small" ? "buttonSmall" : size === "large" ? "buttonLarge" : "buttonMedium";
    const color = mode === "contained" ? (`on${colorVariant.charAt(0).toUpperCase() + colorVariant.slice(1)}` as ColorKey) : colorVariant;

    return displayPrice ? (
      <Trans
        i18nKey={keyWithPrice}
        components={{
          price: (
            <ProductPrice
              price={product.formattedPrice}
              compareAtPrice={product.formattedCompareAtPrice}
              commonTextProps={{ variant, color }}
              theme={buttonProps.theme}
            />
          ),
        }}
      />
    ) : (
      <Trans i18nKey={keyWithoutPrice} />
    );
  };

  const mapButton: Record<BuyAction | AssignAction, JSX.Element | undefined> = {
    addToCart: isSellable ? (
      ButtonComponent ? (
        <ButtonComponent onPress={handleOpenVariantPicker} loading={loadingVariants && buyAction === "addToCart"} />
      ) : (
        <Button
          mode="contained"
          disabled={allVariantsSoldOut}
          loading={loadingVariants && buyAction === "addToCart"}
          icon={ShoppingCartSimple}
          onPress={handleOpenVariantPicker}
          {...buttonProps}>
          {allVariantsSoldOut ? <Trans i18nKey="cart.soldOut" /> : getLabelButton("cta.addToCart", "cta.addToCartWithPrice", buttonProps.size)}
        </Button>
      )
    ) : undefined,
    assignVariant: ButtonComponent ? (
      <ButtonComponent onPress={handleOpenVariantPicker} loading={loadingVariants && buyAction === "assignVariant"} />
    ) : (
      <Button
        mode="contained"
        disabled={allVariantsSoldOut}
        loading={loadingVariants && buyAction === "assignVariant"}
        icon={CaretDown}
        onPress={handleOpenVariantPicker}
        contentStyle={styles.pickAVariantButton}
        {...buttonProps}>
        {allVariantsSoldOut ? t("cart.soldOut") : t("cta.pickAVariant")}
      </Button>
    ),
    buyNow:
      !allVariantsSoldOut && isSellable ? (
        ButtonComponent ? (
          <ButtonComponent onPress={handleOpenVariantPicker} loading={loadingVariants && buyAction === "buyNow"} />
        ) : (
          <Button mode="outlined" loading={loadingVariants && buyAction === "buyNow"} icon={Tote} onPress={handleOpenVariantPicker} {...buttonProps}>
            {getLabelButton("cta.buyNow", "cta.buyNowWithPrice", buttonProps.size, "outlined")}
          </Button>
        )
      ) : undefined,
    preorder: canBePreordered ? (
      ButtonComponent ? (
        <ButtonComponent onPress={handleOpenVariantPicker} loading={loadingVariants && buyAction === "preorder"} />
      ) : (
        <Button
          mode="contained"
          disabled={allVariantsSoldOut || closedCampaign}
          loading={loadingVariants && buyAction === "preorder"}
          icon={({ size, ...iconProps }) => <HandCoins {...iconProps} width={size} height={size} />}
          onPress={handleOpenVariantPicker}
          {...buttonProps}>
          {allVariantsSoldOut
            ? t("cart.soldOut")
            : closedCampaign
            ? t("cta.reserve")
            : getLabelButton("cta.reserve", "cta.reserveWithPrice", buttonProps.size)}
        </Button>
      )
    ) : undefined,
  };

  const variantPickerProps = buyAction === "assignVariant" ? { buyAction, cartItemId } : { buyAction };

  if (!canBePreordered && !isSellable) return null;

  return (
    <>
      {mapButton[buyAction]}
      <BottomSheetVariantPicker ref={variantPickerSheetRef} collabVariants={variants?.nodes?.[0]} product={product} {...variantPickerProps} />
    </>
  );
};
