/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { View, FlatList, ListRenderItem, StyleProp, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { StudioImage, StudioImageStatus } from "@app/common/graphql/generated/schema.graphql";
import { StudioImageWithIndex } from "@app/common/types/studio.type";
import { BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { LoadingPrompt } from "@app/components/studio-mini/loading-prompt/loading-prompt.component";
import { StudioImagePrompt } from "@app/components/studio-mini/studio-image-prompt/studio-image-prompt.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { GenerationTaskType, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useStartGeneratingPoses } from "@app/hooks/api/studio/use-start-generating-poses.hook";
import { useStartGeneratingVariations } from "@app/hooks/api/studio/use-start-generating-variations.hook";
import { useDeleteStudioImages } from "@app/hooks/api/use-delete-studio-images.hook";
import { useSaveTemporaryImages } from "@app/hooks/api/use-save-temp-images.hook";
import { useTemporaryStudioImages } from "@app/hooks/api/use-temporary-studio-images.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";

import { BottomSheetPromptSet } from "../bottom-sheet-prompt-set/bottom-sheet-prompt-set.component";

import { StudioMainActions } from "./studio-main-actions/studio-main-actions.component";
import { StudioNavigationSet } from "./studio-navigation-set/studio-navigation-set.component";
import { styles } from "./studio-prompt-set.style";

interface Props {
  style: StyleProp<ViewStyle>;
  indexSet: number;
  taskId: string;
  task: GenerationTaskType;
  taskCount: number;
  onNext: () => void;
  onPrevious: () => void;
  onReset: () => void;
  onGenerate: () => Promise<void>;
}

export const StudioPromptSet: FC<Props> = ({ style, indexSet, task, taskId, taskCount, onNext, onPrevious, onReset, onGenerate }) => {
  const { colors } = useTheme();
  const sheetRef = useRef<BottomSheetRefProps>(null);
  const { bottom: bottomInset } = useSafeAreaInsets();
  const { t } = useTranslation();

  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { inputs, setGeneration, setIsCurrentlyGenerating } = useStudioMiniContext();

  const [selectedImages, setSelectedImages] = useState<StudioImageWithIndex[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const userId = profile?.userId;

  const { saveTemporaryImages } = useSaveTemporaryImages();
  const { deleteStudioImages } = useDeleteStudioImages();
  const { data, stopPolling, error } = useTemporaryStudioImages({
    variables: { where: { userId: userId ?? "", studioTaskId: taskId ?? "" } },
    skip: !userId || !taskId,
    pollInterval: 2000,
    onCompleted: response => {
      if (response.temporaryStudioImages.nodes && response.temporaryStudioImages.nodes.length > 0) {
        stopPolling?.();
        setIsCurrentlyGenerating(false);
        setLoading(false);
      } else {
        setIsCurrentlyGenerating(true);
      }
    },
    onError: e => {
      setLoading(false);
      stopPolling?.();
      setIsCurrentlyGenerating(false);
      showErrorSnackbar({ error: e });
    },
  });
  const { startGeneratingVariations } = useStartGeneratingVariations();
  const { startGeneratingPoses } = useStartGeneratingPoses();

  const areSelectedImageSaved = useMemo(() => selectedImages.every(si => si.status === StudioImageStatus.permanent), [selectedImages]);

  const handleSelectImage = useCallback(
    (image?: StudioImageWithIndex): void => {
      if (image && (selectedImages.length !== 1 || selectedImages[0].imageId !== image.imageId)) {
        setSelectedImages(imgs => {
          const imageIndex = imgs.findIndex(s => s.imageId === image?.imageId);
          if (imageIndex === -1) {
            return [...imgs, image];
          } else {
            const newArr = [...imgs];
            newArr.splice(imageIndex, 1);
            return newArr;
          }
        });
        sheetRef.current?.open();
      } else {
        sheetRef.current?.close();
      }
    },
    [selectedImages],
  );

  const handleOnClickSaveImages = async (): Promise<void> => {
    try {
      if (areSelectedImageSaved) {
        const idToUnSave = selectedImages.map(s => s.imageId);

        await deleteStudioImages({
          variables: { imageIds: idToUnSave },
          onCompleted: () => {
            setSelectedImages(prev => prev.filter(i => idToUnSave.includes(i.imageId)));
          },
        });
      } else {
        const imageIds = selectedImages.filter(si => si.status === StudioImageStatus.temporary).map(imgs => imgs.imageId);

        await saveTemporaryImages({
          variables: { input: { imageIds } },
          onCompleted: () => {
            setSelectedImages(prev => prev.map(i => ({ ...i, status: StudioImageStatus.permanent })));
          },
        });
      }
    } catch (e: unknown) {
      showErrorSnackbar({ error: e as Error });
    }
  };

  const handleOnClickGenerateVariations = async (): Promise<void> => {
    if (!userId) return;

    setIsCurrentlyGenerating(true);

    sheetRef.current?.close();

    const studioImageId = selectedImages[0].imageId ?? "";
    await startGeneratingVariations({
      variables: {
        input: {
          studioImageId,
          contestId: inputs.contest?.id,
        },
        userId,
      },
      onCompleted: response =>
        setGeneration(prev => ({
          ...prev,
          taskIds: { ...(prev.taskIds ?? {}), [response.startGeneratingVariations.studioTaskId]: GenerationTaskType.variation },
        })),
      onError: e => {
        showErrorSnackbar({ error: e, message: t("error.generic") });
        sheetRef.current?.open();
        setIsCurrentlyGenerating(false);
      },
    });
  };

  const handleOnClickGeneratePose = async (): Promise<void> => {
    if (!userId) return;

    setIsCurrentlyGenerating(true);

    sheetRef.current?.close();

    const studioImageId = selectedImages[0].imageId ?? "";
    await startGeneratingPoses({
      variables: {
        input: {
          studioImageId,
          contestId: inputs.contest?.id,
        },
        userId,
      },
      onCompleted: response =>
        setGeneration(prev => ({
          ...prev,
          taskIds: { ...(prev.taskIds ?? {}), [response.startGeneratingPoses.studioTaskId]: GenerationTaskType.pose },
        })),
      onError: e => {
        showErrorSnackbar({ error: e, message: t("error.generic") });
        sheetRef.current?.open();
        setIsCurrentlyGenerating(false);
      },
    });
  };

  const handleOnPrevious = (): void => {
    handleSelectImage();
    onPrevious();
  };

  const handleOnNext = (): void => {
    handleSelectImage();
    onNext();
  };

  const renderItem = useCallback<ListRenderItem<StudioImage>>(
    ({ item, index: indexImage }) => (
      <StudioImagePrompt
        item={item}
        indexImage={indexImage}
        onPress={handleSelectImage}
        isSelected={!!selectedImages.find(img => img.imageId === item.imageId)}
        itemSelectedCount={selectedImages.length}
      />
    ),
    [handleSelectImage, selectedImages],
  );

  useFocusEffect(
    useCallback(() => {
      return () => sheetRef.current?.close();
    }, []),
  );

  return (
    <View style={style}>
      <StudioNavigationSet
        style={[styles.pagePadding, styles.navigationContainer]}
        index={indexSet}
        onPrevious={handleOnPrevious}
        onNext={handleOnNext}
        taskCount={taskCount}
        taskType={task}
      />
      <FlatList
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[styles.listContainer, styles.pagePadding, ...conditionalItem(!!loading || !!error, { flex: 1 })]}
        ListEmptyComponent={<LoadingPrompt loading={loading} error={error} taskId={taskId} />}
        keyExtractor={node => node.imageId}
        columnWrapperStyle={styles.listColumnContainer}
        data={data?.nodes}
        numColumns={2}
        renderItem={renderItem}
        extraData={[selectedImages]}
      />
      {!loading && !error ? (
        <StudioMainActions
          style={{ backgroundColor: colors.tertiaryContainer, marginBottom: bottomInset }}
          onReset={onReset}
          onPressGenerate={onGenerate}
        />
      ) : null}
      <BottomSheetPromptSet
        ref={sheetRef}
        onDismiss={() => setSelectedImages([])}
        onClose={() => handleSelectImage()}
        handleStyle={styles.handleContainer}
        contentContainerStyle={styles.bottomSheetContainer}
        selectedImages={selectedImages}
        onGenerateVariations={task === GenerationTaskType.original ? handleOnClickGenerateVariations : undefined}
        onGeneratePoses={task === GenerationTaskType.pose ? undefined : handleOnClickGeneratePose}
        onSave={handleOnClickSaveImages}
      />
    </View>
  );
};
