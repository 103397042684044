import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import SwipeThin from "@app/assets/icons/swipe-thin.svg";
import { useNavigation } from "@app/common/types/navigation.type";
import { getReferrerShareId } from "@app/utils/share/share.util";

import { Button } from "../button/button.component";
import { Text } from "../text/text.component";

import { styles } from "./share-more.style";

export const ShareMore: FC = () => {
  const { t } = useTranslation();
  const { goBack } = useNavigation();

  const isShareMoreVisible = !!getReferrerShareId();

  if (!isShareMoreVisible) return null;

  return (
    <View style={styles.root}>
      <Text variant="title" textAlign="center">
        {t("share.curious")}
      </Text>
      <Button
        mode="contained"
        size="large"
        icon={({ size, ...iconProps }) => <SwipeThin style={styles.buttonIcon} width={size} height={size} {...iconProps} />}
        onPress={() => goBack()}>
        {t("share.browseFeed")}
      </Button>
    </View>
  );
};
