import React, { FC, useCallback, useMemo } from "react";

import { add, formatDate } from "date-fns";
import { CaretRight, WarningOctagon } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Divider, useTheme } from "react-native-paper";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { CollabCampaignStatus, Order, OrderLineItem, OrderStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useCartNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { GoalsProgress } from "@app/components/products/goals-progress/goals-progress.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useChargeCustomerForPreorder } from "@app/hooks/api/preorder/use-charge-customer-for-preorder.hook";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { ColorKey } from "@app/utils/color-theme.util";

import { CartOrderProductInfo } from "../../cart-order-product-info/cart-order-product-info.component";
import { CancelPreorderButton } from "../cancel-preorder-button/cancel-preorder-button.component";
import { OrderTotalPriceDetails } from "../order-total-price-details/order-total-price-details.component";

import { styles } from "./order-list-item.style";

interface Props {
  order: Order;
}

export const OrderListItem: FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { navigateToWebview } = useNavigateToWebview();
  const { navigate } = useCartNavigation();
  const { chargeCustomerForPreorder, loading } = useChargeCustomerForPreorder();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();

  const preorderItem = order.lineItems?.[0];
  const campaignStatusForPreorder = preorderItem.collab?.campaign?.status;
  const displayCancelPreorder =
    order.isPreorder &&
    order.status !== OrderStatus.cancelled &&
    (campaignStatusForPreorder === CollabCampaignStatus.inProgress || order.paymentInError);
  const displayPaymentError = order.paymentInError && order.status !== OrderStatus.cancelled;

  const orderStatusColor = useMemo<ColorKey>(() => {
    if (order.status === OrderStatus.cancelled || order.paymentInError) {
      return "error";
    }
    if (order.status === OrderStatus.onHold) return "warning";
    return "primary";
  }, [order.paymentInError, order.status]);

  const orderStatusText = useMemo(() => {
    if (order.paymentInError) return t("enum.orderStatus.paymentDeclined");
    return t(`enum.orderStatus.${order.status}`);
  }, [order.paymentInError, order.status, t]);

  const handleEditPreorder = (): void => {
    if (!preorderItem || !preorderItem.collab || !preorderItem.variant) return;

    navigate(Routes.preorderStack, {
      screen: Routes.preorder,
      params: {
        collabId: preorderItem.collab.collabId,
        variantId: preorderItem.variant.variantId,
        quantity: preorderItem.quantity.toString(),
        orderId: order.orderId,
      },
    });
  };

  const handleChargePreorder = (): void => {
    void chargeCustomerForPreorder({
      variables: { orderId: order.orderId },
      onCompleted: () => showSuccessSnackbar(),
      onError: error =>
        showErrorSnackbar({ error, message: isErrorCode(error, "ORDER_ALREADY_PAID") ? t("preorder.error.orderAlreadyPaid") : undefined }),
    });
  };

  const renderOrderLineItem = useCallback(
    ({ item }: { item: OrderLineItem }) => {
      const product = item.collab;
      const stage = product?.progress.stage;
      const reviewEndAt = product?.progress.reviewEndAt;

      return (
        <View style={styles.orderLineItemContainer}>
          <CartOrderProductInfo
            id={order.orderId}
            context="order"
            product={product}
            variant={item.variant}
            quantity={item.quantity}
            price={item.formattedPrice}
          />

          {product &&
            stage === Stage.underReview &&
            reviewEndAt &&
            (order.isPreorder || product.campaign) &&
            order.status !== OrderStatus.cancelled && (
              <>
                <GoalsProgress campaign={product.campaign} collabProgress={product.progress} hideLikeProgress />
                <Trans
                  i18nKey="preorder.reserveCaptionWithInfo"
                  context={product.campaign?.status}
                  values={{
                    price: order.formattedTotalPrice,
                    date: formatDate(reviewEndAt, "MMMM d, yyyy"),
                    shipDateMin: formatDate(add(new Date(reviewEndAt), { months: 3 }), "MMMM d, yyyy"),
                    shipDateMax: formatDate(add(new Date(reviewEndAt), { months: 6 }), "MMMM d, yyyy"),
                  }}
                  parent={({ children }: ChildrenProp) => (
                    <Text variant="caption" textAlign="center">
                      {children}
                    </Text>
                  )}
                  components={{
                    b: (
                      <Text variant="subtitle2" textAlign="center">
                        <></>
                      </Text>
                    ),
                  }}
                />
              </>
            )}

          <Divider style={styles.divider} />
        </View>
      );
    },
    [order.formattedTotalPrice, order.isPreorder, order.orderId, order.status],
  );

  return (
    <View style={styles.root}>
      <FlatList
        data={order.lineItems}
        renderItem={renderOrderLineItem}
        keyExtractor={(item, index) => `order-line-item-${index}-${item.variant?.variantId ?? ""}-${order.orderId}`}
        style={styles.lineItemsContainer}
        scrollEnabled={false}
      />

      <View style={styles.statusRow}>
        <Text variant="body2">{t("order.orderStatus")}</Text>
        <Text variant="h9" color={orderStatusColor}>
          {orderStatusText}
        </Text>
      </View>
      {order.paymentInError && order.paymentFailedAt ? (
        <EmbeddedAlert
          variant={AlertVariant.error}
          icon={props => <WarningOctagon color={colors.error} {...props} />}
          text={
            order.paymentWillBeRetriedAt
              ? t("preorder.paymentDeclined", {
                  charge: order.formattedTotalPrice,
                  date: formatDate(order.paymentFailedAt, "MMMM d, yyyy"),
                  nextDate: formatDate(order.paymentWillBeRetriedAt, "MMMM d, yyyy"),
                })
              : t("preorder.paymentDeclinedWithoutNext", {
                  charge: order.formattedTotalPrice,
                  date: formatDate(order.paymentFailedAt, "MMMM d, yyyy"),
                })
          }
        />
      ) : (
        <>
          <OrderTotalPriceDetails
            totalPrice={order.totalPrice}
            subtotalPrice={order.subtotalPrice}
            shippingPrice={order.shippingPrice}
            salesTaxPrice={order.salesTaxPrice}
            rewards={order.supercoinAmount}
          />
          <ListItem
            title={t("order.viewOrderSummary")}
            titleProps={{ variant: "body2" }}
            right={<CaretRight color={colors.primary} weight="thin" />}
            onPress={() => navigateToWebview(order.orderPageUrl)}
            style={styles.viewOrderSummaryContainer}
          />
        </>
      )}
      {displayPaymentError && (
        <>
          <Button fullWidth onPress={handleEditPreorder} mode="outlined">
            {t("preorder.editReservation")}
          </Button>
          <Button fullWidth onPress={handleChargePreorder} mode="outlined" loading={loading}>
            {t("cta.tryAgain")}
          </Button>
        </>
      )}
      {displayCancelPreorder && (
        <CancelPreorderButton
          orderId={order.orderId}
          productName={preorderItem.collab?.name ?? ""}
          price={order.formattedTotalPrice}
          isCampaignSuccessful={campaignStatusForPreorder === CollabCampaignStatus.successful}
        />
      )}
    </View>
  );
};
