import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef, useState, JSX, useMemo } from "react";

import { NetworkStatus, QueryHookOptions } from "@apollo/client";
import { FlashList, FlashListProps, ListRenderItem } from "@shopify/flash-list";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import useDeepCompareEffect from "use-deep-compare-effect";

import { PaginatedProfilesWithFacets, Profile } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { Filter } from "@app/common/types/filtering.type";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { BrowseUsersVariables, ProfilesResponse } from "@app/hooks/api/use-browse-users.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { useResponsiveWidthListItem } from "@app/hooks/utils/use-responsive-width-list-item.hook";

import { ProfileListEmpty } from "./profile-list-empty/profile-list-empty.component";
import { ProfileListFilterButton } from "./profile-list-filter-button/profile-list-filter-button.component";
import ProfileListItem from "./profile-list-item/profile-list-item.component";
import { styles } from "./profile-list.style";

type Variables = BrowseUsersVariables;

interface Props<TVars extends Variables> extends Omit<FlashListProps<Profile>, "renderItem" | "data"> {
  useProfiles: (options: QueryHookOptions<ProfilesResponse, TVars>) => QueryResult<ProfilesResponse, "profiles">;
  variables: TVars;
  filters: Filter[];
  titleVisible?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ListHeaderComponent?: JSX.Element;
}

export interface ProfileListRefProps {
  data: PaginatedProfilesWithFacets | undefined;
}

const ProfileListInner = <TVars extends Variables>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  { useProfiles, variables, filters, titleVisible, contentContainerStyle, ListHeaderComponent, ...flatListProps }: Props<TVars>,
  ref: ForwardedRef<ProfileListRefProps>,
): JSX.Element => {
  const { showErrorSnackbar } = useSnackbarContext();

  const listRef = useRef<FlashList<Profile>>(null);

  const nbProfilesAtOnce = 20;
  const estimatedUnderImageContentHeight = 40;
  const { itemWidth, listPadding, nbColumns } = useResponsiveWidthListItem();

  const [refreshing, setRefreshing] = useState(false);
  const [filteringArgs, setFilteringArgs] = useState("where" in variables ? variables.where : undefined);

  const { data, loading, networkStatus, fetchMore, refetch } = useProfiles({
    variables: {
      ...variables,
      ...(filteringArgs ? { where: filteringArgs } : {}),
      first: nbProfilesAtOnce,
    },
    onError: error => showErrorSnackbar({ refetch, error }),
  });
  const { fetchMoreItems, resetOffset } = useFetchMoreItems("profiles", fetchMore, nbProfilesAtOnce);

  useDeepCompareEffect(() => {
    listRef.current?.scrollToOffset({ offset: 0, animated: false });
    resetOffset();
  }, [resetOffset, variables, filteringArgs]);

  const profiles = data?.nodes;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  useImperativeHandle(ref, () => ({ data }), [data]);

  const handleFetchMore = (): void => {
    if (fetchMore && !isFetchingMore && !refreshing && profiles && data?.pageInfo.hasNextPage) {
      void fetchMoreItems({});
    }
  };

  const handleRefreshing = async (): Promise<void> => {
    if (!refetch) return;

    setRefreshing(true);
    resetOffset();
    await refetch();
    setRefreshing(false);
  };

  const renderProfile = useCallback<ListRenderItem<Profile>>(
    ({ item }) => (
      <View style={[styles.itemWrapper, { width: itemWidth }]}>
        <ProfileListItem profile={item} width={itemWidth} />
      </View>
    ),
    [itemWidth],
  );

  const commonFlashListProps: Omit<FlashListProps<Profile>, "renderItem" | "data"> = useMemo(
    () => ({
      numColumns: nbColumns,
      contentContainerStyle: { padding: listPadding, ...contentContainerStyle },
      showsVerticalScrollIndicator: false,
      estimatedItemSize: itemWidth + estimatedUnderImageContentHeight,
      getItemType: () => undefined,
    }),
    [contentContainerStyle, itemWidth, listPadding, nbColumns],
  );

  const listHeader = useMemo(
    () => (
      <ProfileListFilterButton
        titleVisible={titleVisible}
        filters={filters}
        filteringArgs={filteringArgs}
        setFilteringArgs={setFilteringArgs}
        ListHeaderComponent={ListHeaderComponent}
      />
    ),
    [ListHeaderComponent, filteringArgs, filters, titleVisible],
  );

  const listEmpty = useMemo(
    () => <ProfileListEmpty loading={loading} loadingFlashListProps={commonFlashListProps} itemWidth={itemWidth} />,
    [commonFlashListProps, itemWidth, loading],
  );

  return (
    <FlashList
      ref={listRef}
      data={profiles}
      renderItem={renderProfile}
      keyExtractor={item => item.userId}
      ListHeaderComponent={listHeader}
      ListFooterComponent={isFetchingMore ? <ActivityIndicator size="small" /> : undefined}
      ListEmptyComponent={listEmpty}
      onEndReached={handleFetchMore}
      onEndReachedThreshold={0.1}
      refreshing={refreshing}
      onRefresh={() => void handleRefreshing()}
      {...commonFlashListProps}
      {...flatListProps}
    />
  );
};

export const ProfileList = forwardRef(ProfileListInner);
