export function isObject(entity: unknown): entity is object {
  return !!(entity && typeof entity === "object");
}

// Also used here `cypress/utils/object.util.ts`
export function isObjectWithProperties<T extends object>(entity: unknown, ...properties: (keyof T)[]): entity is T {
  if (!isObject(entity)) {
    return false;
  }

  for (const property of properties) {
    if (!(property in entity)) {
      return false;
    }
  }

  return true;
}

export function removeEmptyStringFromObject<T extends object>(object: T): T {
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === "string" && value?.trim() === "") {
      delete object[key as keyof T];
    }
  });

  return object;
}
