import React, { FC } from "react";

import { View, ViewProps } from "react-native";

import Heart from "@app/assets/icons/heart-straight-duotone.svg";
import ShareIcon from "@app/assets/icons/share-duotone.svg";
import { CollabImage, CollabUpdate } from "@app/common/graphql/generated/schema.graphql";
import { commonStyles } from "@app/common/style/common.style";
import { lightTheme } from "@app/common/style/theme";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { Text } from "@app/components/common/text/text.component";
import { ZoomOnImageButton } from "@app/components/common/zoom-on-image-button/zoom-on-image-button.component";

import { CommentsButton } from "../../products/comments/comments-button/comments-button.component";
import { LikeUpdateButton } from "../like-update-button/like-update-button.component";
import { UpdateMoreButton } from "../update-more-button/update-more-button.component";

import { styles } from "./update-side-icon-buttons.style";

interface Props extends ViewProps {
  update: CollabUpdate;
  index?: number;
  currentImage?: CollabImage;
  iconColor?: string;
}

export const UpdateSideIconButtons: FC<Props> = ({
  update,
  index,
  currentImage,
  iconColor = lightTheme.colors.common.white,
  style,
  ...viewProps
}) => {
  const commonIconProps = { iconColor, style: commonStyles.sideIconButton };

  return (
    <View style={[styles.root, style]} {...viewProps}>
      <ZoomOnImageButton imageUrl={currentImage?.imageUrl} style={commonStyles.sideIconButton} />
      <CommentsButton parentId={update.collabUpdateId} numberOfComments={update?.formattedNumberOfComments ?? "0"} {...commonIconProps} />
      <LikeUpdateButton
        collabUpdateId={update.collabUpdateId}
        liked={update.liked}
        icon={iconProps => (
          <View style={styles.iconWithCountContainer}>
            <Heart {...iconProps} width={24} height={24} color={update.liked ? lightTheme.colors.secondary : iconProps.color} />
            {update.likeCount > 0 && (
              <Text variant="caption" style={[{ color: iconColor }, styles.textShadow]}>
                {update.formattedLikeCount}
              </Text>
            )}
          </View>
        )}
        {...commonIconProps}
      />
      <ShareButton
        title={update.title}
        entityId={update.collabUpdateId}
        entityType="collabUpdate"
        index={index}
        image={currentImage}
        icon={iconProps => (
          <View style={styles.iconWithCountContainer}>
            <ShareIcon {...iconProps} width={24} height={24} />
            {update.shareCount > 0 && (
              <Text variant="caption" style={[{ color: iconColor }, styles.textShadow]}>
                {update.formattedShareCount}
              </Text>
            )}
          </View>
        )}
        {...commonIconProps}
      />
      <UpdateMoreButton
        updateId={update.collabUpdateId}
        title={update.title}
        content={update.content}
        images={update.images}
        creatorId={update.user.userId}
        {...commonIconProps}
      />
    </View>
  );
};
