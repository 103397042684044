import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { AccessRole, FeatureAccess } from "@app/common/graphql/generated/schema.graphql";

export function getSuperplanRequiredForFeature(featureAccess?: FeatureAccess): SuperplanType | undefined {
  const superplanRoles = {
    [AccessRole.admin]: undefined,
    [AccessRole.staff]: undefined,
    [AccessRole.supercreator]: SuperplanType.supercreator,
    [AccessRole.superseller]: SuperplanType.superseller,
  };

  const requiredRole = featureAccess?.requiredRole;

  return requiredRole ? superplanRoles[requiredRole] : undefined;
}
