import React, { FC, useCallback, useEffect, useMemo, useRef, useState, JSX } from "react";

import * as WebBrowser from "expo-web-browser";
import { ArrowLeft, X } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { FlatList, ListRenderItem, View, ViewStyle } from "react-native";
import { IconButton } from "react-native-paper";

import { links } from "@app/common/constants/links.const";
import { EmailType } from "@app/common/enums/email-type.enum";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes } from "@app/common/types/navigation.type";
import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { NestedAnimatedView } from "@app/components/common/bottom-sheet/bottom-sheet.common";
import { BottomSheet } from "@app/components/common/bottom-sheet/bottom-sheet.component";
import { BottomSheetProps, BottomSheetRefProps } from "@app/components/common/bottom-sheet/bottom-sheet.types";
import { Text } from "@app/components/common/text/text.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { CustomableLoginBottomSheetProps } from "@app/context/auth/login/login.type";
import { useBranchContext } from "@app/context/branch/branch.context";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useProfile } from "@app/hooks/api/use-profile.hook";
import { Channel } from "@app/hooks/utils/deeplink/branch.common";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isAndroid, isIos, isWeb } from "@app/utils/device.util";
import { log } from "@app/utils/logger/logger.util";
import { getReferrerShareId } from "@app/utils/share/share.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

import { CheckYourEmail } from "../check-your-email/check-your-email.component";
import { CreateAccount } from "../create-account/create-account.component";
import { EnterEmail } from "../enter-email/enter-email.component";
import { EnterPassword } from "../enter-password/enter-password.component";
import { Login } from "../login/login.component";

import { styles } from "./login-bottom-sheet.style";

WebBrowser.maybeCompleteAuthSession();

export enum LoginStep {
  login = 0,
  enterEmail = 1,
  createAccount = 2,
  enterPassword = 3,
  checkYourEmail = 4,
}

interface StepInterface extends Pick<BottomSheetProps, "title" | "leftHeader"> {
  content: JSX.Element;
  id: LoginStep;
  onBack?: () => void;
}

interface Props extends CustomableLoginBottomSheetProps {
  sheetRef: React.RefObject<BottomSheetRefProps>;
  initialRoute?: string;
  step: LoginStep;
  setStep: (newStep: LoginStep) => void;
  email: string;
  setEmail: (newEmail: string) => void;
  emailType: EmailType;
  setEmailType: (newEmailType: EmailType) => void;
  onDismiss?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const LoginBottomSheet: FC<Props> = ({
  sheetRef,
  initialRoute,
  step,
  setStep,
  email,
  setEmail,
  emailType,
  setEmailType,
  onDismiss,
  buttonMode,
  anonymousLogin,
  ...bottomSheetProps
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { navigateToWebview } = useNavigateToWebview();

  const { state } = useAuthContext();
  const { isFeatureEnabled } = useLaunchDarklyContext();
  const { channel } = useBranchContext();
  const { openLinkProvidersPrompt } = useLoginContext();

  const potentialShareId = getReferrerShareId();

  const { data: referrerProfile } = useProfile({ variables: { where: { shareId: potentialShareId } }, skip: !potentialShareId });

  const listRef = useRef<FlatList<StepInterface>>(null);

  const inEmbeddedWebviewRef = useRef(isLikelyInEmbeddedWebview());
  const shouldShowDownloadApp = (isIos || (isAndroid && isFeatureEnabled(FeatureFlag.enableAndroidDeeplink))) && isWeb;

  const shouldOpenOnLoad = useMemo(() => {
    const connectedAndVerified = state.connected && state.verified;
    const notFromShopify = !!channel && channel !== Channel.shopify;
    const onFeed = initialRoute === Routes.feed;

    return !connectedAndVerified && !inEmbeddedWebviewRef.current && notFromShopify && onFeed;
  }, [channel, initialRoute, state.connected, state.verified]);

  const [seenOnLoad, setSeenOnLoad] = useState(false);
  const [showDownloadApp, setShowDownloadApp] = useState(shouldShowDownloadApp);

  useEffect(() => {
    if (state.connected && state.verified) {
      setSeenOnLoad(true);
      sheetRef.current?.close();
    }
  }, [sheetRef, state.connected, state.verified]);

  useEffect(() => {
    if (shouldOpenOnLoad && !seenOnLoad) {
      sheetRef.current?.open();
      setSeenOnLoad(true);
    }
  }, [seenOnLoad, sheetRef, shouldOpenOnLoad]);

  const handleOnDismiss = (): void => {
    setStep(LoginStep.login);
    setEmail("");
    setShowDownloadApp(shouldShowDownloadApp);
    onDismiss?.();
  };

  const slides = useMemo<StepInterface[]>(
    () => [
      {
        id: LoginStep.login,
        title: referrerProfile
          ? t("downloadPrompt.joinUser", { username: referrerProfile.username })
          : showDownloadApp
          ? t("downloadPrompt.howToProceed")
          : t("cta.signIn"),
        leftHeader: referrerProfile && <AvatarImage image={referrerProfile.imageUrl} fallbackText={referrerProfile.username.charAt(0)} size={40} />,
        content: (
          <Login
            showDownloadApp={showDownloadApp}
            hideDownloadApp={() => setShowDownloadApp(false)}
            goToEnterEmail={() => setStep(LoginStep.enterEmail)}
            goToCheckEmail={(emailToCheck: string) => {
              setEmail(emailToCheck);
              setEmailType(EmailType.verifyEmail);
              setStep(LoginStep.checkYourEmail);
            }}
            openLinkProvidersPrompt={openLinkProvidersPrompt}
            buttonMode={buttonMode}
            anonymousLogin={anonymousLogin}
          />
        ),
        onBack: !showDownloadApp && shouldShowDownloadApp ? () => setShowDownloadApp(true) : undefined,
      },
      {
        id: LoginStep.enterEmail,
        title: t("login.email.enterEmail"),
        content: (
          <EnterEmail
            goToEnterPassword={(enteredEmail: string) => {
              setEmail(enteredEmail);
              setStep(LoginStep.enterPassword);
            }}
            goToCreateAccount={(enteredEmail: string) => {
              setEmail(enteredEmail);
              setStep(LoginStep.createAccount);
            }}
          />
        ),
        onBack: () => setStep(LoginStep.login),
      },
      {
        id: LoginStep.createAccount,
        title: t("login.email.createAnAccount"),
        content: (
          <CreateAccount
            email={email}
            onSuccess={() => {
              setEmailType(EmailType.verifyEmail);
              setStep(LoginStep.checkYourEmail);
            }}
            openLinkProvidersPrompt={openLinkProvidersPrompt}
          />
        ),
        onBack: () => setStep(LoginStep.enterEmail),
      },
      {
        id: LoginStep.enterPassword,
        title: t("login.email.signIn"),
        content: (
          <EnterPassword
            email={email}
            onCheckEmail={(checkEmailType: EmailType) => {
              log.info("Navigating to on check email step");
              setEmailType(checkEmailType);
              setStep(LoginStep.checkYourEmail);
            }}
          />
        ),
        onBack: () => setStep(LoginStep.enterEmail),
      },
      {
        id: LoginStep.checkYourEmail,
        title: t("login.email.checkYourEmail"),
        content: <CheckYourEmail email={email} emailType={emailType} />,
        onBack: () => setStep(LoginStep.login),
      },
    ],
    [
      anonymousLogin,
      buttonMode,
      email,
      emailType,
      openLinkProvidersPrompt,
      referrerProfile,
      setEmail,
      setEmailType,
      setStep,
      shouldShowDownloadApp,
      showDownloadApp,
      t,
    ],
  );

  const navigateToLinkPressed = useCallback(
    (link: string, title: string = t("settings.about.termsOfUse")): (() => void) =>
      () => {
        sheetRef.current?.close();
        navigateToWebview(link, title);
      },
    [navigateToWebview, sheetRef, t],
  );

  const footer = useMemo(() => {
    return step !== LoginStep.login || !showDownloadApp ? (
      <Trans
        i18nKey="login.footer"
        parent={({ children }: ChildrenProp) => (
          <Text variant="caption" textAlign="center" color="tertiary" style={styles.footer}>
            {children}
          </Text>
        )}
        components={{
          tclink: (
            <Text variant="caption" onPress={navigateToLinkPressed(links.external.termsOfUse)}>
              <></>
            </Text>
          ),
          ppLink: (
            <Text variant="caption" onPress={navigateToLinkPressed(links.external.googlePrivacyPolicy)}>
              <></>
            </Text>
          ),
          toslink: (
            <Text variant="caption" onPress={navigateToLinkPressed(links.external.googleTos)}>
              <></>
            </Text>
          ),
        }}
      />
    ) : undefined;
  }, [navigateToLinkPressed, showDownloadApp, step]);

  const renderSlide = useCallback<ListRenderItem<StepInterface>>(
    ({ item }) => (
      <NestedAnimatedView isVisible={item.id === step} width={width}>
        <View style={styles.nested}>{item.content}</View>
      </NestedAnimatedView>
    ),
    [step, width],
  );

  useEffect(() => {
    isWeb ? listRef.current?.scrollToOffset({ offset: step * width, animated: false }) : listRef.current?.scrollToIndex({ index: step });
  }, [step, width]);

  const titleStyle: ViewStyle = { maxWidth: slides[step].onBack || slides[step].leftHeader ? "70%" : "100%" };

  return (
    <BottomSheet
      ref={sheetRef}
      contentContainerStyle={styles.sheetContainer}
      title={slides[step].title}
      titleStyle={titleStyle}
      leftHeader={
        slides[step].onBack ? (
          <IconButton icon={({ color, size }) => <ArrowLeft color={color} size={size} />} onPress={slides[step].onBack} />
        ) : (
          slides[step].leftHeader
        )
      }
      rightHeader={<IconButton icon={X} onPress={() => sheetRef.current?.close()} testID="login-bottom-sheet-close-button" />}
      stackBehavior="push"
      onDismiss={handleOnDismiss}
      footer={footer}
      {...bottomSheetProps}>
      <FlatList
        ref={listRef}
        initialNumToRender={slides.length}
        data={slides}
        contentContainerStyle={isWeb ? { width } : undefined}
        keyExtractor={item => `login-bottom-sheet-${item.id.toString()}`}
        horizontal
        scrollEnabled={false}
        pagingEnabled
        renderItem={renderSlide}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        getItemLayout={(_data, index) => ({ length: width, offset: index * width, index })}
      />
    </BottomSheet>
  );
};
