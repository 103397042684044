import { compare } from "compare-versions";
import Constants from "expo-constants";

export enum FeatureFlag {
  alwaysOn = "alwaysOn",
  bypassAppRedirection = "bypassAppRedirection",
  disableInstagramFeatures = "disableInstagramFeatures",
  enableAndroidDeeplink = "enableAndroidDeeplink",
  enableHealthCheckWebsocket = "enableHealthCheckWebsocket",
  infiniteStudioTokens = "infiniteStudioTokens", // all users have infinite tokens
  maintenance = "maintenance", // show maintenance
  minVersionSupported = "minVersionSupported", // force update to new version available
  newFacebookLogin = "newFacebookLogin",
  showFeatureAnnouncement = "featureAnnouncementToShow", // id of the announcement to display, or empty string if none
  studioPosesEnabled = "studioPosesEnabled",
  supercreator = "supercreator",
}

export const currentFeatureAnnouncementId = "supercreator";

export const featureFlagMap = (map: Record<string, boolean | string>): Record<FeatureFlag, boolean> => ({
  [FeatureFlag.alwaysOn]: !!map[FeatureFlag.alwaysOn],
  [FeatureFlag.bypassAppRedirection]: !!map[FeatureFlag.bypassAppRedirection],
  [FeatureFlag.disableInstagramFeatures]: !!map[FeatureFlag.disableInstagramFeatures],
  [FeatureFlag.enableAndroidDeeplink]: !!map[FeatureFlag.enableAndroidDeeplink],
  [FeatureFlag.enableHealthCheckWebsocket]: !!map[FeatureFlag.enableHealthCheckWebsocket],
  [FeatureFlag.infiniteStudioTokens]: !!map[FeatureFlag.infiniteStudioTokens],
  [FeatureFlag.maintenance]: !!map[FeatureFlag.maintenance],
  [FeatureFlag.minVersionSupported]:
    compare((map[FeatureFlag.minVersionSupported] as string) ?? "1.0.0", Constants.expoConfig?.version ?? "1.0.0", "<=") ?? false,
  [FeatureFlag.newFacebookLogin]: !!map[FeatureFlag.newFacebookLogin],
  [FeatureFlag.showFeatureAnnouncement]: map[FeatureFlag.showFeatureAnnouncement] === currentFeatureAnnouncementId,
  [FeatureFlag.studioPosesEnabled]: !!map[FeatureFlag.studioPosesEnabled],
  [FeatureFlag.supercreator]: !!map[FeatureFlag.supercreator],
});
