import { QueryHookOptions, gql, useLazyQuery, LazyQueryExecFunction } from "@apollo/client";

import { InstagramLink } from "@app/common/graphql/generated/schema.graphql";
import { BaseQueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  instagramLink: InstagramLink;
}

interface Variables {
  userId: string;
}

interface InstagramLinkResult extends BaseQueryResult<Response, "instagramLink"> {
  getInstagramLink: LazyQueryExecFunction<Response, Variables>;
}

const instagramLinkQuery = gql`
  query InstagramLink($userId: UserId!) {
    instagramLink(userId: $userId) {
      instagramLink
    }
  }
`;

export function useInstagramLink(options: QueryHookOptions<Response, Variables>): InstagramLinkResult {
  const [getInstagramLink, { data, loading, error }] = useLazyQuery<Response, Variables>(instagramLinkQuery, { ...options });

  return { getInstagramLink, data: data?.instagramLink, loading, error };
}
