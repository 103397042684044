import React, { FC, useCallback, useState, ReactNode, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import { Route, TabBar, TabView } from "react-native-tab-view";

import { BrowseOption } from "@app/common/enums/browse-option.enum";
import { TrackEvent } from "@app/common/enums/track-events.enum";
import { SearchStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { Contests } from "@app/components/contests/contests/contests.component";
import { BrowseProductResults } from "@app/components/products/browse-product-results/browse-product-results.component";
import { BrowseProfileResults } from "@app/components/profile/browse-profile-results/browse-profile-results.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./search.style";

const indexMap: Record<BrowseOption, number> = {
  [BrowseOption.underReview]: 0,
  [BrowseOption.sellables]: 1,
  [BrowseOption.concepts]: 2,
  [BrowseOption.contests]: 3,
  [BrowseOption.users]: 4,
};

export const SearchScreen: FC<SearchStackScreenProps<Routes.search>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { width: windowWidth } = useWindowDimensions();
  const { trackInMixpanel } = useMixpanelContext();

  const currentTab = route.params?.browseOption;

  const [index, setIndex] = useState(currentTab && currentTab in indexMap ? indexMap[currentTab] : 0);
  const [routes] = useState<Route[]>([
    { key: BrowseOption.underReview },
    { key: BrowseOption.sellables },
    { key: BrowseOption.concepts },
    { key: BrowseOption.contests },
    { key: BrowseOption.users },
  ]);

  useEffect(() => {
    setIndex(currentTab && currentTab in indexMap ? indexMap[currentTab] : 0);
  }, [currentTab]);

  const renderScene = ({ route: contestRoute }: { route: Route }): ReactNode => {
    switch (contestRoute.key) {
      case BrowseOption.sellables:
        return <BrowseProductResults browseOption={BrowseOption.sellables} />;
      case BrowseOption.concepts:
        return <BrowseProductResults browseOption={BrowseOption.concepts} />;
      case BrowseOption.underReview:
        return <BrowseProductResults browseOption={BrowseOption.underReview} />;
      case BrowseOption.users:
        return <BrowseProfileResults />;
      case BrowseOption.contests:
        return <Contests />;
      default:
        return null;
    }
  };

  const handleIndexChange = useCallback(
    (tabIndex: number) => {
      setIndex(tabIndex);
      const tab = Object.keys(indexMap)[tabIndex] as BrowseOption;
      navigation.setParams({ browseOption: tab });
      trackInMixpanel(TrackEvent.navigate, { tab });
    },
    [navigation, trackInMixpanel],
  );

  const loadingState = (): ReactNode => (
    <View style={styles.loading}>
      <ActivityIndicator size="large" />
    </View>
  );

  return (
    <ScreenWrapper>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={handleIndexChange}
        lazy
        renderLazyPlaceholder={loadingState}
        initialLayout={{ width: windowWidth }}
        renderTabBar={props => (
          <TabBar
            {...props}
            getTestID={({ route: routeFromLabel }) => `${routeFromLabel.key}_search_top_bar`}
            scrollEnabled
            gap={4}
            renderIndicator={() => undefined}
            tabStyle={styles.tabContainer}
            style={[{ backgroundColor: colors.background }, styles.tabs]}
            contentContainerStyle={styles.contentContainerTabs}
            renderLabel={({ route: routeFromLabel, focused }) => (
              <Text
                variant="buttonMedium"
                color={focused ? "background" : undefined}
                style={[
                  styles.tab,
                  { borderRadius: roundness, borderColor: colors.outline },
                  focused ? { backgroundColor: colors.onBackground } : undefined,
                  routeFromLabel.key === routes[routes.length - 1].key ? styles.lastTab : undefined,
                ]}>
                {t(`browseOption.${routeFromLabel.key}.title`)}
              </Text>
            )}
          />
        )}
      />
    </ScreenWrapper>
  );
};
