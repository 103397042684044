import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  actionsWhenNoImage: {
    flexDirection: "row",
  },
  image: {
    position: "absolute",
  },
  imageContentContainer: {
    alignItems: "flex-end",
    flex: 1,
    justifyContent: "flex-end",
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  root: {
    rowGap: 8,
  },
  rowSpaceBetween: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textContainer: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    rowGap: 16,
  },
});
