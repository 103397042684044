import React, { FC, useCallback } from "react";

import { Star } from "phosphor-react-native";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import ArrowLineUp from "@app/assets/icons/arrow-line-up-duotone.svg";
import DownloadSimple from "@app/assets/icons/download-simple-duotone.svg";
import TrashSimple from "@app/assets/icons/trash-simple-duotone.svg";
import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { commonStyles } from "@app/common/style/common.style";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ZoomOnImageButton } from "@app/components/common/zoom-on-image-button/zoom-on-image-button.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useSetImageShownInExplore } from "@app/hooks/api/studio/use-set-image-shown-in-explore.hook";
import { useDeleteStudioImages } from "@app/hooks/api/use-delete-studio-images.hook";
import { useGenerateStudioImageDownloadLink } from "@app/hooks/api/use-generate-jpg-download-link.hook";
import { downloadFile } from "@app/utils/file.util";
import { isAdmin } from "@app/utils/user.util";

import { styles } from "./studio-image-side-icon-buttons.style";

interface Props {
  image: StudioImage;
  context: "saved" | "explore";
}

export const StudioImageSideIconButtons: FC<Props> = ({ image, context }) => {
  const { colors } = useTheme();

  const navigation = useNavigation();
  const { state } = useAuthContext();
  const { openLogin } = useLoginContext();
  const { profile } = useProfileContext();

  const { generateStudioImageDownloadLink, loading: loadingDownload } = useGenerateStudioImageDownloadLink();
  const { deleteStudioImages, loading: loadingDelete } = useDeleteStudioImages();
  const { setImageShownInExplore, loading: loadingExplore } = useSetImageShownInExplore();

  const { showErrorSnackbar } = useSnackbarContext();
  const { setInputImages: setImages } = useStudioMiniContext();

  const handleAddExplore = (): void => {
    if (!image?.imageId) return;

    void setImageShownInExplore({
      variables: { imageId: image?.imageId, input: { isExploreImage: !image?.isExploreImage } },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  const handleDownloadImage = useCallback((): void => {
    if (!profile?.userId) {
      openLogin();
      return;
    }

    void generateStudioImageDownloadLink({
      variables: { input: { imageId: image.imageId } },
      onCompleted: response =>
        void downloadFile(response.generateStudioImageDownloadLink.imageUrl, image.imageId).catch((error: Error) => showErrorSnackbar({ error })),
      onError: e => showErrorSnackbar({ error: e }),
    });
  }, [generateStudioImageDownloadLink, image.imageId, openLogin, profile?.userId, showErrorSnackbar]);

  const handleDeleteImage = useCallback((): void => {
    void deleteStudioImages({
      variables: { imageIds: [image.imageId] },
      onCompleted: () => navigation.goBack(),
      onError: e => showErrorSnackbar({ error: e }),
    });
  }, [deleteStudioImages, navigation, image.imageId, showErrorSnackbar]);

  const handlePublish = useCallback((): void => {
    if (!image) return;
    setImages(prevImages => [...prevImages, image]);

    navigation.navigate(Routes.studioMiniCreateCollab);
  }, [image, navigation, setImages]);

  const commonButtonProps = { iconColor: colors.common.white, size: 24, style: commonStyles.sideIconButton };

  return (
    <View style={styles.root}>
      {isAdmin(state) && (
        <IconButton
          icon={({ size, color }) =>
            loadingExplore ? (
              <ActivityIndicator color={commonButtonProps.iconColor} />
            ) : (
              <Star color={color} size={size} weight={image?.isExploreImage ? "fill" : "thin"} />
            )
          }
          containerColor={colors.godMode}
          onPress={handleAddExplore}
          {...commonButtonProps}
        />
      )}
      <ZoomOnImageButton imageUrl={image.imageUrl} style={commonStyles.sideIconButton} />
      {context === "saved" && (
        <IconButton
          icon={({ size, ...iconProps }) => <ArrowLineUp {...iconProps} width={size} height={size} />}
          onPress={handlePublish}
          {...commonButtonProps}
        />
      )}
      <IconButton
        icon={({ size, ...iconProps }) =>
          loadingDownload ? <ActivityIndicator color={commonButtonProps.iconColor} /> : <DownloadSimple {...iconProps} width={size} height={size} />
        }
        onPress={handleDownloadImage}
        {...commonButtonProps}
      />
      {context === "saved" && (
        <IconButton
          icon={({ size, ...iconProps }) =>
            loadingDelete ? <ActivityIndicator color={commonButtonProps.iconColor} /> : <TrashSimple {...iconProps} width={size} height={size} />
          }
          onPress={handleDeleteImage}
          {...commonButtonProps}
        />
      )}
    </View>
  );
};
