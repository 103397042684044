/* eslint-disable complexity */
import React, { FC, Fragment, JSX, memo, useMemo } from "react";

import { HourglassSimpleLow } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { Pressable, View, StyleProp, ViewStyle } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import Heart from "@app/assets/icons/heart-straight-duotone.svg";
import { links } from "@app/common/constants/links.const";
import { Collab, ContestStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { customFonts } from "@app/common/style/fonts";
import { darkTheme } from "@app/common/style/theme";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Box } from "@app/components/common/box/box.component";
import { Chip } from "@app/components/common/chip/chip.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { GoalsProgress } from "../../goals-progress/goals-progress.component";
import { goalsProgressHeight } from "../../goals-progress/goals-progress.style";
import { LikeButton } from "../../like-button/like-button.component";
import { ProductPrice } from "../../product-price/product-price.component";
import { ReviewStatusChip } from "../../review-status-chip/review-status-chip.component";

import { defaultRowGap, paddingVerticalGoals, styles } from "./product-list-item.style";

export interface ContestEntryProps {
  winner: boolean;
  contestStatus: ContestStatus;
}

export interface ProductListItemProps {
  product: Collab;
  width: number;
  isFeaturedItem?: boolean;
  hideStageChip?: boolean;
  hideCreatorName?: boolean;
  actionButtons?: JSX.Element[];
  contestEntryProps?: ContestEntryProps;
  style?: StyleProp<ViewStyle>;
}

const ProductListItem: FC<ProductListItemProps> = ({
  product,
  width,
  hideStageChip,
  hideCreatorName,
  actionButtons,
  isFeaturedItem,
  contestEntryProps,
  style,
}) => {
  const { t } = useTranslation();
  const { fontScale } = useWindowDimensions();
  const { colors, roundness } = useTheme();
  const navigation = useNavigation();
  const { navigateToWebview } = useNavigateToWebview();

  const isHidden = product.progress.stage === Stage.hidden;
  const isContestWinner = contestEntryProps?.winner;
  const allVariantsSoldOut = product.inventoryQuantity !== undefined && product.inventoryQuantity <= 0 && product.progress.stage === Stage.forSale;

  const onScreenWithButtons = !!actionButtons?.length;
  const fixedNumberOfLinesForProductName = 2;
  const fixedNumberOfLinesForCreator = hideCreatorName ? 0 : 1;
  const hideLikeProgress = product.progress.stage !== Stage.concept && product.progress.stage !== Stage.contest;
  const hidePreorderProgress = product.progress.stage !== Stage.underReview || product.progress.inDevelopment;
  const goalProgressHeight = !hideLikeProgress || !hidePreorderProgress ? goalsProgressHeight + paddingVerticalGoals * 2 : 0;
  const fixedNamesHeight =
    (fixedNumberOfLinesForProductName + fixedNumberOfLinesForCreator) * customFonts.caption.lineHeight * fontScale +
    goalProgressHeight +
    2 * defaultRowGap;

  const handleImagePress = (): void => navigation.navigate(Routes.product, { id: product.handle });

  const stageChipLabel = useMemo(() => {
    if (isFeaturedItem) return t("featured.label");
    if (isContestWinner) return t("contest.contestWinner");
    return t(`enum.stage.${product.progress.stage}`);
  }, [isContestWinner, isFeaturedItem, product.progress.stage, t]);

  return (
    <View style={[styles.root, { width }, style]}>
      <Pressable onPress={handleImagePress} style={style}>
        <View>
          <Image key={product.collabId} source={product.images[0]?.imageUrl} width={width} style={styles.image} />
          {(!hideStageChip || isContestWinner) && (
            <Chip
              label={stageChipLabel}
              textColor={isContestWinner ? "onWarning" : "onTertiaryContainer"}
              bgColor={isContestWinner ? "warning" : "tertiaryContainer"}
              textVariant="body2"
              theme={darkTheme}
              style={[styles.statusChip, { borderBottomRightRadius: roundness }]}
            />
          )}
          <LikeButton
            liked={product.liked}
            collabId={product.collabId}
            numberOfLikes={product.numberOfLikes}
            buttonComponent={({ onPress }) => (
              <IconButton
                icon={iconProps => (
                  <Heart {...iconProps} width={24} height={24} color={product.liked ? darkTheme.colors.secondary : colors.common.white} />
                )}
                iconColor={colors.common.white}
                onPress={onPress}
                style={styles.likeButton}
              />
            )}
          />
        </View>

        <View style={styles.content}>
          <ReviewStatusChip progress={product.progress} campaignStatus={product.campaign?.status} style={styles.progressChip} />

          <View style={styles.priceRow}>
            <ProductPrice
              price={product.formattedPrice}
              compareAtPrice={product.formattedCompareAtPrice}
              stage={product.progress.stage}
              commonTextProps={{ variant: "body2" }}
            />
            {allVariantsSoldOut && <Chip label={t("cart.soldOut")} disabled style={styles.soldOutChip} />}
          </View>

          <View style={[styles.namesWithGoalsContainer, { height: onScreenWithButtons ? fixedNamesHeight : undefined }]}>
            <Text variant="caption" numberOfLines={onScreenWithButtons ? fixedNumberOfLinesForProductName : 3} style={styles.productName}>
              {product.name}
            </Text>
            {isHidden && (
              <Box columnGap={4} flexDirection="row">
                <HourglassSimpleLow size={16} color={colors.warning} weight="fill" />
                <Trans
                  i18nKey="productDetails.flagged"
                  parent={({ children }: ChildrenProp) => (
                    <Text variant="caption" color="warning" fontStyle="italic">
                      {children}
                    </Text>
                  )}
                  components={{
                    glink: (
                      <Text
                        fontStyle="italic"
                        variant="caption"
                        color="warning"
                        textDecorationLine="underline"
                        onPress={() => navigateToWebview(links.external.termsOfUse, t("settings.about.termsOfUse"))}>
                        <></>
                      </Text>
                    ),
                  }}
                />
              </Box>
            )}
            {!hideCreatorName && (
              <CreatorName
                name={product.creator.displayName}
                badge={product.creator.badge}
                iconSize={16}
                textProps={{ variant: "caption", color: "tertiary", numberOfLines: onScreenWithButtons ? fixedNumberOfLinesForCreator : 3 }}
              />
            )}
            <GoalsProgress
              liked={product.liked}
              numberOfLikes={product.numberOfLikes}
              campaign={product.campaign}
              collabProgress={product.progress}
              creatorId={product.creator.userId}
              hideProgressStatus
              hideLikeProgress={hideLikeProgress}
              hidePreorderProgress={hidePreorderProgress}
              style={[styles.goalsProgress, { height: goalProgressHeight }]}
            />
          </View>
        </View>
      </Pressable>

      {onScreenWithButtons && (
        <View style={[styles.content, styles.buttonsContainer]}>
          {actionButtons?.map((button, index) => <Fragment key={`product-list-item-action-${index}-${product.collabId}`}>{button}</Fragment>)}
        </View>
      )}
    </View>
  );
};

export default memo(ProductListItem);
