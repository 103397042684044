import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import { Collab, ContestStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { UpdateList } from "@app/components/updates/update-list/update-list.component";

import { GoalsProgress } from "../goals-progress/goals-progress.component";

import { CreatorSection } from "./creator-section/creator-section.component";
import { ExpandableText } from "./expandable-text/expandable-text.component";
import { styles } from "./product-details.style";

interface Props {
  product: Collab;
}

export const ProductDetails: FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const isUpdatesEnabled = ![Stage.concept, Stage.contest].includes(product.progress.stage);

  const contest = product.contest;
  const isContestEntry = !!contest;
  const isLiveContestEntry = isContestEntry && contest?.status === ContestStatus.inProgress;
  const contestIdOrHandle = contest?.handle ?? contest?.contestId ?? "";

  const handlePartipateInContest = (): void => navigation.navigate(Routes.contest, { id: contestIdOrHandle });

  const handleViewContestEntries = (): void => navigation.navigate(Routes.contestEntries, { id: contestIdOrHandle });

  return (
    <View style={styles.root}>
      <View style={styles.section}>
        <GoalsProgress liked={product.liked} numberOfLikes={product.numberOfLikes} campaign={product.campaign} collabProgress={product.progress} />
        <Text variant="h6">{product.name}</Text>
        <CreatorSection creator={product.creator} />
      </View>
      <Divider />

      <View style={styles.section}>
        <ListItem
          title={contest?.name ? [product.category.name, t("contest.label", { name: contest?.name })].join(", ") : product.category.name}
          titleProps={{
            variant: "overline",
            textTransform: "uppercase",
            color: "tertiary",
            numberOfLines: 0,
            textAlign: "right",
            style: styles.categoryName,
          }}
          left={
            <Text variant="body1" textTransform="uppercase" color="tertiary">
              {t("productDetails.category")}
            </Text>
          }
          contentContainerStyle={styles.categoryRow}
        />
      </View>
      <Divider />

      {isContestEntry && (
        <>
          <View style={styles.section}>
            {isLiveContestEntry && (
              <Button size="large" mode="outlined" fullWidth onPress={handlePartipateInContest}>
                {t("contest.participateInContest")}
              </Button>
            )}
            <Button size="large" mode="outlined" fullWidth onPress={handleViewContestEntries}>
              {t("contest.viewOtherEntries")}
            </Button>
          </View>
          <Divider />
        </>
      )}

      <ExpandableText title={t("productDetails.description")} text={product.description} />

      {isUpdatesEnabled && (
        <>
          <Divider />
          <UpdateList
            collabId={product.collabId}
            canCreateCollabUpdate={product.progress.canCreateCollabUpdate}
            displayOnlyLatestUpdate
            ListEmptyComponent={
              <Text variant="body2" color="tertiary" style={styles.updatesEmptyState}>
                {t("emptyState.updates.message")}
              </Text>
            }
          />
        </>
      )}
    </View>
  );
};
