import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const styles = StyleSheet.create({
  chip: {
    borderRadius: 0,
    left: 0,
    paddingHorizontal: 1,
    paddingVertical: 4,
    position: "absolute",
    top: 0,
  },
  content: {
    flex: 1,
    margin: 8,
    rowGap: 4,
  },
  image: {
    aspectRatio: imageRatio,
  },
  root: {
    flex: 1,
    marginBottom: 8,
  },
  username: {
    fontFamily: "PPNeueMontreal_600SemiBold",
    fontWeight: "600",
  },
});
