import { StyleSheet } from "react-native";

import { headerHeight } from "@app/router/router.style";

export const borderWidth = 1;
export const marginVertical = 24;
export const marginHorizontal = 20;

export const styles = StyleSheet.create({
  button: {
    flex: 1,
  },
  buttonsContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  contentContainer: {
    overflow: "hidden",
    rowGap: 20,
  },
  image: {
    left: 0,
    position: "absolute",
    top: 0,
  },
  modal: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: marginVertical,
    marginHorizontal,
    marginTop: marginVertical + headerHeight,
  },
  popupContainer: {
    borderWidth,
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  textContainer: {
    marginBottom: 10,
    marginHorizontal: 10,
    rowGap: 20,
  },
  textShadow: {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
  },
});
