export enum StudioFlowType {
  blank = "blank",
  catalog = "catalog",
  image = "image",
}

export enum StudioBottomSheetStep {
  selectFlow = 0,
  catalog = 1,
  catalogProduct = 2,
  prompt = 3,
}
