import { createNavigationContainerRef, useNavigation as useNavigationRN } from "@react-navigation/native";

import {
  CartStackNavigation,
  FeedStackNavigation,
  ProfileStackNavigation,
  SearchStackNavigation,
  StudioStackNavigation,
  ProductUpdateNavigation,
  RootNavigation,
  OnboardingNavigation,
} from "./navigation/screen-navigation.type";
import { RootStackParamList, HomeStackParamList, ProductUpdateStackParamList, OnboardingStackParamList } from "./navigation/stack-param-list.type";

export type { RootStackParamList, HomeStackParamList, ProductUpdateStackParamList, OnboardingStackParamList };

export const useNavigation = (): RootNavigation => useNavigationRN<RootNavigation>();
export const useFeedNavigation = (): FeedStackNavigation => useNavigationRN<FeedStackNavigation>();
export const useCartNavigation = (): CartStackNavigation => useNavigationRN<CartStackNavigation>();
export const useSearchNavigation = (): SearchStackNavigation => useNavigationRN<SearchStackNavigation>();
export const useProfileNavigation = (): ProfileStackNavigation => useNavigationRN<ProfileStackNavigation>();
export const useStudioNavigation = (): StudioStackNavigation => useNavigationRN<StudioStackNavigation>();
export const useProductUpdateNavigation = (): ProductUpdateNavigation => useNavigationRN<ProductUpdateNavigation>();
export const useOnboardingNavigation = (): OnboardingNavigation => useNavigationRN<OnboardingNavigation>();

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export { Routes, Tabs } from "./navigation/routes.enum";
export type {
  NavigationProps,
  StudioStackScreenProps,
  CartStackScreenProps,
  StudioStackNavigation,
  FeedStackScreenProps,
  SearchStackScreenProps,
  ProfileStackScreenProps,
  HomeStackScreenProps,
  ProductUpdateStackScreenProps,
  OnboardingStackScreenProps,
} from "./navigation/screen-navigation.type";
