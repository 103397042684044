import { StyleSheet } from "react-native";

export const borderColor = "rgba(255,255,255,0.1)";
const borderRadius = 10;

export const styles = StyleSheet.create({
  canvas: {
    flex: 1,
  },
  container: {
    borderRadius,
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    width: "100%",
  },
  root: {
    borderRadius,
    borderWidth: 1,
  },
});
