import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { DismissableEmbeddedBanner } from "@app/components/common/dismissable-embedded-banner/dismissable-embedded-banner.component";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { LocalStorageKeys } from "@app/context/local-storage/local-storage.type";

export const EarlyBirdBanner: FC = () => {
  const { t } = useTranslation();

  const { earlyBirdAlertDismissed, setLocalStorage } = useLocalStorageContext();

  const handleDismiss = (): void => {
    setLocalStorage(LocalStorageKeys.earlyBirdAlertDismissed, true);
  };

  return (
    <DismissableEmbeddedBanner
      message={t("products.earlyBirdAlert")}
      color="success"
      alreadyDismissed={earlyBirdAlertDismissed}
      onDismiss={handleDismiss}
    />
  );
};
