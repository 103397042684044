import { isWeb } from "./device.util";

export const generateImageToFormData = async (uri: string, name?: string | null): Promise<FormData> => {
  if (isWeb) {
    const blob = await fetch(uri).then(response => response.blob());
    const formData = new FormData();
    formData.append("image", blob);
    return formData;
  } else {
    const fileType = uri.substring(uri.lastIndexOf(".") + 1);
    /// current issue where the formData isn't actually sent to apollo https://github.com/apollographql/apollo-link-rest/issues/240
    const formData = new FormData();
    // // https://stackoverflow.com/questions/54845956/image-upload-in-react-native-expo-using-fetch-results-in-400-error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append("image", { uri, name: name ?? `photo.${fileType}`, type: `image/${fileType}` });
    return formData;
  }
};
