import React, { FC, useEffect, useState, useRef } from "react";

import { ApolloError } from "@apollo/client";
import LottieView from "lottie-react-native";
import { Warning } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import studioAnimatedDark from "@app/assets/lotties/studio-loading-dark.json";
import studioAnimatedLight from "@app/assets/lotties/studio-loading-light.json";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Text } from "@app/components/common/text/text.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

import { styles } from "./loading-prompt.style";

const generateRandomIndex = (): number => Math.floor(Math.random() * 7) + 1;

interface Props {
  loading: boolean;
  error?: ApolloError;
  taskId: string;
}

export const LoadingPrompt: FC<Props> = ({ loading, error, taskId }) => {
  const { dark } = useTheme();
  const { t } = useTranslation();
  const { bottom: bottomInset } = useSafeAreaInsets();
  const [randomIndex, setRandomIndex] = useState(generateRandomIndex());
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const animatedIconSource = dark ? studioAnimatedDark : studioAnimatedLight;

  useEffect(() => {
    if (loading) {
      intervalRef.current = setInterval(() => {
        setRandomIndex(generateRandomIndex);
      }, 6000);
    }

    return () => (intervalRef.current ? clearInterval(intervalRef.current) : undefined);
  }, [loading]);

  const { setGeneration } = useStudioMiniContext();

  const handleRemoveSet = (): void => {
    setGeneration(prev => {
      if (prev.taskIds && Object.keys(prev.taskIds).length <= 1) return { ...prev, taskIds: null };
      const taskIds = { ...prev.taskIds };
      delete taskIds[taskId];
      return { ...prev, taskIds };
    });
  };

  if (loading)
    return (
      <View style={[styles.flexBox, { marginBottom: bottomInset }]}>
        <View style={styles.loadingContainer}>
          <View style={styles.flexBox}></View>
          <View style={[styles.flexBox, styles.centeredContainer]}>
            <LottieView
              autoPlay
              loop={true}
              useNativeLooping={true}
              source={animatedIconSource}
              resizeMode="contain"
              style={styles.animationContainer}
            />
          </View>
        </View>
        <View style={[styles.flexBox, styles.legendContainer]}>
          <Trans
            i18nKey={`studioMini.prompt.loading.${randomIndex}`}
            parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
            components={{
              tip: (
                <Text color="info">
                  <></>
                </Text>
              ),
              eg: (
                <Text color="tertiary">
                  <></>
                </Text>
              ),
            }}
          />
          <Text variant="body2" textAlign="center" color="action.active">
            {t("studioMini.prompt.loadingMessage")}
          </Text>
        </View>
      </View>
    );

  if (error)
    return (
      <EmptyState
        style={styles.error}
        icon={Warning}
        message={t("studioMini.prompt.generationError")}
        cta={[{ label: t("studioMini.prompt.deleteSet"), onPress: handleRemoveSet }]}
      />
    );

  return null;
};
