import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { IconButton } from "react-native-paper";

import { ProfileStackParamList } from "@app/common/types/navigation/stack-param-list.type";
import { HomeStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { AboutScreen } from "@app/screens/about/about.screen";
import { ChangePasswordScreen } from "@app/screens/change-password/change-password.screen";
import { DeleteAccountScreen } from "@app/screens/delete-account/delete-account.screen";
import { EditProfileScreen } from "@app/screens/edit-profile/edit-profile.screen";
import { EditProfileFieldScreen } from "@app/screens/edit-profile-field/edit-profile-field.screen";
import { GodModeScreen } from "@app/screens/god-mode/god-mode.screen";
import { ManagePromosScreen } from "@app/screens/god-mode/manage-promos/manage-promos.screen";
import { NotificationSettingsScreen } from "@app/screens/notification-settings/notification-settings.screen";
import { ProfileScreen } from "@app/screens/profile/profile.screen";
import { ReferAFriendScreen } from "@app/screens/refer-a-friend/refer-a-friend.screen";
import { SettingsScreen } from "@app/screens/settings/settings.screen";

import { commonScreenOptions } from "../router.const";

const Stack = createNativeStackNavigator<ProfileStackParamList>();

export const ProfileStack: FC<HomeStackScreenProps<Tabs.profile>> = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator initialRouteName={Routes.profile} screenOptions={commonScreenOptions()}>
      <Stack.Screen
        name={Routes.profile}
        component={ProfileScreen}
        getId={({ params }) => params?.id ?? "current"}
        options={{ headerLeft: () => undefined }}
      />
      <Stack.Screen name={Routes.editProfile} component={EditProfileScreen} />
      <Stack.Screen name={Routes.editProfileField} component={EditProfileFieldScreen} />
      <Stack.Screen name={Routes.settings} component={SettingsScreen} />
      <Stack.Screen
        name={Routes.notificationSettings}
        component={NotificationSettingsScreen}
        options={{ title: t("settings.notifications.label") }}
      />
      <Stack.Screen name={Routes.about} component={AboutScreen} />
      <Stack.Screen name={Routes.changePassword} component={ChangePasswordScreen} />
      <Stack.Screen name={Routes.godMode} component={GodModeScreen} />
      <Stack.Screen
        name={Routes.managePromos}
        component={ManagePromosScreen}
        options={{
          headerRight: props => <IconButton {...props} icon={Plus} onPress={() => navigation.navigate(Routes.createPromo)} />,
        }}
      />
      <Stack.Screen name={Routes.deleteAccount} component={DeleteAccountScreen} options={{ title: t("settings.deleteAccount.title") }} />
      <Stack.Screen name={Routes.referAFriend} component={ReferAFriendScreen} />
    </Stack.Navigator>
  );
};
