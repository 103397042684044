import React, { FC, useCallback } from "react";

import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useStudioNavigation } from "@app/common/types/navigation.type";
import { StudioImageList } from "@app/components/studio-mini/studio-image-list/studio-image-list.component";
import { useStudioExploreImages } from "@app/hooks/api/studio/use-studio-explore-images.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { isWeb } from "@app/utils/device.util";

export const ExploreSection: FC = () => {
  const navigation = useStudioNavigation();
  const { data, loading, fetchMore, networkStatus, refetch } = useStudioExploreImages();
  const { fetchMoreItems } = useFetchMoreItems("studioImages", fetchMore);

  const handleOnClickImage = useCallback(
    (image: StudioImage) => navigation.navigate(Routes.studioMiniExploreDetail, { imageId: image.imageId }),
    [navigation],
  );

  const handleFetchMore = (): void => {
    if (fetchMore && !loading && data?.nodes?.length && data?.pageInfo.hasNextPage && data.nodes.length < data.totalCount) {
      void fetchMoreItems({ offset: data?.nodes.length });
    }
  };

  return (
    <StudioImageList
      images={data?.nodes}
      loading={loading}
      networkStatus={networkStatus}
      displayPrompt
      onClickImage={handleOnClickImage}
      onEndReached={handleFetchMore}
      refetch={refetch}
      scrollEnabled={isWeb}
    />
  );
};
