import React, { FC, useCallback, useEffect, useMemo } from "react";

import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { reportError } from "@app/utils/logger/logger.util";

import { FeatureFlag, featureFlagMap } from "./feature-flag.enum";
import { LaunchDarklyContext, LaunchDarklyContextInterface } from "./launch-darkly.context";

export const LaunchDarklyProvider: FC<ChildrenProp> = ({ children }) => {
  const ldClient = useLDClient();
  const flags = useFlags();
  const mapFF = useMemo(() => featureFlagMap(flags), [flags]);

  const isFeatureEnabled = useCallback((name: FeatureFlag): boolean => mapFF[name] ?? false, [mapFF]);

  const alwaysOnFeatureFlagIsDefinedButOff = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const flag = flags[FeatureFlag.alwaysOn];

    if (flag === undefined || flag === null) return false;

    return !flag;
  }, [flags]);

  useEffect(() => {
    if (alwaysOnFeatureFlagIsDefinedButOff) {
      reportError(`Feature flag that should always be on returned false`);
    }
  }, [alwaysOnFeatureFlagIsDefinedButOff]);

  const context = useMemo<LaunchDarklyContextInterface>(() => ({ ldClient, isFeatureEnabled }), [isFeatureEnabled, ldClient]);

  return <LaunchDarklyContext.Provider value={context}>{children}</LaunchDarklyContext.Provider>;
};
