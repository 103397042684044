import React, { FC, useState } from "react";

import { AuthError, AuthErrorCodes, UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Keyboard, View } from "react-native";

import { BottomSheetTextInput } from "@app/components/common/bottom-sheet/bottom-sheet-text-input/bottom-sheet-text-input.component";
import { Button } from "@app/components/common/button/button.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";

import { styles } from "./enter-password-fields.style";

interface Props {
  email: string;
  buttonLabel?: string;
  onSignInSuccess?: (credential: UserCredential) => void;
  onForgotPassword?: () => void;
  onVerifyEmail: () => void;
}

export const EnterPasswordFields: FC<Props> = ({ email, buttonLabel, onSignInSuccess, onForgotPassword, onVerifyEmail }) => {
  const { t } = useTranslation();
  const { handleAuthError } = useHandleAuthError();
  const { state, setVerified } = useAuthContext();

  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeText = (value: string): void => {
    setError(false);
    setPassword(value);
  };

  const handleNext = (): void => {
    if (!password) return;

    setLoading(true);
    Keyboard.dismiss();

    getAuthWhenReady()
      .then(auth =>
        signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            if (onSignInSuccess) {
              onSignInSuccess(userCredential);
            } else if (!userCredential.user.emailVerified) {
              onVerifyEmail();
            } else if (!state.verified) {
              setVerified();
            }

            setLoading(false);
          })
          .catch((e: AuthError) => {
            setLoading(false);

            if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
              setError(true);
            } else {
              handleAuthError(e, LoginAction.loginWithEmail);
            }
          }),
      )
      .catch((e: AuthError) => {
        setLoading(false);
        handleAuthError(e, LoginAction.getAuth);
      });
  };

  return (
    <View style={styles.formView}>
      <View style={styles.inputView}>
        <BottomSheetTextInput label={t("login.email.emailAddress")} value={email} disabled autoComplete="email" />
        <BottomSheetTextInput
          label={t("login.email.password")}
          value={password}
          onChangeText={handleChangeText}
          secureTextEntry={true}
          error={error}
          errorText={t("login.email.invalidPassword")}
          autoComplete="password"
          selectTextOnFocus
          onSubmitEditing={handleNext}
          returnKeyType="next"
          testID="login-password-input"
        />
      </View>
      <Button mode="contained" size="large" loading={loading} disabled={!password} onPress={handleNext}>
        {buttonLabel ?? t("cta.next")}
      </Button>
      {onForgotPassword && (
        <Button size="large" onPress={onForgotPassword}>
          {t("login.email.forgotPassword")}
        </Button>
      )}
    </View>
  );
};
