import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioImages, StudioImagesFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface StudioImagesResponse {
  studioImages: PaginatedStudioImages;
}

interface Variables {
  first?: number;
  offset?: number;
  where: StudioImagesFilteringArgs;
}

const studioImagesQuery = gql`
  query StudioImages($first: Int, $offset: Int, $where: StudioImagesFilteringArgs!) {
    studioImages(first: $first, offset: $offset, where: $where) {
      nodes {
        ...CoreStudioImageFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
`;

export function useStudioImages(options: QueryHookOptions<StudioImagesResponse, Variables>): QueryResult<StudioImagesResponse, "studioImages"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<StudioImagesResponse, Variables>(studioImagesQuery, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });

  return { data: data?.studioImages, loading, error, networkStatus, fetchMore, refetch };
}
