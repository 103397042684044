import { StyleSheet } from "react-native";

import { imageRatio } from "@app/common/constants/image.const";

export const defaultRowGap = 4;
export const paddingVerticalGoals = 8;

export const styles = StyleSheet.create({
  buttonsContainer: {
    rowGap: 8,
  },
  content: {
    flex: 1,
    margin: 8,
    rowGap: defaultRowGap,
  },
  goalsProgress: {
    flexDirection: "column",
    paddingVertical: paddingVerticalGoals,
  },
  image: {
    aspectRatio: imageRatio,
  },
  likeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  namesWithGoalsContainer: {
    rowGap: defaultRowGap,
  },
  priceRow: {
    alignItems: "center",
    columnGap: 4,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  productName: {
    fontFamily: "PPNeueMontreal_600SemiBold",
    fontWeight: "600",
  },
  progressChip: {
    alignSelf: "flex-start",
  },
  root: {
    flex: 1,
    marginBottom: 8,
  },
  soldOutChip: {
    borderRadius: 64,
  },
  statusChip: {
    borderRadius: 0,
    left: 0,
    paddingHorizontal: 1,
    paddingVertical: 4,
    position: "absolute",
    top: 0,
  },
});
