import { StyleSheet } from "react-native";

export const padding = 8;
export const paddingBottom = 78;

export const styles = StyleSheet.create({
  collaboratorRow: {
    columnGap: 4,
  },
  collaboratorRowContainer: {
    marginRight: 8,
  },
  contestName: {
    borderRadius: 2,
  },
  detailsContainer: {
    marginBottom: paddingBottom,
    overflow: "hidden",
    padding,
  },
  namesContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
