import { gql, useQuery } from "@apollo/client";

import { userStateFields } from "@app/common/graphql/fragments.graphql";
import { FeatureAccesses, UserState } from "@app/common/graphql/generated/schema.graphql";
import { BaseResult } from "@app/common/types/apollo-result.type";
import { useProfileContext } from "@app/context/profile/profile.context";

interface Response {
  featureAccesses: FeatureAccesses;
  userState: UserState;
}

interface Variables {
  userId: string;
}

const combinedQuery = gql`
  query UserState($userId: UserId!) {
    featureAccesses(userId: $userId) {
      infiniteTokens {
        allowed
        requiredRole
      }
    }
    userState(userId: $userId) {
      ...UserStateFields
    }
  }
  ${userStateFields}
`;

export function useUserState(userId?: string): BaseResult<Response> {
  const { profile } = useProfileContext();

  return useQuery<Response, Variables>(combinedQuery, {
    variables: { userId: userId ?? profile?.userId ?? "" },
    skip: !(userId ?? profile?.userId),
  });
}
