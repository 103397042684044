/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Modal, useTheme } from "react-native-paper";

import { transparent } from "@app/common/style/theme";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Image } from "@app/components/common/image/image.component";
import { TextProps } from "@app/components/common/text/text.component";
import { useFeedEntityCardHeight } from "@app/hooks/utils/use-feed-entity-card-height.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isDesktop } from "@app/utils/device.util";

import { borderWidth, marginHorizontal, styles } from "./generic-dialog.style";

interface Props {
  title: FC<Omit<TextProps, "children">>;
  subtitle?: FC<Omit<TextProps, "children">>;
  caption?: FC<Omit<TextProps, "children">>;
  buttons: [FC<Omit<ButtonProps, "children">>, FC<Omit<ButtonProps, "children">> | undefined];
  imageUrl: string;
  loading?: boolean;
  dismissLabel?: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onDismiss?: () => void;
}

export const GenericDialog: FC<Props> = ({
  title: TitleComponent,
  subtitle: SubtitleComponent,
  caption: CaptionComponent,
  buttons: Buttons,
  imageUrl,
  dismissLabel,
  loading,
  visible,
  setVisible,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const { dark, colors, roundness } = useTheme();
  const { cardHeight } = useFeedEntityCardHeight();
  const { width: windowWidth } = useWindowDimensions();

  const FirstButton = Buttons[0];
  const SecondButton = Buttons[1];
  const maxDialogWidth = windowWidth - marginHorizontal * 2;
  const dialogHeight = isDesktop ? 700 : 500;
  const imageWidth = windowWidth - (marginHorizontal + borderWidth) * 2;
  const borderRadius = roundness * 2;
  const gradient = dark
    ? ["rgba(29,29,29,0)", "rgba(29,29,29,0)", "rgba(29,29,29,1)"]
    : ["rgba(247,247,247,0)", "rgba(247,247,247,0)", "rgba(247,247,247,1)"];
  const textShadowStyle = [styles.textShadow, { textShadowColor: dark ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)" }];

  const handleDismiss = useCallback(() => {
    onDismiss?.();
    setVisible(false);
  }, [onDismiss, setVisible]);

  return (
    <Modal visible={visible} dismissable={false} style={styles.modal} contentContainerStyle={{ shadowColor: transparent }}>
      {!loading && (
        <View style={[styles.contentContainer, { maxWidth: isDesktop ? maxDialogWidth : undefined }]}>
          <View
            style={[
              styles.popupContainer,
              {
                backgroundColor: colors.tertiaryContainer,
                borderColor: colors.onSurfaceDisabled,
                borderRadius,
                height: dialogHeight,
                maxHeight: cardHeight - 80,
              },
            ]}>
            <Image
              source={imageUrl}
              width={imageWidth}
              style={[styles.image, { width: imageWidth, height: imageWidth, borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }]}
            />
            <LinearGradient
              locations={[0, 0.3, 1]}
              colors={gradient}
              style={[styles.image, { width: imageWidth, height: imageWidth, borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }]}
            />

            <View style={styles.textContainer}>
              <TitleComponent variant="h5" textAlign="center" style={textShadowStyle} />

              {SubtitleComponent && <SubtitleComponent variant="h8" textAlign="center" style={textShadowStyle} />}

              {CaptionComponent && <CaptionComponent variant="body2" textAlign="center" />}

              <View style={styles.buttonsContainer}>
                <FirstButton size="large" color="primary" mode={!SecondButton ? "contained" : "outlined"} containerStyle={styles.button} />
                {SecondButton && <SecondButton size="large" mode="contained" containerStyle={styles.button} />}
              </View>
            </View>
          </View>

          <Button onPress={handleDismiss}>{dismissLabel ?? t("cta.dismiss")}</Button>
        </View>
      )}
    </Modal>
  );
};
