import React, { FC } from "react";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useTheme } from "react-native-paper";

import { NavigationProps, OnboardingStackParamList, Routes } from "@app/common/types/navigation.type";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { LinkInstagramAccountScreen } from "@app/screens/onboarding/link-instagram-account/link-instagram-account.screen";
import { NotificationsPermissionScreen } from "@app/screens/onboarding/notifications-permission/notifications-permission.screen";
import { UserInfoScreen } from "@app/screens/onboarding/user-info/user-info.screen";

import { commonScreenOptions, logoAsHeaderTitle } from "../router.const";

const Stack = createNativeStackNavigator<OnboardingStackParamList>();

export const OnboardingStack: FC<NavigationProps<Routes.onboardingStack>> = () => {
  const { colors } = useTheme();
  const { initialStep } = useOnboardingContext();

  return (
    <Stack.Navigator
      initialRouteName={initialStep}
      screenOptions={{ ...commonScreenOptions(), ...logoAsHeaderTitle(colors.onBackground), headerLeft: () => undefined }}>
      <Stack.Screen name={Routes.userInfo} component={UserInfoScreen} />
      <Stack.Screen name={Routes.notificationsPermission} component={NotificationsPermissionScreen} />
      <Stack.Screen name={Routes.linkInstagramAccount} component={LinkInstagramAccountScreen} />
    </Stack.Navigator>
  );
};
