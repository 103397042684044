import React, { FC, useState } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { imageRatio } from "@app/common/constants/image.const";
import { productStackMargin } from "@app/common/constants/products.const";
import { Collab, CollabImage } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { Text } from "@app/components/common/text/text.component";
import { useFeedEntityCardHeight } from "@app/hooks/utils/use-feed-entity-card-height.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { ProductImages } from "../product-images/product-images.component";
import { ProductPrice } from "../product-price/product-price.component";
import { ProductSideIconButtons } from "../product-side-icon-buttons/product-side-icon-buttons.component";

import { ProductCardDetails } from "./product-card-details/product-card-details.component";
import { styles } from "./product-card.style";

interface Props {
  product: Collab;
  feedIndex: number;
}

export const ProductCard: FC<Props> = ({ product, feedIndex }) => {
  const { width: windowWidth } = useWindowDimensions();
  const { t } = useTranslation();
  const { roundness } = useTheme();
  const { cardHeight } = useFeedEntityCardHeight();

  const { collabId, images } = product;

  const [currentImage, setCurrentImage] = useState<CollabImage | undefined>(product.images[0]);

  const imageWidth = windowWidth - 2 * productStackMargin;
  const maxImageHeight = imageWidth * imageRatio;
  const [imageHeight, setImageHeight] = useState(maxImageHeight);

  const bottomGradient = ["rgba(0,0,0,0)", "rgba(0,0,0,0)", "#404040"];

  const background = <LinearGradient locations={[0, 0.4, 1]} colors={bottomGradient} style={styles.background} />;

  const contentOnImage = (
    <View style={styles.imageContentContainer}>
      <View style={styles.imageContent}>
        <View style={[styles.stageChip, { borderTopRightRadius: roundness, backgroundColor: darkTheme.colors.tertiaryContainer }]}>
          <Text variant="body2" color="onTertiaryContainer" theme={darkTheme}>
            {t(`enum.stage.${product.progress.stage}`)}
          </Text>

          <ProductPrice
            price={product.formattedPrice}
            compareAtPrice={product.formattedCompareAtPrice}
            stage={product.progress.stage}
            commonTextProps={{ variant: "body2", color: "onTertiaryContainer" }}
            theme={darkTheme}
          />
        </View>
      </View>

      <ProductSideIconButtons product={product} currentImage={currentImage} context="card" />
    </View>
  );

  return (
    <ProductImages
      collabId={collabId}
      images={images}
      setCurrentImage={setCurrentImage}
      imageProps={{ height: imageHeight, width: imageWidth }}
      background={background}
      contentOnImage={contentOnImage}
      contentUnderImage={
        <ProductCardDetails product={product} feedIndex={feedIndex} maxImageHeight={maxImageHeight} setImageHeight={setImageHeight} />
      }
      style={{ height: cardHeight }}
    />
  );
};
