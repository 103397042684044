import { createContext, useContext } from "react";

import { ReactNativeLDClient } from "@launchdarkly/react-native-client-sdk";
import { LDClient } from "launchdarkly-react-client-sdk";

import { FeatureFlag } from "./feature-flag.enum";

export interface LaunchDarklyContextInterface {
  ldClient?: LDClient | ReactNativeLDClient | null;
  isFeatureEnabled: (name: FeatureFlag) => boolean;
}

export const LaunchDarklyContext = createContext<LaunchDarklyContextInterface>({
  ldClient: null,
  isFeatureEnabled: (_name: FeatureFlag) => false,
});

export const defaultLDContext = {
  kind: "user",
  key: "anonymous",
  anonymous: true,
};

export const useLaunchDarklyContext = (): LaunchDarklyContextInterface => useContext(LaunchDarklyContext);
