import { StyleSheet } from "react-native";

export const catalogProductImageSize = 40;

export const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    height: 40,
    justifyContent: "center",
    marginTop: 8,
  },
  catalogProductButton: {
    marginLeft: "auto",
  },
  catalogProductContainer: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
  },
  divider: {
    height: "60%",
    marginVertical: 16,
    width: 1,
  },
});
