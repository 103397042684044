import React, { FC, useMemo } from "react";

import { Trans } from "react-i18next";

import { links } from "@app/common/constants/links.const";
import { campaignDurationInDays } from "@app/common/constants/products.const";
import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { useCampaignContext } from "@app/context/campaign/campaign.context";
import { useConfigContext } from "@app/context/config/config.context";
import { useNavigateToWebview } from "@app/hooks/utils/use-navigate-to-webview.hook";

interface Props extends Omit<TextProps, "children"> {
  collab: Collab;
}

export const StartCampaignInfo: FC<Props> = ({ collab, ...textProps }) => {
  const { config } = useConfigContext();
  const { navigateToWebview } = useNavigateToWebview();
  const { closeStartCampaignDialog } = useCampaignContext();

  const offScriptPercentageOfRevenue = config?.offScriptPercentageOfRevenue;

  const { formattedPrice: price, formattedCompareAtPrice: compareAtPrice, campaign, progress } = collab;

  const orderCountNeeded = campaign?.orderCountNeeded;

  const stringKey = useMemo(() => {
    const baseKey = "startCampaign.dialog.explanation";

    if (progress.waitingForQuote) return `${baseKey}.waitingForQuote`;

    if (progress.canRequestQuote) return `${baseKey}.canRequestQuote`;

    return `${baseKey}.canStartCampaign`;
  }, [progress.canRequestQuote, progress.waitingForQuote]);

  const openCreatorHandbookLink = (): void => {
    closeStartCampaignDialog();
    navigateToWebview(links.external.creatorHandbook);
  };

  return (
    <Trans
      i18nKey={stringKey}
      parent={({ children }: ChildrenProp) => <Text {...textProps}>{children}</Text>}
      values={{
        campaignDurationInDays,
        price,
        compareAtPrice,
        orderCountNeeded,
        offScriptPercentageOfRevenue,
      }}
      components={{
        bold: (
          <Text {...textProps} variant="h9">
            <></>
          </Text>
        ),
        chLink: (
          <Text {...textProps} textDecorationLine="underline" onPress={openCreatorHandbookLink}>
            <></>
          </Text>
        ),
      }}
    />
  );
};
