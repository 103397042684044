import React, { FC } from "react";

import { View } from "react-native";

import { Badge } from "@app/common/graphql/generated/schema.graphql";
import { Text, TextProps } from "@app/components/common/text/text.component";
import { UserBadge } from "@app/components/common/user-badge/user-badge.component";

import { styles } from "./creator-name.style";

interface Props {
  name: string;
  badge: Badge;
  iconSize?: number;
  textProps: Omit<TextProps, "children">;
}

export const CreatorName: FC<Props> = ({ name, badge, iconSize = 16, textProps }) => {
  return (
    <View style={styles.root}>
      <Text numberOfLines={2} {...textProps} style={[textProps.style, styles.name]}>
        {name}
      </Text>
      <UserBadge type={badge} size={iconSize} />
    </View>
  );
};
