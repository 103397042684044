import React, { FC, useCallback, JSX, useState } from "react";

import { Skeleton } from "moti/skeleton";
import { Trophy } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { FlatList, ListRenderItem } from "react-native";
import { useTheme } from "react-native-paper";

import { contestListItemImageSize } from "@app/common/constants/contests.const";
import { Contest, ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { Box } from "@app/components/common/box/box.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useContests } from "@app/hooks/api/contests/use-contests.hook";

import { ContestListItem } from "../contest-list-item/contest-list-item.component";

import { styles } from "./contest-list.style";

interface Props {
  status: ContestStatus;
  includeHidden?: boolean;
}

export const ContestList: FC<Props> = ({ status, includeHidden }) => {
  const { t } = useTranslation();
  const { dark } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();

  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, refetch } = useContests({
    variables: { where: { includeHidden, status } },
    onError: error => showErrorSnackbar({ error }),
  });

  const contests = data?.nodes;
  const noContests = !contests?.length;

  const handleRefreshing = async (): Promise<void> => {
    if (!refetch) return;

    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadingState = (
    <FlatList
      data={new Array(10).fill(0)}
      keyExtractor={(_item, index) => `skeleton-${status}-${index}`}
      renderItem={() => (
        <Box paddingBottom={1}>
          <Skeleton height={contestListItemImageSize} width="100%" radius="square" colorMode={dark ? "dark" : "light"} />
        </Box>
      )}
      contentContainerStyle={styles.listContentContainer}
      showsVerticalScrollIndicator={false}
    />
  );

  const listEmpty = loading ? (
    loadingState
  ) : noContests ? (
    <EmptyState icon={Trophy} message={t("emptyState.contests", { context: status })} />
  ) : undefined;

  const renderContestItem = useCallback<ListRenderItem<Contest>>(
    ({ item }): JSX.Element => <ContestListItem contest={item} style={styles.listItem} textContainerStyle={styles.listItemTextContainer} />,
    [],
  );

  return (
    <FlatList
      data={contests}
      renderItem={renderContestItem}
      keyExtractor={item => `contest-item-${item.contestId}`}
      ListEmptyComponent={listEmpty}
      numColumns={1}
      scrollEnabled
      contentContainerStyle={styles.listContentContainer}
      showsVerticalScrollIndicator={false}
      refreshing={refreshing}
      onRefresh={() => void handleRefreshing()}
    />
  );
};
