/* eslint-disable max-lines */
import React, { JSX } from "react";

import {
  ChatCenteredDots,
  HeartStraight,
  Newspaper,
  RocketLaunch,
  ShoppingCartSimple,
  Tote,
  Trophy,
  Wallet,
  Warning,
  Confetti,
} from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";

import HandCoins from "@app/assets/icons/hand-coins-fill.svg";
import SealIcon from "@app/assets/icons/seal-fill.svg";
import VerifiedBadge from "@app/assets/icons/verified-badge.svg";
import { CartOrdersTab } from "@app/common/enums/cart-orders-tab.enum";
import { TrackEvent } from "@app/common/enums/track-events.enum";
import { ActivityVerb, Contest, Notification, NotificationActor, NotificationObject } from "@app/common/graphql/generated/schema.graphql";
import { Routes, Tabs, useFeedNavigation } from "@app/common/types/navigation.type";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";

import { CtaType, NotifTrackingData, NotificationProps } from "./notification-props.type";
import { getButton, getContestFromNotifiableEntity, getIdFromNotifiableEntity, getImageFromNotifiableEntity } from "./notification-props.util";
import { useNotificationPropsSubtitle } from "./use-notification-props-subtitle.hook";
import { useNotificationPropsTitle } from "./use-notification-props-title.hook";

// eslint-disable-next-line max-lines-per-function, complexity
export function useNotificationProps(notification: Notification): NotificationProps | undefined {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useFeedNavigation();

  const { trackInMixpanel } = useMixpanelContext();

  const title = useNotificationPropsTitle(notification);
  const subtitle = useNotificationPropsSubtitle(notification);

  const activity = notification.activities[0];
  const notifObject = activity.object;
  const notifActor = activity.actor;
  const timestamp = activity.happenedAt;

  function wrapWithTracking(onPress: () => void, notifData: NotifTrackingData): () => void {
    return () => {
      onPress();
      trackInMixpanel(TrackEvent.inAppNotifInteraction, notifData);
    };
  }

  function getProductPageOnPress(entity: NotificationActor | NotificationObject, notifData: NotifTrackingData): (() => void) | undefined {
    if (entity.__typename === "Collab") return wrapWithTracking(() => navigation.navigate(Routes.product, { id: entity.handle }), notifData);
    if (entity.__typename === "CollabUpdate")
      return wrapWithTracking(
        () => navigation.navigate(Routes.productUpdateStack, { screen: Routes.productUpdate, params: { updateId: entity.collabUpdateId } }),
        notifData,
      );
    if (entity.__typename === "Order")
      return wrapWithTracking(
        () =>
          navigation.navigate(Routes.home, { screen: Tabs.cartOrders, params: { screen: Routes.cartOrders, params: { tab: CartOrdersTab.orders } } }),
        notifData,
      );

    return undefined;
  }

  function getViewItemDetailsButton(
    entity: NotificationActor | NotificationObject,
    notifData: NotifTrackingData,
    overrideLabel?: string,
  ): JSX.Element | undefined {
    if (entity.__typename === "Collab") return getButton(overrideLabel ?? t("cta.viewItemDetails"), getProductPageOnPress(entity, notifData));
    if (entity.__typename === "CollabUpdate")
      return getButton(overrideLabel ?? t("cta.viewCollabUpdateDetails"), getProductPageOnPress(entity, notifData));
    if (entity.__typename === "Order") return getButton(overrideLabel ?? t("cta.viewOrders"), getProductPageOnPress(entity, notifData));

    return undefined;
  }

  function getShareConceptButton(entity: NotificationActor | NotificationObject, notifData: NotifTrackingData): JSX.Element | undefined {
    if (entity.__typename !== "Collab") return undefined;

    return (
      <ShareButton
        buttonComponent={({ onPress }) => getButton(t("cta.shareConcept"), wrapWithTracking(onPress, notifData))}
        entityId={entity.collabId}
        prettyId={entity.handle}
        entityType="collab"
        title={entity.name}
      />
    );
  }

  function getPaymentIcon(verb: ActivityVerb): JSX.Element {
    switch (verb) {
      case ActivityVerb.paymentFailed:
        return <Warning key={`left-image-${notification.notificationId}`} color={colors.warning} size={24} weight="fill" />;
      case ActivityVerb.paymentIncoming:
        return <Wallet key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />;
      default:
        return <Confetti key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />;
    }
  }

  function getContestPageOnPress(contest: Contest, notifData: NotifTrackingData): () => void {
    const contestIdOrHandle = contest.handle ?? contest.contestId;

    return wrapWithTracking(() => navigation.navigate(Routes.contest, { id: contestIdOrHandle }), notifData);
  }

  switch (notification.verb) {
    case ActivityVerb.becomeCreator:
      return {
        leftIcon: <VerifiedBadge key={`left-image-${notification.notificationId}`} width={24} height={24} />,
        title,
        timestamp,
      };
    case ActivityVerb.becomeSellable: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };
      return {
        leftIcon: <Tote key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData),
      };
    }
    case ActivityVerb.contestEndingSoon:
    case ActivityVerb.contestGoLive:
    case ActivityVerb.contestTopUser: {
      const contest = getContestFromNotifiableEntity(notifObject, notification.verb);
      if (!contest) return undefined;

      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: contest.contestId,
        ctaType: CtaType.viewContestDetails,
      };

      return {
        leftIcon: <Trophy key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(contest),
        onPress: getContestPageOnPress(contest, notifData),
        button: getButton(t("cta.viewContestDetails"), getContestPageOnPress(contest, notifData)),
      };
    }
    case ActivityVerb.deniedCollabToDevelopment: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };
      return {
        leftIcon: <Newspaper key={`left-image-${notification.notificationId}`} color={colors.warning} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData),
      };
    }
    case ActivityVerb.deniedCollabToUnderReview: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };
      return {
        leftIcon: <Newspaper key={`left-image-${notification.notificationId}`} color={colors.warning} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData),
      };
    }
    case ActivityVerb.fullLikesMilestone: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.shareConcept,
      };
      return {
        leftIcon: <HeartStraight key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData, t("startCampaign.moreInfo")),
      };
    }
    case ActivityVerb.halfLikesMilestone: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.shareConcept,
      };
      return {
        leftIcon: <HeartStraight key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getShareConceptButton(notifObject, notifData),
      };
    }
    case ActivityVerb.like: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };
      return {
        leftIcon: <HeartStraight key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData),
      };
    }
    case ActivityVerb.promotedCollabToDevelopment: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };
      return {
        leftIcon: <ShoppingCartSimple key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData),
      };
    }
    case ActivityVerb.promotedCollabToUnderReview: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };
      return {
        leftIcon: <HandCoins key={`left-image-${notification.notificationId}`} color={colors.secondary} width={24} height={24} />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData),
      };
    }
    case ActivityVerb.promotedCollabToUnderReviewReady: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.startCampaign,
      };
      return {
        leftIcon: <RocketLaunch key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getViewItemDetailsButton(notifObject, notifData, t("startCampaign.moreInfo")),
      };
    }
    case ActivityVerb.underReviewEndingInTenDays: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.shareConcept,
      };
      return {
        leftIcon: <SealIcon key={`left-image-${notification.notificationId}`} color={colors.warning} width={24} height={24} />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getShareConceptButton(notifObject, notifData),
      };
    }
    case ActivityVerb.underReviewEndingInThreeDays: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.shareConcept,
      };
      return {
        leftIcon: <SealIcon key={`left-image-${notification.notificationId}`} color={colors.warning} width={24} height={24} />,
        title,
        subtitle,
        timestamp,
        rightImage: getImageFromNotifiableEntity(notifObject),
        onPress: getProductPageOnPress(notifObject, notifData),
        button: getShareConceptButton(notifObject, notifData),
      };
    }
    case ActivityVerb.collabUpdate: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewUpdateDetails,
      };

      return {
        title,
        subtitle,
        button: getViewItemDetailsButton(notifActor, notifData),
        leftIcon: <Newspaper key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        rightImage: getImageFromNotifiableEntity(notifActor) ?? getImageFromNotifiableEntity(notifObject),
        timestamp,
      };
    }
    case ActivityVerb.collabUpdateComment:
    case ActivityVerb.collabComment: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };

      return {
        title,
        titleProps: { variant: "caption" },
        button: getViewItemDetailsButton(notifObject, notifData),
        leftIcon: <ChatCenteredDots key={`left-image-${notification.notificationId}`} color={colors.onBackground} size={24} weight="fill" />,
        rightImage: getImageFromNotifiableEntity(notifObject),
        timestamp,
      };
    }
    case ActivityVerb.paymentFailed:
    case ActivityVerb.paymentSucceeded:
    case ActivityVerb.paymentIncoming: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewOrders,
      };

      return {
        title,
        subtitle,
        button: getViewItemDetailsButton(notifObject, notifData),
        leftIcon: getPaymentIcon(notification.verb),
        rightImage: getImageFromNotifiableEntity(notifObject),
        timestamp,
      };
    }
    case ActivityVerb.preorderPlaced: {
      const notifData: NotifTrackingData = {
        notifType: notification.verb,
        title,
        subtitle,
        entityId: getIdFromNotifiableEntity(notifObject),
        ctaType: CtaType.viewItemDetails,
      };

      return {
        title,
        subtitle,
        button: getViewItemDetailsButton(notifObject, notifData),
        leftIcon: <HandCoins key={`left-image-${notification.notificationId}`} color={colors.onBackground} width={24} height={24} />,
        rightImage: getImageFromNotifiableEntity(notifObject),
        timestamp,
      };
    }
    default:
      return undefined;
  }
}
