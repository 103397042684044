import React, { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";
import { WebView, WebViewNavigation } from "react-native-webview";

import { NavigationProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { useAuthProfile } from "@app/hooks/api/use-auth-profile.hook";
import { logoAsHeaderTitle } from "@app/router/router.const";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./webview.style";

export const WebViewScreen: React.FC<NavigationProps<Routes.webview>> = ({ navigation, route }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { getAuthProfile } = useAuthProfile();

  const handleClose = useCallback((): void => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(Routes.home, { screen: Tabs.feed, params: { screen: Routes.feed } });
    }
  }, [navigation]);

  useEffect(() => {
    if (!route.params?.url) {
      handleClose();
    }
  }, [handleClose, navigation, route.params?.url]);

  useEffect(() => {
    navigation.setOptions({
      ...(route.params?.title ? { title: route.params.title } : logoAsHeaderTitle(colors.onBackground)),
      headerTintColor: colors.common.black,
      headerStyle: { backgroundColor: colors.common.white },
    });
  }, [colors, navigation, route.params]);

  const handleStateChange = (e: WebViewNavigation): void => {
    if (e.url.includes("/profile?type=instagram&result=success")) {
      void getAuthProfile().then(() => handleClose());
    }
    if (e.url.includes("/profile?type=instagram&result=error")) {
      Alert.alert(t("error.label"), t("error.generic"), [{ text: t("cta.ok"), onPress: () => handleClose(), style: "default" }]);
    }
  };

  return !isWeb ? <WebView style={styles.container} source={{ uri: route.params?.url ?? "" }} onNavigationStateChange={handleStateChange} /> : null;
};
