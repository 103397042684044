import React, { FC, useEffect } from "react";

import { NavigationProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { HandleResetPassword } from "@app/components/login/handle-reset-password/handle-reset-password.component";
import { HandleVerifyEmail } from "@app/components/login/handle-verify-email/handle-verify-email.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";

import { styles } from "./handle-auth-action.style";

export const HandleAuthActionScreen: FC<NavigationProps<Routes.handleAuthAction>> = ({ navigation, route }) => {
  const { showErrorSnackbar } = useSnackbarContext();

  const { state } = useAuthContext();
  const { setHideLoginScreen } = useOnboardingContext();

  const mode = route.params?.mode;
  const actionCode = route.params?.oobCode;

  // Revert hideLoginScreen, so the login screen is shown after logout
  useEffect(() => {
    return () => setHideLoginScreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mode || !actionCode || !["verifyEmail", "resetPassword"].includes(mode)) {
      showErrorSnackbar();
      navigation.navigate(Routes.home, { screen: Tabs.feed, params: { screen: Routes.feed } });
    }
  }, [actionCode, mode, navigation, showErrorSnackbar, state.connected, state.verified]);

  return (
    <ScreenWrapper style={styles.root}>
      {actionCode && mode === "verifyEmail" && <HandleVerifyEmail actionCode={actionCode} />}

      {actionCode && mode === "resetPassword" && <HandleResetPassword actionCode={actionCode} />}
    </ScreenWrapper>
  );
};
