import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  bottomSheetContainer: {
    marginBottom: 16,
    paddingHorizontal: 0,
  },
  creditButtonContainer: {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  creditButtonOverlay: {
    position: "absolute",
    right: 5,
    top: 5,
  },
});
