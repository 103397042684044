import React, { useEffect, JSX, FC } from "react";

import { useSharedValue } from "react-native-reanimated";

import { ImageToBeUsed } from "@app/common/types/image.type";
import { arrToHash } from "@app/utils/drag-drop.util";

import { DraggableImageItem } from "../draggable-image-item/draggable-image-item.component";

interface Props {
  images: ImageToBeUsed[];
  onRemove: (imageUrl: string) => void;
  onImageOrderChange: (images: ImageToBeUsed[]) => void;
}

export const DraggableImages: FC<Props> = ({ onImageOrderChange, images, onRemove }): JSX.Element[] => {
  const positions = useSharedValue(arrToHash(images, "imageUrl"));

  useEffect(() => {
    positions.value = arrToHash(images, "imageUrl");
  }, [images, positions]);

  const handleOnDropEnd = (): void => {
    const newOrders = Array<ImageToBeUsed | undefined>(images.length - 1).fill(undefined);
    const positionEntries = Object.entries(positions.value);
    positionEntries.forEach(([url, index]) => {
      newOrders[index - 1] = images.find(i => i.imageUrl === url);
    });

    onImageOrderChange(newOrders.filter(Boolean) as ImageToBeUsed[]);
  };

  return images.map(image => (
    <DraggableImageItem
      key={image.imageUrl}
      image={image}
      onRemove={onRemove}
      positions={positions}
      itemsCount={images.length}
      onDropFinish={handleOnDropEnd}
    />
  ));
};
