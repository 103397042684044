import React, { FC } from "react";

import { useFeaturedCreator } from "@app/hooks/api/profile/use-featured-creator.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import ProfileListItem from "../profile-list/profile-list-item/profile-list-item.component";

import { styles } from "./featured-profile.style";

export const FeaturedProfile: FC = () => {
  const { width: windowWidth } = useWindowDimensions();

  const { data: profile } = useFeaturedCreator();

  if (!profile) return null;

  return <ProfileListItem profile={profile} isFeaturedItem width={windowWidth / 2} style={[styles.root, { width: windowWidth }]} />;
};
