import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { StyleProp, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { Collab, Stage } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";

import { ProductPrice } from "../product-price/product-price.component";
import { ReviewStatusChip } from "../review-status-chip/review-status-chip.component";

import { ProductOverlayActionButtons } from "./product-overlay-action-buttons/product-overlay-action-buttons.component";
import { styles } from "./product-overlay.style";

type ProductStatus = Stage | "inDevelopment";

interface Props {
  product: Collab;
  style?: StyleProp<ViewStyle>;
}

export const ProductOverlay: FC<Props> = ({ product, style }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { config } = useConfigContext();
  const likeCountObjective = config?.likeCountObjective ?? 1;
  const productStatus = product.progress.inDevelopment ? "inDevelopment" : product.progress.stage;

  const statusColorMap: Record<ProductStatus, string> = {
    [Stage.concept]: colors.secondary,
    [Stage.underReview]: colors.warning,
    [Stage.forSale]: colors.success,
    inDevelopment: colors.success,
    [Stage.contest]: colors.secondary,
    [Stage.hidden]: colors.tertiary,
  };

  const handlePress = (): void => navigation.navigate(Routes.product, { id: product.handle });

  return (
    <ListItem
      title={product.name}
      titleProps={{ variant: "caption", textTransform: "uppercase", numberOfLines: 1 }}
      subtitle={
        <ProductPrice price={product.formattedPrice} compareAtPrice={product.formattedCompareAtPrice} commonTextProps={{ variant: "caption" }} />
      }
      description={
        <View style={styles.statusContainer}>
          <View style={[styles.statusBadge, { backgroundColor: statusColorMap[productStatus] }]} />
          <Text variant="caption">
            {t("products.status", {
              context: productStatus,
              fundedPercentage: product.campaign?.percentage,
              likeCount: product.formattedNumberOfLikes,
              likeCountObjective,
            })}
          </Text>
          {productStatus === Stage.underReview && <ReviewStatusChip progress={product.progress} campaignStatus={product.campaign?.status} />}
        </View>
      }
      imageProps={{ image: product.images?.[0].imageUrl, size: 80, style: { borderRadius: 0 } }}
      right={<ProductOverlayActionButtons product={product} />}
      onPress={handlePress}
      contentContainerStyle={styles.contentContainer}
      textContainerStyle={styles.textContainer}
      style={[{ backgroundColor: colors.tertiaryContainer }, styles.root, style]}
    />
  );
};
