import React, { FC } from "react";

import { formatDate } from "date-fns";
import { Trans } from "react-i18next";

import { CollabCampaignStatus, Stage } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Text } from "@app/components/common/text/text.component";

interface Props {
  stage: Stage;
  reviewEndAt?: string;
  campaignStatus?: CollabCampaignStatus;
}

export const PurchaseCampaignItemCaption: FC<Props> = ({ stage, reviewEndAt, campaignStatus }) => {
  if (stage !== Stage.underReview || !reviewEndAt) return null;

  return (
    <Trans
      i18nKey="preorder.reserveCaption"
      values={{ date: formatDate(new Date(reviewEndAt), "MMMM d, yyyy") }}
      context={campaignStatus}
      parent={({ children }: ChildrenProp) => (
        <Text variant="caption" color="tertiary" textAlign="center">
          {children}
        </Text>
      )}
      components={{
        b: (
          <Text variant="caption" textAlign="center">
            <></>
          </Text>
        ),
      }}
    />
  );
};
