import React, { FC, useCallback, useMemo } from "react";

import { Skeleton } from "moti/skeleton";
import { Placeholder } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ListRenderItem, TouchableOpacity } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useTheme } from "react-native-paper";

import { CatalogProduct, CategoryWithCount } from "@app/common/graphql/generated/schema.graphql";
import { Box } from "@app/components/common/box/box.component";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useCatalogProducts } from "@app/hooks/api/use-catalog-products.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

interface Props {
  onSelectProduct: () => void;
  category?: CategoryWithCount | null;
}

export const StudioSelectProduct: FC<Props> = ({ onSelectProduct, category }) => {
  const { roundness, dark } = useTheme();
  const { t } = useTranslation();

  const { setGeneration, generation } = useStudioMiniContext();
  const { width: screenWidth } = useWindowDimensions();

  const { data, loading } = useCatalogProducts({
    variables: { where: { categoryId: category?.categoryId ?? generation.catalogProduct?.categoryId ?? "" } },
    skip: !category?.categoryId,
  });

  const nbColumns = 2;
  const betweenImagesGap = 20;
  const listPadding = 20;
  const screenWidthWithoutPadding = screenWidth - 2 * listPadding - betweenImagesGap;
  const imageWidth = screenWidthWithoutPadding / nbColumns;

  const handleSelectProduct = useCallback(
    (catalogProduct: CatalogProduct): void => {
      setGeneration(prev => ({ ...prev, catalogProduct }));
      onSelectProduct();
    },
    [onSelectProduct, setGeneration],
  );

  const emptyComponent = useMemo(
    () =>
      loading && !data ? (
        <Box flexDirection="row" flexWrap="wrap" rowGap={betweenImagesGap} columnGap={betweenImagesGap}>
          {Array.from(Array(2).keys()).map(key => (
            <Skeleton key={key} width={imageWidth} radius={roundness} height={imageWidth} colorMode={dark ? "dark" : "light"} />
          ))}
        </Box>
      ) : (
        <EmptyState icon={({ color }) => <Placeholder color={color} size={32} />} message={t("emptyState.catalogProducts.message")} />
      ),
    [dark, data, imageWidth, loading, roundness, t],
  );

  const renderItem = useCallback<ListRenderItem<CatalogProduct>>(
    ({ item }) => (
      <TouchableOpacity style={{ rowGap: betweenImagesGap }} onPress={() => handleSelectProduct(item)}>
        <Image source={item.imageUrl} width={imageWidth} height={imageWidth} style={{ borderRadius: roundness }} />
        <Text variant="caption">{item.name}</Text>
      </TouchableOpacity>
    ),
    [handleSelectProduct, imageWidth, roundness],
  );

  return (
    <FlatList
      data={data?.nodes}
      contentContainerStyle={[{ rowGap: betweenImagesGap }]}
      columnWrapperStyle={{ columnGap: betweenImagesGap }}
      ListEmptyComponent={emptyComponent}
      keyExtractor={item => item.catalogProductId}
      numColumns={nbColumns}
      scrollEnabled={false}
      renderItem={renderItem}
    />
  );
};
