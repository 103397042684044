import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  emptyStateContainer: {
    height: "100%",
    justifyContent: "center",
  },
  header: {
    rowGap: 16,
  },
});
