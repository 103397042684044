import { QueryHookOptions, gql } from "@apollo/client";

import { collabFields, genericPromoFields, standardPromoInFeedFields } from "@app/common/graphql/fragments.graphql";
import { FeedCollabOrPromo, PaginatedFeedCollabOrPromo } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { useQueryWithTransformation } from "@app/hooks/utils/use-query-with-transformation.hook";
import { isPromo } from "@app/utils/feed.util";
import { FeedCollabOrPromoFromHook, mapPromoToRealType, PaginatedFeedCollabOrPromoFromHook } from "@app/utils/promo.util";

interface InternalResponse {
  feed: PaginatedFeedCollabOrPromoFromHook;
}

export interface FeedResponse {
  feed: PaginatedFeedCollabOrPromo;
}

interface Variables {
  after?: string;
  first?: number;
  userId?: string;
}

export type FeedResult = QueryResult<FeedResponse, "feed">;

export const feedQuery = gql`
  query Feed($after: FeedEntityId, $first: Int, $userId: UserId) {
    feed(after: $after, first: $first, userId: $userId) {
      nodes {
        ... on Collab {
          ...CollabFields
        }
        ... on GenericPromo {
          ...GenericPromoFields
        }
        ... on StandardPromo {
          ...StandardPromoInFeedFields
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFields}
  ${genericPromoFields}
  ${standardPromoInFeedFields}
`;

export function useFeed(options: QueryHookOptions<FeedResponse, Variables>): FeedResult {
  const transformationPerNode = (node: FeedCollabOrPromoFromHook): FeedCollabOrPromo => (isPromo(node) ? mapPromoToRealType(node) : node);
  const transformation = (feed: PaginatedFeedCollabOrPromoFromHook): PaginatedFeedCollabOrPromo => ({
    ...feed,
    nodes: feed.nodes?.map(transformationPerNode),
  });

  const { loading, error, data, networkStatus, fetchMore, refetch } = useQueryWithTransformation<FeedResponse, InternalResponse, Variables>(
    feedQuery,
    "feed",
    transformation,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );

  const feed = data
    ? {
        ...data.feed,
        nodes: data.feed.nodes?.map(entity => {
          if (isPromo(entity)) return mapPromoToRealType(entity);

          return entity;
        }),
      }
    : undefined;

  return { loading, error, data: feed, networkStatus, fetchMore, refetch };
}
