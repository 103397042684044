import { StyleSheet } from "react-native";

import { transparent } from "@app/common/style/theme";
import { isWeb } from "@app/utils/device.util";

export const styles = StyleSheet.create({
  contentContainerTabs: {
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  lastTab: {
    marginRight: isWeb ? 8 : 16,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
  },
  tab: {
    borderWidth: 1,
    overflow: "hidden",
    paddingHorizontal: 15,
    paddingVertical: 7,
  },
  tabContainer: {
    minHeight: 40,
    padding: 0,
    width: "auto",
  },
  tabs: {
    elevation: 0,
    shadowColor: transparent,
  },
});
