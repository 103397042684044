export enum UrlType {
  twitter = "twitterLink",
  website = "externalLink",
}

export function isUrlInput(input: string): boolean {
  const urlTypes = Object.values(UrlType);
  return urlTypes.includes(input as UrlType);
}

export function urlStartsWithHttps(url: string): boolean {
  return /^https?:\/\//gi.test(url);
}
