import { Contest } from "@app/common/graphql/generated/schema.graphql";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { Suggestion, defaultInputsValue, useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

import { useCachedCollab } from "./use-cached-collab.hook";
import { useCachedContest } from "./use-cached-contest.hook";

interface SourceImageUtilities {
  openCreateStudioCollab: () => void;
  openEditStudio: (collabId: string) => void;
  openImageGeneration: () => void;
  openStudio: () => void;
}

interface SourceImageParams {
  userId?: string;
  contestId?: string;
  collabId?: string;
}

export function useOpenStudio({ contestId }: SourceImageParams = {}): SourceImageUtilities {
  const navigation = useNavigation();

  const { setInputs } = useStudioMiniContext();

  const { getCachedContest } = useCachedContest();
  const { getCachedCollab } = useCachedCollab();

  const contestToStudioContest = (contest: Contest | null): null | Suggestion =>
    contest
      ? {
          id: contest.contestId,
          name: contest.name,
          image: contest.imageUrl,
          handle: contest.handle,
          passwordRequired: contest.passwordRequired,
          category: contest.category,
        }
      : null;

  const cachedContest = getCachedContest(contestId);
  const contest = contestToStudioContest(cachedContest);

  const openImageGeneration = (): void => {
    setInputs({ ...defaultInputsValue, contest });
    navigation.navigate(Routes.studioMiniGeneration);
  };

  const openStudio = (): void => {
    setInputs({ ...defaultInputsValue, contest });
    navigation.navigate(Routes.home, { screen: Tabs.studio, params: { screen: Routes.studio } });
  };

  const openCreateStudioCollab = (): void => {
    setInputs({ ...defaultInputsValue, contest });
    navigation.navigate(Routes.studioMiniCreateCollab);
  };

  const openEditStudio = (collabId: string): void => {
    const collab = getCachedCollab(collabId);
    if (!collab) return;

    const attachedContest = getCachedContest(collab.contest?.contestId);

    setInputs({
      collabId,
      productName: collab.name,
      productDescription: collab.description,
      productCategory: { id: collab.category.categoryId, name: collab.category.name },
      images: collab.images.map(image => ({ imageId: image.collabImageId, imageUrl: image.imageUrl })),
      contest: contestToStudioContest(attachedContest),
    });
    navigation.navigate(Routes.studioMiniCreateCollab);
  };

  return {
    openCreateStudioCollab,
    openEditStudio,
    openImageGeneration,
    openStudio,
  };
}
