import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { coreProfileFields, userFacetsFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedProfilesWithFacets, SearchUsersFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

export interface ProfilesResponse {
  profiles: PaginatedProfilesWithFacets;
}

export interface BrowseUsersVariables {
  first?: number;
  offset?: number;
  where?: SearchUsersFilteringArgs;
}

const browseUsersQuery = gql`
  query BrowseUsers($first: Int, $offset: Int, $where: SearchUsersFilteringArgs) {
    profiles: browseUsers(first: $first, offset: $offset, where: $where) {
      facets {
        ...UserFacetsFields
      }
      nodes {
        ...CoreProfileFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${userFacetsFields}
  ${coreProfileFields}
`;

export function useBrowseUsers(options?: QueryHookOptions<ProfilesResponse, BrowseUsersVariables>): QueryResult<ProfilesResponse, "profiles"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<ProfilesResponse, BrowseUsersVariables>(browseUsersQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.profiles, loading, error, networkStatus, fetchMore, refetch };
}
