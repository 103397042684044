import { useCallback, useEffect, useState } from "react";

import { Badge } from "@app/common/graphql/generated/schema.graphql";
import { isWeb } from "@app/utils/device.util";
import { isCollabId, isUserId } from "@app/utils/id.util";
import { log } from "@app/utils/logger/logger.util";

import { useLazyContest } from "../api/contests/use-contest.hook";
import { useLazyCollab } from "../api/use-collab.hook";
import { useLazyProfile, useProfile } from "../api/use-profile.hook";

export type InAppBrowserContentType = "contest" | "user" | "concept" | null;

interface SharedItemPreview {
  imageUrl?: string;
  title?: string;
  subtitle?: string;
  badgeLocation?: "title" | "subtitle";
  profileBadge?: Badge;
}

interface Response {
  loadingSharedItem: boolean;
  referrer: {
    creatorImageUrl?: string;
    creatorUsername?: string;
    loading: boolean;
  } | null;
  sharedItem: SharedItemPreview | null;
  type: InAppBrowserContentType;
}

export const useGetSharedItem = (shareId: string | null): Response => {
  const [loadingSharedItem, setLoadingSharedItem] = useState<boolean>(true);
  const [sharedItem, setSharedItem] = useState<SharedItemPreview | null>(null);
  const [type, setType] = useState<InAppBrowserContentType>(null);

  const { data: referrerProfile, loading: loadingReferrerProfile } = useProfile({ variables: { where: { shareId } }, skip: !shareId });
  const { getCollab } = useLazyCollab();
  const { getProfile } = useLazyProfile();
  const { getContest } = useLazyContest();

  const getOutput = useCallback(async (): Promise<void> => {
    const pathname = window.location.pathname;

    const match = pathname.match(/^\/(p|u|contest)\/([^/]+)$/);

    if (!match?.length) return;

    const id = match[2];

    if (pathname.startsWith("/u/")) {
      setType("user");

      await getProfile({
        variables: { where: isUserId(id) ? { userId: id } : { username: id } },
        onCompleted: response => {
          setSharedItem({
            imageUrl: response.profile.imageUrl,
            title: response.profile.username,
            badgeLocation: "title",
            profileBadge: response.profile.badge,
          });
        },
      });
    } else if (pathname.startsWith("/p/")) {
      setType("concept");

      await getCollab({
        variables: isCollabId(id) ? { collabId: id } : { handle: id },
        onCompleted: response => {
          setSharedItem({
            imageUrl: response.collab.images[0].imageUrl,
            title: response.collab.name,
            subtitle: response.collab.creator.username,
            badgeLocation: "subtitle",
            profileBadge: response.collab.creator.badge,
          });
        },
      });
    } else if (pathname.startsWith("/contest/")) {
      setType("contest");

      await getContest({
        variables: { handle: id },
        onCompleted: response => {
          setSharedItem({
            imageUrl: response.contest.imageUrl,
            title: response.contest.name,
          });
        },
      });
    } else {
      return;
    }
  }, [getCollab, getContest, getProfile]);

  useEffect(() => {
    if (!isWeb) return;

    setLoadingSharedItem(true);
    getOutput()
      .catch(error => log.debug(`[getOutput]: ${error}`))
      .finally(() => setLoadingSharedItem(false));
  }, [getOutput]);

  return {
    type,
    referrer: { creatorImageUrl: referrerProfile?.imageUrl, creatorUsername: referrerProfile?.username, loading: loadingReferrerProfile },
    sharedItem,
    loadingSharedItem,
  };
};
