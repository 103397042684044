import React from "react";

import { useTranslation } from "react-i18next";
import { TextInput } from "react-native-paper";

import { UpdateProfileInput } from "@app/common/graphql/generated/schema.graphql";
import { TextInputProps } from "@app/components/common/text-input/text-input.component";

type MapInputProps = Record<keyof UpdateProfileInput, TextInputProps>;

interface ProfileInputPropsUtils {
  commonInputProps: MapInputProps;
}

export function useProfileInputProps(): ProfileInputPropsUtils {
  const { t } = useTranslation();

  const nameInputProps: TextInputProps = {
    label: t("userInfo.fields.displayName.label"),
    required: true,
    includeCharacterCount: true,
    maxLength: 50,
  };

  const usernameInputProps: TextInputProps = {
    label: t("userInfo.fields.username.label"),
    required: true,
    errorText: t("error.usernameTaken"),
    helperText: t("userInfo.fields.username.helper"),
    includeCharacterCount: true,
    maxLength: 30,
    left: color => <TextInput.Affix text="@" textStyle={{ color }} />,
  };

  const occupationInputProps: TextInputProps = {
    placeholder: t("userInfo.fields.occupation.placeholder"),
    includeCharacterCount: true,
    maxLength: 100,
  };

  const bioInputProps: TextInputProps = {
    label: t("userInfo.fields.bio.label"),
    includeCharacterCount: true,
    maxLength: 500,
    multiline: true,
    numberOfLines: 10,
  };

  const externalLinkInputProps: TextInputProps = {
    placeholder: t("userInfo.fields.externalLink.placeholder"),
    keyboardType: "url",
  };

  const twitterLinkInputProps: TextInputProps = {
    placeholder: t("userInfo.fields.twitterLink.placeholder"),
    keyboardType: "url",
    autoCapitalize: "none",
    autoCorrect: false,
  };

  const pronounInputProps: TextInputProps = {
    label: t("userInfo.fields.pronouns.pronoun"),
    helperText: t("userInfo.fields.pronouns.helper"),
    includeCharacterCount: true,
    maxLength: 6,
  };

  const tagInputProps: TextInputProps = {
    label: t("userInfo.fields.tags.tag"),
    helperText: t("userInfo.fields.tags.helper"),
    includeCharacterCount: true,
    maxLength: 20,
  };

  const commonInputProps: MapInputProps = {
    displayName: nameInputProps,
    username: usernameInputProps,
    occupation: occupationInputProps,
    bio: bioInputProps,
    externalLink: externalLinkInputProps,
    twitterLink: twitterLinkInputProps,
    pronouns: pronounInputProps,
    tags: tagInputProps,
  };

  return { commonInputProps };
}
