import React, { FC, useCallback } from "react";

import { ArrowLeft, ArrowRight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { StyleProp, View, ViewStyle } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { Text } from "@app/components/common/text/text.component";
import { GenerationTaskType } from "@app/context/studio-mini/studio-mini.context";

import { styles } from "./studio-navigation-set.style";

interface Props {
  index: number;
  style: StyleProp<ViewStyle>;
  onNext: () => void;
  onPrevious: () => void;
  taskCount: number;
  taskType: GenerationTaskType;
}

export const StudioNavigationSet: FC<Props> = ({ index, style, onNext, onPrevious, taskCount, taskType }) => {
  const { colors, roundness } = useTheme();
  const { t } = useTranslation();

  const getSetTitle = useCallback(
    (number: number, type: GenerationTaskType): string => {
      switch (type) {
        case GenerationTaskType.pose:
          return t("studioMini.prompt.setPoses", { number });
        case GenerationTaskType.variation:
          return t("studioMini.prompt.setVariations", { number });
        default:
          return t("studioMini.prompt.set", { number });
      }
    },
    [t],
  );

  return (
    <View style={style}>
      {index !== 0 ? (
        <IconButton
          icon={() => <ArrowLeft color={colors.background} weight="bold" />}
          mode="contained"
          size={24}
          style={{ borderRadius: roundness }}
          containerColor={colors.onBackground}
          onPress={onPrevious}
        />
      ) : (
        <View style={styles.navigationButtonPlaceholder} />
      )}
      <Text variant="tooltip">{getSetTitle(index + 1, taskType)}</Text>
      {index < taskCount - 1 ? (
        <IconButton
          icon={() => <ArrowRight color={colors.background} weight="bold" />}
          mode="contained"
          size={24}
          style={{ borderRadius: roundness }}
          containerColor={colors.onBackground}
          onPress={onNext}
        />
      ) : (
        <View style={styles.navigationButtonPlaceholder} />
      )}
    </View>
  );
};
