import React, { FC, useCallback, useMemo, useState } from "react";

import { PlusCircle, CaretRight, Trash, Megaphone, MonitorPlay, HeartStraight, Bug, Link, ArrowsClockwise } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, List, ListItemProps, Text, useTheme } from "react-native-paper";

import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { DevView } from "@app/components/profile/dev-view/dev-view.component";
import { useBranchContext } from "@app/context/branch/branch.context";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteAllLikes } from "@app/hooks/api/use-delete-all-likes.hook";
import { useDeleteAllPromoImpressions } from "@app/hooks/api/use-delete-all-promo-impressions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isWeb } from "@app/utils/device.util";

import ErrorComponent from "./error-component/error.component";

export const GodModeScreen: FC<ProfileStackScreenProps<Routes.godMode>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const [isErrorComponentVisible, setIsErrorComponentVisible] = useState(false);

  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();
  const { refreshOnboardingStepsState } = useOnboardingContext();
  const { openDeepLink } = useBranchContext();

  const { deleteAllLikes, loading: resetLikesLoading } = useDeleteAllLikes();
  const { deleteAllPromoImpressions, loading: resetPromoImpressionsLoading } = useDeleteAllPromoImpressions();

  const resetLikes = useCallback((): void => {
    if (!profile) return;

    void deleteAllLikes({
      variables: { userId: profile.userId },
      onCompleted: data => {
        Alert.alert(t("godMode.success"), t("godMode.likesDeleted", { count: data.deleteAllLikes.deletedLikes }));
      },
      onError: e => {
        Alert.alert(t("error.label", e.message));
      },
    });
  }, [deleteAllLikes, profile, t]);

  const resetPromoImpressions = useCallback((): void => {
    if (!profile) return;

    void deleteAllPromoImpressions({
      variables: { userId: profile.userId },
      onCompleted: data => {
        Alert.alert(t("godMode.success"), t("godMode.promoImpressionsDeleted", { count: data.deleteAllPromoImpressions.deletedImpressions }));
      },
      onError: e => {
        Alert.alert(t("error.label", e.message));
      },
    });
  }, [deleteAllPromoImpressions, profile, t]);

  const options = useMemo<ListItemProps[]>(
    () => [
      {
        title: t("godMode.contest.create"),
        onPress: () => navigation.navigate(Routes.createContest),
        left: props => <PlusCircle {...props} color={colors.godMode} />,
        right: props => <CaretRight {...props} />,
        titleStyle: { color: colors.godMode },
      },
      {
        title: t("godMode.catalogProduct.create"),
        onPress: () => navigation.navigate(Routes.createCatalogProduct),
        left: props => <PlusCircle {...props} color={colors.godMode} />,
        right: props => <CaretRight {...props} />,
        titleStyle: { color: colors.godMode },
      },
      {
        title: t("godMode.managePromos.title"),
        onPress: () => navigation.navigate(Routes.managePromos),
        left: props => <Megaphone {...props} color={colors.godMode} />,
        right: props => <CaretRight {...props} />,
        titleStyle: { color: colors.godMode },
      },
      {
        title: t("godMode.resetMyLikes"),
        left: props => <Trash {...props} color={colors.error} />,
        right: props => (resetLikesLoading ? <ActivityIndicator size="small" /> : <HeartStraight {...props} size={24} />),
        titleStyle: { color: colors.error },
        onPress: resetLikes,
      },
      {
        title: t("godMode.resetMyPromoImpressions"),
        left: props => <Trash {...props} color={colors.error} />,
        right: props => (resetPromoImpressionsLoading ? <ActivityIndicator size="small" /> : <Megaphone {...props} />),
        titleStyle: { color: colors.error },
        onPress: resetPromoImpressions,
      },
      {
        title: t("godMode.throwError"),
        left: props => <Bug {...props} color={colors.error} />,
        right: props => <CaretRight {...props} />,
        titleStyle: { color: colors.error },
        onPress: () => setIsErrorComponentVisible(true),
      },
      ...conditionalItem<ListItemProps>(isWeb, {
        title: t("godMode.testDeeplink"),
        left: props => <Link {...props} color={colors.error} />,
        right: props => <CaretRight {...props} />,
        titleStyle: { color: colors.error },
        onPress: () => void openDeepLink(window.location.pathname).catch((error: Error) => showErrorSnackbar({ error })),
      }),
      {
        title: t("godMode.resetOnboarding"),
        left: props => <ArrowsClockwise {...props} color={colors.godMode} />,
        titleStyle: { color: colors.godMode },
        onPress: refreshOnboardingStepsState,
      },
      {
        title: t("godMode.testVideo"),
        left: props => <MonitorPlay {...props} color={colors.godMode} />,
        titleStyle: { color: colors.godMode },
        onPress: () =>
          navigation.navigate(Routes.video, {
            videoUrl: "https://customer-u7pxwd239bwgmyto.cloudflarestream.com/bf32a2eeb2fb51712c72ad4d438bb197/manifest/video.m3u8",
            posterUrl: "https://customer-u7pxwd239bwgmyto.cloudflarestream.com/f930e6616fdfbf21b7aa99d6742526f2/thumbnails/thumbnail.jpg",
          }),
      },
    ],
    [
      t,
      colors.godMode,
      colors.error,
      resetLikes,
      resetPromoImpressions,
      refreshOnboardingStepsState,
      navigation,
      resetLikesLoading,
      resetPromoImpressionsLoading,
      openDeepLink,
      showErrorSnackbar,
    ],
  );

  return (
    <ScreenWrapper withScrollView>
      <List.Section>
        <List.Subheader>
          <Text variant="labelLarge">{t("godMode.mainActions")}</Text>
        </List.Subheader>
        {options.map((item, index) => (
          <List.Item key={`god-mode-item-${index}`} {...item} />
        ))}
      </List.Section>
      <DevView />
      {isErrorComponentVisible && <ErrorComponent />}
    </ScreenWrapper>
  );
};
