import { StyleSheet } from "react-native";

import { isDesktop } from "@app/utils/device.util";

export const marginHorizontal = 20;

export const styles = StyleSheet.create({
  announcementContainer: {
    borderWidth: 1,
    height: 554,
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  announcementText: {
    marginBottom: 10,
    marginHorizontal: 10,
    rowGap: 20,
  },
  background: {
    height: "100%",
    position: "absolute",
    top: -12,
    width: "100%",
  },
  contentButton: {
    flexDirection: "row-reverse",
  },
  contentContainer: {
    marginHorizontal: isDesktop ? "auto" : 0,
    rowGap: 20,
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
  },
  modal: {
    marginHorizontal,
    marginVertical: 24,
  },
});
