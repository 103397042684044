import React, { FC, useCallback, useEffect, useState } from "react";

import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { UpdateProfileInput } from "@app/common/graphql/generated/schema.graphql";
import { ProfileStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { Button } from "@app/components/common/button/button.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { TextInput, TextInputProps } from "@app/components/common/text-input/text-input.component";
import { TextInputMultipleValue } from "@app/components/common/text-input-multiple-values/text-input-multiple-values.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useUpdateProfile } from "@app/hooks/api/use-update-profile.hook";
import { useProfileInputProps } from "@app/hooks/utils/use-profile-input-props.hook";
import { toBoolean } from "@app/utils/boolean.util";
import { isUrlInput, urlStartsWithHttps } from "@app/utils/url.util";

import { styles } from "./edit-profile-field.style";

const editableFields: (keyof UpdateProfileInput)[] = [
  "bio",
  "displayName",
  "externalLink",
  "occupation",
  "pronouns",
  "tags",
  "twitterLink",
  "username",
];

export const EditProfileFieldScreen: FC<ProfileStackScreenProps<Routes.editProfileField>> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();

  const { fieldName } = route.params;
  const multipleValues = toBoolean(route.params.multipleValues);
  const { commonInputProps } = useProfileInputProps();
  const inputProps = commonInputProps[fieldName];
  const maxValues = fieldName === "pronouns" ? 4 : fieldName === "tags" ? 10 : undefined;

  useEffect(() => {
    if (!editableFields.includes(fieldName)) {
      navigation.replace(Routes.editProfile);
    }
  }, [fieldName, navigation]);

  const { profile, loading: loadingProfile } = useProfileContext();
  const { updateProfile, loading } = useUpdateProfile();

  const [values, setValues] = useState<string[]>([]);
  const [value, setValue] = useState<string | undefined>();
  const [error, setError] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);

  const hasError = error || requiredError || invalidFormat;

  const handleChangeValue = (newValue: string): void => {
    setValue(newValue);
    setError(false);
    setRequiredError(false);
    setInvalidFormat(false);
    if (!newValue && inputProps?.required) setRequiredError(true);
  };

  const handleSubmit = useCallback(() => {
    if (!profile) return;

    const formattedValue =
      value && typeof value === "string" && isUrlInput(fieldName) && !urlStartsWithHttps(value.toLowerCase())
        ? `https://${value.toLowerCase()}`
        : value;
    void updateProfile({
      variables: { userId: profile.userId, input: { [fieldName]: multipleValues ? values : formattedValue?.trim() || null } },
      onCompleted: () => navigation.navigate(Routes.editProfile),
      onError: (e: ApolloError) => {
        if (e.graphQLErrors?.find(({ extensions }) => extensions.code === "USERNAME_ALREADY_TAKEN")) {
          setError(true);
        } else if (e.graphQLErrors?.find(({ extensions }) => extensions.code === "BAD_USER_INPUT")) {
          setInvalidFormat(true);
        } else {
          showErrorSnackbar({ error: e });
        }
      },
    });
  }, [fieldName, multipleValues, navigation, profile, showErrorSnackbar, updateProfile, value, values]);

  useEffect(() => {
    if (!profile || loadingProfile) return;

    const initialValue = fieldName === "uploadVideoId" ? profile.video?.profileVideoId : profile[fieldName];
    if (!initialValue || typeof initialValue === "string") setValue(initialValue);
    if (Array.isArray(initialValue)) setValues(initialValue);
  }, [fieldName, loadingProfile, profile]);

  const submitButton = useCallback(
    () => (
      <Button loading={loading} disabled={hasError} onPress={handleSubmit}>
        {t("cta.done")}
      </Button>
    ),
    [handleSubmit, hasError, loading, t],
  );

  useEffect(() => {
    navigation.setOptions({
      title: t(`userInfo.fields.${fieldName}.label`),
      headerRight: submitButton,
    });
  }, [fieldName, handleSubmit, hasError, loading, navigation, submitButton, t]);

  const textInputProps: TextInputProps = {
    value,
    onChangeText: handleChangeValue,
    loading: loadingProfile,
    error: hasError,
    ...inputProps,
    errorText: requiredError
      ? t("error.required")
      : invalidFormat
      ? fieldName === "username"
        ? t("error.invalidUsername")
        : t("error.invalidFormat")
      : error
      ? inputProps?.errorText
      : undefined,
  };

  return (
    <ScreenWrapper style={styles.root}>
      {multipleValues ? (
        <TextInputMultipleValue
          values={values}
          maxValues={maxValues}
          setValues={setValues}
          resetValue={() => setValue("")}
          inputProps={textInputProps}
        />
      ) : (
        <TextInput {...textInputProps} />
      )}
    </ScreenWrapper>
  );
};
