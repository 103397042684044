import React, { useEffect, useMemo, Dispatch, SetStateAction, FC, useState, ReactNode } from "react";

import { Image, ImageProps as ExpoImageProps } from "expo-image";
import { Skeleton } from "moti/skeleton";
import { GestureResponderEvent, StyleProp, View, ViewStyle } from "react-native";
import { TouchableRipple, useTheme } from "react-native-paper";
import Animated, { AnimatedStyle, Easing, useAnimatedStyle, useSharedValue, withSequence, withTiming } from "react-native-reanimated";

import { imageRatio } from "@app/common/constants/image.const";
import { TrackEvent } from "@app/common/enums/track-events.enum";
import { CollabImage } from "@app/common/graphql/generated/schema.graphql";
import { useFeedContext } from "@app/context/feed/feed.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { previewWidth, useImage } from "@app/hooks/utils/use-image.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isAndroidApp } from "@app/utils/device.util";

import { ProductImageTab } from "./product-image-tab/product-image-tab.component";
import { styles } from "./product-images.style";

interface ImageProps extends ExpoImageProps {
  width?: number;
  height?: number;
}

interface Props {
  collabId: string;
  images: CollabImage[];
  loading?: boolean;
  imageProps?: ImageProps;
  background?: ReactNode;
  contentOnImage?: ReactNode;
  contentUnderImage?: ReactNode;
  style?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>;
  setCurrentImage: Dispatch<SetStateAction<CollabImage | undefined>>;
}

const angle = 8;
const time = 150;
const easing = Easing.linear;

export const ProductImages: FC<Props> = ({
  collabId,
  images,
  loading,
  imageProps,
  background,
  style,
  setCurrentImage,
  contentOnImage,
  contentUnderImage,
}) => {
  const { dark, colors } = useTheme();
  const { trackInMixpanel } = useMixpanelContext();

  const {
    feed: { currentIndexImageMap, setCurrentIndexImageMap },
  } = useFeedContext();
  const [currentIndexImage, setCurrentIndexImage] = useState(0);
  const currentIndex = setCurrentIndexImageMap ? currentIndexImageMap.get(collabId) ?? 0 : currentIndexImage;
  const setCurrentIndex = (indexImage: number): void => {
    setCurrentImage(images[indexImage]);
    setCurrentIndexImageMap ? setCurrentIndexImageMap(new Map(currentIndexImageMap.set(collabId, indexImage))) : setCurrentIndexImage(indexImage);
    trackInMixpanel(TrackEvent.switchImages, { collabId, index: indexImage, numberOfImages: images.length });
  };

  const { width, height, style: imageStyle, ...expoImageProps } = imageProps ?? {};
  const imageRotation = useSharedValue(0);
  const { width: windowWidth } = useWindowDimensions();
  const imageWidth = width ?? windowWidth;
  const imageHeight = height ?? imageWidth * imageRatio;
  const imageSizeStyle = useMemo(() => ({ height: imageHeight, width: imageWidth }), [imageHeight, imageWidth]);
  const colorMode = dark ? "dark" : "light";
  const multipleImages = images.length > 1;
  const { getResponsiveImageUrl, getPreviewImageUrl, defaultImage } = useImage({ width: imageWidth });

  const animateImage = (value: number): void => {
    imageRotation.value = withSequence(withTiming(value, { duration: time / 2, easing }), withTiming(0, { duration: time / 2, easing }));
  };

  const handleImagePress = (event: GestureResponderEvent): void => {
    const middle = windowWidth / 2;
    if (event.nativeEvent.pageX > middle) {
      if (!multipleImages || currentIndex === images.length - 1) {
        animateImage(angle);
      }
      if (multipleImages) {
        setCurrentIndex?.(Math.min(currentIndex + 1, images.length - 1));
      }
    } else {
      if (!multipleImages || currentIndex === 0) {
        animateImage(-angle);
      }
      if (multipleImages) {
        setCurrentIndex?.(Math.max(currentIndex - 1, 0));
      }
    }
  };

  useEffect(() => {
    const imagesToPrefetch = images.map(image => getResponsiveImageUrl(image.imageUrl) ?? image.imageUrl).filter(Boolean);
    const previewImagesToPrefetch = images.map(image => getPreviewImageUrl(image.imageUrl) ?? image.imageUrl).filter(Boolean);
    void Image.prefetch([...imagesToPrefetch, ...previewImagesToPrefetch]);
  }, [getPreviewImageUrl, getResponsiveImageUrl, images]);

  const imageTransformStyle = useAnimatedStyle(() => {
    return {
      transform: [{ perspective: 1000 }, { rotateY: `${imageRotation.value}deg` }],
    };
  });

  const previewSource = useMemo(
    () =>
      images[currentIndex]?.imageUrl
        ? { uri: getPreviewImageUrl(images[currentIndex]?.imageUrl), width: previewWidth, height: previewWidth }
        : undefined,
    [currentIndex, getPreviewImageUrl, images],
  );

  const imageComponent = useMemo(() => {
    const imageSource = images[currentIndex]?.imageUrl
      ? { uri: getResponsiveImageUrl(images[currentIndex]?.imageUrl), ...imageSizeStyle }
      : undefined;

    return (
      <Image
        source={imageSource ?? defaultImage}
        placeholder={previewSource}
        responsivePolicy="initial"
        cachePolicy="memory-disk"
        placeholderContentFit="cover"
        contentFit="contain"
        allowDownscaling={!isAndroidApp}
        style={[imageSizeStyle, { backgroundColor: colors.skeleton }, imageStyle]}
        {...expoImageProps}
      />
    );
  }, [colors.skeleton, currentIndex, defaultImage, expoImageProps, getResponsiveImageUrl, imageSizeStyle, imageStyle, images, previewSource]);

  return (
    <Animated.View style={[{ height: imageHeight, backgroundColor: colors.background }, imageTransformStyle, style]}>
      <Image
        source={previewSource ?? defaultImage}
        placeholder={previewSource}
        responsivePolicy="initial"
        cachePolicy="memory-disk"
        placeholderContentFit="cover"
        contentFit="cover"
        style={styles.previewImage}
      />
      {background}

      <Skeleton show={loading} colorMode={colorMode} radius="square" height={imageHeight} width={imageWidth} disableExitAnimation>
        {!loading ? imageComponent : null}
      </Skeleton>

      <TouchableRipple style={[imageSizeStyle, styles.touchableZone]} rippleColor="transparent" onPress={handleImagePress}>
        <View style={[imageSizeStyle, styles.imageContent]}>
          <View style={styles.topImageContent}>
            {multipleImages && (
              <View style={styles.imageTabsContainer}>
                {images.map((image, index) => (
                  <ProductImageTab
                    key={`tab-product-image-${image.collabImageId}`}
                    isActive={index === currentIndex}
                    hasMultipleImages={multipleImages}
                  />
                ))}
              </View>
            )}
          </View>

          <View style={imageSizeStyle}>{contentOnImage}</View>
        </View>
      </TouchableRipple>

      {contentUnderImage}
    </Animated.View>
  );
};
