import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ApolloError, useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react-native";
import { sub } from "date-fns";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import { isNetworkError } from "@app/common/apollo/apollo.utils";
import { userStateFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { useAuthProfile } from "@app/hooks/api/use-auth-profile.hook";
import { useSetReferrerUser } from "@app/hooks/api/use-set-referrer-user.hook";
import { useUpdateUserState } from "@app/hooks/api/use-update-user-state.hook";
import { useUserState } from "@app/hooks/api/use-user-state.hook";
import { getAppVersion } from "@app/utils/app.util";
import { crashlytics } from "@app/utils/crashlytics/crashlytics.util";
import { log, reportError } from "@app/utils/logger/logger.util";

import { useAuthContext } from "../auth/auth.context";
import { useLaunchDarklyContext } from "../launch-darkly/launch-darkly.context";
import { useMixpanelContext } from "../mixpanel/mixpanel.context";
import { useSnackbarContext } from "../snackbar/snackbar.context";

import { ProfileContext, ProfileContextType, UpdateUserState } from "./profile.context";
import { UserState } from "./user-state.enum";

export const ProfileContextProvider: FC<ChildrenProp> = ({ children }) => {
  const { showErrorSnackbar } = useSnackbarContext();
  const { t } = useTranslation();
  const client = useApolloClient();

  const { ldClient } = useLaunchDarklyContext();
  const { mixpanel } = useMixpanelContext();
  const { state, logout } = useAuthContext();
  const { data: dataProfile, loading: loadingProfile, getAuthProfile } = useAuthProfile();
  const { data: dataUserState, loading: loadingUserState } = useUserState(dataProfile?.userId);
  const { updateUserState } = useUpdateUserState();
  const { setReferrerUser, loading: loadingSetReferrer } = useSetReferrerUser();

  const [referrerId, setReferrerId] = useState<string | undefined>(undefined);
  const [loadingUpdateState, setLoadingUpdateState] = useState(false);
  const [profile, setProfile] = useState<Profile | undefined>();

  const uState = profile ? dataUserState?.userState.frontendState : undefined;
  const featureAccesses = profile ? dataUserState?.featureAccesses : undefined;
  const loading = loadingProfile || loadingUserState || (!!state.token && !!state.verified && !uState);

  const updateStateInCache = useCallback(
    (frontendState: UserState, userId: string): void => {
      client.writeFragment({
        id: `userState-${profile?.userId}`,
        fragment: userStateFields,
        data: {
          userId,
          frontendState,
        },
      });
    },
    [client, profile?.userId],
  );

  const updateState = useCallback(
    ({ onCompleted, onError, input }: UpdateUserState) => {
      if (loading || !profile?.userId) {
        const message = "Updating app state but app not ready";

        reportError(new Error(message));
        return;
      }

      setLoadingUpdateState(true);

      const stateUpdated = { ...(uState ?? {}), ...input };
      updateStateInCache(stateUpdated, profile.userId);

      void updateUserState({
        variables: { input: { frontendState: stateUpdated }, userId: profile?.userId },
        onCompleted: data => {
          setLoadingUpdateState(false);
          onCompleted?.(data);
        },
        onError: error => {
          setLoadingUpdateState(false);
          onError?.(error);
        },
      });
    },
    [loading, profile?.userId, uState, updateStateInCache, updateUserState],
  );

  const context = useMemo<ProfileContextType>(
    () => ({
      profile,
      loading,
      loadingUpdateState,
      setReferrerId,
      state: uState,
      updateState,
      featureAccesses,
    }),
    [profile, loading, loadingUpdateState, uState, updateState, featureAccesses],
  );

  const handleLoginProfileCompleted = useCallback(
    async (p: Profile): Promise<void> => {
      setProfile(p);
      try {
        void ldClient?.identify({
          kind: "user",
          key: p.userId,
          anonymous: false,
          version: getAppVersion(),
        });
        Sentry.setUser({ id: p.userId });
        mixpanel?.identify(p.userId);
        void crashlytics.identify(p.userId);

        const almostOneDayAgo = sub(new Date(), { hours: 23 });
        if (!loadingSetReferrer && referrerId && new Date(p.createdAt) > almostOneDayAgo) {
          await setReferrerUser({
            variables: { userId: p.userId, input: { referrerShareId: referrerId } },
            onCompleted: () => setReferrerId(undefined),
            onError: () => setReferrerId(undefined),
          });
        }
      } catch (e: unknown) {
        reportError(e);
      }
    },
    [ldClient, loadingSetReferrer, mixpanel, referrerId, setReferrerUser],
  );

  const handleLoginProfileError = useCallback(
    async (error: ApolloError, shouldRetryOnNetworkError: boolean): Promise<void> => {
      if (!isNetworkError(error)) {
        showErrorSnackbar({ message: t("error.noProfile"), error });
        await logout();
        return;
      }

      if (dataProfile) {
        setProfile(dataProfile);
        return;
      }

      if (shouldRetryOnNetworkError && state.connected && state.verified && state.token) {
        void getAuthProfile({
          onCompleted: data => void handleLoginProfileCompleted(data.profileFromToken),
          onError: e => void handleLoginProfileError(e, false),
        });
        return;
      }

      showErrorSnackbar({ message: t("error.noInternet"), error });
      await logout();
    },
    [showErrorSnackbar, t, logout, dataProfile, state.connected, state.verified, state.token, getAuthProfile, handleLoginProfileCompleted],
  );

  useEffect(() => {
    if (!state.token || !state.verified) {
      setProfile(undefined);
      return;
    }

    log.info("[ProfileProvider]: Fetching profile from new token");
    void getAuthProfile({
      onCompleted: data => void handleLoginProfileCompleted(data.profileFromToken),
      onError: error => void handleLoginProfileError(error, true),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthProfile, state.token, state.verified]);

  useEffect(() => {
    if (profile && dataProfile && !isEqual(profile, dataProfile)) {
      setProfile(dataProfile);
    }
  }, [dataProfile, profile]);

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};
