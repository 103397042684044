import React, { FC, useCallback, useState, JSX, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { FlatList, NativeScrollEvent, NativeSyntheticEvent, View } from "react-native";
import { Divider } from "react-native-paper";

import { isErrorCode } from "@app/common/apollo/apollo.utils";
import { AlertVariant, EmbeddedAlert } from "@app/components/common/embedded-alert/embedded-alert.component";
import { Image } from "@app/components/common/image/image.component";
import { PageControl } from "@app/components/common/page-control/page-control.component";
import { Text } from "@app/components/common/text/text.component";
import { useInstagramPictures } from "@app/hooks/api/use-instagram-pictures.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { imageGap, screenPadding, styles } from "./instagram-feed.style";

interface Props {
  userId: string;
  instagramUsername?: string;
  isCurrentUser: boolean;
}

const numOfRow = 2;
const numOfItemPerPage = 6;

export const InstagramFeed: FC<Props> = ({ userId, instagramUsername, isCurrentUser }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const [maxImageToDisplay, setMaxImageToDisplay] = useState(3);
  const [currentIndex, setCurrentIndex] = useState(0);

  const numberOfItemPerRow = numOfItemPerPage / numOfRow;
  const imageSize = (width - (imageGap * numberOfItemPerRow + screenPadding * 2)) / numberOfItemPerRow;

  const { data, error } = useInstagramPictures({
    skip: !instagramUsername,
    variables: { where: { userId } },
  });
  const images = useMemo(() => data?.nodes ?? [], [data?.nodes]);

  const renderItem = useCallback(
    ({ index }: { index: number }): JSX.Element => {
      const imgIndex = numOfRow * index;
      const commonImageProps = { width: imageSize, height: imageSize };

      return (
        <View style={styles.columnWrapper}>
          {images[imgIndex] && <Image source={images[imgIndex].imageUrl} {...commonImageProps} />}
          {images[imgIndex + 1] && <Image source={images[imgIndex + 1].imageUrl} {...commonImageProps} />}
        </View>
      );
    },
    [imageSize, images],
  );

  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const totalWidth = event.nativeEvent.layoutMeasurement.width;
      const xPosition = event.nativeEvent.contentOffset.x;
      const ratio = xPosition / totalWidth;
      const newIndex = Math.round(ratio);
      if (ratio > 0.5) {
        setMaxImageToDisplay(prev => {
          const newRange = (newIndex + 1) * 3;
          return newRange >= prev ? newRange : prev;
        });
      }

      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex);
      }
    },
    [currentIndex],
  );

  if (error && !isErrorCode(error, "NOT_FOUND") && isCurrentUser) {
    return (
      <>
        <Divider />
        <EmbeddedAlert variant={AlertVariant.error} text={t("profile.instagramError")} />
      </>
    );
  }

  if (!images.length) return null;

  const numberOfPages = Math.ceil(images.length / numOfItemPerPage);

  return (
    <>
      <Divider style={[styles.divider, { width }]} />
      <View>
        <View style={styles.headerContainer}>
          <Text variant="body2">{t("profile.instagramPhoto", { count: data?.totalCount })}</Text>
          <PageControl currentPage={currentIndex} numberOfPages={numberOfPages} />
        </View>
        <FlatList
          data={images.slice(0, maxImageToDisplay)}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${item.imageUrl}-${index}`}
          initialNumToRender={6}
          horizontal
          scrollEnabled
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[{ width: width * numberOfPages }, styles.contentContainer]}
          style={styles.imagesContainer}
          onScroll={handleOnScroll}
        />
      </View>
    </>
  );
};
