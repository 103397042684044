import React, { ReactElement, useCallback, useMemo, useRef, useState } from "react";

import { useFocusEffect } from "@react-navigation/native";
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView } from "react-native";

import { ProfileTab } from "@app/common/enums/profile-tab.enum";
import { NavigateContext, TrackEvent } from "@app/common/enums/track-events.enum";
import { Profile as ProfileSchema } from "@app/common/graphql/generated/schema.graphql";
import { useNavigation } from "@app/common/types/navigation.type";
import { NestedAnimatedView } from "@app/components/common/bottom-sheet/bottom-sheet.common";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { isWeb } from "@app/utils/device.util";

import { ProductListRefProps } from "../../products/product-list/product-list.component";
import { SupercoinRewardList, SupercoinRewardListRefProps } from "../../supercoins/supercoin-reward-list/supercoin-reward-list.component";
import { CreationList } from "../creation-list/creation-list.component";
import { LikeList } from "../like-list/like-list.component";
import { ProfileHeader } from "../profile-header/profile-header.component";

import { ProfileTabs } from "./profile-tabs/profile-tabs.component";
import { styles } from "./profile.style";

const tabTitleMap = {
  [ProfileTab.creations]: "creations",
  [ProfileTab.likes]: "likes",
  [ProfileTab.supercoins]: "supercoins",
};

interface Tab {
  key: ProfileTab;
  content: ReactElement | null;
}

interface Props {
  profile: ProfileSchema;
  isCurrentUser: boolean;
  initialTab?: ProfileTab;
}

export const Profile: React.FC<Props> = ({ profile, isCurrentUser, initialTab }) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const { trackInMixpanel } = useMixpanelContext();

  const tabListRef = useRef<ScrollView>(null);
  const currentCreationListRef = useRef<ProductListRefProps>(null);
  const likeListRef = useRef<ProductListRefProps>(null);
  const rewardsListRef = useRef<SupercoinRewardListRefProps>(null);

  const [currentTab, setCurrentTab] = useState<ProfileTab>(initialTab ?? ProfileTab.creations);
  const [focusEffectCalledOnce, setFocusEffectCalledOnce] = useState(false);

  const handleTabChange = useCallback(
    (index: ProfileTab): void => {
      setCurrentTab(index);
      navigation.setParams({ tab: index });
      trackInMixpanel(TrackEvent.navigate, { tab: tabTitleMap[index], context: NavigateContext.currentProfileScreen });
    },
    [navigation, trackInMixpanel],
  );

  const tabs = useMemo<Tab[]>(
    () => [
      {
        key: ProfileTab.creations,
        content: (
          <CreationList
            ref={currentCreationListRef}
            userId={profile.userId}
            instagramUsername={profile.instagramUsername}
            isCurrentUser
            onEndReached={() => undefined}
          />
        ),
      },
      { key: ProfileTab.likes, content: <LikeList ref={likeListRef} /> },
      {
        key: ProfileTab.supercoins,
        content: <SupercoinRewardList ref={rewardsListRef} />,
      },
    ],
    [profile.instagramUsername, profile.userId],
  );

  const renderScene = useCallback(
    (tab: Tab) => (
      <NestedAnimatedView key={`profile-tab-${tab.key}`} isVisible={tab.key === currentTab} width={width}>
        {tab.content}
      </NestedAnimatedView>
    ),
    [currentTab, width],
  );

  const scrollToTab = useCallback(
    (tab: ProfileTab) => {
      tabListRef.current?.scrollTo({ x: tab * width, animated: false });
    },
    [width],
  );

  useFocusEffect(
    useCallback(() => {
      let timeout: NodeJS.Timeout;

      if (initialTab !== undefined && !focusEffectCalledOnce) {
        timeout = setTimeout(() => {
          scrollToTab(initialTab);
          setFocusEffectCalledOnce(true);
        }, 500);
      }

      return () => (timeout ? clearTimeout(timeout) : undefined);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusEffectCalledOnce]),
  );

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent): boolean => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const horizontalScrollHandler = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const totalWidth = event.nativeEvent.layoutMeasurement.width;
      const xPosition = event.nativeEvent.contentOffset.x;
      const ratio = xPosition / totalWidth;
      const newIndex = Math.round(ratio);

      if (newIndex !== currentTab) {
        handleTabChange(newIndex);
      }
    },
    [currentTab, handleTabChange],
  );

  const scrollHandler = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (isCloseToBottom(nativeEvent)) {
        switch (currentTab) {
          case ProfileTab.creations:
            currentCreationListRef.current?.fetchMore();
            break;
          case ProfileTab.likes:
            likeListRef.current?.fetchMore();
            break;
          case ProfileTab.supercoins:
            rewardsListRef.current?.fetchMore();
            break;
          default:
            break;
        }
      }
    },
    [currentTab],
  );

  return isCurrentUser ? (
    <ScrollView
      style={styles.root}
      stickyHeaderIndices={[1]}
      contentContainerStyle={styles.scrollContent}
      showsVerticalScrollIndicator={false}
      onScroll={scrollHandler}
      scrollEventThrottle={400}>
      <ProfileHeader profile={profile} isCurrentUser style={styles.header} />
      <ProfileTabs currentTab={currentTab} scrollToTab={scrollToTab} />
      <ScrollView
        ref={tabListRef}
        contentContainerStyle={isWeb ? { width } : undefined}
        horizontal
        scrollEnabled
        pagingEnabled
        snapToInterval={width}
        decelerationRate="fast"
        snapToAlignment="start"
        showsHorizontalScrollIndicator={false}
        onScroll={horizontalScrollHandler}
        scrollEventThrottle={200}>
        {tabs.map(tab => renderScene(tab))}
      </ScrollView>
    </ScrollView>
  ) : (
    <CreationList
      userId={profile.userId}
      isCurrentUser={false}
      instagramUsername={profile.instagramUsername}
      scrollEnabled
      ListHeaderComponent={<ProfileHeader profile={profile} isCurrentUser={false} />}
    />
  );
};
