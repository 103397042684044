import React, { FC, useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { BrowseOption } from "@app/common/enums/browse-option.enum";
import { FilterCategory } from "@app/common/enums/filter-category.enum";
import { SuperplanType } from "@app/common/enums/superplan-type.enum";
import { ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { SearchCollabsSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Text } from "@app/components/common/text/text.component";
import { ProductList, ProductListRefProps } from "@app/components/products/product-list/product-list.component";
import { useBrowseCollabs } from "@app/hooks/api/use-browse-collabs.hook";
import { Facets, FacetsTypename } from "@app/hooks/utils/filtering-args/use-filtering-args.const";
import { useFilteringArgs } from "@app/hooks/utils/filtering-args/use-filtering-args.hook";
import { getStageFromBrowseOption } from "@app/utils/map-stage-browse-option.util";

import { SuperplanAlert } from "../../superplans/superplan-alert/superplan-alert.component";
import { EarlyBirdBanner } from "../early-bird-discounts-banner/early-bird-discounts-banner.component";
import { FeaturedProduct } from "../featured-product/featured-product.component";

import { styles } from "./browse-product-results.style";

interface Props {
  browseOption: BrowseOption.concepts | BrowseOption.sellables | BrowseOption.underReview;
}

export const BrowseProductResults: FC<Props> = ({ browseOption }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { getBrowseFilteringArgs, getSearchFiltersFromFacets } = useFilteringArgs();
  const [facets, setFacets] = useState<Facets | undefined>(undefined);

  const productListRef = useCallback((node: ProductListRefProps | null) => {
    if (node?.data && "facets" in node.data) {
      setFacets(node.data.facets);
    }
  }, []);

  const filters = useMemo(
    () => getSearchFiltersFromFacets(FacetsTypename.collab, facets, [FilterCategory.stage]),
    [facets, getSearchFiltersFromFacets],
  );

  return (
    <ProductList
      ref={productListRef}
      useProducts={useBrowseCollabs}
      variables={{ where: getBrowseFilteringArgs(browseOption) }}
      sortProps={{
        sortButtonVisible: true,
        sortingMethodEnum: SearchCollabsSortingMethod,
        excludedSort:
          browseOption === BrowseOption.concepts ? [SearchCollabsSortingMethod.priceAsc, SearchCollabsSortingMethod.priceDesc] : undefined,
        defaultSort: SearchCollabsSortingMethod.popular,
      }}
      filterProps={{
        filterButtonVisible: true,
        filters,
      }}
      useBatchAsOffset
      scrollEnabled
      stickyHeaderHiddenOnScroll={false}
      stickyHeaderIndices={undefined}
      ListHeaderComponent={
        <View style={styles.header}>
          {browseOption === BrowseOption.underReview && (
            <>
              <EarlyBirdBanner />
              <SuperplanAlert
                superplanType={SuperplanType.superseller}
                flexDirection="row"
                origin={ViewSuperplanScreenOrigin.browseCampaigns}
                onLearnMorePress={tab => navigation.navigate(Routes.superplans, { tab })}
              />
            </>
          )}
          <FeaturedProduct stage={getStageFromBrowseOption(browseOption)} />
        </View>
      }
      listItemProps={_item => ({ hideStageChip: true })}
      emptyState={
        <View style={styles.emptyStateContainer}>
          <Text variant="body2" color="tertiary" textAlign="center">
            {t("noResults.default")}
          </Text>
        </View>
      }
    />
  );
};
