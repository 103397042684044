import { createContext, useContext } from "react";

export interface SearchContextInterface {
  query: string;
  setQuery: (query: string) => void;
}

export const SearchContext = createContext<SearchContextInterface>({ query: "", setQuery: (_query: string) => undefined });

export const useSearchContext = (): SearchContextInterface => useContext(SearchContext);
