import React, { FC, useCallback, JSX, useEffect, useState, useMemo } from "react";

import { SafeAreaView, View } from "react-native";
import { useTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { TrackEvent } from "@app/common/enums/track-events.enum";
import { AvailablePurchaseMethod, Collab, FeedCollabOrPromo, Stage } from "@app/common/graphql/generated/schema.graphql";
import { FeedStackScreenProps, Routes } from "@app/common/types/navigation.type";
import { PurchaseCampaignItemCaption } from "@app/components/campaign/purchase-campaign-item-caption/purchase-campaign-item-caption";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { OpenVariantPickerButton } from "@app/components/products/open-variant-picker-button/open-variant-picker-button.component";
import { Product } from "@app/components/products/product/product.component";
import { ProductCardActionButtons } from "@app/components/products/product-card-action-buttons/product-card-action-buttons.component";
import { useFeedContext } from "@app/context/feed/feed.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { isCollab, isStandardPromo } from "@app/utils/feed.util";

import { styles } from "./product-from-feed.style";

export const ProductFromFeed: FC<FeedStackScreenProps<Routes.productFromFeed>> = ({ route, navigation }) => {
  const { index: productIndex } = route.params;
  const { colors } = useTheme();
  const { trackInMixpanel } = useMixpanelContext();
  const { bottom: bottomInset } = useSafeAreaInsets();

  const {
    feed: {
      swiperRef,
      feedResult: { data },
      swipeRightProps,
      swipeLeftProps,
    },
  } = useFeedContext();

  const extractProduct = (entity?: FeedCollabOrPromo): Collab | undefined => {
    if (isCollab(entity)) return entity;
    if (isStandardPromo(entity)) return entity.collab;

    return undefined;
  };

  const [feedEntity] = useState(
    typeof productIndex === "string" ? data?.nodes?.find(p => extractProduct(p)?.handle === productIndex) : data?.nodes?.[productIndex],
  );

  const product = extractProduct(feedEntity);
  const onlyMainActionButtons =
    isCollab(feedEntity) &&
    !(
      [Stage.underReview, Stage.forSale].includes(feedEntity.progress.stage) &&
      [AvailablePurchaseMethod.checkout, AvailablePurchaseMethod.preorder].includes(feedEntity.availablePurchaseMethod)
    );

  useEffect(() => {
    if (typeof productIndex === "string") return;

    trackInMixpanel(TrackEvent.infoClickOnFeed, { collabId: product?.collabId });
    navigation.setParams({ index: product?.handle });
  }, [navigation, product?.collabId, product?.handle, productIndex, trackInMixpanel]);

  useEffect(() => {
    if (!product) {
      navigation.navigate(Routes.feed);
    }
  }, [navigation, product]);

  const mainActionButtons = useMemo(
    () => (
      <ProductCardActionButtons
        swipeRightProps={{
          onSwipe: () => {
            navigation.goBack();
            swiperRef?.current?.swipeRight();
          },
          content: swipeRightProps.content,
        }}
        swipeLeftProps={{
          onSwipe: () => {
            navigation.goBack();
            swiperRef?.current?.swipeLeft();
          },
          content: swipeLeftProps.content,
        }}
      />
    ),
    [navigation, swipeLeftProps.content, swipeRightProps.content, swiperRef],
  );

  const mainActionButtonsWithWrapper = useMemo(
    () => <View style={[{ bottom: bottomInset + 8 }, styles.actionButtonsContainer]}>{mainActionButtons}</View>,
    [bottomInset, mainActionButtons],
  );

  const mapPurchaseButtons = useCallback(
    (feedEntityAsCollab: Collab): Record<AvailablePurchaseMethod, JSX.Element | undefined> => ({
      [AvailablePurchaseMethod.unavailable]: mainActionButtonsWithWrapper,
      [AvailablePurchaseMethod.preorder]: (
        <View style={[{ backgroundColor: colors.tertiaryContainer }, styles.buttonsContainer]}>
          <PurchaseCampaignItemCaption
            stage={feedEntityAsCollab.progress.stage}
            reviewEndAt={feedEntityAsCollab.progress.reviewEndAt}
            campaignStatus={feedEntityAsCollab.campaign?.status}
          />
          <OpenVariantPickerButton product={feedEntityAsCollab} buyAction="preorder" size="large" displayPrice />
          {mainActionButtons}
        </View>
      ),
      [AvailablePurchaseMethod.checkout]: (
        <View style={[{ backgroundColor: colors.tertiaryContainer }, styles.buttonsContainer]}>
          <PurchaseCampaignItemCaption
            stage={feedEntityAsCollab.progress.stage}
            reviewEndAt={feedEntityAsCollab.progress.reviewEndAt}
            campaignStatus={feedEntityAsCollab.campaign?.status}
          />
          <OpenVariantPickerButton product={feedEntityAsCollab} buyAction="addToCart" size="large" displayPrice />
          <OpenVariantPickerButton product={feedEntityAsCollab} buyAction="buyNow" size="large" mode="outlined" displayPrice />
          {mainActionButtons}
        </View>
      ),
    }),
    [colors.tertiaryContainer, mainActionButtons, mainActionButtonsWithWrapper],
  );

  const mapButtons = useCallback(
    (feedEntityAsCollab: Collab): Record<Stage, JSX.Element | undefined> => ({
      [Stage.concept]: mainActionButtonsWithWrapper,
      [Stage.underReview]: mapPurchaseButtons(feedEntityAsCollab)[feedEntityAsCollab.availablePurchaseMethod],
      [Stage.forSale]: mapPurchaseButtons(feedEntityAsCollab)[feedEntityAsCollab.availablePurchaseMethod],
      [Stage.contest]: mainActionButtonsWithWrapper,
      [Stage.hidden]: mainActionButtonsWithWrapper,
    }),
    [mainActionButtonsWithWrapper, mapPurchaseButtons],
  );

  return (
    <>
      <ScreenWrapper
        withScrollView
        contentContainerStyle={onlyMainActionButtons ? styles.marginBottom : undefined}
        staticContent={isCollab(feedEntity) ? mapButtons(feedEntity)[feedEntity.progress.stage] : undefined}>
        {product && <Product product={product} />}
      </ScreenWrapper>

      {!onlyMainActionButtons && isCollab(feedEntity) && (
        <SafeAreaView style={[styles.bottomIosHandle, { backgroundColor: colors.tertiaryContainer }]} />
      )}
    </>
  );
};
