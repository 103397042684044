import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreStudioImageFields } from "@app/common/graphql/fragments.graphql";
import { PaginatedStudioImages } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteStudioImages: PaginatedStudioImages;
}

interface Variables {
  imageIds: string[];
}

interface DeleteStudioImageResult extends MutationResult<Response, "deleteStudioImages"> {
  deleteStudioImages: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteStudioImagesMutation = gql`
  mutation DeleteStudioImages($imageIds: [StudioImageId!]!) {
    deleteStudioImages(imageIds: $imageIds) {
      nodes {
        ...CoreStudioImageFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${coreStudioImageFields}
`;

export function useDeleteStudioImages(): DeleteStudioImageResult {
  const [deleteStudioImages, { loading, error, data }] = useMutation<Response, Variables>(deleteStudioImagesMutation, {
    update: (cache, result, options) => {
      cache.modify({
        fields: {
          studioImages(existingStudioImages: PaginatedStudioImages, { readField }) {
            return {
              ...existingStudioImages,
              nodes: existingStudioImages.nodes?.filter(entity => !options.variables?.imageIds.includes(readField("imageId", entity) as string)),
              totalCount: existingStudioImages.totalCount - (result.data?.deleteStudioImages.nodes?.length ?? 0),
              __typename: existingStudioImages.__typename,
            };
          },
        },
      });
    },
  });

  return { deleteStudioImages, loading, error, data: data?.deleteStudioImages };
}
