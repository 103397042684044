import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ArrowLineUp, ArrowsClockwise, BookmarkSimple, Coins, DownloadSimple, Person, X } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, useTheme, ActivityIndicator, IconButtonProps } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

import { StudioImageStatus } from "@app/common/graphql/generated/schema.graphql";
import { transparent } from "@app/common/style/theme";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { StudioImageWithIndex } from "@app/common/types/studio.type";
import { HeaderContent } from "@app/components/common/bottom-sheet/bottom-sheet.common";
import { Popper } from "@app/components/common/popper/popper.component";
import { Text } from "@app/components/common/text/text.component";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useGenerateStudioImageDownloadLink } from "@app/hooks/api/use-generate-jpg-download-link.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { downloadFile } from "@app/utils/file.util";

import { styles } from "./studio-image-actions.style";

interface Option {
  icon: IconSource;
  disabled?: boolean;
  onPress?: () => void;
  title: string;
}

interface Props {
  onClose: () => void;
  selectedImages: StudioImageWithIndex[];
  onSave: () => Promise<void>;
  onGeneratePoses?: () => Promise<void>;
  onGenerateVariations?: () => Promise<void>;
  onDownload?: () => void;
}

export const StudioImageActions: FC<Props> = ({ onClose, selectedImages, onGeneratePoses, onGenerateVariations, onSave }) => {
  const { roundness, colors } = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { showErrorSnackbar } = useSnackbarContext();
  const { isFeatureEnabled } = useLaunchDarklyContext();
  const { state, updateState } = useProfileContext();
  const { setInputImages: setImages, isCurrentlyGenerating } = useStudioMiniContext();

  const [variationsPopperOpen, setVariationsPopperOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({ poses: false, post: false, variations: false, save: false });

  const { generateStudioImageDownloadLink, loading } = useGenerateStudioImageDownloadLink();

  const isPoseFeatureEnabled = isFeatureEnabled(FeatureFlag.studioPosesEnabled);
  const areMultipleItemsSelected = selectedImages.length > 1;
  // we check this to avoid user to delete temporary image as the call the save them will no work afterward.
  const areAllSelectedImagesSaved = useMemo(() => selectedImages.every(si => si.status === StudioImageStatus.permanent), [selectedImages]);

  const handleCloseVariationsPopper = useCallback(() => {
    updateState({ input: { [UserStateEnum.studioVariationsPopperDismissed]: true } });
  }, [updateState]);

  const handleDownloadPress = useCallback((): void => {
    if (areMultipleItemsSelected) return;
    const fileName = t("studioMini.prompt.imageId", { id: selectedImages?.[0]?.index ?? 0 + 1 });
    void generateStudioImageDownloadLink({
      variables: { input: { imageId: selectedImages[0].imageId } },
      onCompleted: response =>
        void downloadFile(response.generateStudioImageDownloadLink.imageUrl, selectedImages[0].imageId, fileName).catch((error: Error) =>
          showErrorSnackbar({ error }),
        ),
      onError: e => showErrorSnackbar({ error: e, message: t("error.generic") }),
    });
  }, [areMultipleItemsSelected, generateStudioImageDownloadLink, selectedImages, showErrorSnackbar, t]);

  const handleSavePress = useCallback((): void => {
    setIsLoading(prev => ({ ...prev, save: true }));
    onSave()
      .then(() => setIsLoading(prev => ({ ...prev, save: false })))
      .catch(() => setIsLoading(prev => ({ ...prev, save: false })));
  }, [onSave]);

  const handlePostPress = useCallback(() => {
    if (!areAllSelectedImagesSaved) {
      setIsLoading(prev => ({ ...prev, post: true }));
      onSave()
        .then(() => setIsLoading(prev => ({ ...prev, post: false })))
        .catch(() => setIsLoading(prev => ({ ...prev, post: false })));
    }

    setImages(selectedImages);
    navigation.navigate(Routes.studioMiniCreateCollab);
  }, [areAllSelectedImagesSaved, navigation, onSave, selectedImages, setImages]);

  const commonButtonProps: Partial<IconButtonProps> = {
    mode: "outlined",
    size: 24,
    style: [{ borderRadius: roundness }, styles.button],
  };

  const actions = useMemo<Option[]>(
    () => [
      ...conditionalItem<Option>(!!onGenerateVariations, {
        icon: props => (
          <View>
            <ArrowsClockwise weight="thin" {...props} />
            <View style={styles.buttonOverlay}>
              <Coins size={12} weight="thin" color={colors.primary} />
            </View>
          </View>
        ),
        disabled: areMultipleItemsSelected || isCurrentlyGenerating,
        onPress: onGenerateVariations,
        title: t("studioMini.prompt.actions.variations"),
      }),
      ...conditionalItem<Option>(isPoseFeatureEnabled && !!onGeneratePoses, {
        icon: props => (
          <View>
            <Person weight="thin" {...props} />
            <View style={styles.buttonOverlay}>
              <Coins size={12} weight="thin" color={colors.primary} />
            </View>
          </View>
        ),
        disabled: areMultipleItemsSelected || isCurrentlyGenerating || !isPoseFeatureEnabled,
        onPress: onGeneratePoses,
        title: isPoseFeatureEnabled ? t("studioMini.prompt.actions.poses") : t("studioMini.prompt.actions.posesDisabled"),
      }),
      {
        icon: props =>
          isLoading.save ? (
            <ActivityIndicator animating />
          ) : (
            <BookmarkSimple weight={selectedImages.every(img => img.status === StudioImageStatus.permanent) ? "fill" : "thin"} {...props} />
          ),
        disabled: areAllSelectedImagesSaved,
        onPress: handleSavePress,
        title: t("studioMini.prompt.actions.save"),
      },
      {
        icon: props => (loading ? <ActivityIndicator animating /> : <DownloadSimple weight="thin" {...props} />),
        disabled: areMultipleItemsSelected,
        onPress: handleDownloadPress,
        title: t("studioMini.prompt.download"),
      },
      {
        icon: props => (isLoading.post ? <ActivityIndicator animating /> : <ArrowLineUp weight="thin" {...props} />),
        disabled: isLoading.save,
        onPress: handlePostPress,
        title: t("studioMini.prompt.actions.post"),
      },
    ],
    [
      onGenerateVariations,
      areMultipleItemsSelected,
      isCurrentlyGenerating,
      t,
      isPoseFeatureEnabled,
      onGeneratePoses,
      areAllSelectedImagesSaved,
      handleSavePress,
      handleDownloadPress,
      isLoading.save,
      isLoading.post,
      handlePostPress,
      colors.primary,
      selectedImages,
      loading,
    ],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!state?.[UserStateEnum.studioVariationsPopperDismissed]) {
        setVariationsPopperOpen(true);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [state]);

  return (
    <View style={styles.root}>
      <View style={styles.titleContainer}>
        <HeaderContent
          title={t("studioMini.prompt.imageId", { id: selectedImages?.map(i => i.index + 1).join(", ") })}
          rightHeader={<IconButton icon={X} onPress={onClose} />}
        />
      </View>

      <View style={styles.content}>
        {actions.map(action => (
          <View style={styles.buttonContainer} key={action.title}>
            {action.title === t("studioMini.prompt.actions.variations") ? (
              <Popper
                isOpen={variationsPopperOpen}
                setIsOpen={setVariationsPopperOpen}
                message={t("studioMini.popper.variations")}
                onClose={handleCloseVariationsPopper}
                backgroundColor={transparent}
                childContentSpacing={10}>
                <IconButton icon={action.icon} disabled={action.disabled} onPress={action.onPress} {...commonButtonProps} />
              </Popper>
            ) : (
              <IconButton icon={action.icon} disabled={action.disabled} onPress={action.onPress} {...commonButtonProps} />
            )}
            <Text variant="tooltip" textAlign="center" color={action.disabled ? "action.disabled" : undefined}>
              {action.title}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};
