import React, { FC, useCallback, useEffect, useState } from "react";

import { ArrowLineUp, DownloadSimple, TrashSimple } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";
import Animated, { useSharedValue, withTiming } from "react-native-reanimated";

import { nbProductsPerFetch } from "@app/common/constants/products.const";
import { StudioImage } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { ImageToBeUsed } from "@app/common/types/image.type";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { StudioImageList } from "@app/components/studio-mini/studio-image-list/studio-image-list.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useStudioImages } from "@app/hooks/api/studio/use-studio-images.hook";
import { useDeleteStudioImages } from "@app/hooks/api/use-delete-studio-images.hook";
import { useGenerateStudioImageDownloadLink } from "@app/hooks/api/use-generate-jpg-download-link.hook";
import { useFetchMoreItems } from "@app/hooks/utils/use-fetch-more-items.hook";
import { downloadFile } from "@app/utils/file.util";

import { styles } from "./studio-saved-images.style";
import { SelectionModeOption } from "./studio-saved-images.type";

interface Props {
  selectionMode?: SelectionModeOption;
  onSelectImage?: (image: StudioImage) => void;
  allowMultipleSelection: boolean;
  isSelecting: boolean;
  onUseImages?: (images: ImageToBeUsed[]) => void;
}

export const StudioSavedImages: FC<Props> = ({ selectionMode, onSelectImage, allowMultipleSelection = true, isSelecting, onUseImages }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { data, loading, fetchMore, networkStatus, refetch } = useStudioImages({
    variables: { first: nbProductsPerFetch, where: { userId: profile?.userId ?? "" } },
    skip: !profile?.userId,
  });
  const { fetchMoreItems } = useFetchMoreItems("studioImages", fetchMore);
  const { deleteStudioImages, loading: loadingDelete } = useDeleteStudioImages();
  const { generateStudioImageDownloadLink, loading: loadingDownload } = useGenerateStudioImageDownloadLink();

  const [selectedImages, setSelectedImages] = useState<ImageToBeUsed[]>([]);

  const navHeight = useSharedValue(0);
  const navOpacity = useSharedValue(0);

  useEffect(() => {
    navOpacity.value = withTiming(isSelecting ? 1 : 0, { duration: 500 });
    navHeight.value = withTiming(isSelecting ? 50 : 0, { duration: 500 });
  }, [isSelecting, navHeight, navOpacity]);

  useEffect(() => {
    setSelectedImages([]);
  }, [isSelecting]);

  const handleDownloadImage = (): void => {
    const image = selectedImages[0];
    void generateStudioImageDownloadLink({
      variables: { input: { imageId: image.imageId } },
      onCompleted: response =>
        void downloadFile(response.generateStudioImageDownloadLink.imageUrl, image.imageId).catch((error: Error) => showErrorSnackbar({ error })),
      onError: e => showErrorSnackbar({ error: e }),
    });
  };

  const handleDeleteImages = (): void => {
    void deleteStudioImages({ variables: { imageIds: selectedImages.map(s => s.imageId) }, onCompleted: () => setSelectedImages([]) });
  };

  const handleOnClickImage = useCallback(
    (image: StudioImage) => {
      if (isSelecting) {
        if (allowMultipleSelection) {
          setSelectedImages(prev => {
            const index = prev.findIndex(img => img.imageId === image.imageId);
            if (index >= 0) {
              const copy = [...prev];
              copy.splice(index, 1);
              return copy;
            }
            return [...prev, image];
          });
        } else {
          setSelectedImages([image]);
        }
      } else {
        onSelectImage?.(image);
      }
    },
    [allowMultipleSelection, isSelecting, onSelectImage],
  );

  const handleFetchMore = (): void => {
    if (fetchMore && !loading && data?.nodes?.length && data?.pageInfo.hasNextPage && data.nodes.length < data.totalCount) {
      void fetchMoreItems({ offset: data?.nodes.length });
    }
  };

  const commonIconButtonProps = { iconColor: colors.onSecondary, theme: { colors: { onSurfaceDisabled: darkTheme.colors.onSurfaceDisabled } } };

  return (
    <>
      <Animated.View style={[{ height: navHeight, opacity: navOpacity }, { backgroundColor: colors.secondary }]}>
        {isSelecting && (
          <View style={styles.actionBackContainer}>
            <Text variant="h8" color="onSecondary">
              {t("studioMini.saved.selectedImages", { count: selectedImages.length })}
            </Text>
            <View style={styles.actionContainer}>
              {selectionMode === "always" ? (
                <Button
                  size="large"
                  theme={{ colors: { ...colors, primary: colors.onSecondary, onSurfaceDisabled: darkTheme.colors.onSurfaceDisabled } }}
                  icon={ArrowLineUp}
                  disabled={selectedImages.length <= 0}
                  onPress={() => onUseImages?.(selectedImages)}>
                  {t("cta.post")}
                </Button>
              ) : (
                <>
                  <IconButton
                    icon={({ color, ...props }) =>
                      loadingDownload ? <ActivityIndicator animating color={color} /> : <DownloadSimple color={color} {...props} />
                    }
                    disabled={selectedImages.length !== 1}
                    onPress={handleDownloadImage}
                    {...commonIconButtonProps}
                  />
                  <IconButton
                    icon={({ color, ...props }) =>
                      loadingDelete ? <ActivityIndicator animating color={color} /> : <TrashSimple color={color} {...props} />
                    }
                    disabled={selectedImages.length <= 0}
                    onPress={handleDeleteImages}
                    {...commonIconButtonProps}
                  />
                  <IconButton
                    icon={ArrowLineUp}
                    disabled={selectedImages.length <= 0}
                    onPress={() => onUseImages?.(selectedImages)}
                    {...commonIconButtonProps}
                  />
                </>
              )}
            </View>
          </View>
        )}
      </Animated.View>
      <StudioImageList
        images={data?.nodes}
        selectedImages={selectedImages}
        loading={loading}
        networkStatus={networkStatus}
        onClickImage={handleOnClickImage}
        onEndReached={handleFetchMore}
        refetch={refetch}
        scrollEnabled={selectionMode === "off" ? false : undefined}
      />
    </>
  );
};
