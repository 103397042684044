import React, { FC, useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { IconContext } from "phosphor-react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { client } from "@app/common/apollo/apollo-client";
import { darkTheme, lightTheme } from "@app/common/style/theme";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { AppStateContextProvider } from "@app/context/app-state/app-state.provider";
import { AuthContextProvider } from "@app/context/auth/auth.provider";
import { LoginContextProvider } from "@app/context/auth/login/login.provider";
import { BranchProvider } from "@app/context/branch/branch.provider";
import { CampaignContextProvider } from "@app/context/campaign/campaign.provider";
import { CartContextProvider } from "@app/context/cart/cart.provider";
import { useColorSchemeContext } from "@app/context/color-scheme/color-scheme.context";
import { ConfigContextProvider } from "@app/context/config/config.provider";
import { InAppPurchasesProvider } from "@app/context/in-app-purchases/in-app-purchases.provider";
import { LaunchDarklyProvider } from "@app/context/launch-darkly/launch-darkly.provider";
import { LocalStorageContextProvider } from "@app/context/local-storage/local-storage.provider";
import { MixpanelContextProvider } from "@app/context/mixpanel/mixpanel.provider";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { OnboardingContextProvider } from "@app/context/onboarding/onboarding.provider";
import { PopperContextProvider } from "@app/context/popper/popper.provider";
import { ProfileContextProvider } from "@app/context/profile/profile.provider";
import { IncomingPushNotificationsContextProvider } from "@app/context/push-notifications/incoming/incoming-push-notifications.provider";
import { PushNotificationPermissionContextProvider } from "@app/context/push-notifications/permission/push-notification-permission.provider";
import { SnackbarContextProvider } from "@app/context/snackbar/snackbar.provider";
import { StreamContextProvider } from "@app/context/stream/stream.provider";
import { WrappedStripeProvider } from "@app/context/stripe/stripe.provider";
import { StudioMiniProvider } from "@app/context/studio-mini/studio-mini.provider";

import { styles } from "./router.style";

if (__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const RootContextProviders: FC<ChildrenProp> = ({ children }) => {
  const { colorScheme } = useColorSchemeContext();

  return (
    <AppStateContextProvider>
      <ApolloProvider client={client}>
        <LaunchDarklyProvider>
          <PaperProvider theme={colorScheme === "dark" ? darkTheme : lightTheme}>
            <SafeAreaProvider>
              <IconContext.Provider value={{ weight: "thin" }}>
                <MixpanelContextProvider>
                  <SnackbarContextProvider>
                    <LocalStorageContextProvider>
                      <WrappedStripeProvider>
                        <AuthContextProvider>
                          <ProfileContextProvider>
                            <InAppPurchasesProvider>
                              <ConfigContextProvider>
                                <PopperContextProvider>
                                  <PushNotificationPermissionContextProvider>
                                    <StudioMiniProvider>
                                      <OnboardingContextProvider>{children}</OnboardingContextProvider>
                                    </StudioMiniProvider>
                                  </PushNotificationPermissionContextProvider>
                                </PopperContextProvider>
                              </ConfigContextProvider>
                            </InAppPurchasesProvider>
                          </ProfileContextProvider>
                        </AuthContextProvider>
                      </WrappedStripeProvider>
                    </LocalStorageContextProvider>
                  </SnackbarContextProvider>
                </MixpanelContextProvider>
              </IconContext.Provider>
            </SafeAreaProvider>
          </PaperProvider>
        </LaunchDarklyProvider>
      </ApolloProvider>
    </AppStateContextProvider>
  );
};

interface AppProps extends ChildrenProp {
  initialRoute?: string;
}

/**
 * Providers that need to access the navigation and are not critical to the app launch
 */
export const AppContextProviders: FC<AppProps> = ({ children, initialRoute }) => {
  const navigation = useNavigation();

  const { showOnboarding, initialStep } = useOnboardingContext();

  useEffect(() => {
    if (showOnboarding && initialStep) {
      navigation.navigate(Routes.onboardingStack, { screen: initialStep });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnboarding]);

  return (
    <IncomingPushNotificationsContextProvider>
      <BranchProvider>
        <CartContextProvider>
          <StreamContextProvider>
            <GestureHandlerRootView style={styles.rootWrapperStyle}>
              <BottomSheetModalProvider>
                <LoginContextProvider initialRoute={initialRoute}>
                  <CampaignContextProvider>{children}</CampaignContextProvider>
                </LoginContextProvider>
              </BottomSheetModalProvider>
            </GestureHandlerRootView>
          </StreamContextProvider>
        </CartContextProvider>
      </BranchProvider>
    </IncomingPushNotificationsContextProvider>
  );
};
