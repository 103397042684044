import React, { FC, useState } from "react";

import { formatDate } from "date-fns";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { CollabImage, CollabUpdate, ImageSource } from "@app/common/graphql/generated/schema.graphql";
import { Routes } from "@app/common/types/navigation/routes.enum";
import { useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";

import { ProductImages } from "../../products/product-images/product-images.component";
import { UpdateSideIconButtons } from "../update-side-icon-buttons/update-side-icon-buttons.component";

import { styles } from "./update-details.style";

interface Props {
  update: CollabUpdate;
  index?: number;
}

export const UpdateDetails: FC<Props> = ({ update, index }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation();

  const updateImages: CollabImage[] = update.images.map(i => ({
    collabImageId: i.collabUpdateImageId,
    imageUrl: i.imageUrl,
    source: ImageSource.upload,
    index: 0,
    __typename: "CollabImage",
  }));
  const noImages = !updateImages.length;

  const [currentImage, setCurrentImage] = useState<CollabImage | undefined>(updateImages[0]);

  const contentOnImage = (
    <View style={styles.imageContentContainer}>
      <UpdateSideIconButtons update={update} index={index} currentImage={currentImage} />
    </View>
  );

  return (
    <View style={styles.root}>
      {!!updateImages.length && (
        <ProductImages images={updateImages} collabId={update.collabId} setCurrentImage={setCurrentImage} contentOnImage={contentOnImage} />
      )}

      <View style={styles.textContainer}>
        <Text variant="h7">{update.title}</Text>

        {noImages && (
          <UpdateSideIconButtons
            update={update}
            index={index}
            currentImage={currentImage}
            iconColor={colors.onBackground}
            style={styles.actionsWhenNoImage}
          />
        )}

        <View style={styles.rowSpaceBetween}>
          <Text variant="caption" color="tertiary">
            {index ? t("productDetails.updates.updateWithIndex", { index }) : t("productDetails.updates.update")}
          </Text>
          <Text variant="caption" color="tertiary">
            {formatDate(update.createdAt, "MMMM d, yyyy")}
          </Text>
        </View>
        <Text variant="body2">{update.content}</Text>

        <ListItem
          title={<CreatorName name={update.user.displayName} badge={update.user.badge} textProps={{ variant: "h8", color: "tertiary" }} />}
          imageProps={{ image: update.user.imageUrl, size: 40, fallbackText: update.user.displayName.charAt(0) }}
          onPress={() => navigation.navigate(Routes.profile, { id: update.user.username })}
        />
      </View>
    </View>
  );
};
