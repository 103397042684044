import React, { FC, useCallback, useMemo, useState } from "react";

import { ProfileList, ProfileListRefProps } from "@app/components/profile/profile-list/profile-list.component";
import { useBrowseUsers } from "@app/hooks/api/use-browse-users.hook";
import { Facets, FacetsTypename } from "@app/hooks/utils/filtering-args/use-filtering-args.const";
import { useFilteringArgs } from "@app/hooks/utils/filtering-args/use-filtering-args.hook";

import { FeaturedProfile } from "../featured-profile/featured-profile.component";

export const BrowseProfileResults: FC = () => {
  const { getSearchFiltersFromFacets } = useFilteringArgs();

  const [facets, setFacets] = useState<Facets | undefined>(undefined);

  const profileListRef = useCallback((node: ProfileListRefProps | null) => {
    if (node?.data && "facets" in node.data) {
      setFacets(node.data.facets);
    }
  }, []);

  const filters = useMemo(() => getSearchFiltersFromFacets(FacetsTypename.user, facets), [facets, getSearchFiltersFromFacets]);

  return (
    <ProfileList
      ref={profileListRef}
      useProfiles={useBrowseUsers}
      variables={{}}
      filters={filters}
      stickyHeaderHiddenOnScroll={false}
      stickyHeaderIndices={undefined}
      ListHeaderComponent={<FeaturedProfile />}
    />
  );
};
