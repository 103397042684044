import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { InstagramLoginUrl } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  generateInstagramLoginUrl: InstagramLoginUrl;
}

interface Variables {
  userId: string;
}

interface GenerateInstagramLoginUrlResult extends MutationResult<Response, "generateInstagramLoginUrl"> {
  generateInstagramLoginUrl: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const generateInstagramLoginUrlMutation = gql`
  mutation GenerateInstagramLoginUrl($userId: UserId!) {
    generateInstagramLoginUrl(userId: $userId) {
      url
    }
  }
`;

export function useGenerateInstagramLoginUrl(): GenerateInstagramLoginUrlResult {
  const [generateInstagramLoginUrl, { loading, error, data }] = useMutation<Response, Variables>(generateInstagramLoginUrlMutation);

  return { generateInstagramLoginUrl, loading, error, data: data?.generateInstagramLoginUrl };
}
