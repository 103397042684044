/* eslint-disable max-lines-per-function */
import React, { FC } from "react";

import { Trophy, Lightbulb, CaretRight, SkipBack, ChatTeardropText, BookmarkSimple } from "phosphor-react-native";
import { Trans, useTranslation } from "react-i18next";
import { Divider, List, useTheme } from "react-native-paper";

import { TrackEvent, ViewContestsOrigin, ViewSuperplanScreenOrigin } from "@app/common/enums/track-events.enum";
import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Routes, StudioStackScreenProps } from "@app/common/types/navigation.type";
import { Alert } from "@app/components/common/alert/alert.component";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { FeaturedContest } from "@app/components/contests/featured-contest/featured-contest.component";
import { ExploreSection } from "@app/components/studio-mini/explore/explore.component";
import { StudioTokenButton } from "@app/components/studio-mini/studio-token-button/studio-token-button.component";
import { SuperplanAlert } from "@app/components/superplans/superplan-alert/superplan-alert.component";
import { useLoginContext } from "@app/context/auth/login/login.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";
import { useContests } from "@app/hooks/api/contests/use-contests.hook";

import { styles } from "./studio.style";

export const StudioScreen: FC<StudioStackScreenProps<Routes.studio>> = ({ navigation }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { trackInMixpanel } = useMixpanelContext();
  const { generation, resetGeneration } = useStudioMiniContext();
  const { data, loading } = useContests({ variables: { where: { includeHidden: false, status: ContestStatus.inProgress }, first: 1, offset: 0 } });
  const { profile } = useProfileContext();
  const { openLogin } = useLoginContext();

  const areProjectInProgress = !!generation.taskIds;
  const featuredContest = data?.nodes?.[0];

  const handlePress = (): void => {
    if (areProjectInProgress) {
      Alert.alert(t("error.warning"), t("studioMini.warningMessage"), [
        { text: t("cta.cancel"), style: "cancel" },
        {
          style: "destructive",
          text: t("cta.yes"),
          onPress: () => {
            resetGeneration();
            navigation.navigate(Routes.studioMiniGeneration);
          },
        },
      ]);
    } else {
      resetGeneration();
      navigation.navigate(Routes.studioMiniGeneration);
    }
  };

  const handleOnPressPublish = (): void => {
    if (!profile) {
      openLogin();
    } else {
      navigation.navigate(Routes.studioMiniCreateCollab);
    }
  };

  const handleOpenGeneration = (): void => navigation.navigate(Routes.studioMiniGeneration);

  const handleNavigateToContest = (): void => {
    trackInMixpanel(TrackEvent.viewContests, { origin: ViewContestsOrigin.studio });
    navigation.navigate(Routes.activeContests);
  };

  const listItemStyle = [{ backgroundColor: colors.tertiaryContainer }, styles.listItem];

  const commonActionProps: Partial<ListItemProps> = {
    descriptionProps: { variant: "body2" },
    style: listItemStyle,
    textContainerStyle: styles.minorRowGap,
    right: <CaretRight color={colors.primary} />,
  };

  const commonHeaderProps: Partial<ListItemProps> = {
    descriptionProps: { variant: "body2" },
    style: styles.listItem,
    titleProps: { variant: "h8" },
    textContainerStyle: styles.rowGap,
  };

  return (
    <ScreenWrapper safeAreaEdges={featuredContest ? ["right", "left"] : undefined} withScrollView>
      <FeaturedContest contest={featuredContest} loading={loading} />
      <List.Section>
        <ListItem {...commonHeaderProps} title={t("studioMini.projects.publish.title")} description={t("studioMini.projects.publish.subtitle")} />
        <ListItem
          {...commonActionProps}
          title={
            <Trans
              i18nKey={"studioMini.projects.publish.post.title"}
              parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
              components={{
                b: (
                  <Text variant="h8">
                    <></>
                  </Text>
                ),
              }}
            />
          }
          description={t("studioMini.projects.publish.post.subtitle")}
          left={<Lightbulb weight="fill" color={colors.primary} />}
          onPress={handleOnPressPublish}
        />
        <Divider />
        <ListItem
          {...commonActionProps}
          title={
            <Trans
              i18nKey={"studioMini.projects.publish.contest.title"}
              parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
              components={{
                b: (
                  <Text variant="h8">
                    <></>
                  </Text>
                ),
              }}
            />
          }
          description={t("studioMini.projects.publish.contest.subtitle")}
          left={<Trophy weight="fill" color={colors.primary} />}
          onPress={handleNavigateToContest}
        />
      </List.Section>

      <List.Section>
        <ListItem
          {...commonHeaderProps}
          title={t("studioMini.projects.create.title")}
          description={t("studioMini.projects.create.subtitle")}
          right={<StudioTokenButton />}
        />

        <SuperplanAlert
          feature="infiniteTokens"
          flexDirection="row"
          origin={ViewSuperplanScreenOrigin.studioTab}
          onLearnMorePress={tab => navigation.navigate(Routes.superplans, { tab })}
        />

        <ListItem
          {...commonActionProps}
          title={
            <Trans
              i18nKey={"studioMini.projects.create.start.title"}
              parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
              components={{
                b: (
                  <Text variant="h8">
                    <></>
                  </Text>
                ),
              }}
            />
          }
          description={t("studioMini.projects.create.start.subtitle")}
          left={<ChatTeardropText weight="fill" color={colors.primary} />}
          onPress={handlePress}
        />
        <Divider />
        {areProjectInProgress && (
          <>
            <ListItem
              {...commonActionProps}
              title={
                <Trans
                  i18nKey={"studioMini.projects.create.resume.title"}
                  parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
                  components={{
                    b: (
                      <Text variant="h8">
                        <></>
                      </Text>
                    ),
                  }}
                />
              }
              description={t("studioMini.projects.create.resume.subtitle")}
              left={<SkipBack weight="fill" color={colors.primary} />}
              onPress={handleOpenGeneration}
            />
            <Divider />
          </>
        )}
        <ListItem
          {...commonActionProps}
          title={
            <Trans
              i18nKey={"studioMini.projects.publish.saved.title"}
              parent={({ children }: ChildrenProp) => <Text variant="body1">{children}</Text>}
              components={{
                b: (
                  <Text variant="h8">
                    <></>
                  </Text>
                ),
              }}
            />
          }
          description={t("studioMini.projects.publish.saved.subtitle")}
          left={<BookmarkSimple weight="fill" color={colors.primary} />}
          onPress={() => navigation.navigate(Routes.studioMiniSaved, { selectionMode: "allowed" })}
        />
      </List.Section>

      <List.Section>
        <ListItem
          {...commonHeaderProps}
          title={t("studioMini.projects.inspiration.title")}
          description={t("studioMini.projects.inspiration.subtitle")}
        />
      </List.Section>
      <ExploreSection />
    </ScreenWrapper>
  );
};
