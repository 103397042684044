import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { CreateCollabUpdateInput } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useProductUpdateNavigation } from "@app/common/types/navigation.type";
import { ImagesPicker } from "@app/components/common/images-picker/images-picker.component";
import { TextInput } from "@app/components/common/text-input/text-input.component";
import { useProductUpdateContext } from "@app/context/product-update/product-update.context";

import { styles } from "./create-update-form.style";

export const CreateUpdateForm: FC = () => {
  const navigation = useProductUpdateNavigation();
  const { t } = useTranslation();

  const {
    inputs: { images, title, content },
    setImages,
  } = useProductUpdateContext();

  const handleEditField = (fieldName: keyof Omit<CreateCollabUpdateInput, "imageIds">) => () => {
    navigation.navigate(Routes.editProductUpdateField, { fieldName });
  };

  return (
    <>
      <ImagesPicker images={images} setImages={setImages} required={false} style={styles.rowGap} />
      <View style={styles.rowGap}>
        <TextInput label={t("productUpdates.fields.title.label")} value={title} editable={false} onPressIn={handleEditField("title")} />
        <TextInput
          label={t("productUpdates.fields.content.label")}
          multiline
          value={content}
          editable={false}
          onPressIn={handleEditField("content")}
        />
      </View>
    </>
  );
};
