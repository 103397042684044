import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { APP_ENV } from "@env";
import { NavigationContainer, RouteProp } from "@react-navigation/native";
import * as SplashScreen from "expo-splash-screen";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

import { analytics } from "@app/common/analytics/analytics";
import { navigationDarkTheme, navigationLightTheme } from "@app/common/style/navigation-theme";
import { navigationRef, RootStackParamList, Routes } from "@app/common/types/navigation.type";
import { FeatureAnnouncement } from "@app/components/common/feature-announcement/feature-announcement.component";
import { Head } from "@app/components/common/head/head.component";
import { AnimatedAppLoader } from "@app/components/initial-states/animated-app-loader/animated-app-loader.component";
import { InAppBrowser } from "@app/components/initial-states/in-app-browser/in-app-browser.component";
import { LoginScreen } from "@app/components/initial-states/login-screen/login-screen.component";
import { Maintenance } from "@app/components/initial-states/maintenance/maintenance.component";
import { UpdateAvailable } from "@app/components/initial-states/update-available/update-available.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useColorSchemeContext } from "@app/context/color-scheme/color-scheme.context";
import { useConfigContext } from "@app/context/config/config.context";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useLocalStorageContext } from "@app/context/local-storage/local-storage.context";
import { useOnboardingContext } from "@app/context/onboarding/onboarding.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useStorefrontLocation } from "@app/hooks/api/use-storefront-location.hook";
import { useHealthCheck } from "@app/hooks/api/use-websocket-health-check.hook";
import { useRequestReview } from "@app/hooks/utils/use-request-review.hook";
import { AppContextProviders } from "@app/router/app-context-providers";
import { CollabSuccessScreen } from "@app/screens/collab-success/collab-success.screen";
import { ContestScreen } from "@app/screens/contests/contest/contest.screen";
import { ContestEntriesScreen } from "@app/screens/contests/contest-entries/contest-entries.screen";
import { FeedbackScreen } from "@app/screens/feedback/feedback.screen";
import { CreateCatalogProductScreen } from "@app/screens/god-mode/create-catalog-product/create-catalog-product.screen";
import { CreateContestScreen } from "@app/screens/god-mode/create-contest/create-contest.screen";
import { CreatePromoScreen } from "@app/screens/god-mode/create-promo/create-promo.screen";
import { UpdateCollabStageScreen } from "@app/screens/god-mode/update-collab-stage/update-collab-stage.screen";
import { HandleAuthActionScreen } from "@app/screens/handle-auth-action/handle-auth-action.screen";
import { ImageScreen } from "@app/screens/image/image.screen";
import { ManagePaymentMethodsScreen } from "@app/screens/manage-payment-methods/manage-payment-methods.screen";
import { ManageShippingAddressScreen } from "@app/screens/manage-shipping-address/manage-shipping-address.screen";
import { NotFoundScreen } from "@app/screens/not-found/not-found.screen";
import { ProductScreen } from "@app/screens/product/product.screen";
import { ProductUpdatesScreen } from "@app/screens/product-updates/product-updates.screen";
import { ProfileScreen } from "@app/screens/profile/profile.screen";
import { ActiveContestsScreen } from "@app/screens/studio-mini/active-contests/active-contests.screen";
import { CreateStudioCollabScreen } from "@app/screens/studio-mini/create-studio-collab/create-studio-collab.screen";
import { EditStudioFieldScreen } from "@app/screens/studio-mini/edit-studio-field/edit-studio-field.screen";
import { ExploreDetailsScreen } from "@app/screens/studio-mini/explore-details/explore-details.screen";
import { SavedImageDetailsScreen } from "@app/screens/studio-mini/saved-image-details/saved-image-details.screen";
import { SavedImagesScreen } from "@app/screens/studio-mini/saved-images/saved-images.screen";
import { SelectImageSourceScreen } from "@app/screens/studio-mini/select-image-source/select-image-source.screen";
import { StudioGenerationScreen } from "@app/screens/studio-mini/studio-generation/studio-generation.screen";
import { SuperplansScreen } from "@app/screens/superplans/superplans.screen";
import { VideoScreen } from "@app/screens/video/video.screen";
import { WebViewScreen } from "@app/screens/webview/webview.screen";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isDesktop, isWeb } from "@app/utils/device.util";
import { routingInstrumentation } from "@app/utils/init-sentry";
import { log } from "@app/utils/logger/logger.util";
import { isAdmin } from "@app/utils/user.util";

import { HomeStack } from "./home.stack";
import { getLinking } from "./linking";
import { commonScreenOptions, Stack } from "./router.const";
import { styles } from "./router.style";
import { OnboardingStack } from "./stacks/onboarding.stack";
import { PreorderStack } from "./stacks/preorder.stack";
import { ProductUpdateStack } from "./stacks/product-update.stack";

// eslint-disable-next-line complexity
export const Router: FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { isFeatureEnabled } = useLaunchDarklyContext();
  const { colorScheme } = useColorSchemeContext();

  const routeNameRef = useRef<string | null>(null);
  const [initialRoute, setInitialRoute] = useState<string | undefined>(undefined);
  const [splashScreenDismissed, setSplashScreenDismissed] = useState(false);

  const { state } = useAuthContext();
  const { loading: loadingProfile } = useProfileContext();
  const { loading: loadingLocalStorage } = useLocalStorageContext();
  const { loading: loadingConfig } = useConfigContext();
  const { showOnboarding, showLoginScreen } = useOnboardingContext();

  useHealthCheck();

  const connectedAndVerified = state.connected && state.verified;

  const isProfileRestored = state.restored ? !loadingProfile : true;
  const isAppReady = !state.loading && !loadingLocalStorage && !loadingConfig && isProfileRestored;

  const isVersionSupported = isFeatureEnabled(FeatureFlag.minVersionSupported);
  const isInMaintenance = isFeatureEnabled(FeatureFlag.maintenance);
  const shouldBypassRedirection = isFeatureEnabled(FeatureFlag.bypassAppRedirection);

  const { requestFirstReview } = useRequestReview();
  const { data: storefrontData, loading: storefrontLoading } = useStorefrontLocation(!state.loading && !loadingProfile, shouldBypassRedirection);

  const onLayoutRootView = useCallback(async () => {
    if (isAppReady) {
      await SplashScreen.hideAsync();
    }
  }, [isAppReady]);

  const initialRouteName = useMemo<Routes.onboardingStack | Routes.home>(() => {
    if (showOnboarding) return Routes.onboardingStack;

    return Routes.home;
  }, [showOnboarding]);

  useEffect(() => {
    if (!isAppReady) return;
    requestFirstReview();
  }, [isAppReady, requestFirstReview]);

  if (isDesktop && APP_ENV === "production" && ((storefrontData && !storefrontLoading) || storefrontLoading)) return null;

  if (!isVersionSupported) {
    return <UpdateAvailable onLayout={() => void onLayoutRootView()} style={styles.rootWrapperStyle} />;
  }

  if (isInMaintenance) {
    return <Maintenance onLayout={() => void onLayoutRootView()} style={styles.rootWrapperStyle} />;
  }

  const rootDesktopStyle = isDesktop ? { ...styles.desktopWrapper, backgroundColor: colors.tertiaryContainer } : {};

  return (
    <AnimatedAppLoader
      isAppReady={isAppReady}
      image={require("../../assets/splash.png") as number}
      splashScreenDismissed={splashScreenDismissed}
      setSplashScreenDismissed={setSplashScreenDismissed}>
      <NavigationContainer
        theme={colorScheme === "dark" ? navigationDarkTheme : navigationLightTheme}
        linking={getLinking(initialRouteName)}
        ref={navigationRef}
        onStateChange={() => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;
          const params = navigationRef.current?.getCurrentRoute()?.params;

          if (!currentRouteName || previousRouteName === currentRouteName) return;

          log.info(`[Navigating]: from ${previousRouteName} to ${currentRouteName} with params ${JSON.stringify(params)}`);

          void analytics.logScreenView(currentRouteName);
          routeNameRef.current = currentRouteName;
        }}
        onReady={() => {
          routingInstrumentation.registerNavigationContainer(navigationRef);

          setInitialRoute(navigationRef.isReady() ? navigationRef.getCurrentRoute()?.name : undefined);
        }}>
        <AppContextProviders initialRoute={initialRoute}>
          <Head />
          <FeatureAnnouncement />
          <View style={[styles.rootWrapperStyle, rootDesktopStyle]} onLayout={() => void onLayoutRootView()}>
            <View style={[styles.rootViewStyle, ...conditionalItem(isDesktop, styles.desktop)]}>
              <Stack.Navigator screenOptions={commonScreenOptions()} initialRouteName={initialRouteName}>
                <Stack.Group navigationKey={state.loading ? "loading" : "loaded"}>
                  <Stack.Screen name={Routes.home} component={HomeStack} options={{ headerShown: false }} />
                  {showOnboarding && <Stack.Screen name={Routes.onboardingStack} component={OnboardingStack} options={{ headerShown: false }} />}
                  {!connectedAndVerified && <Stack.Screen name={Routes.handleAuthAction} component={HandleAuthActionScreen} />}
                </Stack.Group>

                {isAdmin(state) && (
                  <Stack.Group screenOptions={{ gestureDirection: "vertical" }}>
                    <Stack.Screen name={Routes.updateCollabStage} component={UpdateCollabStageScreen} />
                    <Stack.Screen name={Routes.createContest} component={CreateContestScreen} />
                    <Stack.Screen name={Routes.createCatalogProduct} component={CreateCatalogProductScreen} />
                    <Stack.Screen name={Routes.createPromo} component={CreatePromoScreen} />
                  </Stack.Group>
                )}

                <Stack.Group screenOptions={{ gestureDirection: "vertical" }}>
                  <Stack.Screen name={Routes.collabSuccess} component={CollabSuccessScreen} options={{ headerLeft: () => undefined, title: "" }} />
                </Stack.Group>

                <Stack.Screen name={Routes.productUpdateStack} component={ProductUpdateStack} options={{ headerShown: false }} />

                <Stack.Group screenOptions={{ presentation: "card" }}>
                  <Stack.Screen name={Routes.product} component={ProductScreen} getId={({ params }) => params.id} options={{ title: "" }} />
                  <Stack.Screen name={Routes.productUpdates} component={ProductUpdatesScreen} getId={({ params }) => params.collabId} />
                  <Stack.Screen name={Routes.preorderStack} component={PreorderStack} options={{ headerShown: false }} />
                  <Stack.Screen name={Routes.managePaymentMethods} component={ManagePaymentMethodsScreen} />
                  <Stack.Screen name={Routes.manageShippingAddress} component={ManageShippingAddressScreen} />
                  <Stack.Screen name={Routes.contest} component={ContestScreen} getId={({ params }) => params.id} />
                  <Stack.Screen
                    name={Routes.contestEntries}
                    component={ContestEntriesScreen}
                    getId={({ params }) => params?.id}
                    options={({ route }: { route: RouteProp<RootStackParamList, Routes.contestEntries> }) => ({
                      headerTransparent: true,
                      headerLeft: route.params.fromFeed ? () => undefined : undefined,
                    })}
                  />
                  <Stack.Screen name={Routes.profile} component={ProfileScreen} getId={({ params }) => params?.id ?? "current"} />
                  <Stack.Screen name={Routes.webview} component={WebViewScreen} getId={({ params }) => params?.url} />
                  <Stack.Screen name={Routes.feedback} component={FeedbackScreen} options={{ title: t("feedback.pageTitle") }} />
                  <Stack.Screen name={Routes.superplans} component={SuperplansScreen} options={{ title: "", headerLeft: () => undefined }} />
                  <Stack.Screen name={Routes.superseller} component={SuperplansScreen} options={{ title: "", headerLeft: () => undefined }} />
                  <Stack.Screen name={Routes.notFound} component={NotFoundScreen} options={{ header: () => undefined }} />
                </Stack.Group>

                {/* Studio Screens */}
                <Stack.Group screenOptions={{ presentation: "card", title: "" }}>
                  <Stack.Screen
                    name={Routes.studioMiniSelectImageSource}
                    component={SelectImageSourceScreen}
                    options={{
                      title: t("studioMini.addImage"),
                      headerBackTitleVisible: false,
                    }}
                  />
                  <Stack.Screen name={Routes.studioMiniSaved} component={SavedImagesScreen} />
                  <Stack.Screen name={Routes.studioMiniSavedDetails} component={SavedImageDetailsScreen} />
                  <Stack.Screen name={Routes.studioMiniGeneration} component={StudioGenerationScreen} />
                  <Stack.Screen name={Routes.studioMiniEditField} component={EditStudioFieldScreen} />
                  <Stack.Screen name={Routes.studioMiniCreateCollab} component={CreateStudioCollabScreen} />
                  <Stack.Screen name={Routes.activeContests} component={ActiveContestsScreen} />
                  <Stack.Screen name={Routes.studioMiniExploreDetail} component={ExploreDetailsScreen} />
                </Stack.Group>

                <Stack.Group screenOptions={{ animation: "fade", headerLeft: () => undefined, title: "" }}>
                  <Stack.Screen name={Routes.image} component={ImageScreen} />
                  <Stack.Screen name={Routes.video} component={VideoScreen} />
                </Stack.Group>
              </Stack.Navigator>
            </View>
          </View>

          {isWeb && !isDesktop && <InAppBrowser style={styles.fullScreenOnTopOfEverything} />}

          {showLoginScreen && (
            <View style={[styles.fullScreenOnTopOfEverything, rootDesktopStyle]}>
              <LoginScreen style={[conditionalItem(isDesktop, styles.desktop), styles.rootViewStyle]} />
            </View>
          )}
        </AppContextProviders>
      </NavigationContainer>
    </AnimatedAppLoader>
  );
};
