import { REACT_APP_BRANCH_KEY } from "@env";
import branch from "branch-sdk";

import { reportError } from "@app/utils/logger/logger.util";

import { logBranch } from "./branch.common";

type DeepLinkCallback = (data: branch.SessionData) => void;

export function setUpBranch(handleOpenLink: DeepLinkCallback, callback: () => void): () => void {
  branch.init(
    REACT_APP_BRANCH_KEY,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { tracking_disabled: true },
    (error: branch.BranchError, data: branch.SessionData | null) => {
      if (error) {
        logBranch.debug("init failed");
        reportError(new Error(error), { level: "log" });
        throw error;
      }
      logBranch.debug("init SUCCESSFUL", data);

      if (data) {
        handleOpenLink(data);
      }

      callback();
    },
  );

  return () => undefined;
}
