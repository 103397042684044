import React, { FC } from "react";

import { useRoute } from "@react-navigation/native";
import { format, formatDistanceToNowStrict } from "date-fns";
import { ArrowRight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View, ViewProps } from "react-native";
import { Theme, useTheme } from "react-native-paper";
import Animated, { SharedValue, useAnimatedStyle } from "react-native-reanimated";

import { contestListItemImageSize } from "@app/common/constants/contests.const";
import { Contest, ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Button, ButtonProps } from "@app/components/common/button/button.component";
import { Chip } from "@app/components/common/chip/chip.component";
import { Image } from "@app/components/common/image/image.component";
import { ListItem, ListItemProps } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";

import { styles } from "./contest-list-item.style";

export interface Props extends Pick<ViewProps, "pointerEvents" | "onLayout">, Omit<ListItemProps, "title"> {
  contest: Contest;
  collapsed?: Readonly<SharedValue<boolean>>;
  theme?: Theme;
}

export const ContestListItem: FC<Props> = ({
  contest,
  style,
  pointerEvents,
  onLayout,
  collapsed,
  theme,
  textContainerStyle,
  contentContainerStyle,
  ...props
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { colors } = useTheme();
  const route = useRoute();

  const contestIdOrHandle = contest?.handle ?? contest.contestId;

  const onContestEntriesScreen = route.name === Routes.contestEntries;
  const endDate = format(new Date(contest.endOfVoteDate), "dd.MM.yyyy");
  const startDate = format(new Date(contest.goLiveDate), "dd.MM.yyyy");
  const timeLeft = contest.status === ContestStatus.inProgress ? formatDistanceToNowStrict(new Date(contest.endOfVoteDate)) : "";

  const getSubtitle = (): string | undefined => {
    switch (contest.status) {
      case ContestStatus.inProgress:
        return `${timeLeft} ${t("contest.nbDaysLeft")}`;
      case ContestStatus.completed:
        return `${t("contest.contestEnded")} ${endDate}`;
      case ContestStatus.notStarted:
        return `${t("contest.contestStarting")} ${startDate}`;
      default:
        return undefined;
    }
  };

  const handleViewDetails = (): void => navigation.navigate(Routes.contest, { id: contestIdOrHandle });

  const handleViewContestEntries = (): void => navigation.navigate(Routes.contestEntries, { id: contestIdOrHandle });

  const handlePress = (): void => {
    switch (contest.status) {
      case ContestStatus.notStarted:
      case ContestStatus.inProgress:
        handleViewDetails();
        break;
      case ContestStatus.completed:
        handleViewContestEntries();
        break;
      default:
        break;
    }
  };

  const animatedCollapsableStyle = useAnimatedStyle(() => ({
    display: collapsed?.value ? "none" : "flex",
  }));

  const commonButtonProps: Omit<ButtonProps, "children"> = {
    mode: "outlined",
    color: "primary",
    theme,
  };

  return (
    <View onLayout={onLayout}>
      <ListItem
        title={contest.name}
        titleProps={{ variant: "body1", numberOfLines: 0, theme }}
        subtitle={getSubtitle()}
        subtitleProps={{
          variant: "badgeLabel",
          color: contest.status === ContestStatus.completed ? "onError" : "onWarning",
          style: [styles.chip, styles.subtitle, { backgroundColor: contest.status === ContestStatus.completed ? colors.error : colors.warning }],
          theme,
        }}
        description={
          <>
            {contest.status === ContestStatus.completed && (
              <Chip label={t("contest.nbSubmissions", { count: contest.numberOfSubmissions })} color="primary" style={styles.chip} theme={theme} />
            )}

            <Animated.View style={[animatedCollapsableStyle, styles.rewardContainer]}>
              <Text variant="badgeLabel" theme={theme}>{`${t("contest.prize")}:`}</Text>
              <Text variant="badgeLabel" theme={theme}>
                {t(`enum.contestRewardType.${contest.rewardType}`, { value: contest.rewardValue })}
              </Text>
            </Animated.View>

            {contest.isHidden && <Chip color="godMode" label={t("godMode.onlyForAdmin")} textVariant="badgeLabel" style={styles.chip} />}

            {onContestEntriesScreen && (
              <Animated.View style={animatedCollapsableStyle}>
                <Button {...commonButtonProps} contentStyle={styles.participateButton} icon={ArrowRight} onPress={handleViewDetails}>
                  {contest.status === ContestStatus.inProgress ? t("contest.participate") : t("contest.viewContestDetails")}
                </Button>
              </Animated.View>
            )}
          </>
        }
        left={<Image source={contest.imageUrl} width={contestListItemImageSize} style={styles.image} />}
        onPress={pointerEvents === "none" ? undefined : handlePress}
        textContainerStyle={[styles.textContainer, textContainerStyle]}
        contentContainerStyle={[styles.content, contentContainerStyle]}
        style={[styles.root, style]}
        {...props}
      />
    </View>
  );
};
