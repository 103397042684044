import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { collabFacetsFields, collabFields } from "@app/common/graphql/fragments.graphql";
import { SearchCollabsFilteringArgs, SearchCollabsSortingMethod } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

import { CollabsResponse } from "./use-collabs.hook";

export interface BrowseCollabsVariables {
  first?: number;
  offset?: number;
  sortBy?: SearchCollabsSortingMethod;
  where?: SearchCollabsFilteringArgs;
}

const browseCollabsQuery = gql`
  query BrowseCollabs($first: Int, $offset: Int, $sortBy: SearchCollabsSortingMethod, $where: SearchCollabsFilteringArgs) {
    collabs: browseCollabs(first: $first, offset: $offset, sortBy: $sortBy, where: $where) {
      facets {
        ...CollabFacetsFields
      }
      nodes {
        ...CollabFields
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${collabFacetsFields}
  ${collabFields}
`;

export function useBrowseCollabs(options?: QueryHookOptions<CollabsResponse, BrowseCollabsVariables>): QueryResult<CollabsResponse, "collabs"> {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<CollabsResponse, BrowseCollabsVariables>(browseCollabsQuery, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  return { data: data?.collabs, loading, error, networkStatus, fetchMore, refetch };
}
