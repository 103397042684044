import React, { FC, useState } from "react";

import { Minus, Plus } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { TouchableHighlight, View } from "react-native";
import { useTheme } from "react-native-paper";
import Animated, { FadeIn } from "react-native-reanimated";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { ListItem } from "@app/components/common/list-item/list-item.component";
import { Text } from "@app/components/common/text/text.component";
import { CreatorName } from "@app/components/profile/creator-name/creator-name.component";

import { styles } from "./creator-section.style";

interface Props {
  creator: Profile;
}

export const CreatorSection: FC<Props> = ({ creator }) => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const navigation = useNavigation();

  const [expanded, setExpanded] = useState(false);
  const expandable = !!creator.pronouns.length || !!creator.bio;

  const handleExpand = (): void => setExpanded(isExpanded => !isExpanded);

  const handleNavigateToProfile = (): void => navigation.navigate(Routes.profile, { id: creator.username });

  return (
    <View style={styles.root}>
      <ListItem
        title={
          <ListItem
            title={<CreatorName name={creator.displayName} badge={creator.badge} textProps={{ variant: "h8", color: "tertiary" }} />}
            imageProps={{ image: creator.imageUrl, size: 40, fallbackText: creator.displayName.charAt(0).toUpperCase() }}
            onPress={handleNavigateToProfile}
          />
        }
        right={
          expandable && (
            <TouchableHighlight activeOpacity={1} underlayColor={colors.action.selected} onPress={handleExpand}>
              <View style={styles.expandableButton}>
                <Text variant="body2" color="tertiary" textTransform="uppercase">
                  {t("productDetails.aboutMe")}
                </Text>
                {expanded ? (
                  <Minus color={colors.onBackground} weight="thin" size={24} />
                ) : (
                  <Plus color={colors.onBackground} weight="thin" size={24} />
                )}
              </View>
            </TouchableHighlight>
          )
        }
      />

      {expanded && expandable && (
        <Animated.View
          entering={FadeIn}
          style={[styles.bioPronounsContainer, { backgroundColor: colors.tertiaryContainer, borderRadius: roundness }]}>
          <Text style={styles.bioPronouns}>
            {!!creator.pronouns.length && <Text color="tertiary">{`${creator.pronouns.join("/")} `}</Text>}
            <Text>{creator.bio}</Text>
          </Text>
        </Animated.View>
      )}
    </View>
  );
};
