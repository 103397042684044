import { QueryHookOptions, gql, useQuery } from "@apollo/client";

import { MilestoneObjective, SmallestMilestoneFilteringArgs } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";

interface Response {
  smallestMilestoneObjective: MilestoneObjective;
}

interface Variables {
  where: SmallestMilestoneFilteringArgs;
}

export const smallestMilestoneObjectiveQuery = gql`
  query SmallestMilestoneObjective($where: SmallestMilestoneFilteringArgs!) {
    smallestMilestoneObjective(where: $where) {
      milestoneType
      requiredCount
      reward
    }
  }
`;

export function useSmallestMilestoneObjective(options: QueryHookOptions<Response, Variables>): QueryResult<Response, "smallestMilestoneObjective"> {
  const { data, loading, error, refetch } = useQuery<Response, Variables>(smallestMilestoneObjectiveQuery, options);

  return { data: data?.smallestMilestoneObjective, loading, error, refetch };
}
