/* eslint-disable complexity */
import React, { FC, useMemo } from "react";

import { HeartStraight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { View, ViewProps } from "react-native";
import { Theme, useTheme } from "react-native-paper";
import * as Progress from "react-native-progress";

import HandCoins from "@app/assets/icons/hand-coins-fill.svg";
import { CollabCampaign, CollabCampaignStatus, Stage, Progress as CollabProgress } from "@app/common/graphql/generated/schema.graphql";
import { Text } from "@app/components/common/text/text.component";
import { useConfigContext } from "@app/context/config/config.context";
import { useStartCampaignButton } from "@app/hooks/utils/use-start-campaign-button.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { formattedNumber } from "@app/utils/format.util";

import { ReviewStatusChip } from "../review-status-chip/review-status-chip.component";

import { GoalsProgressPopper } from "./goals-progress-popper/goals-progress-popper.component";
import { styles } from "./goals-progress.style";

interface Props extends ViewProps {
  numberOfLikes?: number;
  liked?: boolean;
  campaign?: CollabCampaign;
  collabProgress: CollabProgress;
  creatorId?: string;
  hideProgressStatus?: boolean;
  hideLikeProgress?: boolean;
  hidePreorderProgress?: boolean;
  showPopper?: boolean;
  theme?: Theme;
}

export const GoalsProgress: FC<Props> = ({
  numberOfLikes,
  liked,
  campaign,
  collabProgress,
  creatorId,
  hideProgressStatus,
  hideLikeProgress,
  hidePreorderProgress,
  showPopper,
  theme,
  style,
  ...viewProps
}) => {
  const { colors: currentColors } = useTheme();
  const { t } = useTranslation();

  const colors = theme?.colors ?? currentColors;
  const progressBarBgColor = colors.common.gray.toString().replace(",1)", ",0.6)");

  const { stage: collabStage, inDevelopment } = collabProgress;
  const closedCampaign = campaign?.status === CollabCampaignStatus.cancelled;
  const isUnderReview = collabStage === Stage.underReview;
  const isForSale = collabStage === Stage.forSale;
  const isSellable = isForSale || isUnderReview;
  const forSaleWithoutCampaign = !campaign && isForSale;

  const { config } = useConfigContext();
  const likeCountObjective = config?.likeCountObjective ?? 1;
  const likeCount = numberOfLikes ?? 0;
  const progressLikes = likeCount / likeCountObjective;
  const progressLikesValue = Math.min(progressLikes, 1);
  const likesGoalReached = progressLikesValue === 1;

  const orderCount = campaign?.orderCount ?? 0;
  const orderCountObjective = campaign?.orderCountNeeded ?? 1;
  const maxPercentage = 100;
  const percentage = campaign?.percentage ?? 0;
  const progressOrders = percentage / maxPercentage;
  const progressOrdersValue = forSaleWithoutCampaign ? 1 : Math.min(progressOrders, 1);

  const displayLikeProgress = numberOfLikes !== undefined && !hideLikeProgress;
  const displayPreorderProgress = !hidePreorderProgress;

  const { isStartCampaignButtonVisible } = useStartCampaignButton();
  const startCampaignVisible = collabProgress && creatorId ? isStartCampaignButtonVisible({ progress: collabProgress, creatorId }) : false;

  const preorderLabel = useMemo(() => {
    if (inDevelopment) return "";
    if (isSellable && campaign) return `${formattedNumber(orderCount)}/${orderCountObjective}`;
    if (isForSale) return t(`enum.collabCampaignStatus.${CollabCampaignStatus.successful}`);
    if (likesGoalReached || (isUnderReview && !campaign)) return t("productDetails.campaignStartTBD");

    return t("productDetails.awaitingFirstGoal");
  }, [campaign, inDevelopment, isForSale, isSellable, isUnderReview, likesGoalReached, orderCount, orderCountObjective, t]);

  const progressBarCommonProps = { unfilledColor: progressBarBgColor, borderRadius: 20, borderWidth: 0, width: null, height: 2 };

  if (!displayLikeProgress && !displayPreorderProgress) return null;

  return (
    <GoalsProgressPopper visible={showPopper}>
      <View style={[styles.root, style]} {...viewProps}>
        {displayLikeProgress && (
          <View style={styles.goalContainer}>
            <View style={styles.labelContainer}>
              <HeartStraight color={liked ? colors.secondary : colors.primary} weight="fill" size={16} />
              <Text variant="badgeLabel" theme={theme}>
                {[`${formattedNumber(likeCount)}/${likeCountObjective}`, ...conditionalItem(startCampaignVisible, "🔥")].join(" ")}
              </Text>
            </View>
            <Progress.Bar progress={progressLikesValue} color={colors.secondary} {...progressBarCommonProps} />
          </View>
        )}

        {displayPreorderProgress && (
          <View style={styles.goalContainer}>
            <View style={[styles.labelContainer, styles.chipWithLabelContainer]}>
              <View style={styles.labelContainer}>
                <HandCoins color={colors.primary} height={16} width={16} />
                <Text variant="badgeLabel" theme={theme}>
                  {preorderLabel}
                </Text>
              </View>
              {!hideProgressStatus && (
                <ReviewStatusChip progress={collabProgress} campaignStatus={campaign?.status} short={displayLikeProgress} theme={theme} />
              )}
            </View>
            <Progress.Bar progress={progressOrdersValue} color={closedCampaign ? colors.common.gray : colors.success} {...progressBarCommonProps} />
          </View>
        )}
      </View>
    </GoalsProgressPopper>
  );
};
