import React, { FC } from "react";

import { HourglassSimpleLow } from "phosphor-react-native";
import { View } from "react-native";
import { ActivityIndicator, IconButton, useTheme } from "react-native-paper";

import HandCoins from "@app/assets/icons/hand-coins-duotone.svg";
import ShareIcon from "@app/assets/icons/share-duotone.svg";
import ShoppingCartSimple from "@app/assets/icons/shopping-cart-simple-duotone.svg";
import { Collab, CollabCampaignStatus, CollabImage, Stage } from "@app/common/graphql/generated/schema.graphql";
import { commonStyles } from "@app/common/style/common.style";
import { ShareButton } from "@app/components/common/share-button/share-button.component";
import { Text } from "@app/components/common/text/text.component";
import { ZoomOnImageButton } from "@app/components/common/zoom-on-image-button/zoom-on-image-button.component";

import { CommentsButton } from "../comments/comments-button/comments-button.component";
import { OpenVariantPickerButton } from "../open-variant-picker-button/open-variant-picker-button.component";
import { ProductMoreButton } from "../product-more-button/product-more-button.component";

import { styles } from "./product-side-icon-buttons.style";

interface Props {
  product: Collab;
  currentImage?: CollabImage;
  context: "card" | "fullScreen";
}

export const ProductSideIconButtons: FC<Props> = ({ product, currentImage, context }) => {
  const { colors } = useTheme();

  const purchaseButtonVisible = context === "card";
  const closedCampaign = product.campaign?.status === CollabCampaignStatus.cancelled;
  const commonIconProps = { iconColor: colors.common.white, style: commonStyles.sideIconButton };

  return (
    <View style={styles.root}>
      <ZoomOnImageButton imageUrl={currentImage?.imageUrl} style={commonStyles.sideIconButton} />
      <CommentsButton parentId={product.collabId} numberOfComments={product.formattedNumberOfComments} {...commonIconProps} />
      {purchaseButtonVisible && (
        <OpenVariantPickerButton
          product={product}
          buyAction="addToCart"
          buttonComponent={({ onPress, loading }) => (
            <IconButton
              icon={iconProps =>
                loading ? (
                  <ActivityIndicator {...iconProps} color={colors.common.gray} />
                ) : (
                  <ShoppingCartSimple {...iconProps} height={24} width={24} />
                )
              }
              onPress={onPress}
              {...commonIconProps}
            />
          )}
        />
      )}
      {purchaseButtonVisible && !closedCampaign && (
        <OpenVariantPickerButton
          product={product}
          buyAction="preorder"
          buttonComponent={({ onPress, loading }) => (
            <IconButton
              icon={iconProps =>
                loading ? <ActivityIndicator {...iconProps} color={colors.common.gray} /> : <HandCoins {...iconProps} height={24} width={24} />
              }
              onPress={onPress}
              {...commonIconProps}
            />
          )}
        />
      )}
      <ShareButton
        title={product.name}
        entityId={product.collabId}
        image={currentImage}
        prettyId={product.handle}
        entityType="collab"
        buttonComponent={({ onPress }) => (
          <IconButton
            icon={iconProps => (
              <View style={styles.iconWithCountContainer}>
                <ShareIcon {...iconProps} width={24} height={24} color={colors.common.white} />
                {product.numberOfShares > 0 && (
                  <Text variant="caption" color="common.white" style={styles.textShadow}>
                    {product.formattedNumberOfShares}
                  </Text>
                )}
              </View>
            )}
            onPress={onPress}
            {...commonIconProps}
          />
        )}
      />
      {product.progress.stage === Stage.hidden && <HourglassSimpleLow color={colors.warning} weight="fill" />}
      <ProductMoreButton
        productId={product.collabId}
        productCreatorId={product.creator.userId}
        productStage={product.progress.stage}
        canStartCampaign={product.progress.canStartCampaign}
        orderCountNeeded={product.campaign?.orderCountNeeded}
        {...commonIconProps}
      />
    </View>
  );
};
