import React, { FC } from "react";

import { ArrowLeft } from "phosphor-react-native";
import { useTranslation } from "react-i18next";
import { SafeAreaView, View } from "react-native";
import { Divider } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { analytics } from "@app/common/analytics/analytics";
import { Routes, Tabs, navigationRef } from "@app/common/types/navigation.type";
import { BackButton } from "@app/components/common/navigation/back-button.component";
import { SearchBar } from "@app/components/common/search-bar/search-bar.component";
import { useSearchContext } from "@app/context/search/search.context";
import { isWeb } from "@app/utils/device.util";

import { styles } from "./search-header.style";

export const SearchHeader: FC = () => {
  const { t } = useTranslation();
  const { top } = useSafeAreaInsets();
  const navigation = navigationRef;
  const currentRoute = navigation.getCurrentRoute()?.name;
  const onFirstSearchScreen = currentRoute === Routes.search || currentRoute === Tabs.search || !currentRoute;
  const marginLeftStyle = isWeb ? { marginLeft: onFirstSearchScreen ? 8 : 0 } : { marginLeft: 16 };

  const { query, setQuery } = useSearchContext();

  const handleBack = (): void => {
    setQuery("");

    if (onFirstSearchScreen) return;
    navigation.goBack();
  };

  const handleSearch = (): void => {
    const formattedQuery = query.trim();
    if (!formattedQuery) return;

    navigation.navigate(Routes.home, { screen: Tabs.search, params: { screen: Routes.searchResults, params: { query: formattedQuery } } });
    void analytics.logSearch(formattedQuery);
  };

  return (
    <SafeAreaView style={[styles.header, { marginTop: top }]}>
      <View style={[styles.row, marginLeftStyle]}>
        {!onFirstSearchScreen && <BackButton backImage={({ tintColor }) => <ArrowLeft color={tintColor} weight="thin" />} onPress={handleBack} />}
        <SearchBar
          value={query}
          placeholder={t("search.placeholder")}
          onChangeText={setQuery}
          onKeyPress={e => (e.nativeEvent.key === "Enter" ? handleSearch() : undefined)}
          maxLength={100}
          onEndEditing={handleSearch}
          onClearIconPress={handleBack}
          style={styles.searchBar}
        />
      </View>
      <Divider />
    </SafeAreaView>
  );
};
