import React, { FC, useEffect, useRef, useState } from "react";

import { LinearGradient } from "expo-linear-gradient";
import * as Linking from "expo-linking";
import { useTranslation } from "react-i18next";
import { ScrollView, View, ViewProps } from "react-native";
import { Divider, useTheme } from "react-native-paper";
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";

import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { ActionFromInAppBrowserScreen, TrackEvent } from "@app/common/enums/track-events.enum";
import { supercoinsGradient } from "@app/common/style/common.style";
import { darkTheme } from "@app/common/style/theme";
import { Button } from "@app/components/common/button/button.component";
import { Text } from "@app/components/common/text/text.component";
import { ShareBanner } from "@app/components/initial-states/in-app-browser/share-banner/share-banner.component";
import { useBranchContext } from "@app/context/branch/branch.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { BranchCampaign, BranchLinkPath, getExistingBranchLink, logBranch } from "@app/hooks/utils/deeplink/branch.common";
import { useGetSharedItem } from "@app/hooks/utils/use-get-shared-item.hook";
import { isAndroid, isWeb } from "@app/utils/device.util";
import { openAppStore } from "@app/utils/share/share.common.util";
import { getReferrerShareId } from "@app/utils/share/share.util";
import { isLikelyInEmbeddedWebview } from "@app/utils/user-agent.util";

import { styles } from "./in-app-browser.style";
import { JoinMeCard } from "./join-me-card/join-me-card.component";
import { SharedItemPreviewCard } from "./shared-item-preview-card/shared-item-preview-card.component";

export const InAppBrowser: FC<ViewProps> = viewProps => {
  const { t } = useTranslation();
  const { colors, roundness } = useTheme();
  const { bottom, top } = useSafeAreaInsets();

  const { showErrorSnackbar } = useSnackbarContext();
  const { openDeepLink } = useBranchContext();
  const { profile } = useProfileContext();
  const { trackInMixpanel } = useMixpanelContext();

  const isLikelyEmbedded = useRef(isLikelyInEmbeddedWebview());
  const [showScreen, setShowScreen] = useState(isLikelyEmbedded.current);

  const shareId = getReferrerShareId(!isWeb);

  const { sharedItem, type, referrer } = useGetSharedItem(shareId);

  const ogData = { title: sharedItem?.title, description: sharedItem?.subtitle, imageUrls: sharedItem?.imageUrl ? [sharedItem.imageUrl] : undefined };

  const shouldHideView = !isLikelyEmbedded.current || !sharedItem;

  const trackOpenApp = (): void => trackInMixpanel(TrackEvent.actionFromInAppBrowserScreen, { action: ActionFromInAppBrowserScreen.openApp });

  const handleOpenInAppPress = (): void => {
    openDeepLink(window.location.pathname, ogData, BranchCampaign.sharing, shareId ?? undefined, undefined)
      .then(trackOpenApp)
      .catch(e => {
        logBranch.warn("Link fallback");
        if (isAndroid) {
          const link = getExistingBranchLink(BranchLinkPath.referral, `?referrerId=${shareId}&path=${window.location.pathname}`);
          if (isLikelyEmbedded) {
            trackOpenApp();
            window.open(`intent:${link}#Intent;end`, "_blank");
          } else {
            void Linking.openURL(link).then(() => trackOpenApp());
          }
        } else {
          showErrorSnackbar({
            error: e as Error,
            message: t("error.redirection"),
            cta: { label: t("cta.openStore"), onPress: () => void openAppStore() },
          });
        }
      });
  };

  const handleContinue = (): void => {
    trackInMixpanel(TrackEvent.actionFromInAppBrowserScreen, { action: ActionFromInAppBrowserScreen.continueOnBrowser });
    setShowScreen(false);
  };

  useEffect(() => {
    trackInMixpanel(TrackEvent.viewInAppBrowserScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (shouldHideView) return null;

  return (
    <>
      <ShareBanner
        hidden={showScreen || !!profile}
        ogData={ogData}
        isLikelyEmbedded={isLikelyEmbedded.current}
        onOpenInAppPress={handleOpenInAppPress}
      />

      {showScreen && (
        <SafeAreaView edges={["right", "left"]} {...viewProps}>
          <LinearGradient
            colors={["rgba(109, 33, 97, 1)", "rgba(0, 0, 0, 1)", "rgba(0, 0, 0, 1)", "rgba(16, 127, 199, 1)"]}
            locations={[0.03, 0.35, 0.65, 0.97]}
            start={{ x: 1, y: 0 }}
            end={{ x: 0, y: 1 }}
            style={styles.gradient}
          />

          <View style={[{ bottom }, styles.mainContentContainer]}>
            <View style={[{ top }, styles.mainContent, styles.verticalAlign, styles.rowGap]}>
              <OffscriptLogo height={32} width={89} color={colors.common.white} />
              <Text variant="title" textAlign="center" color="common.white">
                {t("inAppBrowser.launchpad")}
              </Text>

              <ScrollView contentContainerStyle={styles.scrollingContent} showsVerticalScrollIndicator={false}>
                {referrer && <JoinMeCard userImageUrl={referrer.creatorImageUrl} username={referrer.creatorUsername} sharedItemType={type} />}
                {sharedItem && (
                  <View style={styles.verticalAlign}>
                    <SharedItemPreviewCard
                      imageUrl={sharedItem.imageUrl}
                      title={sharedItem.title}
                      subtitle={sharedItem.subtitle}
                      badgeLocation={sharedItem.badgeLocation}
                      profileBadge={sharedItem.profileBadge}
                    />
                  </View>
                )}
              </ScrollView>
            </View>

            <View style={styles.footer}>
              <View style={styles.buttonsContainer}>
                <LinearGradient colors={supercoinsGradient} locations={[0, 1]} style={[styles.continueOnAppButton, { borderRadius: roundness }]}>
                  <Button size="large" onPress={handleOpenInAppPress} fullWidth textColor={colors.common.white}>
                    {t("inAppBrowser.continueOnApp")}
                  </Button>
                </LinearGradient>
                <View style={styles.orContainer}>
                  <Divider style={[styles.orDivider, { backgroundColor: colors.common.white }]} />
                  <Text color="common.white" style={styles.orText}>
                    {t("inAppBrowser.or")}
                  </Text>
                  <Divider style={[styles.orDivider, { backgroundColor: colors.common.white }]} />
                </View>
                <Button theme={darkTheme} mode="outlined" size="large" onPress={handleContinue} fullWidth>
                  {t("inAppBrowser.continueOnBrowser")}
                </Button>
              </View>

              <Text variant="caption" textAlign="center" color="common.white">
                {referrer?.creatorUsername
                  ? t("inAppBrowser.otherDesigner", { username: referrer.creatorUsername })
                  : t("inAppBrowser.joinDesigners")}
              </Text>
            </View>
          </View>
        </SafeAreaView>
      )}
    </>
  );
};
