import React, { useCallback, useMemo, useState } from "react";

import { Collab } from "@app/common/graphql/generated/schema.graphql";
import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { StartCampaignDialog } from "@app/components/campaign/start-campaign-dialog/start-campaign-dialog.component";

import { CampaignContext, CampaignContextInterface } from "./campaign.context";

export const CampaignContextProvider: React.FC<ChildrenProp> = ({ children }) => {
  const [startCampaignDialogVisible, setStartCampaignDialogVisible] = useState(false);
  const [collab, setCollab] = useState<Collab | undefined>(undefined);

  const openStartCampaignDialog = useCallback((c: Collab): void => {
    setCollab(c);
    setStartCampaignDialogVisible(true);
  }, []);

  const context = useMemo<CampaignContextInterface>(
    () => ({
      openStartCampaignDialog,
      closeStartCampaignDialog: () => setStartCampaignDialogVisible(false),
    }),
    [openStartCampaignDialog],
  );

  return (
    <CampaignContext.Provider value={context}>
      {children}

      {collab && <StartCampaignDialog collab={collab} visible={startCampaignDialogVisible} setVisible={setStartCampaignDialogVisible} />}
    </CampaignContext.Provider>
  );
};
