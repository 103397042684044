import React, { FC, useState } from "react";

import { ResponseType } from "expo-auth-session";
import * as Facebook from "expo-auth-session/providers/facebook";
import {
  signInWithPopup,
  browserPopupRedirectResolver,
  OAuthCredential,
  AuthError,
  UserCredential,
  FacebookAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { FacebookLogo } from "phosphor-react-native";
import { ActivityIndicator, IconButton, IconButtonProps, useTheme } from "react-native-paper";

import FacebookIcon from "@app/assets/logos/facebook-logo.svg";
import { useAuthContext } from "@app/context/auth/auth.context";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { facebookConfig } from "@app/context/auth/firebase.common";
import { LinkLoginProvidersProps } from "@app/context/auth/login/login.context";
import { FeatureFlag } from "@app/context/launch-darkly/feature-flag.enum";
import { useLaunchDarklyContext } from "@app/context/launch-darkly/launch-darkly.context";
import { useHandleAuthError, LoginAction } from "@app/hooks/utils/use-handle-auth-error.hook";
import { isWeb } from "@app/utils/device.util";
import { reportError } from "@app/utils/logger/logger.util";

interface Props extends Omit<IconButtonProps, "children" | "icon"> {
  onSignInSuccess?: (credential: UserCredential) => void;
  goToCheckEmail: (email: string) => void;
  openLinkProvidersPrompt?: (props: LinkLoginProvidersProps) => void;
}

export const FacebookButton: FC<Props> = ({ onSignInSuccess, goToCheckEmail, openLinkProvidersPrompt, ...props }) => {
  const { colors } = useTheme();

  const { state, setVerified } = useAuthContext();
  const { isFeatureEnabled } = useLaunchDarklyContext();

  const [loading, setLoading] = useState(false);

  const { handleAuthErrorWithAccountLinkingErrors, handleAuthError } = useHandleAuthError();

  const [_request, _response, promptAsync] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: facebookConfig.clientId,
  });

  const loginMobile = async (): Promise<OAuthCredential | null> => {
    try {
      const response = await promptAsync();

      if (response?.type === "success" && response.authentication) {
        return FacebookAuthProvider.credential(response.authentication.accessToken);
      }
      if (response.type === "error" && response.error) {
        handleAuthError({ ...response.error, customData: { appName: "Offscript" } }, LoginAction.loginWithFacebook);
        return null;
      }

      return null;
    } catch (e) {
      reportError(e, { extra: { loginAction: LoginAction.loginWithFacebook } });
      return null;
    }
  };

  const loginWeb = async (): Promise<OAuthCredential | null> => {
    const provider = new FacebookAuthProvider();
    provider.addScope("public_profile");
    provider.addScope("email");
    provider.setCustomParameters({ display: "popup" });

    const auth = await getAuthWhenReady();

    return signInWithPopup(auth, provider, browserPopupRedirectResolver)
      .then(result => {
        const credential = FacebookAuthProvider.credentialFromResult(result);

        if (!credential) {
          reportError(new Error("No credential when trying to connect on app web with facebook after success on signInWithPopup"), {
            extra: { loginAction: LoginAction.loginWithFacebook },
          });
        }

        return credential;
      })
      .catch((authError: AuthError) => {
        const credential = FacebookAuthProvider.credentialFromError(authError);

        if (!credential) {
          reportError(new Error("No credential when trying to connect on app web with facebook after error on signInWithPopup"), {
            extra: { loginAction: LoginAction.loginWithFacebook },
          });
          return null;
        }

        if (openLinkProvidersPrompt) {
          handleAuthErrorWithAccountLinkingErrors(authError, {
            email: authError.customData.email ?? "",
            pendingCredential: credential,
            loginAction: LoginAction.loginWithFacebook,
            setLoading,
            currentSignInMethod: FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD,
            openLinkProvidersPrompt,
          });
        } else {
          handleAuthError(authError, LoginAction.loginWithFacebook);
        }

        return null;
      });
  };

  const handleOnPress = async (): Promise<void> => {
    setLoading(true);

    const credential = isWeb && isFeatureEnabled(FeatureFlag.newFacebookLogin) ? await loginWeb() : await loginMobile();

    if (!credential) {
      setLoading(false);
      return;
    }

    const auth = await getAuthWhenReady();

    signInWithCredential(auth, credential)
      .then(userCredential => {
        if (onSignInSuccess) {
          onSignInSuccess(userCredential);
        } else if (!userCredential.user.emailVerified) {
          goToCheckEmail(userCredential.user.email ?? "");
        } else if (!state.verified) {
          setVerified();
        }
      })
      .catch((error: AuthError) => {
        if (openLinkProvidersPrompt) {
          handleAuthErrorWithAccountLinkingErrors(error, {
            email: error.customData.email ?? "",
            pendingCredential: credential,
            loginAction: LoginAction.loginWithFacebook,
            setLoading,
            currentSignInMethod: FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD,
            openLinkProvidersPrompt,
          });
        } else {
          handleAuthError(error, LoginAction.loginWithFacebook);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <IconButton
      icon={({ size, color }) =>
        loading ? (
          <ActivityIndicator color={colors.secondary} size={size} />
        ) : props.iconColor ? (
          <FacebookLogo weight="fill" size={size + 6} color={color} />
        ) : (
          <FacebookIcon height={size} width={size} />
        )
      }
      disabled={loading}
      onPress={() => void handleOnPress()}
      {...props}
    />
  );
};
