import React, { FC, useCallback, JSX, useState, useEffect } from "react";

import { useIsFocused } from "@react-navigation/core";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import cartShoppingAnimatedDark from "@app/assets/lotties/shopping-cart-dark.json";
import cartShoppingAnimatedLight from "@app/assets/lotties/shopping-cart-light.json";
import { CartItem } from "@app/common/graphql/generated/schema.graphql";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { useCartContext } from "@app/context/cart/cart.context";

import { CartListItem } from "./cart-list-item/cart-list-item.component";
import { styles } from "./cart-list.style";
import { CheckoutButton } from "./checkout-button/checkout-button.component";

export const CartList: FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { colors } = useTheme();
  const isFocused = useIsFocused();

  const [refreshing, setRefreshing] = useState(false);
  const { cartItems, cartIdentity, loading, refetch } = useCartContext();

  const noItems = !cartIdentity || !cartItems?.length;

  useEffect(() => {
    if (!isFocused) return;

    void refetch?.();
  }, [isFocused, refetch]);

  const handleRefreshing = async (): Promise<void> => {
    if (!refetch) return;

    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadingState = (
    <View style={styles.center}>
      <ActivityIndicator size="large" />
    </View>
  );

  const listEmpty = loading ? (
    loadingState
  ) : noItems ? (
    <EmptyState
      animatedIcon={{
        dark: cartShoppingAnimatedDark,
        light: cartShoppingAnimatedLight,
      }}
      message={t("emptyState.cart.message")}
      cta={[
        {
          label: t("emptyState.cart.cta"),
          onPress: () => navigation.navigate(Routes.home, { screen: Tabs.feed, params: { screen: Routes.feed } }),
        },
      ]}
    />
  ) : undefined;

  const renderCartItem = useCallback(
    ({ item }: { item: CartItem }): JSX.Element => (
      <View style={[styles.cartItemWrapper, { borderColor: colors.onSurfaceDisabled }]}>
        <CartListItem cartItem={item} />
      </View>
    ),
    [colors.onSurfaceDisabled],
  );

  return (
    <View style={styles.root}>
      <FlatList
        data={cartItems}
        renderItem={renderCartItem}
        keyExtractor={item => `cart-item-${item.variant?.variantId}-${item.collab?.collabId}`}
        ListEmptyComponent={listEmpty}
        numColumns={1}
        scrollEnabled
        contentContainerStyle={styles.listContentContainer}
        showsVerticalScrollIndicator={false}
        refreshing={refreshing}
        onRefresh={() => void handleRefreshing()}
      />

      {!noItems && <CheckoutButton />}
    </View>
  );
};
