import React, { FC, useCallback, useEffect, useMemo } from "react";

import { NetworkStatus } from "@apollo/client";
import { useIsFocused } from "@react-navigation/native";
import { Image } from "expo-image";
import { Bell } from "phosphor-react-native";
import { Pressable, View } from "react-native";
import { ActivityIndicator, Badge, IconButton, Portal, useTheme } from "react-native-paper";

import supercoin from "@app/assets/images/gold-dollar-coins.png";
import OffscriptLogo from "@app/assets/logos/offscript-logo.svg";
import { ProfileTab } from "@app/common/enums/profile-tab.enum";
import { customFonts } from "@app/common/style/fonts";
import { FeedStackScreenProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { Text } from "@app/components/common/text/text.component";
import { ProductStack } from "@app/components/products/product-stack/product-stack.component";
import { SwipeOnboardingCard } from "@app/components/products/swipe-onboarding-card/swipe-onboarding-card.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useFeedContext } from "@app/context/feed/feed.context";
import { usePopperContext } from "@app/context/popper/popper.context";
import { useProfileContext } from "@app/context/profile/profile.context";
import { UserStateEnum } from "@app/context/profile/user-state.enum";
import { useStreamContext } from "@app/context/stream/stream.context";
import { useMilestonesProgress } from "@app/hooks/api/supercoins/use-milestones-progress.hook";
import { useSupercoinBalance } from "@app/hooks/api/supercoins/use-supercoin-balance.hook";

import { styles } from "./feed.style";

export const FeedScreen: FC<FeedStackScreenProps<Routes.feed>> = ({ navigation }) => {
  const { colors } = useTheme();
  const isFocused = useIsFocused();

  const { state } = useAuthContext();
  const { profile, state: userState, loading: loadingUserState } = useProfileContext();
  const { userHasNewNotifs } = useStreamContext();
  const {
    data: supercoinBalance,
    loading: loadingSupercoinBalance,
    refetch: refetchSupercoinBalance,
  } = useSupercoinBalance({ variables: { userId: profile?.userId }, skip: !profile?.userId });
  const { refetch: refetchMilestonesProgress } = useMilestonesProgress({
    variables: { userId: profile?.userId },
    skip: !profile?.userId,
  });
  const { feed } = useFeedContext();
  const {
    feedResult: { data, loading, networkStatus },
  } = feed;

  const {
    goalsPopperState: [_goalsPopperOpen, setGoalsPopperOpen],
  } = usePopperContext();

  const swipeOnboardingVisible = !!profile && !loadingUserState && !userState?.[UserStateEnum.swipeOnboardingCompleted] && state.connected;

  useEffect(() => {
    if (!isFocused || loadingUserState) return undefined;

    let timeout: NodeJS.Timeout;
    if (userState?.[UserStateEnum.swipeOnboardingCompleted] === true && !userState?.[UserStateEnum.goalPoppersDismissed]) {
      timeout = setTimeout(() => {
        setGoalsPopperOpen(true);
      }, 1000);
    }

    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [isFocused, loadingUserState, setGoalsPopperOpen, userState]);

  const notificationsButton = useMemo(
    () => (
      <View>
        <IconButton
          icon={({ color, size }) => <Bell color={color} size={size} />}
          onPress={() => navigation.navigate(Routes.notifications)}
          testID="notifications-button"
        />
        <Badge visible={!!userHasNewNotifs} size={8} style={styles.notifButtonBadge} />
      </View>
    ),
    [navigation, userHasNewNotifs],
  );

  const handleSupercoinsPress = useCallback(() => {
    void refetchSupercoinBalance?.();
    void refetchMilestonesProgress?.();
    navigation.push(Routes.home, { screen: Tabs.profile, params: { screen: Routes.profile, params: { tab: ProfileTab.supercoins } } });
  }, [navigation, refetchMilestonesProgress, refetchSupercoinBalance]);

  const supercoinsButton = useMemo(
    () => (
      <Pressable onPress={handleSupercoinsPress} style={[styles.row, styles.supercoinsButton, { backgroundColor: colors.tertiaryContainer }]}>
        <Image source={supercoin} style={styles.supercoinImage} />
        {loadingSupercoinBalance ? (
          <ActivityIndicator size={customFonts.caption.lineHeight} color={colors.onBackground} />
        ) : (
          <Text variant="caption">{supercoinBalance?.formattedAmount}</Text>
        )}
      </Pressable>
    ),
    [colors.onBackground, colors.tertiaryContainer, handleSupercoinsPress, loadingSupercoinBalance, supercoinBalance?.formattedAmount],
  );

  const headerRight = useCallback(
    () => (
      <View style={styles.row}>
        {profile?.userId && supercoinsButton}
        {notificationsButton}
      </View>
    ),
    [notificationsButton, profile?.userId, supercoinsButton],
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <OffscriptLogo height={40} width={120} color={colors.onBackground} />,
      headerRight,
    });
  }, [colors.onBackground, headerRight, navigation]);

  return (
    <>
      <ScreenWrapper>
        {swipeOnboardingVisible && (
          <Portal>
            <SwipeOnboardingCard />
          </Portal>
        )}

        <View style={styles.feedRoot}>
          {!data?.nodes || (loading && networkStatus !== NetworkStatus.fetchMore) || loadingUserState ? (
            <ActivityIndicator animating size="large" />
          ) : (
            <ProductStack products={data.nodes} connected={!!profile} feedProps={feed} />
          )}
        </View>
      </ScreenWrapper>
    </>
  );
};
