import { StyleSheet } from "react-native";

export const imageGap = 1;
export const screenPadding = 1;

export const styles = StyleSheet.create({
  columnWrapper: {
    rowGap: imageGap,
  },
  contentContainer: {
    columnGap: imageGap,
  },
  divider: {
    marginLeft: -screenPadding,
    marginVertical: 16,
  },
  headerContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 4,
  },
  imagesContainer: {
    paddingHorizontal: screenPadding,
  },
});
