import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { coreProfileFields } from "@app/common/graphql/fragments.graphql";
import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  deleteInstagramAccountData: Profile;
}

interface Variables {
  userId: string;
}

interface DeleteInstagramAccountDataResult extends MutationResult<Response, "deleteInstagramAccountData"> {
  deleteInstagramAccountData: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const deleteInstagramAccountDataMutation = gql`
  mutation DeleteInstagramAccountData($userId: UserId!) {
    deleteInstagramAccountData(userId: $userId) {
      ...CoreProfileFields
    }
  }
  ${coreProfileFields}
`;

export function useDeleteInstagramAccountData(): DeleteInstagramAccountDataResult {
  const [deleteInstagramAccountData, { loading, error, data: response }] = useMutation<Response, Variables>(deleteInstagramAccountDataMutation);

  return { deleteInstagramAccountData, loading, error, data: response?.deleteInstagramAccountData };
}
