import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  emptyPicture: {
    borderRadius: 45,
    borderWidth: 0.5,
  },
  pictureButtonContainer: {
    columnGap: 8,
    flexDirection: "row",
  },
  pictureContainer: {
    alignItems: "center",
  },
});
