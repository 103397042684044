import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";
import { Route, TabBar, TabView } from "react-native-tab-view";

import { NavigateContext, TrackEvent } from "@app/common/enums/track-events.enum";
import { ContestStatus } from "@app/common/graphql/generated/schema.graphql";
import { useNavigation } from "@app/common/types/navigation.type";
import { Text } from "@app/components/common/text/text.component";
import { ContestList } from "@app/components/contests/contest-list/contest-list.component";
import { useAuthContext } from "@app/context/auth/auth.context";
import { useMixpanelContext } from "@app/context/mixpanel/mixpanel.context";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { conditionalItem } from "@app/utils/conditional-item-in-array.util";
import { isAdmin } from "@app/utils/user.util";

import { styles } from "./contests.style";

const indexMap: Record<ContestStatus, number> = {
  [ContestStatus.inProgress]: 0,
  [ContestStatus.completed]: 1,
  [ContestStatus.notStarted]: 2,
};

export const Contests: FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { state } = useAuthContext();
  const { width: windowWidth } = useWindowDimensions();
  const { trackInMixpanel } = useMixpanelContext();

  const admin = useMemo(() => isAdmin(state), [state]);

  const [index, setIndex] = useState(0);
  const [routes] = useState<Route[]>([
    { key: ContestStatus.inProgress, title: t("enum.contestStatus.inProgress") },
    { key: ContestStatus.completed, title: t("enum.contestStatus.completed") },
    ...conditionalItem(admin, { key: ContestStatus.notStarted, title: t("enum.contestStatus.notStarted") }),
  ]);

  const renderScene = ({ route: contestRoute }: { route: Route }): ReactNode => {
    switch (contestRoute.key) {
      case ContestStatus.inProgress:
        return <ContestList status={ContestStatus.inProgress} includeHidden={admin} />;
      case ContestStatus.completed:
        return <ContestList status={ContestStatus.completed} includeHidden={admin} />;
      case ContestStatus.notStarted:
        return <ContestList status={ContestStatus.notStarted} includeHidden={admin} />;
      default:
        return null;
    }
  };

  const handleIndexChange = useCallback(
    (tabIndex: number) => {
      setIndex(tabIndex);
      const tab = Object.keys(indexMap)[tabIndex] as ContestStatus;
      navigation.setParams({ tab });
      trackInMixpanel(TrackEvent.navigate, { tab, context: NavigateContext.contestsScreen });
    },
    [navigation, trackInMixpanel],
  );

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={handleIndexChange}
      lazy
      initialLayout={{ width: windowWidth }}
      renderTabBar={props => (
        <TabBar
          {...props}
          indicatorStyle={{ backgroundColor: colors.onBackground }}
          style={[{ backgroundColor: colors.background }, styles.tab]}
          labelStyle={styles.tabLabel}
          renderLabel={({ route: routeFromLabel }) => (
            <Text variant="buttonSmall" color={routeFromLabel.title === t("contest.noStarted") ? "godMode" : "onBackground"}>
              {routeFromLabel.title}
            </Text>
          )}
        />
      )}
    />
  );
};
