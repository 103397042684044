import React, { FC, useCallback, useEffect } from "react";

import { X } from "phosphor-react-native";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

import { isNotFound } from "@app/common/apollo/apollo.utils";
import { CollabSuccessType } from "@app/common/enums/collab-success-type.enum";
import { NavigationProps, Routes, Tabs } from "@app/common/types/navigation.type";
import { CollabSuccess } from "@app/components/campaign/collab-success/collab-success.component";
import { Image } from "@app/components/common/image/image.component";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useCollab } from "@app/hooks/api/use-collab.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";

import { styles } from "./collab-success.style";

export const CollabSuccessScreen: FC<NavigationProps<Routes.collabSuccess>> = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const { width: windowWidth } = useWindowDimensions();

  const { collabId, type: successType } = route.params;

  const { data: collab, refetch: refetchCollab } = useCollab({
    variables: { collabId },
    onError: err => {
      if (isNotFound(err)) {
        navigation.replace(Routes.notFound);
        return;
      }
      showErrorSnackbar({ refetch: refetchCollab, error: err });
    },
  });

  const contest = collab?.contest;

  const handleClose = useCallback(() => {
    if ([CollabSuccessType.conceptSubmitted, CollabSuccessType.contestEntrySubmitted].includes(successType)) {
      navigation.navigate(Routes.home, { screen: Tabs.profile, params: { screen: Routes.profile, initial: false } });
    } else {
      navigation.goBack();
    }
  }, [navigation, successType]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <IconButton icon={X} onPress={handleClose} />,
    });
  }, [handleClose, navigation]);

  const collabSuccessProps =
    successType === CollabSuccessType.contestEntrySubmitted && contest
      ? { successType, reward: { type: contest.rewardType, value: contest.rewardValue } }
      : { successType };

  return (
    <ScreenWrapper>
      {collab && <Image source={collab?.images[0]?.imageUrl} width={windowWidth} style={styles.fullScreen} />}
      <View style={[styles.fullScreen, { backgroundColor: colors.backdrop }]} />

      <CollabSuccess collabId={collabId} handle={collab?.handle} image={collab?.images?.[0]} {...collabSuccessProps} />
    </ScreenWrapper>
  );
};
