import { useMemo } from "react";

import { gql, useQuery } from "@apollo/client";
import crawlers from "crawler-user-agents";

import { StorefrontLocation } from "@app/common/graphql/generated/schema.graphql";
import { QueryResult } from "@app/common/types/apollo-result.type";
import { isDesktop, isWeb } from "@app/utils/device.util";
import { isCollabId } from "@app/utils/id.util";

interface Response {
  storefrontLocation: StorefrontLocation;
}

interface Variables {
  collabId?: string;
  handle?: string;
}

const storefrontLocationQuery = gql`
  query StorefrontLocation($collabId: CollabId, $handle: String) {
    storefrontLocation(collabId: $collabId, handle: $handle) {
      url
    }
  }
`;

/**
 * @param isReady this is needed in order to be sure that apollo finished loading and won't be dropped during the call
 * @param shouldBypassRedirection boolean to decide to bypass the redirection
 */
export function useStorefrontLocation(isReady: boolean, shouldBypassRedirection: boolean): QueryResult<Response, "storefrontLocation"> {
  const url = new URL(window.location.href);
  const collabIdOrHandle = url.pathname.includes("/p/") ? url.pathname.replace("/p/", "").replace("/", "") : undefined;
  const shouldSkipRedirect = url.searchParams.get("redirect") === "false";

  const variables: Variables | undefined = collabIdOrHandle
    ? isCollabId(collabIdOrHandle)
      ? { collabId: collabIdOrHandle }
      : { handle: collabIdOrHandle }
    : undefined;
  const isBot = useMemo(() => isWeb && crawlers.some(bot => RegExp(bot.pattern).test(navigator.userAgent)), []);
  const skipRedirection = shouldBypassRedirection || shouldSkipRedirect || !isDesktop || !isReady || isBot;

  const { data, loading, error } = useQuery<Response, Variables>(storefrontLocationQuery, {
    variables,
    // disabled the storefront for:
    // - bots
    // - apollo not loaded fully
    // - not a desktop
    skip: skipRedirection,
    notifyOnNetworkStatusChange: true,
    onCompleted: dataLocation => {
      window.open(dataLocation.storefrontLocation.url, "_self");
    },
    onError: () => undefined,
  });

  return { data: data?.storefrontLocation, loading, error };
}
