import React, { FC, useCallback, useEffect, useState } from "react";

import { verifyPasswordResetCode, confirmPasswordReset, AuthError } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native-paper";

import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { getAuthWhenReady } from "@app/context/auth/firebase";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { LoginAction, useHandleAuthError } from "@app/hooks/utils/use-handle-auth-error.hook";

import { ChoosePassword } from "../choose-password/choose-password.component";

interface Props {
  actionCode: string;
}

export const HandleResetPassword: FC<Props> = ({ actionCode }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { showErrorSnackbar } = useSnackbarContext();
  const { handleAuthError } = useHandleAuthError();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");

  useEffect(() => {
    navigation.setOptions({ headerTitle: t("login.email.passwordReset") });
  }, [navigation, t]);

  const navigateBack = useCallback((): void => {
    navigation.replace(Routes.home, { screen: Tabs.feed, params: { screen: Routes.feed } });
  }, [navigation]);

  useEffect(() => {
    getAuthWhenReady()
      .then(auth =>
        verifyPasswordResetCode(auth, actionCode)
          .then(userEmail => setEmail(userEmail))
          .catch((error: Error) => {
            showErrorSnackbar({ error, message: t("login.email.invalidActionCode") });
            navigateBack();
          }),
      )
      .catch((error: AuthError) => handleAuthError(error, LoginAction.getAuth));
  }, [actionCode, handleAuthError, navigateBack, navigation, showErrorSnackbar, t]);

  const updatePassword = (): void => {
    setLoading(true);

    getAuthWhenReady()
      .then(auth =>
        confirmPasswordReset(auth, actionCode, password)
          .then(() => {
            showErrorSnackbar({ message: t("login.email.passwordUpdatedRelogin") });
            navigateBack();
          })
          .catch(() => {
            showErrorSnackbar({ message: t("login.email.invalidActionCode") });
            navigateBack();
          }),
      )
      .catch((error: AuthError) => {
        setLoading(false);
        handleAuthError(error, LoginAction.getAuth);
      });
  };

  return email ? (
    <ChoosePassword
      email={email}
      password={password}
      setPassword={setPassword}
      loading={loading}
      handleSubmit={updatePassword}
      buttonLabel={t("cta.confirm")}
    />
  ) : (
    <ActivityIndicator size="large" />
  );
};
