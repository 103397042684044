import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { TouchableHighlight, View } from "react-native";
import { useTheme } from "react-native-paper";

import { AvatarImage } from "@app/components/common/avatar-image/avatar-image.component";
import { Button } from "@app/components/common/button/button.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useDeleteProfileImage } from "@app/hooks/api/use-delete-profile-image.hook";
import { useUploadProfileImage } from "@app/hooks/api/use-upload-profile-image.hook";
import { pickImage } from "@app/utils/image-picker.util";
import { generateImageToFormData } from "@app/utils/image-upload.util";

import { styles } from "./edit-profile-picture.style";

export const EditProfilePicture: FC = () => {
  const avatarImageSize = 90;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const { profile } = useProfileContext();
  const { showErrorSnackbar } = useSnackbarContext();

  const { uploadImage, loading: uploadImageLoading } = useUploadProfileImage();
  const { deleteProfileImage, loading: deleteImageLoading } = useDeleteProfileImage();

  const imageLoading = uploadImageLoading || deleteImageLoading;

  const chooseAndUploadImage = async (): Promise<void> => {
    const image = await pickImage();
    if (image.error || !profile || !image.asset) return;

    const form = await generateImageToFormData(image.asset.uri, image.asset.fileName);
    void uploadImage({
      variables: { input: form, userId: profile.userId },
      onError: error => showErrorSnackbar({ error }),
    });
  };

  return (
    <View style={styles.pictureContainer}>
      {!!profile && (profile.imageUrl || imageLoading) ? (
        <>
          <AvatarImage image={profile.imageUrl} loading={imageLoading} fallbackText="" size={avatarImageSize} />
          <View style={styles.pictureButtonContainer}>
            <Button
              size="large"
              color="error"
              disabled={imageLoading}
              onPress={() => void deleteProfileImage({ variables: { userId: profile.userId }, onError: error => showErrorSnackbar({ error }) })}>
              {t("userInfo.fields.picture.removePicture")}
            </Button>
            <Button size="large" disabled={imageLoading} onPress={() => void chooseAndUploadImage()}>
              {t("userInfo.fields.picture.changePicture")}
            </Button>
          </View>
        </>
      ) : (
        <>
          <TouchableHighlight
            activeOpacity={1}
            underlayColor={colors.action.selected}
            onPress={() => void chooseAndUploadImage()}
            style={[
              styles.emptyPicture,
              { height: avatarImageSize, width: avatarImageSize, borderColor: colors.outline, backgroundColor: colors.surfaceVariant },
            ]}>
            <></>
          </TouchableHighlight>
          <Button size="large" disabled={imageLoading} onPress={() => void chooseAndUploadImage()}>
            {t("userInfo.fields.picture.editPicture")}
          </Button>
        </>
      )}
    </View>
  );
};
