import React, { FC, useCallback, JSX, useState, useMemo } from "react";

import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";

import cartShoppingAnimatedDark from "@app/assets/lotties/shopping-cart-dark.json";
import cartShoppingAnimatedLight from "@app/assets/lotties/shopping-cart-light.json";
import { Order } from "@app/common/graphql/generated/schema.graphql";
import { Routes, Tabs, useNavigation } from "@app/common/types/navigation.type";
import { EmptyState } from "@app/components/common/empty-state/empty-state.component";
import { LoginPrompt } from "@app/components/common/login-prompt/login-prompt.component";
import { useProfileContext } from "@app/context/profile/profile.context";
import { useSnackbarContext } from "@app/context/snackbar/snackbar.context";
import { useOrders } from "@app/hooks/api/use-orders.hook";
import { useComponentSize } from "@app/hooks/utils/use-component-size.hook";
import { isWeb } from "@app/utils/device.util";

import { OrderListItem } from "./order-list-item/order-list-item.component";
import { styles } from "./order-list.style";

export const OrderList: FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { showErrorSnackbar } = useSnackbarContext();
  const { profile, loading: loadingProfile } = useProfileContext();

  const [listSize, handleListLayout] = useComponentSize({});
  const [refreshing, setRefreshing] = useState(false);

  const {
    data: orders,
    loading,
    refetch,
  } = useOrders({
    skip: !profile,
    onError: error => showErrorSnackbar({ refetch, error }),
  });
  const orderItems = orders?.nodes;
  const noOrders = orders?.totalCount === 0;
  const estimatedItemHeight = 350;

  const handleRefreshing = async (): Promise<void> => {
    if (!refetch) return;

    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadingState = useMemo(
    () => (
      <View style={[styles.center, { height: listSize.height }]}>
        <ActivityIndicator size="large" />
      </View>
    ),
    [listSize.height],
  );

  const emptyState = useMemo(
    () => (
      <EmptyState
        animatedIcon={{
          dark: cartShoppingAnimatedDark,
          light: cartShoppingAnimatedLight,
        }}
        message={t("emptyState.orders.message")}
        cta={[
          {
            label: t("emptyState.orders.cta"),
            onPress: () => navigation.navigate(Routes.home, { screen: Tabs.feed, params: { screen: Routes.feed } }),
          },
        ]}
        style={{ height: listSize.height }}
      />
    ),
    [listSize.height, navigation, t],
  );

  const listEmpty = useMemo(() => (loading ? loadingState : noOrders ? emptyState : undefined), [emptyState, loading, loadingState, noOrders]);

  const renderOrderItem = useCallback<ListRenderItem<Order>>(
    ({ item }): JSX.Element => (
      <View style={[styles.orderItemWrapper, { borderColor: colors.onSurfaceDisabled }]}>
        <OrderListItem order={item} />
      </View>
    ),
    [colors.onSurfaceDisabled],
  );

  if (!profile && !loadingProfile) return <LoginPrompt message={{ text: t("loginPrompt.orders.message") }} onTopOfEverything={false} />;

  if (!orderItems?.length && isWeb) return listEmpty;

  return (
    <View style={styles.rootWrapper} onLayout={handleListLayout}>
      <FlashList
        data={orderItems}
        renderItem={renderOrderItem}
        keyExtractor={item => `order-item-${item.orderId}`}
        estimatedItemSize={estimatedItemHeight}
        ListEmptyComponent={listEmpty}
        ItemSeparatorComponent={() => <View style={styles.rowGap} />}
        numColumns={1}
        scrollEnabled
        contentContainerStyle={styles.root}
        showsVerticalScrollIndicator={false}
        refreshing={refreshing}
        onRefresh={() => void handleRefreshing()}
      />
    </View>
  );
};
