import React, { FC } from "react";

import { ActivityIndicator } from "react-native-paper";

import { Routes, Tabs, ProductUpdateStackScreenProps } from "@app/common/types/navigation.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { ProductOverlay } from "@app/components/products/product-overlay/product-overlay.component";
import { UpdateDetails } from "@app/components/updates/update-details/update-details.component";
import { useCollabUpdate } from "@app/hooks/api/updates/use-collab-update.hook";
import { useCollab } from "@app/hooks/api/use-collab.hook";

import { styles } from "./product-update.style";

export const ProductUpdateScreen: FC<ProductUpdateStackScreenProps<Routes.productUpdate>> = ({ navigation, route }) => {
  const { index, updateId } = route.params;

  const { data: update } = useCollabUpdate({
    variables: { collabUpdateId: updateId },
    onError: () =>
      navigation.canGoBack()
        ? navigation.goBack()
        : navigation.navigate(Routes.home, { screen: Tabs.feed, params: { screen: Routes.feed, initial: false } }),
  });

  const collabId = update?.collabId;
  const { data: collab } = useCollab({ variables: { collabId: collabId ?? "" }, skip: !collabId });

  if (!update)
    return (
      <ScreenWrapper>
        <ActivityIndicator size="large" />
      </ScreenWrapper>
    );

  return (
    <ScreenWrapper withScrollView>
      {collab && <ProductOverlay product={collab} style={styles.productOverlay} />}
      <UpdateDetails update={update} index={index != null ? index + 1 : undefined} />
    </ScreenWrapper>
  );
};
