import React, { FC } from "react";

import { formatDate } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";

import { ChildrenProp } from "@app/common/types/children-prop.interface";
import { Text } from "@app/components/common/text/text.component";
import { formatPriceUSD } from "@app/utils/price.util";

import { styles } from "./preorder-total.style";

interface Props {
  salesTax?: number;
  shippingPrice?: number;
  subtotal: number;
  rewards: number;
  reviewEndAt: string;
  isAnonymous: boolean;
}

export const PreorderTotal: FC<Props> = ({ salesTax, shippingPrice, subtotal, rewards, reviewEndAt, isAnonymous }) => {
  const { t } = useTranslation();

  const total = subtotal + (shippingPrice ?? 0) + (salesTax ?? 0) - rewards;

  return (
    <View style={styles.priceContainer}>
      <View style={styles.spaceBetweenText}>
        <Text variant="body1">{t("preorder.subtotal")}</Text>
        <Text variant="body1">{formatPriceUSD(subtotal)}</Text>
      </View>

      <View style={styles.spaceBetweenText}>
        <Text variant="body1">{t("preorder.shipping")}</Text>
        <Text variant="body1">{shippingPrice === undefined ? "$?" : shippingPrice === 0 ? t("preorder.free") : formatPriceUSD(shippingPrice)}</Text>
      </View>

      {salesTax !== 0 && (
        <View style={styles.spaceBetweenText}>
          <Text variant="body1">{t("preorder.tax")}</Text>
          <Text variant="body1">{salesTax === undefined ? "$?" : formatPriceUSD(salesTax)}</Text>
        </View>
      )}

      {!isAnonymous && (
        <View style={styles.spaceBetweenText}>
          <Text variant="body1">{t("supercoins.rewards")}</Text>
          <Text variant="body1">{`-${formatPriceUSD(rewards)}`}</Text>
        </View>
      )}

      <View style={styles.spaceBetweenText}>
        <Text variant="h8">{t("preorder.total")}</Text>
        <Text variant="h8">{formatPriceUSD(total)}</Text>
      </View>

      <Trans
        i18nKey="preorder.reserveCaptionWithInfo"
        values={{ price: formatPriceUSD(total), date: formatDate(reviewEndAt, "MMMM d, yyyy") }}
        parent={({ children }: ChildrenProp) => (
          <Text variant="caption" textAlign="center">
            {children}
          </Text>
        )}
        components={{
          b: (
            <Text variant="subtitle2" textAlign="center">
              <></>
            </Text>
          ),
        }}
      />
    </View>
  );
};
