import { AnyPromo, Collab, FeedCollabOrPromo, StandardPromo } from "@app/common/graphql/generated/schema.graphql";

export function isCollab(entity?: FeedCollabOrPromo): entity is Collab {
  return entity?.__typename === "Collab";
}

export function isStandardPromo(entity?: FeedCollabOrPromo): entity is StandardPromo {
  return entity?.__typename === "StandardPromo";
}

export function isPromo(entity?: FeedCollabOrPromo): entity is AnyPromo {
  return isStandardPromo(entity) || entity?.__typename === "GenericPromo";
}

export function getFeedEntityId(entity?: FeedCollabOrPromo): string | undefined {
  if (isPromo(entity)) return entity.promoId;
  if (isCollab(entity)) return entity.collabId;

  return undefined;
}
