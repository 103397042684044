import { useCallback, useRef, useState } from "react";

import { NetworkStatus } from "@apollo/client";
import { X, HeartStraight } from "phosphor-react-native";
import { useTranslation } from "react-i18next";

import { PromoType } from "@app/common/graphql/generated/schema.graphql";
import { SwiperCardRefProps } from "@app/components/products/product-stack/swiper/swiper.types";
import { useDislikeCollabInFeed } from "@app/hooks/api/likes/use-dislike-collab-in-feed.hook";
import { useLikeCollabInFeed } from "@app/hooks/api/likes/use-like-collab-in-feed.hook";
import { useMarkPromoAsSeen } from "@app/hooks/api/promos/use-mark-promo-as-seen.hook";
import { useFeed } from "@app/hooks/api/use-feed.hook";
import { useFetchMoreEntityFeed } from "@app/hooks/utils/use-fetch-more-entity-feed.hook";
import { getFeedEntityId } from "@app/utils/feed.util";

import { usePopperContext } from "../popper/popper.context";
import { useProfileContext } from "../profile/profile.context";
import { UserStateEnum } from "../profile/user-state.enum";
import { useSnackbarContext } from "../snackbar/snackbar.context";

import { FeedProps, ResetFeedOptions } from "./feed.context";

export const feedLimit = 10;

export function useFeedProps(): FeedProps {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbarContext();

  const {
    studioPopperState: [_studioPopperOpen, setStudioPopperOpen],
  } = usePopperContext();

  const swiperRef = useRef<SwiperCardRefProps>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexImageMap, setCurrentIndexImageMap] = useState<Map<string, number>>(new Map());
  const [currentContestCollabIndex, setCurrentContestCollabIndex] = useState(0);
  const [noCardsLeft, setNoCardsLeft] = useState(false);

  const { profile, state } = useProfileContext();
  const userId = profile?.userId;

  const { likeCollab } = useLikeCollabInFeed();
  const { dislikeCollab } = useDislikeCollabInFeed();
  const { markPromoAsSeen } = useMarkPromoAsSeen();

  const feedResult = useFeed({
    variables: { first: feedLimit, userId },
    onError: error => showErrorSnackbar({ refetch: feedResult.refetch, error }),
  });

  const { data, networkStatus, refetch, fetchMore } = feedResult;
  const { fetchMoreFeedEntity } = useFetchMoreEntityFeed(fetchMore);

  const resetFeed = useCallback(
    (options?: ResetFeedOptions): void => {
      setNoCardsLeft(false);
      setCurrentIndex(0);
      setCurrentIndexImageMap(new Map());
      setCurrentContestCollabIndex(0);

      if (options?.skipRefetch) return;
      void refetch?.();
    },
    [refetch],
  );

  const fetchMoreFromFeed = useCallback(
    (feedEntityId: string): void => {
      const feedEntities = data?.nodes;
      const lastIndex = (feedEntities?.length ?? 0) - 1;

      const lastFewIds = feedEntities?.slice(-15).map(entity => getFeedEntityId(entity));
      const hasFewUnseenEntityLeft = feedEntities?.length && lastFewIds?.includes(feedEntityId);

      if (hasFewUnseenEntityLeft && networkStatus !== NetworkStatus.fetchMore) {
        fetchMoreFeedEntity({ after: getFeedEntityId(feedEntities[lastIndex]), first: feedLimit });
      }
    },
    [data?.nodes, networkStatus, fetchMoreFeedEntity],
  );

  const handleLike = useCallback(
    async (collabId: string): Promise<void> => {
      if (!userId) return;

      await likeCollab({
        variables: { userId, input: { collabId } },
        onError: error => showErrorSnackbar({ error }),
      });
      fetchMoreFromFeed(collabId);
    },
    [userId, likeCollab, fetchMoreFromFeed, showErrorSnackbar],
  );

  const handleDislike = useCallback(
    async (collabId: string): Promise<void> => {
      if (!userId) return;

      await dislikeCollab({
        variables: { userId, input: { collabId } },
        onError: error => showErrorSnackbar({ error }),
      });
      fetchMoreFromFeed(collabId);
    },
    [userId, dislikeCollab, fetchMoreFromFeed, showErrorSnackbar],
  );

  const handleViewedPromo = useCallback(
    async (promoId: string, promoType: PromoType): Promise<void> => {
      if (!userId) return;

      if (!state?.[UserStateEnum.startCreatingPopperDismissed] && promoType === PromoType.tokenGift) {
        setStudioPopperOpen(true);
      }

      await markPromoAsSeen({
        variables: { promoId, userId },
        onError: error => showErrorSnackbar({ error }),
      });
      fetchMoreFromFeed(promoId);
    },
    [fetchMoreFromFeed, markPromoAsSeen, userId, setStudioPopperOpen, showErrorSnackbar, state],
  );

  const onLike = useCallback((collabId: string) => void handleLike(collabId), [handleLike]);
  const onDislike = useCallback((collabId: string) => void handleDislike(collabId), [handleDislike]);

  const onViewedPromo = useCallback((promoId: string, promoType: PromoType) => void handleViewedPromo(promoId, promoType), [handleViewedPromo]);

  return {
    swiperRef,
    feedResult,
    swipeRightProps: { onSwipeCollab: onLike, overlayLabel: t("feed.like"), content: HeartStraight },
    swipeLeftProps: { onSwipeCollab: onDislike, overlayLabel: t("feed.dislike"), content: X },
    currentIndex,
    setCurrentIndex,
    currentIndexImageMap,
    setCurrentIndexImageMap,
    currentContestCollabIndex,
    setCurrentContestCollabIndex,
    noCardsLeft,
    setNoCardsLeft,
    onViewedPromo,
    resetFeed,
  };
}
