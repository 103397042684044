export enum TrackEvent {
  actionFromInAppBrowserScreen = "Action from in-app browser screen",
  dismissFeatureAnnouncement = "Dismiss feature announcement",
  featureAnnouncementInteraction = "Feature announcement interaction",
  inAppNotifInteraction = "In-app notif interaction",
  infoClickOnFeed = "Click on info on feed",
  initialPageLoad = "Initial page load in web",
  navigate = "Navigate",
  openAppFromWeb = "Open app from web",
  pushNotifInteraction = "Push notification interaction",
  pushNotifPermissionStatusChanged = "Push notification permission status changed",
  supercreatorSubscribe = "Tap subscribe to supercreator",
  supersellerSubscribe = "Tap subscribe to superseller",
  switchImages = "Switch images",
  viewContests = "View contests",
  viewInAppBrowserScreen = "View in-app browser screen",
  viewSuperplanScreen = "View superplan screen",
}

export enum ActionFromInAppBrowserScreen {
  continueOnBrowser = "continue on browser",
  openApp = "open App",
}

export enum NavigateContext {
  cartOrdersScreen = "cart-orders screen",
  contestsScreen = "contests screen",
  currentProfileScreen = "current profile screen",
  mainBottomNavigation = "main bottom navigation",
  studioBottomNavigation = "studio bottom navigation",
  superplansScreen = "superplans screen",
}

export enum ViewContestsOrigin {
  search = "search",
  studio = "studio",
}

export enum ViewSuperplanScreenOrigin {
  browseCampaigns = "browse campaigns",
  featureAnnouncement = "featureAnnouncement",
  profileMoreMenu = "profile more menu",
  startCampaignDialog = "start campaign dialog",
  studioTab = "studioTab",
  studioTokensBottomSheet = "studioTokensBottomSheet",
}
