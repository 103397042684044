import { FetchResult, gql, MutationFunctionOptions, useMutation } from "@apollo/client";

import { collabFields } from "@app/common/graphql/fragments.graphql";
import { Collab, UpdateCollabInput } from "@app/common/graphql/generated/schema.graphql";
import { MutationResult } from "@app/common/types/apollo-result.type";

interface Response {
  updateCollab: Collab;
}

interface Variables {
  input: UpdateCollabInput;
  collabId: string;
}

interface UpdateCollabResult extends MutationResult<Response, "updateCollab"> {
  updateCollab: (options?: MutationFunctionOptions<Response, Variables>) => Promise<FetchResult>;
}

const updateCollabMutation = gql`
  mutation UpdateCollab($collabId: CollabId!, $input: UpdateCollabInput!) {
    updateCollab(collabId: $collabId, input: $input) {
      ...CollabFields
    }
  }
  ${collabFields}
`;

export function useUpdateCollab(): UpdateCollabResult {
  const [updateCollab, { loading, error, data }] = useMutation<Response, Variables>(updateCollabMutation);

  return { updateCollab, loading, error, data: data?.updateCollab };
}
