import React, { FC, memo } from "react";

import { useTranslation } from "react-i18next";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

import { Profile } from "@app/common/graphql/generated/schema.graphql";
import { darkTheme } from "@app/common/style/theme";
import { Routes, useNavigation } from "@app/common/types/navigation.type";
import { Chip } from "@app/components/common/chip/chip.component";
import { Image } from "@app/components/common/image/image.component";
import { Text } from "@app/components/common/text/text.component";

import { CreatorName } from "../../creator-name/creator-name.component";

import { styles } from "./profile-list-item.style";

interface Props {
  profile: Profile;
  width: number;
  isFeaturedItem?: boolean;
  style?: StyleProp<ViewStyle>;
}

const profileImagePlaceholder = require("@app/assets/images/profile-image-placeholder.png") as number;

const ProfileListItem: FC<Props> = ({ profile, width, isFeaturedItem, style }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { roundness } = useTheme();

  const handleItemPress = (): void => navigation.navigate(Routes.profile, { id: profile.username });

  return (
    <Pressable onPress={handleItemPress} style={[styles.root, { width }, style]}>
      <View>
        <Image key={profile.userId} source={profile.imageUrl} defaultSource={profileImagePlaceholder} width={width} style={styles.image} />
        {isFeaturedItem && (
          <Chip
            label={t("featured.label")}
            textColor="onTertiaryContainer"
            bgColor="tertiaryContainer"
            textVariant="body2"
            theme={darkTheme}
            style={[styles.chip, { borderBottomRightRadius: roundness }]}
          />
        )}
      </View>

      <View style={styles.content}>
        <CreatorName name={profile.username} badge={profile.badge} textProps={{ variant: "caption", style: styles.username }} />
        <Text variant="caption" color="tertiary">
          {profile.displayName}
        </Text>
      </View>
    </Pressable>
  );
};

export default memo(ProfileListItem);
