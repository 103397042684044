import React, { FC, useCallback } from "react";

import { ImageToBeUsed } from "@app/common/types/image.type";
import { ScreenWrapper } from "@app/components/common/screen-wrapper/screen-wrapper.component";
import { StudioSelectImageSource } from "@app/components/studio-mini/studio-select-image-source/studio-select-image-source.component";
import { useStudioMiniContext } from "@app/context/studio-mini/studio-mini.context";

interface Props {
  onImageSelected: () => void;
  onNavigateToSaved: () => void;
}

export const StudioSelectImageSourceStep: FC<Props> = ({ onImageSelected, onNavigateToSaved }) => {
  const { setGenerationImage } = useStudioMiniContext();

  const handleSetGenerationImage = useCallback(
    (callback: ((prevImages: ImageToBeUsed[]) => ImageToBeUsed[]) | ImageToBeUsed[]): void => {
      if (typeof callback === "function") {
        const newImage = callback([])[0];
        setGenerationImage(newImage);
      } else {
        setGenerationImage(callback[0]);
      }
    },
    [setGenerationImage],
  );

  return (
    <ScreenWrapper>
      <StudioSelectImageSource
        allowMultipleSelection={false}
        setImages={handleSetGenerationImage}
        onImageSelected={onImageSelected}
        onNavigateToSaved={onNavigateToSaved}
      />
    </ScreenWrapper>
  );
};
