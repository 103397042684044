import { createContext, useContext } from "react";

import { Collab } from "@app/common/graphql/generated/schema.graphql";

export interface CampaignContextInterface {
  openStartCampaignDialog: (collab: Collab) => void;
  closeStartCampaignDialog: () => void;
}

export const CampaignContext = createContext<CampaignContextInterface>({
  openStartCampaignDialog: () => undefined,
  closeStartCampaignDialog: () => undefined,
});

export const useCampaignContext = (): CampaignContextInterface => useContext(CampaignContext);
