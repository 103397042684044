import React, { FC } from "react";

import { Stage } from "@app/common/graphql/generated/schema.graphql";
import { useFeaturedCollab } from "@app/hooks/api/products/use-featured-collab.hook";
import { useWindowDimensions } from "@app/hooks/utils/use-window-dimensions.hook";
import { mapFeaturedStage } from "@app/utils/featured.util";

import ProductListItem from "../product-list/product-list-item/product-list-item.component";

import { styles } from "./featured-product.style";

interface Props {
  stage: Stage;
}

export const FeaturedProduct: FC<Props> = ({ stage }) => {
  const { width: windowWidth } = useWindowDimensions();

  const { data: product } = useFeaturedCollab({
    variables: { stage: mapFeaturedStage[stage] },
  });

  if (!product) return null;

  return <ProductListItem product={product} isFeaturedItem width={windowWidth / 2} style={[styles.root, { width: windowWidth }]} />;
};
