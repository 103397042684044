import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  button: {
    justifyContent: "flex-start",
  },
  buttonContainer: {
    flex: 1,
  },
  iconButton: {
    height: 48,
    marginHorizontal: 0,
    margin: 0,
    width: 48,
  },
  row: {
    alignItems: "center",
    columnGap: 8,
    flexDirection: "row",
    flexShrink: 1,
    justifyContent: "space-between",
    width: "100%",
  },
});
